import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconSpeech } from '../../../../../assets/icons/icon-speech.svg';

const SmsIntroductionStep = ({ goToStep }) => {
    return (
        <div className='sms-modal__introduction'>
            <h5 className='sms-modal__title'>Get Text Message Updates</h5>
            <p className='sms-modal__text'>Receive SMS notifications from us when your dog is on the move. We’ll send updates to keep you in the loop on all the details of your furry friend’s location and status.</p>
            <div className='sms-modal__introduction__nav'>
                {/*eslint-disable-next-line*/}
                <a onClick={() => goToStep(1)} className='modal-dialog__success-nav__item sms-modal__introduction__nav-item'>
                    <IconSpeech className='modal-dialog__success-nav__icon'/>
                    <span>Get SMS Updates</span>
                </a>
                {/*eslint-disable-next-line*/}
                <a onClick={() => goToStep(3)}className='sms-modal__introduction__nav-item_text'>Not Now</a>
            </div>
        </div>
    );
};

SmsIntroductionStep.propTypes = {
    goToStep: PropTypes.func.isRequired,
};

export default SmsIntroductionStep;
