import React from 'react';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import { find } from 'lodash';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { buyPackage } from '../../../../../services/packages';
import { setChangePaymentCardModalOpened } from '../../../../../actions/miscActions';
import PaymentField from '../../../../common/Form/PaymentField';

const PackagePurchaseConfirmationStep = ({ handleSubmit, setChangePaymentCardModalOpened, buyPackage, goToStep, invalid, submitting, cardNumberValue, isLoading, submitText, currentPackage, onCancelHandler, formData: { cards }, submitData: { card: cardId }, error }) => { // eslint-disable-line
    const submitButtonClassNames = classNames({
        'button modal-dialog__button modal-dialog__button_customer-order': true,
        'button_disabled': (invalid || submitting || isLoading)
    });
    const card = find(cards, (card) => card.id === cardId);
    const summaryText = currentPackage
        ? `You are purchasing our ${currentPackage.name} which includes ${currentPackage.quantity} class${currentPackage.quantity > 1 ? 'es' : ''}.`
        : 'Loading...';
    return (
        <form
            className='purchase-package__confirmation'
            onSubmit={handleSubmit(() => buyPackage(currentPackage.id, cardId, currentPackage.name))}>
            {error && <div className='form-step__error' key={error}>{error}</div>}
            <div className='purchase-package__confirmation-content'>
                <div className='purchase-package__text-block'>
                    <strong className='purchase-package__text-block__title'>Package</strong>
                    <div className='purchase-package__text-block__content'>{summaryText}</div>
                </div>
                <Field
                    name='payment_card'
                    className='purchase-package__field-container'
                    label='Payment'
                    data={{ card }}
                    handleChange={() => goToStep(2)}
                    component={PaymentField} />
            </div>
            <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                <button
                    className={submitButtonClassNames}
                    type='submit'
                    disabled={submitting || isLoading}>
                    {(submitting || isLoading)
                        ? 'Submitting...'
                        : submitText || 'Next'
                    }
                </button>
                <span className='modal-dialog__cancel-button' onClick={() => onCancelHandler()}>Cancel</span>
            </div>
        </form>
    );
};

const form = reduxForm({
    form: 'PurchasePackageForm',
    pure: false,
    enableReinitialize: true
});

const mapStateToProps = (state, ownProps) => {
    const initialValues = {};
    const tempCard = ownProps.formData.cards || null;
    const cards = state.getIn(['currentCustomer', 'payment_cards', 'cards']).toJS();
    if (tempCard) {
        initialValues.payment_card = tempCard[0].id;
    } else {
        cards.forEach(card => {
            if (card.is_default) {
                initialValues.payment_card = card.id;
            }
        });
    }
    return {
        initialValues: fromJS(initialValues),
        tempPaymentCard: ownProps.formData.cards
    };
};

const mapDispatchToProps = {
    buyPackage,
    setChangePaymentCardModalOpened
};

export default connect(mapStateToProps, mapDispatchToProps)(form(PackagePurchaseConfirmationStep));
