import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { setDefaultInstructorEmployeeData } from '../../../../actions/miscActions';
import renderInputControl from '../../../common/Form/InputControl';
import renderSelectControl from '../../../common/Form/SelectControl';
import renderImageControl from '../../../common/Form/ImageControl';
import { isManager } from '../../../../helpers/userRoles';
import { employeeTypes, permissionsTypes, permissionsTypesManager } from '../../../../helpers/options';
import FormImageUpload from '../../../common/Form/ImageControl/FormImageUpload';

import validate from './validate';
import { phoneNumberNormalizer, PHONE_MASK, PHONE_MASK_CHAR } from '../../../../helpers/normalize';

class InstructorEmployeeModalDialogForm extends React.PureComponent {
    static propTypes = {
        initialValues: PropTypes.shape({}),
        handleSubmit: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool,
        isLoading: PropTypes.bool,
        permissionsList: PropTypes.func,
        edit: PropTypes.bool,
        isFormOpened: PropTypes.bool,
    };

    state = {
        disablePermissions: false,
    };

    componentDidMount() {
        this.props.handleDefaultValues();
    }

    componentWillReceiveProps(newProps) {
        if (newProps.isFormOpened !== this.props.isFormOpened && newProps.isFormOpened === true) {
            this.setState({
                disablePermissions: newProps.initialValues.get('model_type') === 'trainer',
            });
        }
    }

    render() {
        const {
            handleSubmit,
            invalid,
            submitting,
            isLoading,
            edit,
            initialValues,
            isFormOpened,
        } = this.props;
        const photo = initialValues.get('photo');
        const { disablePermissions } = this.state;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': invalid || submitting || isLoading,
        });
        return (
            <form className='modal-dialog__form' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>
                    <div className='form__aligner form__aligner_50-50'>
                        <div className='form__column'>
                            <div className='form__aligner form__aligner_100-0'>
                                <Field
                                    name='model_type'
                                    type='text'
                                    disabled={edit}
                                    component={renderSelectControl}
                                    options={employeeTypes}
                                    label='Employee or Instructor'
                                    onChangeCallBack={value => {
                                        this.setState({
                                            disablePermissions: value.value === 'trainer',
                                        });
                                    }}/>
                            </div>
                            <div className='form__aligner form__aligner_100-0'>
                                <Field name='name' type='text' component={renderInputControl} label='Name' />
                            </div>
                        </div>
                        <div className='form__column'>
                            <Field
                                name='photo'
                                type='file'
                                component={renderImageControl}
                                uploadComponent={FormImageUpload}
                                image={photo}
                                isOpened={isFormOpened}/>
                        </div>
                    </div>
                    <div className='form__aligner form__aligner_50-50'>
                        {disablePermissions
                            ? <Field
                                name='permissions'
                                type='text'
                                component={renderSelectControl}
                                options={isManager() ? permissionsTypesManager : permissionsTypes}
                                label='Permissions'
                                disabled
                                selectedValue='trainer'/>
                            : <Field
                                name='permissions'
                                type='text'
                                component={renderSelectControl}
                                options={isManager() ? permissionsTypesManager : permissionsTypes}
                                label='Permissions'/>}
                        <Field name='position' type='text' component={renderInputControl} label='Position' />
                    </div>
                    <div className='form__aligner form__aligner_50-50'>
                        <Field
                            name='primary_phone'
                            type='text'
                            component={renderInputControl}
                            label='Employee Phone'
                            mask={PHONE_MASK}
                            maskChar={PHONE_MASK_CHAR}
                            isMasked
                            normalize={phoneNumberNormalizer} />
                        <Field
                            name='emergency_name'
                            type='text'
                            component={renderInputControl}
                            label='Emergency Contact'/>
                    </div>
                    <div className='form__aligner form__aligner_50-50'>
                        <Field name='email' type='text' component={renderInputControl} label='Email Address' />
                        <Field
                            name='emergency_phone'
                            type='text'
                            component={renderInputControl}
                            mask={PHONE_MASK}
                            maskChar={PHONE_MASK_CHAR}
                            isMasked
                            normalize={phoneNumberNormalizer}
                            label='Emergency Contact Phone'/>
                    </div>
                    <Field
                        name='qualification'
                        type='text'
                        isTextarea
                        component={renderInputControl}
                        label='Qualified for'/>
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    <button className={submitButtonClassNames} type='submit' disabled={submitting || isLoading}>
                        {submitting || isLoading ? (edit ? 'Updating...' : 'Creating...') : edit ? 'Save' : 'Create'}
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'InstructorEmployeeModalDialogForm',
    enableReinitialize: true,
    validate,
});

const mapStateToProps = state => {
    const instance = state.getIn(['currentEmployee', 'instance']);
    return {
        initialValues: fromJS({
            id: instance.get('id'),
            model_type: instance.get('model_type'),
            name: instance.get('name'),
            permissions: instance.get('permissions'),
            position: instance.get('position'),
            email: instance.get('email'),
            primary_phone: instance.getIn(['primary_phone', 'phone_number']),
            emergency_name: instance.getIn(['emergency_contact', 'name']),
            emergency_phone: instance.getIn(['emergency_contact', 'phone_number']),
            qualification: instance.get('qualification'),
            photo: instance.get('photo') ? instance.get('photo') : '',
            serverData: state.getIn(['currentEmployee', 'initialValues']),
            permissionsList: permissionsTypes,
            date: undefined,
        }),
        isLoading: state.getIn(['misc', 'modals', 'isInstructorEmployeeLoading']),
        permissionsList: () => {
            const permissionsListObject = state.getIn(['directoryList', 'choices']).find(choicesItem => {
                return choicesItem.get('name') === 'Access';
            });

            return permissionsListObject
                ? permissionsListObject.get('items') ? permissionsListObject.get('items').toJS() : []
                : [];
        },
    };
};

const mapDispatchToProps = dispatch => ({
    handleDefaultValues: bindActionCreators(setDefaultInstructorEmployeeData, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(InstructorEmployeeModalDialogForm));
