/* eslint-disable camelcase,prefer-const */
import * as axios from 'axios';
import moment from 'moment';
import { getUserNameSpace } from '../helpers/userRoles';
import { DEFAULT_DATE_FORMAT } from '../reducers/dashboardPageReducer';

export const loadCustomReportData = (args, fileExportType = '') => {
    let {
        type, report, date, date_range: dateRange, trainer, attendance_by_zone_filter,
        attendance_by_zone_filter_extra,
    } = args;
    return dispatch => {
        const reportTypePrefix =
            (type !== 'financial' &&
                report !== 'new_customer_statistics' &&
                report !== 'dog_attendance_statistics' &&
                report !== 'customer-stats-and-demographics' &&
                report !== 'commission')
                ? type + '-'
                : '';

        let nameSpace = null;
        if (report === 'detailed-sales') {
            nameSpace = 'custom-reports';
        } else if (report === 'commission') {
            nameSpace = 'reports.commission';
        } else if (type === 'operational') { 
            nameSpace = 'reports.operational';
        } else if (type === 'marketing') { 
            nameSpace = 'reports.marketing';
        } else if (report === 'trainers-commission') {
            nameSpace = 'reports.trainers-commission';
        } else if (report === 'trainers-invoice') {
            nameSpace = 'reports.trainers-invoice';
        } else {
            nameSpace = 'overview-reports';
        }

        let url = `/v1/${getUserNameSpace(nameSpace)}/reports/${reportTypePrefix}${report}/`;

        if (date && date !== 'None') {
            date = moment.utc(date).format(DEFAULT_DATE_FORMAT);
            url += date + '/';
        } else if (dateRange && dateRange.start !== 'None') {
            const dateStart = moment.utc(dateRange.start).format(DEFAULT_DATE_FORMAT);
            const dateEnd = moment.utc(dateRange.end).format(DEFAULT_DATE_FORMAT);
            url += `${dateStart}/${dateEnd}/`;
        }

        if (trainer && report === 'trainers-invoice') {
            url += trainer.value + '/';
        }

        if (attendance_by_zone_filter && report === 'attendance-by-zone') {
            url += attendance_by_zone_filter.value + '/';

            if (attendance_by_zone_filter.value === 0) {
                url += '0/';
            } else {
                url += attendance_by_zone_filter_extra.value + '/';
            }
        }

        switch (fileExportType) {
            case 'csv': url += '?as_csv=true';
                break;
            case 'pdf': url += '?as_pdf=true';
                break;
            default: break;
        }

        return axios.get(url);
    };
};
