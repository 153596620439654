import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Field, reduxForm } from 'redux-form/immutable';
import { setAddCustomerCardModalOpened } from '../../../../../actions/miscActions';
import AddNewItem from '../../../../common/AddNewItem';
import validate from './validate';

class PaymentChangeStep extends PureComponent {
    state = {
        isPackageSelected: false
    };
    submitStep = (data) => {
        const { submitData: { packageCredit }, addSubmitData, goToStep, nextStep } = this.props;
        const name = this.state.isPackageSelected ? 'packageCredit' : 'card';
        addSubmitData({ name, data: +data.get('card') });
        if (!this.state.isPackageSelected && packageCredit) {
            addSubmitData({ name: 'packageCredit' });
        }
        goToStep(nextStep);
    }

    renderCards = () => {
        const { formData: { cards, classInfo } } = this.props;
        const paymentList = cards;
        if (classInfo && classInfo.packagesAvailable) {
            paymentList.push(...classInfo.packagesAvailable);
        }
        return paymentList.map((paymentItem, index) => (
            <label className='radio-control__controls-label payment-change__radio' key={index}>
                <Field
                    name='card'
                    component='input'
                    type='radio'
                    value={paymentItem.id.toString()}
                    onChange={() => {
                        const type = paymentItem.available_quantity ? 'package' : 'card';
                        this.setState({
                            isPackageSelected: type === 'package'
                        });
                    }}
                    className='radio-control__controls-input'/>
                <div className='radio-control__controls-text payment-change__radio__text'>
                    {!paymentItem.available_quantity && (<p className='payment-change__radio__content'>
                        <strong className='payment-change__radio__title'>{paymentItem.type}</strong> - <span className='payment-change__radio__address'>Ending in {paymentItem.last4}</span>
                    </p>)}
                    {paymentItem.available_quantity && (<p className='payment-change__radio__content'>
                        <strong className='payment-change__radio__title'>{paymentItem.package_detail.name} package credits</strong> - <span className='payment-change__radio__address'>{paymentItem.available_quantity} remaining</span>
                    </p>)}
                </div>
            </label>
        ));
    }

    render() {
        const { handleSubmit, invalid, submitting, formData: { cards },
                isLoading, onCancelHandler, submitText, setAddCustomerCardModalOpened, target } = this.props;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button modal-dialog__button_booking': true,
            'button_disabled': (invalid || submitting || isLoading)
        });
        return (
            <form
                className='modal-dialog__form payment-change'
                onSubmit={handleSubmit(this.submitStep)}>
                <div className='modal-dialog__form-content payment-change__content'>
                    <p className='payment-change__head'>Select a payment method from one of your saved credit cards, or add a new one below.</p>

                    {/* Form Content */}
                    { !!cards.length &&
                        <div>
                            <div className='payment-change__locations-list'>
                                {this.renderCards()}
                            </div>
                        </div>
                    }
                    <AddNewItem
                        className='payment-change__add-btn'
                        text='Add New Credit Card'
                        onClickHandler={() => setAddCustomerCardModalOpened({ target })}/>
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting || isLoading}>
                        {(submitting || isLoading)
                            ? 'Submitting...'
                            : submitText || 'Next'
                        }
                    </button>
                    <span className='modal-dialog__cancel-button' onClick={onCancelHandler}>Cancel</span>
                </div>
            </form>
        );
    }
}

PaymentChangeStep.propTypes = {
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    isLoading: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    submitText: PropTypes.string,
    setAddCustomerCardModalOpened: PropTypes.func,
    formData: PropTypes.shape({}),
    submitData: PropTypes.shape({}),
    addSubmitData: PropTypes.func,
    goToStep: PropTypes.func,
    nextStep: PropTypes.number,
    target: PropTypes.string
};

const form = reduxForm({
    form: 'PaymentChangeForm',
    validate,
    pure: false,
    enableReinitialize: true
});

const mapStateToProps = (state, ownProps) => {
    const { formData, submitData } = ownProps;
    let currentCard = formData.cards[0].id.toString();
    if (!isEmpty(submitData) && 'card' in submitData) {
        currentCard = submitData.card.toString();
    }
    if (currentCard) {
        return {
            initialValues: {
                card: currentCard
            }
        };
    }
    return {};
};

const mapDispatchToProps = {
    setAddCustomerCardModalOpened
};

export default connect(mapStateToProps, mapDispatchToProps)(form(PaymentChangeStep));
