import * as MessagesActions from '../../actions/messagesActions';
import { setGlobalError } from '../errorHandlerActions';
import { setModalSubmitError } from '../modal/modalActions';
/* eslint-disable consistent-return */
import * as ModalOpenActions from '../modal/openActions';

export const handleModalSubmitError = ({ error, formConfig, submitData }) => {
    return dispatch => {
        if (error !== undefined && error.response) {
            let errorField = Object.keys(error.response.data)[0];
            if (error.response.status === 500) {
                return dispatch(setModalSubmitError('Server Error. Something went wrong...'));
            }

            // FIT-2116
            // new stripe server errors case
            if (error.response.data.error_message) {
                return dispatch(setModalSubmitError(error.response.data.error_message));
            }

            let resultError;

            const errorObject = error.response.data[errorField];

            if (Array.isArray(errorObject)) {
                resultError = errorObject.reduce((result, current, i) => {
                    if (i === 0) {
                        return `${result}${current}`;
                    }
                    return `${result}, ${current}`;
                }, '');
            } else if (typeof errorObject === 'string') {
                resultError = errorObject;
            } else {
                const errorKeys = Object.keys(errorObject);

                resultError = errorKeys.reduce((result, current, i) => {
                    let errorText;
                    if (Array.isArray(errorObject[current])) {
                        errorText = errorObject[current].reduce((result, current, i) => {
                            if (i === 0) {
                                return `${result}${current}`;
                            }
                            return `${result}, ${current}`;
                        }, '');
                    }

                    if (errorText !== undefined) {
                        return errorText;
                    }

                    if (i === 0) {
                        return `${result}${errorObject[current]}`;
                    }
                    return `${result}, ${errorObject[current]}`;
                }, '');
                errorField = errorKeys[0];
            }

            if (+errorField === errorField) { // if a number
                errorField = '';
            } else {
                errorField += ': ';
            }

            return dispatch(setModalSubmitError(`${errorField}${resultError}`));
        }
        if (error) {
            let error = true;
            if (error.errors && error.errors._error) {
                error = error.errors._error;
            }
            return dispatch(setModalSubmitError(error));
        }
    };
};

export const handleModalSubmitAllergiesError = ({ error, formConfig, submitData }) => {
    return dispatch => {
        if (error !== undefined && error.response) {
            let errorField = Object.keys(error.response.data)[0];
            if (error.response.status === 500) {
                return dispatch(setModalSubmitError('Server Error. Something went wrong...'));
            }

            // FIT-2116
            // new stripe server errors case
            if (error.response.data.error_message) {
                return dispatch(setModalSubmitError(error.response.data.error_message));
            }

            let resultError;

            const errorObject = error.response.data[errorField];

            if (Array.isArray(errorObject)) {
                resultError = errorObject.reduce((result, current, i) => {
                    if (i === 0) {
                        return `${result}${current}`;
                    }
                    return `${result}, ${current}`;
                }, '');
            } else if (typeof errorObject === 'string') {
                resultError = errorObject;
            } else {
                const errorKeys = Object.keys(errorObject);

                resultError = errorKeys.reduce((result, current, i) => {
                    let errorText;
                    if (Array.isArray(errorObject[current])) {
                        errorText = errorObject[current].reduce((result, current, i) => {
                            if (i === 0) {
                                return `${result}${current}`;
                            }
                            return `${result}, ${current}`;
                        }, '');
                    }

                    if (errorText !== undefined) {
                        return errorText;
                    }

                    if (i === 0) {
                        return `${result}${errorObject[current]}`;
                    }
                    return `${result}, ${errorObject[current]}`;
                }, '');
                errorField = errorKeys[0];
            }

            if (+errorField === errorField) { // if a number
                errorField = '';
            } else {
                errorField += ': ';
            }
            if (errorField === "intolerance_list: " && resultError === "This list may not be empty.") {
                resultError += " Add a comma at the end if you have trouble saving."
                return dispatch(setModalSubmitError(`${errorField}${resultError}`));
            }

            return dispatch(setModalSubmitError(`${errorField}${resultError}`));
        }
        if (error) {
            let error = true;
            if (error.errors && error.errors._error) {
                error = error.errors._error;
            }
            return dispatch(setModalSubmitError(error));
        }
    };
};

export const handleTrainingEventProductPostError = ({ error, formConfig, submitData, formData }) => {
    return dispatch => {
        if (error.response.status !== 409) {
            return dispatch(setGlobalError(error));
        }
        dispatch(ModalOpenActions.openTrainingEventProductCancellationModalDialog({
            type: 'training',
            formConfig,
            submitData,
            formData,
            errorData: error.response.data
        }));
    };
};

export const handleTrainingEventCreateError = ({ error, formConfig, submitData, formData }) => {
    console.log('handleTrainingEventCreateError');
    console.log(error);
    return dispatch => {
        if (error.response.status !== 409) {
            return dispatch(setGlobalError(error));
        }
        dispatch(ModalOpenActions.openTrainingEventProductCancellationModalDialog({
            type: 'new_training',
            formConfig,
            submitData,
            formData,
            errorData: error.response.data
        }));
    };
};

export const handleTrainingEventEditError = ({ error, formConfig, submitData, formData }) => {
    return dispatch => {
        if (error.response.status !== 409) {
            return dispatch(setGlobalError(error));
        }
        dispatch(ModalOpenActions.openEventProductCancellationModalDialog({
            type: 'training',
            formConfig,
            submitData,
            formData,
            errorData: error.response.data
        }));
        console.log('ERROR ACTION: ', error);
    };
};

export const handleClassEventEditError = ({ error, formConfig, submitData, formData }) => {
    return dispatch => {
        if (error.response.status !== 409) {
            return dispatch(setGlobalError(error));
        }
        dispatch(ModalOpenActions.openEventProductCancellationModalDialog({
            type: 'training',
            formConfig,
            submitData,
            formData,
            errorData: error.response.data
        }));
        console.log('ERROR ACTION: ', error);
    };
};

// FIT-1721
export const handleClassScheduleEditError = ({ error, formConfig, submitData, formData }) => {
    return dispatch => {
        const incompatibleErrorText = 'Specified changes are incompatible with subscribed dogs';
        // should it be the only error?
        if (error.response.data.non_field_errors[0] === incompatibleErrorText) {
            dispatch(ModalOpenActions.openEventProductCancellationModalDialog({
                type: 'class-schedule',
                formConfig,
                submitData,
                formData,
                errorData: error.response.data
            }));
        } else {
            dispatch(handleModalSubmitError({ error }));
        }
    };
};

export const handleSentMessageError = ({ error }) => {
    return dispatch => {
        dispatch(MessagesActions.setMessagesFailed());
        return dispatch(setGlobalError(error));
    };
};

export const handleDeleteEmployeeSubmitError = ({ error }) => {
    return dispatch => {
        const { status, data } = error.response;
        if (status !== 409) {
            return dispatch(setGlobalError(error));
        }
        return dispatch(ModalOpenActions.openForceDeleteEmployeeModalDialog(data));
    };
};
