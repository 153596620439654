import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import InfiniteScroll from 'react-infinite-scroller';
import { bindActionCreators } from 'redux';
import AjaxLoader from '../../../common/AjaxLoader';
import NoData from '../../../../components/common/NoData';
import PackagesCard from './PackagesCard';
import { getPackageList, getNextPackageList } from '../../../../services/packages';

class Packages extends React.PureComponent {
    static propTypes = {
        packagesList: PropTypes.arrayOf(PropTypes.shape({})),
        hasMore: PropTypes.bool,
        getPackageList: PropTypes.func,
        getNextData: PropTypes.func,
        isLoading: PropTypes.bool,
        openCustomersTrainingModal: PropTypes.func
    }
    componentDidMount() {
        const { packagesList, getPackageList, isLoading } = this.props;
        if (!packagesList.length && !isLoading) {
            getPackageList('model_type=package&is_paused=false');
        }
    }

    renderPackagesList = () => {
        const { packagesList } = this.props;
        if (!packagesList.length) {
            return (
                <NoData
                    iconType='thinking'
                    title='Sorry, there are no packages' />
            );
        }
        return packagesList.map((packageData, index) => {
            return <PackagesCard key={index} {...packageData} />;
        });
    }
    render() {
        const { hasMore, getNextData, isLoading } = this.props;
        const loader = <AjaxLoader />;
        return (
            <div className='inner-page'>
                <div className='inner-page__container'>
                    <section className='customer-packages'>
                        <h1 className='inner-page__title'>Class packages</h1>
                        <div className="customer-dogs__add">
                            <Link to="/profile" className="add-new-item">
                                <span className="add-new-item__text">See my packages</span>
                            </Link>
                        </div>
                        <div className='customer-packages__list'>
                            <InfiniteScroll
                                className='customer-packages__list-wrapper'
                                pageStart={0}
                                loadMore={() => !isLoading ? getNextData() : null}
                                hasMore={hasMore}
                                loader={loader}>
                                {!isLoading ? this.renderPackagesList() : loader}
                            </InfiniteScroll>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    packagesList: state.getIn(['packageList', 'items']).toJS(),
    hasMore: state.getIn(['packageList', 'nextUrl']) !== null,
    isLoading: state.getIn(['packageList', 'isLoading'])
});
const mapDispatchToProps = (dispatch) => ({
    getPackageList: bindActionCreators(getPackageList, dispatch),
    getNextData: bindActionCreators(getNextPackageList, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Packages);
