import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { getVeterinarianOptions, setVetInfoModalOpened } from '../../../../../actions/miscActions';
import { setDefaultVetData } from '../../../../../actions/onboarding/veterinarianActions';
import { setCurrentRegistrationStep } from '../../../../../actions/onboarding/onBoardingStepsActions';
import renderFileControl from '../../../../common/Form/FileControl';
import { renderWarning } from '../../../../common/Form/RadioControl/RadioControl';
import renderSelectControl from '../../../../common/Form/SelectControl';
import validate from './validate';
import warn from './warn';

class VetInfoForm extends React.PureComponent {
    static propTypes = {
        registrationStep: PropTypes.number,
        updateRegistrationStep: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        onSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
        handleVeterinarianOptions: PropTypes.func,
        veterinarian: PropTypes.shape({}),
        handleDefaultValues: PropTypes.func,
        canContactVeterinarian: PropTypes.string,
        handleVetInfoModalOpened: PropTypes.func,
        veterinarianOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({})
        ]),
        change: PropTypes.func
    };

    componentWillMount() {
        const { veterinarianOptions, handleVeterinarianOptions } = this.props;
        if (!veterinarianOptions.size) {
            handleVeterinarianOptions();
        }
    }

    componentDidMount() {
        const { handleDefaultValues, registrationStep, updateRegistrationStep } = this.props;
        handleDefaultValues();
        const savedRegistrationStep = +localStorage.getItem('registration_step');
        if (savedRegistrationStep && !registrationStep) {
            updateRegistrationStep(savedRegistrationStep);
        }
    }

    componentDidUpdate(prevProps) {
        // if user added new vet, select it automatically
        const { change, veterinarianOptions } = this.props;
        if (prevProps.veterinarianOptions.size &&
            veterinarianOptions.size > prevProps.veterinarianOptions.size) {
            change('veterinarian', veterinarianOptions.toJS()[0]);
        }
    }
    render() {
        // eslint-disable-next-line max-len
        const {
            handleSubmit, submitting, invalid, veterinarian, canContactVeterinarian, handleVetInfoModalOpened,
            veterinarianOptions
        } = this.props;
        const SubmitButtonClassNames = classNames({
            'vet-info__submit-button': true,
            'vet-info__submit-button_disabled': invalid || submitting
        });
        const canContactVeterinarianRadioClassNames = classNames({
            'form__select-aligner': true,
            'form__select-aligner_heighted': canContactVeterinarian === 'Yes'
        });
        return (
            <form onSubmit={handleSubmit} className='vet-info__form'>
                <Field name='can_contact_veterinarian' component={renderWarning}/>
                <div className='vet-info__form-content'>
                    <Field
                        name='veterinarian'
                        type='text'
                        component={renderSelectControl}
                        options={veterinarianOptions.toJS()}
                        label='Who is your veterinarian?'
                        helper='My vet isn’t listed'
                        searchable/>
                        <div className='vet-info__upload-label'>
                            Proof of vaccines is required to participate in activities including Rabies, DHPP, Lepto, Bordetella and Canine Flu.
                        </div>
                        <Field
                            name='records'
                            component={renderFileControl}
                            descriptionTop='Have it handy?'
                            descriptionBottom='Upload your dog’s vaccination records here.'/>
                </div>
                <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>
                    {veterinarian.get('isRecordsLoading')
                        ? 'Loading records...'
                        : 'Last - Complete Registration'
                    }
                </button>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'vetInfoForm',
    destroyOnUnmount: false,
    validate,
    warn
});

const mapStateToProps = state => {
    return {
        veterinarian: state.get('veterinarian'),
        veterinarianOptions: state.getIn(['misc', 'options', 'veterinarian']),
        canContactVeterinarian: state.getIn(['form', 'vetInfoForm', 'values', 'can_contact_veterinarian']),
        initialValues: localStorage.getItem('vetInfoForm') ? JSON.parse(localStorage.getItem('vetInfoForm')) : fromJS({ can_contact_veterinarian: 'Yes' }),
        registrationStep: state.getIn(['steps', 'registration_step'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleDefaultValues: bindActionCreators(setDefaultVetData, dispatch),
    handleVeterinarianOptions: bindActionCreators(getVeterinarianOptions, dispatch),
    handleVetInfoModalOpened: bindActionCreators(setVetInfoModalOpened, dispatch),
    updateRegistrationStep: bindActionCreators(setCurrentRegistrationStep, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(form(VetInfoForm));
