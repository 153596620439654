import { createAction } from 'redux-actions';
import { getDailyNotesData, getSetGlanceInitData } from '../actions/dashboard/dashboardPageActions';
import * as TabTypes from '../types/tabTypes';
import { getAllOverviewData } from './dashboard/overviewActions';
import { isManager, isSuperadmin } from '../helpers/userRoles';

export const setDashboardActivePage = createAction(TabTypes.SET_DASHBOARD_ACTIVE_PAGE);
export const setReportsActivePage = createAction(TabTypes.SET_REPORTS_ACTIVE_PAGE);

export const handlePageChange = ({ nextPageName, params }) => {
    const pageNameMap = {
        glance: {
            setPageAction: setDashboardActivePage,
            path: ['dashboardPage', 'glanceDataAll', 'items'],
            init: getSetGlanceInitData,
        },
        notes: {
            setPageAction: setDashboardActivePage,
            path: ['dashboardPage', 'dailyNotes', 'items'],
            init: getDailyNotesData,
        },
        overview: {
            setPageAction: setReportsActivePage,
            path: ['overview', 'newObjects', 'items'],
            init: (!isManager() && !isSuperadmin()) ? null : getAllOverviewData,
        },
        customReports: {
            setPageAction: setReportsActivePage,
            path: ['customReports', 'trainersInvoice', 'items'],
            init: null,
        }
    };

    return (dispatch, getState) => {
        const path = pageNameMap[nextPageName].path;
        const init = pageNameMap[nextPageName].init;

        if (getState().getIn(path) === undefined && typeof init === 'function') {
            dispatch(init(params));
        }

        dispatch(pageNameMap[nextPageName].setPageAction(nextPageName));
    };
};
