import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Icon from '../../Icon';
import { ReactComponent as IconSpinner } from '../../../../assets/icons/icon-chevron-up.svg';

const renderNumberInputControl = props => {
    const {
        input,
        label,
        meta: { touched, error, valid },
        hint,
        formError,
        className = '',
        disabled,
        onKeyPress,
        maxValue,
        minValue,
        prefix,
        suffix,
        float = false,
        disableSpinner = false,
    } = props;
    const InputContainerClassNames = 'input-control';
    const InputClassNames = classNames({
        'input-control__input': true,
        'input-control__input_touched': touched,
        'input-control__input_failed': (touched && error) || formError,
        'input-control__input_empty': input.value.length === 0 && !(touched && error),
    });
    const PlaceholderClassNames = classNames({
        'input-control__placeholder': true,
        'input-control__placeholder_touched': touched || valid,
        'input-control__placeholder_failed': touched && error,
        'input-control__placeholder_empty': input.value.length === 0 && !(touched && error),
    });
    const SpinnerClassNames = classNames({
        'input-control__number-spinner': true,
        'input-control__number-spinner_max': maxValue !== undefined ? maxValue <= parseInt(input.value, 10) : false,
        'input-control__number-spinner_min': minValue !== undefined ? minValue >= parseInt(input.value, 10) : false,
    });

    const removeSymbols = value => {
        let inputValue = value.toString();

        if (prefix !== undefined) {
            let regString = prefix;
            if (prefix === '$' || prefix === '%') {
                regString = '\\' + prefix;
            }
            inputValue = inputValue.replace(new RegExp(regString, 'gi'), '');
        }

        if (suffix !== undefined) {
            let regString = prefix;
            if (suffix === '$' || suffix === '%') {
                regString = '\\' + suffix;
            }
            inputValue = inputValue.replace(new RegExp(regString, 'gi'), '');
        }

        return inputValue;
    };

    const setInputValue = value => {
        let inputValue = removeSymbols(value);

        if (inputValue && inputValue.length > 0) {
            if (prefix !== undefined) {
                inputValue = prefix + inputValue;
            }

            if (suffix !== undefined) {
                inputValue += suffix;
            }
        }

        return inputValue;
    };
    return (
        <div className={`${InputContainerClassNames} ${className}`}>
            <input
                {...input}
                type={'text'}
                disabled={disabled}
                className={InputClassNames}
                value={setInputValue(input.value)}
                onKeyPress={event => {
                    console.log(event.which);
                    if (
                        (event.which >= 48 && event.which <= 57) ||
                        (event.which === 45 && minValue < 0) ||
                        (event.which === 46 && float)
                    ) {
                        const { target } = event;

                        if (onKeyPress) {
                            onKeyPress(event);
                        }

                        if (event.which === 46) {
                            const valueArray = target.value.toString().split('.');

                            if (valueArray.length > 1) {
                                event.preventDefault();
                            }
                        }
                    } else {
                        event.preventDefault();
                    }
                }}
                onChange={event => {
                    const { target } = event;
                    let inputValue = removeSymbols(target.value);
                    if (inputValue !== '-') {
                        inputValue = !float
                            ? parseInt(inputValue, 10)
                            : (() => {
                                  const valueArray = inputValue.toString().split('.');
                                  if (
                                      valueArray.length <= 1 ||
                                      (valueArray.length === 2 && valueArray[0].length > 0 && valueArray[1].length > 0)
                                  ) {
                                      return parseInt(parseFloat(inputValue, 10) * 100, 10) / 100;
                                  }

                                  return inputValue;
                              })();
                        if (typeof inputValue === 'number') {
                            if (isNaN(inputValue)) {
                                inputValue = '';
                            } else {
                                if (maxValue !== undefined) {
                                    if (inputValue > maxValue) {
                                        inputValue = maxValue;
                                    }
                                }
                                if (minValue !== undefined) {
                                    if (inputValue < minValue) {
                                        inputValue = minValue;
                                    }
                                }
                            }
                        }
                    }
                    target.value = setInputValue(inputValue.toString());
                    if (target.selectionStart === 0 && target.value.length > 0 && prefix !== undefined) {
                        target.selectionStart = 1;
                        target.selectionEnd = 1;
                    }
                    if (target.selectionStart === target.value.length && suffix !== undefined) {
                        if (target.selectionStart > 0) {
                            target.selectionStart -= 1;
                        }
                        if (target.selectionEnd > 0) {
                            target.selectionEnd -= 1;
                        }
                    }
                    input.onChange(inputValue.toString());
                }}/>
            <span className={PlaceholderClassNames}>{label}</span>
            {!disableSpinner && (
                <div className={SpinnerClassNames}>
                    <div
                        className='input-control__arrow input-control__arrow_up'
                        onClick={() => {
                            const currentValue = parseInt(removeSymbols(input.value), 10);
                            if (isNaN(currentValue)) {
                                let numberValue = '0';
                                if (minValue) {
                                    numberValue = minValue.toString();
                                }
                                input.onChange(numberValue);
                            } else if (maxValue !== undefined) {
                                if (currentValue < maxValue) {
                                    const numberValue = (currentValue + 1).toString();
                                    input.value = setInputValue(numberValue);
                                    input.onChange(numberValue);
                                }
                            } else {
                                const numberValue = (currentValue + 1).toString();
                                input.value = setInputValue(numberValue);
                                input.onChange(numberValue);
                            }
                        }}
                        onMouseDown={event => {
                            // blocking text selection on quick mouse clicking
                            event.preventDefault();
                        }}>
                        <Icon glyph={IconSpinner} className='icon-chevron-up' />
                    </div>
                    <div
                        className='input-control__arrow input-control__arrow_down'
                        onClick={() => {
                            const currentValue = parseInt(removeSymbols(input.value), 10);
                            if (isNaN(currentValue)) {
                                let numberValue = '0';
                                if (minValue) {
                                    numberValue = minValue.toString();
                                }
                                input.value = setInputValue(numberValue);
                                input.onChange(numberValue);
                            } else if (minValue !== undefined) {
                                if (currentValue > minValue) {
                                    const numberValue = (currentValue - 1).toString();
                                    input.value = setInputValue(numberValue);
                                    input.onChange(numberValue);
                                }
                            } else {
                                const numberValue = (currentValue - 1).toString();
                                input.value = setInputValue(numberValue);
                                input.onChange(numberValue);
                            }
                        }}
                        onMouseDown={event => {
                            // blocking text selection on quick mouse clicking
                            event.preventDefault();
                        }}>
                        <Icon glyph={IconSpinner} className='icon-chevron-up' />
                    </div>
                </div>
            )}

            {touched && error && <span className='input-control__error'>{error}</span>}
            {hint && <span className='input-control__hint'>{hint}</span>}
        </div>
    );
};

renderNumberInputControl.propTypes = {
    name: PropTypes.string,
    input: PropTypes.shape({}),
    label: PropTypes.string,
    type: PropTypes.string,
    meta: PropTypes.shape({}),
    hint: PropTypes.string,
    formError: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onKeyPress: PropTypes.func,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    prefix: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    suffix: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    float: PropTypes.bool,
    disableSpinner: PropTypes.bool,
};

export default renderNumberInputControl;
