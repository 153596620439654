import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import ContentBlock from '../../../../../common/ContentBlock';
import * as OpenActions from '../../../../../../actions/modal/openActions';
import { uploadImmunization } from '../../../../../../actions/customer/immunizationActions';
import FileUploader from '../../../../../common/FileUploader';
import { ReactComponent as IconCancel } from '../../../../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckmark } from '../../../../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconExclamation } from '../../../../../../assets/icons/icon-exclamation.svg';

const getIcon = (status) => {
  const icons = {
    checkmark: IconCheckmark,
    exclamation: IconExclamation,
    cancel: IconCancel,
  };
  const iconClass =
    status === 'cancel' ? 'icon_immunisation-cancel' : `icon_${status}`;
  const Icon = icons[status];
  return <Icon className={`icon ${iconClass}`} />;
};

const DogInfo = (props) => {
  const {
    dog,
    immunizationOptions,
    handleUploadImmunization,
  } = props;
  
  const renderImmunizationsTable = () => {
    const vaccinationRecords = dog.vaccination_records.items;

    return (
      <div className='immunizations-table'>
        <div className='immunizations-table__row immunizations-table__row_header'>
          <div className='immunizations-table__col'>Type</div>
          <div className='immunizations-table__col'>Exp Date</div>
        </div>
        {vaccinationRecords.length
          ? vaccinationRecords.map((record, index) => {
              const daysLeft = moment(record.expiration_date).diff(
                moment(),
                'days'
              );
              const exp = moment(record.expiration_date).format('MM/DD/YY');
              let status = 'cancel';
              if (daysLeft > 0 && daysLeft <= 30) {
                status = 'exclamation';
              } else if (daysLeft > 30) {
                status = 'checkmark';
              }

              const statusClass = classNames({
                'immunizations-table__col': true,
                'immunizations-table__col_cancel': status === 'cancel',
                'immunizations-table__col_exclamation':
                  status === 'exclamation',
                'immunizations-table__col_checkmark': status === 'checkmark',
              });
              return (
                <div
                  className='immunizations-table__row'
                  key={`immunizations-table__row_${index}`}
                >
                  <div className='immunizations-table__col'>
                    {record.immunization_type_detail}
                  </div>
                  <div className={statusClass}>
                    {getIcon(status)}
                    {exp}
                  </div>
                </div>
              );
            })
          : immunizationOptions.map((option, index) => {
              return (
                <div
                  className='immunizations-table__row'
                  key={`immunizations-table__row_${index}`}
                >
                  <div className='immunizations-table__col'>{option.label}</div>
                  <div className='immunizations-table__col immunizations-table__col_cancel'>
                    {getIcon('cancel')}
                    None
                  </div>
                </div>
              );
            })}
      </div>
    );
  };
  
  const items = [
    {
      content: (

          <FileUploader uploadAction={handleUploadImmunization} />

      ),
      column: true,
      noEdit: true
    },
  ];

  return (
    <ContentBlock
      title='Vaccinations'
      items={items}
      additionalBlock={renderImmunizationsTable()}
    />
  );
};

DogInfo.propTypes = {
  dog: PropTypes.shape({}),
  immunizationOptions: PropTypes.arrayOf(PropTypes.shape({})),
  veterinarianOptions: PropTypes.arrayOf(PropTypes.shape({})),
  openEditMedicalInfoModalDialog: PropTypes.func,
  handleUploadImmunization: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    immunizationOptions: state
      .getIn(['misc', 'options', 'immunization'])
      .toJS(),
    veterinarianOptions: state
      .getIn(['misc', 'options', 'veterinarian'])
      .toJS(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openEditMedicalInfoModalDialog: bindActionCreators(
    OpenActions.openEditMedicalInfoModalDialog,
    dispatch
  ),
  handleUploadImmunization: bindActionCreators(uploadImmunization, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DogInfo);
