import moment from 'moment';

const getStateFromProps = props => {
    let month;
    if (props.value) {
        const m = moment(props.value, props.format, true);
        if (m.isValid()) {
            month = m.toDate();
        } else {
            month = moment().toDate();
        }
    } else {
        month = props.dayPickerProps.initialMonth || props.dayPickerProps.month || new Date();
    }

    return {
        value: props.value,
        month,
    };
};

function componentWillReceiveProps(nextProps) {
    const { month = moment().toDate(), value } = this.state;
    const hasDifferentValue = nextProps.value !== value;

    const shouldDisplayAnotherMonth =
        nextProps.dayPickerProps &&
        nextProps.dayPickerProps.month &&
        (nextProps.dayPickerProps.month.getFullYear() !== month.getFullYear() ||
            nextProps.dayPickerProps.month.getMonth() !== month.getMonth());

    if (hasDifferentValue) {
        this.setState(getStateFromProps(nextProps));
    } else if (shouldDisplayAnotherMonth) {
        this.setState({
            month: nextProps.dayPickerProps.month,
        });
    }
}

export const datePickerRef = datePickerInstance => {
    if (datePickerInstance) {
        const originalHandleChange = datePickerInstance.handleChange;
        const originalHandleFocus = datePickerInstance.handleFocus;
        const originalHandleBlur = datePickerInstance.handleBlur;

        datePickerInstance.componentWillReceiveProps = (...args) => {
            componentWillReceiveProps.apply(datePickerInstance, args);
        };
        datePickerInstance.handleChange = event => {
            const { target } = event;
            const dateArray = target.value.split('/');

            if (dateArray.length === 1 && dateArray[0].length >= 6) {
                if (moment(target.value, 'MM/DD/YY').isValid()) {
                    target.value = moment(target.value, 'MM/DD/YY').format('MM/DD/YY');
                }
            }
            originalHandleChange.call(datePickerInstance, event);
        };
        datePickerInstance.toggleDayPicker = e => {
            if (datePickerInstance.state.showOverlay) {
                originalHandleBlur.call(datePickerInstance, e);
            }
            if (!datePickerInstance.state.showOverlay) {
                originalHandleFocus.call(datePickerInstance, e);
            }
        };
    }

    return datePickerInstance;
};

const DatePickerCustom = {
    datePickerRef,
};

export default DatePickerCustom;
