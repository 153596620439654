/* eslint-disable camelcase */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { ReactComponent as IconCancel } from '../../../../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckMark } from '../../../../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconChevron } from '../../../../../../assets/icons/icon-chevron-table.svg';
import { ReactComponent as IconDots } from '../../../../../../assets/icons/icon-dots.svg';
import { formatDateTimeToAMPM, formatDateToSlashes } from '../../../../../../helpers/date';
import { createClassesDropdownItems, shouldDisplayEventProductDropdown } from '../../../../../../helpers/dropdownItems';
import ClassCard from '../../../../../common/ClassCard';
import Dropdown from '../../../../../common/Dropdown';
import AttendedIcon from '../../../../../common/AttendedIcon';
import Icon from '../../../../../common/Icon/Icon';
import { isMobile } from '../../../../../../helpers/device';

const ClassesContent = (props) => {
    const {
        data,
        isLoading,
        isPrevious,
        classNameModifier = '',
        isCollapsed,
        collapseAction,
        dataName,
        classesActions,
        pageSize,
        pages,
        nextUrl,
        getNext
    } = props;

    const tableDataRef = React.useRef({ 
        isCollapsed, 
        isLoading, 
        nextUrl: Boolean(nextUrl), 
        getNext: Boolean(getNext) 
    });
    
    const columns = [
        {
            id: 'classDate',
            Header: 'Date',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.getIn(['class_occurrence_detail', 'date']),
            Cell: row => formatDateToSlashes(row.value, true),
        },
        {
            id: 'classType',
            Header: 'Type',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.getIn(['class_occurrence_detail', 'training_class_detail', 'name']),
        },
        {
            id: 'classZone',
            Header: 'Neighborhood',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            minWidth: 130,
            accessor: d => {
                const location_detail = d.getIn(['class_occurrence_detail', 'location_detail']);
                const location_array = d.getIn(['class_occurrence_detail', 'location_array']);
                const location_array_detail = d.getIn(['class_occurrence_detail', 'location_array_detail']);

                if (location_array && location_array.size) {
                    const detail = location_array_detail.toJS();
                    return Object.keys(detail).map((key) => detail[key]).join(', ');
                }

                return location_detail;
            },
        },
        {
            id: 'classTrainer',
            Header: 'Trainer',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.getIn(['class_occurrence_detail', 'training_class_detail', 'trainer_detail', 'full_name']),
        },
        {
            id: 'classDog',
            Header: 'Dog',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            accessor: d => d.getIn(['dog_detail', 'name']),
        },
        {
            id: 'classPickupDropoff',
            Header: props => (
                <div>
                    <div>Pickup /</div>
                    <div>Dropoff</div>
                </div>
            ),
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            width: 180,
            accessor: d => d,
            Cell: row => (
                <div className='td__pickup-dropoff'>
                    <div className='pickup__location'>{row.value.getIn(['pickup_location_detail', 'name'])}</div>
                    <div className='pickup__time'>
                        {`${formatDateTimeToAMPM(
                            row.value.getIn(['class_occurrence_detail', 'date']),
                            row.value.getIn(['class_occurrence_detail', 'pickup_start_time']))} `}
                        -
                        {` ${formatDateTimeToAMPM(
                            row.value.getIn(['class_occurrence_detail', 'date']),
                            row.value.getIn(['class_occurrence_detail', 'pickup_end_time']))}`}
                    </div>
                    <div className='dropoff__location'>{row.value.getIn(['drop_off_location_detail', 'name'])}</div>
                    <div className='dropoff__time'>
                        {`${formatDateTimeToAMPM(
                            row.value.getIn(['class_occurrence_detail', 'date']),
                            row.value.getIn(['class_occurrence_detail', 'dropoff_start_time']))} `}
                        -
                        {` ${formatDateTimeToAMPM(
                            row.value.getIn(['class_occurrence_detail', 'date']),
                            row.value.getIn(['class_occurrence_detail', 'dropoff_end_time']))}`}
                    </div>
                </div>
            ),
        },
        {
            id: 'classAttendedOrCancelled',
            Header: props => (
                <div>
                    <div>Attended /</div>
                    <div>Cancelled</div>
                </div>
            ),
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            width: 110,
            accessor: d => d.get('extended_status'),
            Cell: row => (
                <AttendedIcon status={row.value}/>
            ),
        },
        {
            id: 'classReportCard',
            Header: 'Snapshot',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            width: 120,
            accessor: d => d.get('reports'),
            Cell: row => (
                <div>
                    {row.value.size
                        ? <Icon glyph={IconCheckMark} className='icon_check-mark'/>
                        : <Icon glyph={IconCancel} className='icon_cancel'/>
                    }
                </div>
            ),
        },
        {
            id: 'classEdit',
            Header: '',
            headerClassName: 'classes-table__th',
            className: 'classes-table__td',
            width: 50,
            accessor: d => d,
            Cell: row => {
                if (isPrevious && !shouldDisplayEventProductDropdown(row.value.toJS())) {
                    return null;
                }

                return (
                    <Dropdown
                        data={row.value}
                        classNames='dropdown_table'
                        icon={IconDots}
                        iconClass='icon_chevron_down'
                        items={createClassesDropdownItems({
                            classesType: dataName, data: row.value, actions: classesActions, context: 'customers',
                        })}/>
                );
            },
        },
    ];

    const ClassesTableContentClassNames = classNames({
        'classes-table__content': true,
        '-collapsed': isCollapsed,
    });
    const ClassesTableClassNames = classNames({
        'classes-table__table': true,
        '-collapsed': isCollapsed, 
        'classes-table__no-data': !data.size
    });
    const ClassesGridClassNames = classNames({
        'classes-table__grid': true,
        '-collapsed': isCollapsed,
        'classes-table__no-data': !data.size
    });
    const collapseIconClassNames = classNames({
        'icon_chevron-table': true,
        'icon_chevron-table_collapsed': isCollapsed,
    });

    const tableScrollListener = (element) => {
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const offsetHeight = element.offsetHeight;
        const contentHeight = scrollHeight - offsetHeight;

        if (contentHeight <= scrollTop 
            && tableDataRef.current.getNext 
            && tableDataRef.current.nextUrl 
            && !tableDataRef.current.isLoading 
            && !tableDataRef.current.isCollapsed
        ) {
            getNext();
        }
    };

    const pageScrollListener = (element) => {
        const windowScrollHeight = window.pageYOffset + window.innerHeight;
        const tableScrollTop = element.getBoundingClientRect().top + window.pageYOffset;
        const tableOffsetHeight = element.offsetHeight;

        if (windowScrollHeight >= tableScrollTop + tableOffsetHeight 
            && tableDataRef.current.getNext 
            && tableDataRef.current.nextUrl 
            && !tableDataRef.current.isLoading 
            && !tableDataRef.current.isCollapsed
        ) {
            getNext();
        }
    };

    React.useEffect(() => {
        tableDataRef.current = { 
            isLoading, 
            isCollapsed, 
            nextUrl: Boolean(nextUrl),
            getNext: Boolean(getNext)
        };
    });

    React.useEffect(() => {
        const tableEl = document.getElementsByClassName(`classes-table ${classNameModifier}`);

        if (getNext) {
            if (isMobile) {
                const bodyElement = tableEl[0].getElementsByClassName('classes-table__grid');
                if (bodyElement[0] !== undefined) {
                    document.addEventListener('scroll', () => pageScrollListener(bodyElement[0]));
                }
            } else {
                const bodyElement = tableEl[0].getElementsByClassName('classes-table__table');
                if (bodyElement[0] !== undefined) {
                    bodyElement[0].addEventListener('scroll', () => tableScrollListener(bodyElement[0]));
                }
            }
        }

        return () => {
            if (getNext) {
                if (isMobile) {
                    const bodyElement = tableEl[0].getElementsByClassName('classes-table__grid');
                    if (bodyElement[0] !== undefined) {
                        document.removeEventListener('scroll', () => pageScrollListener(bodyElement[0]));
                    }
                } else {
                    const bodyElement = tableEl[0].getElementsByClassName('classes-table__table');
                    if (bodyElement[0] !== undefined) {
                        bodyElement[0].removeEventListener('scroll', () => tableScrollListener(bodyElement[0]));
                    }
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`classes-table ${classNameModifier}`}>
            <div className='classes-table__header' onClick={collapseAction}>
                <div className='classes-table__header-content'>
                    {dataName}
                    <Icon glyph={IconChevron} className={collapseIconClassNames} />
                </div>
            </div>
            <div className={ClassesTableContentClassNames}>
                <ReactTable
                    className={ClassesTableClassNames}
                    manual={true}
                    showPagination={false}
                    defaultPageSize={data.size}
                    sortable={false}
                    data={data}
                    pageSize={pageSize}
                    pages={pages}
                    columns={columns}
                />
                <div className={ClassesGridClassNames}>
                    {data.map(classData => {
                        return (<ClassCard
                            key={`class_${classData.get('id')}`}
                            classData={classData.toJS()}
                            isPrevious={isPrevious}
                            classActions={createClassesDropdownItems({
                                classesType: dataName,
                                data: classData,
                                actions: classesActions,
                                context: 'customers',
                            })} />);
                    })}
                </div>
                {!data.size ? 
                    (<div className='classes-table__empty-content'>
                        {!isLoading ? `No data for ${dataName}` : null}
                    </div>) : null}
                {isLoading ? 
                    (<div className='classes-table__loading-content'>
                        {`Loading...`}
                    </div>) : null}
            </div>
        </div>
    );
};

ClassesContent.propTypes = {
    data: PropTypes.shape({}).isRequired,
    isLoading: PropTypes.bool,
    isPrevious: PropTypes.bool,
    classNameModifier: PropTypes.string,
    isCollapsed: PropTypes.bool,
    collapseAction: PropTypes.func,
    dataName: PropTypes.string.isRequired,
    classesActions: PropTypes.shape({}),
    pageSize: PropTypes.number,
    pages: PropTypes.number,
    nextUrl: PropTypes.string,
    getNext: PropTypes.func,
};

export default ClassesContent;
