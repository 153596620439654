import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconPdf } from '../../../../../assets/icons/icon-pdf.svg';
import Icon from '../../../../common/Icon';

const ExportReportTo = ({ exportReportTo, filterValues }) => {
    return (
       <div className='custom-reports__wrapper'>
        <div
            className='custom-reports__export-report-to'
            onClick={() => exportReportTo(filterValues, 'csv')}>
            <div className='export-report-to__text'>Export to Excel:</div>
            <Icon glyph={IconPdf} className='icon_pdf'/>
        </div>
        <div
          className='custom-reports__export-report-to'
          onClick={() => exportReportTo(filterValues, 'pdf')}>
            <div className='export-report-to__text'>Export to PDF:</div>
            <Icon glyph={IconPdf} className='icon_pdf'/>
        </div>
       </div>
    );
};

ExportReportTo.propTypes = {
    exportReportTo: PropTypes.func.isRequired,
    filterValues: PropTypes.shape({}).isRequired,
};

export default ExportReportTo;
