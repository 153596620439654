/* eslint-disable react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { reduxForm } from 'redux-form/immutable';
import { setEmergencyContactDefaultData } from '../../../../actions/miscActions';
import AddButton from '../../../common/AddButton';
import ModalDialogFormBlock from '../../../common/ModalDialogFormBlock';
import validate from './validate';

class EmergencyContactModalDialogForm extends React.PureComponent {
    static propTypes = {
        initialValues: PropTypes.shape({}),
        handleSubmit: PropTypes.func,
        handleDefaultValues: PropTypes.func,
        invalid: PropTypes.bool,
        submitting: PropTypes.bool,
        emergencyContact: PropTypes.arrayOf(PropTypes.shape({})),
        customersName: PropTypes.string,
        changeFieldValue: PropTypes.func
    };

    state = {
        emergencyContact: {},
        emergencyContactBlockCount: 0,
        areExternalEmergencyContactLoaded: false
    };

    componentDidMount() {
        this.props.handleDefaultValues();
    }

    componentWillReceiveProps(nextProps) {
        const { areExternalEmergencyContactLoaded } = this.state;
        if (nextProps.emergencyContact.length && !areExternalEmergencyContactLoaded) {
            const emergencyContact = { ...this.state.emergencyContact };
            const emergencyContactAmount = nextProps.emergencyContact.length;

            nextProps.emergencyContact.forEach((contact, i) => {
                emergencyContact[i] = contact;
            });

            this.setState({
                emergencyContact,
                emergencyContactBlockCount: emergencyContactAmount,
                areExternalEmergencyContactLoaded: true
            });
        }

        // setting areExternalEmergencyContactLoaded flag to false and empty emergencyContact
        // to reinitiate the form with the actual additional owner from external store
        if (areExternalEmergencyContactLoaded && !nextProps.isEmergencyContactModalOpen) {
            this.setState({
                emergencyContact: {},
                additionalOwnersBlockCount: 0,
                areExternalEmergencyContactLoaded: false
            });
        }
    }

    addAnotherContact = (contact) => {
        const { emergencyContactBlockCount } = this.state;
        const emergencyContact = { ...this.state.emergencyContact };
        emergencyContact[emergencyContactBlockCount] = contact;
        this.setState({
            emergencyContact,
            emergencyContactBlockCount: this.state.emergencyContactBlockCount + 1
        });
    };

    deleteContact = (contactKey) => {
        const emergencyContact = { ...this.state.emergencyContact };
        this.props.changeFieldValue(`${contactKey}.name`, 'deleted');
        this.props.changeFieldValue(`${contactKey}.relationship`, '');
        this.props.changeFieldValue(`${contactKey}.phone_number`, '');
        delete emergencyContact[contactKey];
        this.setState({ emergencyContact });
    };

    render() {
        const { handleSubmit, invalid, submitting, customersName } = this.props;
        const { emergencyContact } = this.state;
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'button_disabled': (invalid || submitting)
        });
        const EmergencyContactFields = [
            { name: 'name', label: 'Name' },
            { name: 'relationship', label: 'Relationship' },
            { name: 'phone_number', label: 'Phone Number' }
        ];
        const emergencyContactCount = Object.keys(emergencyContact).length;
        return (
            <form className='modal-dialog__form' onSubmit={handleSubmit}>
                <div className='modal-dialog__form-content'>
                    {emergencyContactCount
                        ? (Object.keys(emergencyContact).map((contactKey) => {
                            return (
                                <ModalDialogFormBlock
                                    key={`form-block_contact_${contactKey}`}
                                    index={+contactKey}
                                    entityName='Contact'
                                    blockName='Emergency Contact'
                                    deleteBlockHandler={this.deleteContact}
                                    blockFields={EmergencyContactFields}/>
                            );
                        }))
                        : (<div className='modal-dialog__form-title modal-dialog__form-title_empty'>
                            No Emergency Contacts for {customersName}
                        </div>)
                    }
                </div>
                <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                    <AddButton
                        text='Add Emergency Contact'
                        className='modal-dialog__add-button'
                        onClickHandler={() => this.addAnotherContact({ name: '', relationship: '', phone_number: '' })}
                        disabled={submitting}/>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting}>
                        {submitting
                            ? 'Updating...'
                            : 'Save'
                        }
                    </button>
                </div>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'EmergencyContactModalDialogForm',
    enableReinitialize: true,
    validate
});

const mapStateToProps = state => {
    const emergencyContact = state.getIn(['currentCustomer', 'initialInstance', 'emergency_contacts']).toJS();
    const initialValues = emergencyContact.reduce((initialValues, contact, i) => {
        Object.keys(contact).map((key) => {
            if (key === 'phone') {
                contact[`${key}_number`] = contact[key];
            }
            return key;
        });
        initialValues[i] = contact;
        return initialValues;
    }, {});

    return {
        isEmergencyContactModalOpen: state.getIn(['misc', 'modals', 'emergencyContact']),
        emergencyContact,
        initialValues
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleDefaultValues: bindActionCreators(setEmergencyContactDefaultData, dispatch),
    // eslint-disable-next-line object-shorthand
    changeFieldValue: function (field, value) {
        dispatch(change('EmergencyContactModalDialogForm', field, value));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(form(EmergencyContactModalDialogForm));
