import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../common/Icon/';
import { ReactComponent as IconEdit } from '../../../../../../assets/icons/icon-edit-customer.svg';
import moment from "moment";

const AddressHeader = ({ title, subtitle, startDay }) => {
    return (
        <div className='address-header'>
            <div className='address-header__title'>{title}</div>
            <div className='address-header__subtitle'>{subtitle}</div>
            <div className='address-header__edit'>
                {  startDay === moment().format('Do') && Number(moment().format('H')) >= 7
                  ? <Icon glyph={IconEdit} className='address-header__icon-edit address-header__icon-edit--not-allowed' />
                  : <Icon glyph={IconEdit} className='address-header__icon-edit' />
                }
            </div>
        </div>
    );
};

AddressHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    startDay: PropTypes.string,
};

export default AddressHeader;
