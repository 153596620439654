import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import AddNewItem from '../../../../../common/AddNewItem/';
import { ReactComponent as IconEdit } from '../../../../../../assets/icons/icon-edit-customer.svg';
import { ReactComponent as IconTrash } from '../../../../../../assets/icons/icon-delete.svg';
import Icon from '../../../../../common/Icon';
import { getLocationString } from '../../../../../../helpers/normalizeScheduleEvent';
import { setAddAddressModalOpened, setModalValues } from '../../../../../../actions/miscActions';
import { editCustomersAvalibleAddress } from '../../../../../../actions/dashboard/availableAddressesActions';
import { openDeleteAvailableAddressesModalDialog } from '../../../../../../actions/modal/openActions';
import { setAsDefaultPickupAddress, setAsDefaultDropoffAddress } from '../../../../../../actions/customer/CustomerActions';

const ProfileAddresses = ({
    customerData,
    setAddAddressModalOpened,
    setModalValues,
    editCustomersAvalibleAddress,
    openDeleteAvailableAddressesModalDialog,
    setAsDefaultPickupAddress,
    setAsDefaultDropoffAddress
}) => {
    const addresses = customerData.available_addresses;
    const defaultPickup = customerData.pickup_location;
    const defaultDropoff = customerData.drop_off_location;

    const renderActions = (address) => {
        return (
            <div className='profile-addresses__actions change-actions'>
                <div
                    className='change-actions__item'
                    onClick={() => {
                        setAddAddressModalOpened({
                            title: 'Edit Location',
                            subtitle: 'Edit the address for us to use when we pickup or dropoff your dog.',
                            buttonText: 'Save',
                            submitAction: (formData) => {
                                return editCustomersAvalibleAddress({
                                    id: address.id,
                                    ...formData,
                                });
                            }
                        });
                        setModalValues('AddModalDialogForm', address);
                    }}>
                    <Icon glyph={IconEdit} className='change-actions__icon-edit' /> Edit
                </div>
                <div
                    className='change-actions__item'
                    onClick={() => {
                        openDeleteAvailableAddressesModalDialog(address);
                    }}>
                    <Icon glyph={IconTrash} className='change-actions__icon-delete' /> Delete
                </div>
            </div>
        );
    };

    const renderAdresses = () => {
        return addresses.map((address, index) => {
            const isPickupDefault = address.id === defaultPickup;
            const isDropoffDefault = address.id === defaultDropoff;
            const setItemPickupClass = classNames({
                'link-switcher__item': true,
                'link-switcher__item_active': isPickupDefault
            });
            const setItemDropoffClass = classNames({
                'link-switcher__item': true,
                'link-switcher__item_active': isDropoffDefault
            });
            return (
                <div className='profile-addresses__item' key={`address_${index}`}>
                    <div className='profile-addresses__content'>
                        <div className='profile-addresses__title'>{address.name}</div>
                        <div className='profile-addresses__address'>{getLocationString(address)}</div>
                        <div className='profile-addresses__set link-switcher'>
                            {/*eslint-disable-next-line*/}
                            <a
                                href={''}
                                role="button"
                                className={setItemPickupClass}
                                onClick={() => {
                                    if (!isPickupDefault) setAsDefaultPickupAddress(address.id);
                                }}>
                                {!isPickupDefault ? 'Set as d' : 'D'}efault pick up location
                            </a>
                            <div className='profile-addresses__set__divider' />
                            {/*eslint-disable-next-line*/}
                            <a
                              href={''}
                              className={setItemDropoffClass}
                                onClick={() => {
                                    if (!isDropoffDefault) setAsDefaultDropoffAddress(address.id);
                                }}>
                                {!isDropoffDefault ? 'Set as d' : 'D'}efault drop off location
                            </a>
                        </div>
                    </div>
                    {address.id !== 1 && renderActions(address)}
                </div>
            );
        });
    };

    return (
        <div className='content-block profile-addresses'>
            <div className='content-block__header'>
                <div className='content-block__header-text'>Pick up and drop off locations</div>
            </div>
            <div className='profile-addresses__list'>
                {renderAdresses()}
            </div>
            <div className='profile-addresses__add'>
                <AddNewItem
                    className='profile-addresses__add-item'
                    text='Add Location'
                    onClickHandler={() => {
                        setAddAddressModalOpened({
                            title: 'Add Location',
                            buttonText: 'Save'
                        });
                    }}/>
            </div>
        </div>
    );
};

ProfileAddresses.propTypes = {
    customerData: PropTypes.shape({}),
    setAddAddressModalOpened: PropTypes.func,
    setModalValues: PropTypes.func,
    editCustomersAvalibleAddress: PropTypes.func,
    openDeleteAvailableAddressesModalDialog: PropTypes.func,
    setAsDefaultPickupAddress: PropTypes.func,
    setAsDefaultDropoffAddress: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        customerData: state.getIn(['currentCustomer', 'instance']).toJS(),
    };
};

const mapDispatchToProps = {
    setAddAddressModalOpened,
    setModalValues,
    editCustomersAvalibleAddress,
    openDeleteAvailableAddressesModalDialog,
    setAsDefaultPickupAddress,
    setAsDefaultDropoffAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAddresses);
