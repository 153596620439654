import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { submit } from 'redux-form/immutable';

// eslint-disable-next-line max-len
const SkipStep = ({ className, text, linkTo, linkText, skipLinkTo, skipLinkAction, skipLinkText, closeText, closeAction, remoteSubmitText, dispatch, remoteSubmitFormName }) => {
    return (
        <div className={className}>
            {text && <span className='skip-step__text'>{text}</span>}
            {linkText && <Link className='skip-step__link' to={linkTo}>{linkText}</Link>}
            {/*eslint-disable-next-line*/}
            {closeText && <a className='skip-step__close-link' onClick={closeAction}>{closeText}</a>}
            {skipLinkTo &&
            <Link className='skip-step__skip-link' onClick={skipLinkAction} to={skipLinkTo}>{skipLinkText}</Link>}
            {remoteSubmitText &&
              // eslint-disable-next-line
            <a
                className='skip-step__skip-link'
                onClick={() => dispatch(submit(remoteSubmitFormName))}>
                {remoteSubmitText}
            </a>}
        </div>
    );
};

SkipStep.propTypes = {
    className: PropTypes.string.isRequired,
    text: PropTypes.string,
    linkTo: PropTypes.string,
    linkText: PropTypes.string,
    skipLinkAction: PropTypes.func,
    skipLinkTo: PropTypes.string,
    skipLinkText: PropTypes.string,
    closeText: PropTypes.string,
    closeAction: PropTypes.func,
    dispatch: PropTypes.func,
    remoteSubmitText: PropTypes.string,
    remoteSubmitFormName: PropTypes.string
};

export default connect()(SkipStep);
