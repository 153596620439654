import { Link } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { formatDateToSlashes } from '../../../../../../helpers/date';
import { prettifyPhone } from '../../../../../../helpers/normalize';

const columns = [
    {
        id: 'ownerName',
        Header: 'Owner\'s Name',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.owner.full_name,
    },
    {
        id: 'dogName',
        Header: 'Dog\'s Name',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.name,
        Cell: (row) => <Link to={`/dashboard/dog/${row.original.id}/details`}>{row.value}</Link>,
    },
    {
        id: 'lepto',
        Header: 'LEPTO',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => (
            <span className={d.lepto_expiration_date.is_expired ? 'custom-reports__error-text' : ''}>
                {d.lepto_expiration_date.value ? formatDateToSlashes(d.lepto_expiration_date.value) : '-'}
            </span>),
        Cell: (row) => <Link to={`/dashboard/dog/${row.original.id}/details`}>{row.value}</Link>,
    },
    {
        id: 'rabies',
        Header: 'Rabies',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => (
            <span className={d.rabies_expiration_date.is_expired ? 'custom-reports__error-text' : ''}>
                {d.rabies_expiration_date.value ? formatDateToSlashes(d.rabies_expiration_date.value) : '-'}
            </span>),
        Cell: (row) => <Link to={`/dashboard/dog/${row.original.id}/details`}>{row.value}</Link>,
    },
    {
        id: 'dhpp',
        Header: 'DHPP',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => (
            <span className={d.dhpp_expiration_date.is_expired ? 'custom-reports__error-text' : ''}>
                {d.dhpp_expiration_date.value ? formatDateToSlashes(d.dhpp_expiration_date.value) : '-'}
            </span>),
        Cell: (row) => <Link to={`/dashboard/dog/${row.original.id}/details`}>{row.value}</Link>,
    },
    {
        id: 'bordetella',
        Header: 'Bordetella',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => (
            <span className={d.bordetella_expiration_date.is_expired ? 'custom-reports__error-text' : ''}>
                {d.bordetella_expiration_date.value ? formatDateToSlashes(d.bordetella_expiration_date.value) : '-'}
            </span>),
        Cell: (row) => <Link to={`/dashboard/dog/${row.original.id}/details`}>{row.value}</Link>,
    },
    {
        id: 'canine_flu',
        Header: 'Canine Flu',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => (
            <span className={d.canine_flu_expiration_date.is_expired ? 'custom-reports__error-text' : ''}>
                {d.canine_flu_expiration_date.value ? formatDateToSlashes(d.canine_flu_expiration_date.value) : '-'}
            </span>),
        Cell: (row) => <Link to={`/dashboard/dog/${row.original.id}/details`}>{row.value}</Link>,
    },
    {
        id: 'vetName',
        Header: 'Veterinarian Name',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.vet_name || '-',
    },
    {
        id: 'vetPhone',
        Header: 'Veterinarian Phone Number',
        headerClassName: 'custom-reports__th custom-reports__th_wrapped',
        className: 'custom-reports__td',
        accessor: d => d.vet_phone_number ? prettifyPhone(d.vet_phone_number) : '-',
    },
];

const ExpiredVaccination = ({ items }) => {
    const renderTable = () => {
        if (items && items.length) {
            return (
                <ReactTable
                    showPagination={false}
                    defaultPageSize={items.length}
                    data={items}
                    columns={columns} />);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

ExpiredVaccination.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default ExpiredVaccination;
