import PropTypes from 'prop-types';
import React from 'react';
import Wrapper from './MarketingPageContainer';
import MarketingTable from './MarketingTable';
import MarketingGrid from './MarketingGrid';
import Icon from '../common/Icon';
import { ReactComponent as IconGrid } from '../../assets/icons/icon-grid-view.svg';
import { ReactComponent as IconList } from '../../assets/icons/icon-list-view.svg';
import { ReactComponent as IconTrash } from '../../assets/icons/icon-trash.svg';
import { ReactComponent as IconEdit } from '../../assets/icons/icon-edit.svg';

const MAX_WIDTH_MOBILE = 736;
const QUERY_MOBILE = `screen and (max-width: ${MAX_WIDTH_MOBILE}px)`;

const propTypes = {
    model: PropTypes.shape({}),
    gotoCampaignEdit: PropTypes.func,
    openCampaignDeleteModal: PropTypes.func,
    fetchList: PropTypes.func,
    fetchPage: PropTypes.func,
    updateModel: PropTypes.func,
};

class MarketingPage extends React.PureComponent {
    static propTypes = propTypes;

    constructor(props) {
        super(props);
        const { model, gotoCampaignEdit, openCampaignDeleteModal } = props;
        this.state = { isTableView: model.get('isTableView') };

        this.dropdownItems = [
            {
                iconClass: 'icon_edit',
                itemClass: 'dropdown__item-edit',
                icon: IconEdit,
                text: 'Edit',
                onClickCB: campaignData => {
                    gotoCampaignEdit(campaignData.id);
                }
            },
            {
                iconClass: 'icon_trash',
                icon: IconTrash,
                text: 'Delete',
                onClickCB: campaignData => {
                    openCampaignDeleteModal(campaignData);
                }
            }
        ];
    }

    componentDidMount() {
        const { isTableView } = this.state;
        this.mql = window.matchMedia(QUERY_MOBILE);
        this.mql.addListener(this.handleMediaChange);

        if (this.mql.matches && isTableView) {
            this.handleViewToggleIconClick();
        }
    }

    componentDidUpdate() {
        const { model } = this.props;
        this.setState({ isTableView: model.get('isTableView') });
    }

    componentWillUnmount() {
        if (this.mql) {
            this.mql.removeListener(this.handleMediaChange);
        }
    }

    handleMediaChange = (e) => {
        const { isTableView } = this.state;

        if (e.matches && isTableView) {
            this.handleViewToggleIconClick();
        }
    };

    handleViewToggleIconClick = (e) => {
        const { updateModel, model } = this.props;

        if (e && e.preventDefault) {
            e.preventDefault();
        }

        updateModel({ isTableView: !model.get('isTableView') });
    };

    renderViewToggle = () => {
        const { isTableView } = this.state;
        return (
            <div className='marketing-page__view-toggle'>
                {/*eslint-disable-next-line*/}
                <a href='' onClick={this.handleViewToggleIconClick}>
                    { isTableView ?
                        <Icon glyph={IconGrid} className='icon_grid-view marketing-page__view-icon'/> :
                        <Icon glyph={IconList} className='icon_list-view marketing-page__view-icon'/>
                    }
                </a>
            </div>
        );
    };

    render() {
        const { isTableView } = this.state;
        const { model, gotoCampaignEdit, openCampaignDeleteModal, fetchPage, fetchList } = this.props;
        const { isLoading, pageSize, nextQuery, list } = model.toJS();

        const Component = isTableView ? MarketingTable : MarketingGrid;

        return (
            <div className='dashboard__main'>
                <div className='marketing-page'>
                    <div className='marketing-page__header'>
                        {this.renderViewToggle()}
                    </div>
                    <Component
                        dropdownItems={this.dropdownItems}
                        isManual
                        defaultPageSize={pageSize}
                        loading={isLoading}
                        nextUrl={nextQuery}
                        getNext={fetchPage}
                        gotoCampaignEdit={gotoCampaignEdit}
                        openCampaignDeleteModal={openCampaignDeleteModal}
                        onSorting={value => fetchList(`o=${value}`)}
                        data={list}/>
                </div>
            </div>
        );
    }
}

MarketingPage.propTypes = propTypes;

export default Wrapper(MarketingPage);
