import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as DeleteFile } from '../../../../assets/icons/delete.svg';
import Icon from '../../../common/Icon/Icon';

const File = ({ file, onChangeClick, onDeleteClick, refLink }) => {
    return (
        <div key={file.name} className='zone-files__file'>
            <div className='zone-files__file-name' ref={refLink}>{file.name}</div>
            <div className='zone-files__file-controls'>
                {/*eslint-disable-next-line*/}
                <a className='file-controls__change' onClick={onChangeClick}>Change</a>
                 {/*eslint-disable-next-line*/}
                <a className='file-controls__delete' onClick={onDeleteClick}>
                    <Icon glyph={DeleteFile} className='file-controls__delete-icon' size='md'/>
                </a>
            </div>
        </div>
    );
};

File.propTypes = {
    file: PropTypes.shape({}).isRequired,
    refLink: PropTypes.func,
    onChangeClick: PropTypes.func,
    onDeleteClick: PropTypes.func
};

export default File;
