import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAddCreditCardModalClosed } from '../../../../actions/miscActions';
import { createStripeCardToken } from '../../../../services/paymentCards';
import ModalDialog from '../../../common/ModalDialog';
import AddCreditCardModalDialogForm from './AddCreditCardModalDialogForm';
import { clearStripeError } from '../../../../actions/payments';

const AddCreditCardModalDialog = (props) => {
    const {
        isAddCreditCardModalOpened,
        handleAddCreditCardModalClosed,
        handleSubmit,
        customer,
        clearStripeError,
    } = props;
    const customerFullName = customer.get('full_name');
    return (
        <ModalDialog
            form={
                <AddCreditCardModalDialogForm
                    onSubmit={data => {
                        clearStripeError();
                        return handleSubmit(customer.get('id'), data.toJS(), false);
                    }}/>
            }
            title={`Add New ${customerFullName}'s Credit Card`}
            isModalOpened={isAddCreditCardModalOpened}
            handleModalClosed={handleAddCreditCardModalClosed}
            overflowShown/>
    );
};

AddCreditCardModalDialog.propTypes = {
    isAddCreditCardModalOpened: PropTypes.bool,
    handleAddCreditCardModalClosed: PropTypes.func,
    handleSubmit: PropTypes.func,
    customer: PropTypes.shape({})
};

const mapStateToProps = state => {
    return {
        customer: state.getIn(['currentCustomer', 'instance']),
        isAddCreditCardModalOpened: state.getIn(['misc', 'modals', 'addCreditCard'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleAddCreditCardModalClosed: bindActionCreators(setAddCreditCardModalClosed, dispatch),
    handleSubmit: bindActionCreators(createStripeCardToken, dispatch),
    clearStripeError: bindActionCreators(clearStripeError, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditCardModalDialog);
