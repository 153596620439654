import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import { setWelcomeShowed } from '../../../../actions/onboarding/userActions';

class WelcomePage extends PureComponent {
    state = {
        currentStep: 0
    };

    componentDidMount() {
        const { setWelcomeShowed } = this.props;
        setWelcomeShowed();
    }

    render() {
        return (
            <section className='welcome'>
                <i className='welcome__img' style={{ backgroundImage: 'url(/assets/imgs/customer/welcome-screen-1.jpg)' }}/>
                <div className='welcome__content'>
                    <div className='welcome__wrapper welcome__wrapper_scheduling'>
                        <h4 className='welcome__title welcome__title_scheduling welcome__title_centered'>Welcome to the pack!</h4>
                        <div className='welcome__text welcome__text_centered'>
                            <p>
                                You are now ready to get started. We made it easy to book classes online. Click to view our class schedule by day.
                            </p>
                        </div>
                        <Link to='/classes' className='button welcome__button welcome__button_final'>View Class Schedule</Link>
                    </div>
                </div>
            </section>
       )
   }
}

WelcomePage.propTypes = {
    setWelcomeShowed: PropTypes.func
};

const mapDispatchToProps = {
    setWelcomeShowed
};

export default connect(null, mapDispatchToProps)(WelcomePage);

