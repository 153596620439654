import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as uploadVaccineTypes from '../types/customer/uploadVaccineTypes'

const INITIAL_STATE = fromJS({
    error_massage: ''
});

const uploadVaccineErrorReducer = handleActions({
    [uploadVaccineTypes.UPLOAD_VACCINE_ERROR_MASSAGE]: (state, action) => {
        console.log("state, action", state, action);
     return    state.set('error_massage', action.payload.doc_file[0])
    },
}, INITIAL_STATE);

export default uploadVaccineErrorReducer;