/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React from 'react';
import { Field } from 'redux-form/immutable';
import { isMobile } from '../../../helpers/device';
import {
    messageInputNormalize,
    PHONE_MASK,
    PHONE_MASK_CHAR,
    phoneNumberNormalizer,
    timeFieldInputNormalizer,
    timeFieldValueNormalizer,
} from '../../../helpers/normalize';
import { reportCardIcons, weekDays } from '../../../helpers/options';
import {
    isTrainer,
    isTrainerByTrainingProgramFieldDisabled,
    isTrainingInstructorFieldDisabled,
} from '../../../helpers/userRoles';
import * as ValidatorFactory from '../../../helpers/validate';
import { searchClassesByName, searchTrainersByName } from '../../../services/classesSchedule';
import ReportCardImageUpload from '../../common/Form/ImageControl/ReportCardImageUpload';
import MessagesImageUpload from '../../containers/Dashboard/Messages/MessagesImageUpload';
import AllergiesControl from './AllergiesControl';
import renderCheckboxGroupControl from './CheckboxGroupControl';
import renderCheckboxListControl from './CheckboxListControl';
import CreditCardControl from './CreditCardControl';
import renderDateControl from './DateControl';
import renderDateIntervalControl from './DateIntervalControl';
import DogSelectorControl from './DogSelectorControl';
import renderImageControl from './ImageControl';
import ClassImageUpload from './ImageControl/ClassImageUpload';
import FormImageUpload from './ImageControl/FormImageUpload';
import renderInputControl from './InputControl';
import MedicalNotesControl from './MedicalNotesControl';
import renderNumberInputControl from './NumberInputControl';
import {
    availableTrainerOptionRenderer,
    classEventDogOptionRenderer,
    formRecipientOptionForDogsRenderer,
    formRecipientOptionRenderer,
    paymentCardsOptionRenderer,
    trainingEventDogOptionRenderer,
} from './optionRenderers';
import PaymentControl from './PaymentControl';
import PickupDropoffControl from './PickupDropoffControl';
import { renderError, renderWarning } from './RadioControl/RadioControl';
import renderSearchAddControl from './SearchAddControl';
import renderSearchAddTagControl from './SearchAddTagControl';
import renderSelectControl from './SelectControl';
import renderTextEditorControl from './TextEditorControl';
import TotalChargeControl from './TotalChargeControl';
import VeterinarianControl from './VeterinarianControl';
import DogReportEditControl from './DogReportEditControl';
import { getDogOptions } from "../../../actions/modal/getOptionsActions";

import { change } from "redux-form";
import { store } from "../../../index";
import { createDogsProgramsOptions } from "../../../services/options";
import Select from "../Select/Select";
import DogSelector from "./DogSelector/DogSelector";
import DiscountControl from './ChargeInfoControl/ChargeInfoControl';

const inputChangeDebounceTime = 750;

const fields = {
    breed: {
        type: 'select',
        name: 'breed',
        label: 'Breed',
        options: 'getBreedOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    weight: {
        type: 'select',
        name: 'weight',
        label: 'Weight',
        options: 'getWeightOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    markings: {
        type: 'input',
        name: 'markings',
        label: 'Markings',
        get validate() {
            return [
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    month: {
        type: 'select',
        name: 'month',
        label: 'Birthday Month',
        options: 'getMonthOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    year: {
        type: 'select',
        name: 'year',
        label: 'Birthday Year',
        options: 'getYearOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    gender: {
        type: 'select',
        name: 'gender',
        label: 'Gender',
        options: 'getGenderOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    is_altered: {
        type: 'radio',
        name: 'is_altered',
        label: 'Has your dog been spayed/neutered?',
        shouldRenderError: true,
        shouldRenderWarning: true,
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        },
        warn: value => {
            return value === 'No'
                ? 'We require all of our furry friends to be spayed and neutered before attending group classes.'
                : undefined;
        },
        options: [
            {
                value: 'Yes',
                label: 'Yes'
            },
            {
                value: 'No',
                label: 'No'
            }
        ]
    },
    name: {
        type: 'input',
        name: 'name',
        label: 'Name',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    dog_name: {
        type: 'input',
        name: 'dog_name',
        label: 'Name',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    vet_name: {
        type: 'input',
        name: 'name',
        label: 'Enter vet name',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    vet_phone: {
        type: 'input',
        fieldType: 'tel',
        name: 'phone_number',
        label: 'Vet phone number',
        get validate() {
            return [
                ValidatorFactory.phoneRequiredValidator(),
                ValidatorFactory.minPhoneValueValidator(),
                ValidatorFactory.maxPhoneValueValidator(),
                ValidatorFactory.patternPhoneValueValidator()
            ];
        }
    },
    medical_notes: {
        type: 'text_area',
        name: 'medical_notes',
        label: 'Medical notes',
        className: 'modal-dialog__form_medical-notes',
        get validate() {
            return ValidatorFactory.maxTextareaValueValidator();
        }
    },
    medical_notes_requaired: {
        type: 'text_area',
        name: 'medical_notes',
        label: 'Medical notes',
        className: 'modal-dialog__form_medical-notes',
        get validate() {
            return [
                ValidatorFactory.maxTextareaValueValidator(),
                ValidatorFactory.requiredValidator(),
            ];
        }
    },
    event_notes: {
        type: 'text_area',
        name: 'notes',
        label: 'Notes',
        className: 'event-form__notes',
        get validate() {
            return ValidatorFactory.maxTextareaValueValidator();
        }
    },
    training_notes: {
        type: 'text_area',
        name: 'notes',
        label: 'Notes',
        className: 'event-form__notes',
        get validate() {
            return [
                ValidatorFactory.maxTextareaValueValidator(),
            ];
        }
    },
    intolerance_list: {
        type: 'creatable_multi',
        name: 'intolerance_list',
        label: 'Please list all allergies or intolerances.',
        get validate() {
            return ValidatorFactory.intoleranceListValidator();
        }
    },
    intolerance_list_requaired: {
        type: 'creatable_multi',
        name: 'intolerance_list',
        label: 'Please list all allergies or intolerances.',
        get validate() {
            return [
                ValidatorFactory.intoleranceListValidator(),
                ValidatorFactory.requiredValidator(),
            ];
        }
    },
    access_level: {
        type: 'select',
        name: 'access_level',
        label: 'Access level',
        options: 'getAccessLevelOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    veterinarian: {
        type: 'select',
        name: 'veterinarian',
        label: 'Select Veterinarian',
        options: 'getVeterinarianOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    veterinarian_with_create: {
        type: 'veterinarian_with_create',
        name: 'veterinarian_with_create',
        label: 'Veterinarian',
        options: 'getVeterinarianOptions',
    },
    allergies: {
        title: 'Allergies',
        type: 'allergies',
        name: 'allergies',
        label: 'Does your dog have any Allergies?',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        },
        options: [
            {
                value: 'Yes',
                label: 'Yes'
            },
            {
                value: 'No',
                label: 'No'
            }
        ]
    },
    medical_notes_section: {
        title: 'Medical Notes',
        type: 'medical_notes_section',
        name: 'medical_notes_section',
        label: 'Does your dog have any medical conditions?',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        },
        options: [
            {
                value: 'Yes',
                label: 'Yes'
            },
            {
                value: 'No',
                label: 'No'
            }
        ]
    },
    training_class: {
        type: 'autocomplete',
        name: 'training_class',
        label: 'Class Name',
        options: 'getClassOptions',
        noResultsText: <span className='no-results-message'>Please choose an existing class name</span>,
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    program: {
        type: 'autocomplete',
        name: 'program',
        label: 'Training Program',
        options: 'getPurchasedTrainingProgramOptions',
        noResultsText: <span className='no-results-message'>Please choose an existing training name</span>,
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    trainer: {
        type: 'autocomplete',
        name: 'trainer',
        label: 'Select the instructor',
        options: 'getTrainerOptions',
        noResultsText: <span className='no-results-message'>Please choose an existing instructor</span>,
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    trainer_by_training_program: {
        type: 'trainer_by_training_program',
        name: 'trainer',
        label: 'Instructor',
        options: 'getTrainerByProgramOptions',
        disabled: isTrainerByTrainingProgramFieldDisabled,
        optionRenderer: availableTrainerOptionRenderer,
        noResultsText: <span className='no-results-message'>Please choose an existing instructor</span>,
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    location_array: {
        type: 'location_array',
        name: 'location_array',
        label: 'Neighborhoods',
        options: 'getLocationOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    full_name: {
        type: 'input',
        name: 'full_name',
        label: 'Customer\'s full name',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                // ValidatorFactory.minTextValueValidator(),
                // ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    email: {
        type: 'input',
        fieldType: 'email',
        name: 'email',
        label: 'Email',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minEmailValueValidator(),
                ValidatorFactory.maxEmailValueValidator(),
                ValidatorFactory.patternEmailValueValidator()
            ];
        }
    },
    phone: {
        type: 'input',
        fieldType: 'tel',
        name: 'phone',
        label: 'Phone',
        get validate() {
            return [
                ValidatorFactory.phoneRequiredValidator(),
                ValidatorFactory.minPhoneValueValidator(),
                ValidatorFactory.maxPhoneValueValidator(),
                ValidatorFactory.patternPhoneValueValidator()
            ];
        }
    },
    report_form_photo: {
        type: 'report_form_photo',
        name: 'photo',
        label: 'Photo',
        icons: reportCardIcons,
        get validate() {
            return ValidatorFactory.requiredValidator();
        }
    },
    report_edit_form_photo: {
        type: 'report_form_photo',
        name: 'photo',
        label: 'Photo',
        icons: reportCardIcons
    },
    report_form_icon_checkbox: {
        type: 'report_form_icon_checkbox',
        name: 'icon_select',
        label: 'Select three icons to include',
        icons: reportCardIcons,
        get validate() {
            return ValidatorFactory.dogReportIconsValidator();
        }
    },
    report_form_selectable: {
        type: 'report_form_selectable',
        icons: reportCardIcons,
        options: 'getDogReportIconOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    start_time: {
        type: 'creatable',
        name: 'start_time',
        label: 'Time',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect()
            ];
        }
    },
    end_time: {
        type: 'creatable',
        name: 'end_time',
        label: 'Time',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect()
            ];
        }
    },
    pickup_start_time: {
        type: 'creatable',
        name: 'pickup_start_time',
        label: 'Pickup Start',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.timeValidator(),
                ValidatorFactory.startEndTimeValidator({
                    endField: 'pickup_end_time',
                    startName: 'Pickup Start',
                    endName: 'Pickup End'
                })
            ];
        }
    },
    pickup_end_time: {
        type: 'creatable',
        name: 'pickup_end_time',
        label: 'Pickup End',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.timeValidator(),
                ValidatorFactory.startEndTimeValidator({
                    startField: 'pickup_start_time',
                    startName: 'Pickup Start',
                    endName: 'Pickup End'
                }),
                ValidatorFactory.startEndTimeValidator({
                    endField: 'dropoff_start_time',
                    startName: 'Pickup End',
                    endName: 'Dropoff Start'
                })
            ];
        }
    },
    dropoff_start_time: {
        type: 'creatable',
        name: 'dropoff_start_time',
        label: 'Dropoff Start',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.timeValidator(),
                ValidatorFactory.startEndTimeValidator({
                    endField: 'dropoff_end_time',
                    startName: 'Dropoff Start',
                    endName: 'Dropoff End'
                }),
                ValidatorFactory.startEndTimeValidator({
                    startField: 'pickup_end_time',
                    startName: 'Pickup End',
                    endName: 'Dropoff start'
                })
            ];
        }
    },
    dropoff_end_time: {
        type: 'creatable',
        name: 'dropoff_end_time',
        label: 'Dropoff End',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.timeValidator(),
                ValidatorFactory.startEndTimeValidator({
                    startField: 'dropoff_start_time',
                    startName: 'Dropoff Start',
                    endName: 'Dropoff End'
                })
            ];
        }
    },
    date: {
        type: 'date',
        name: 'date',
        label: 'Start Date',
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.minDateValueValidator(),
                ValidatorFactory.dateValueValidator('this field'),
            ];
        }
    },
    report_form_title: {
        type: 'input',
        name: 'title',
        label: 'Class name or report title (Ex. First up the mountain)',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    create_class_location: {
        type: 'location_array',
        name: 'location_array',
        label: 'Location/Neighborhoods',
        options: 'getLocationOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    create_class_link_website: {
        type: 'input',
        name: 'link_to_website',
        label: 'Link to website page',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    separator: {
        type: 'separator',
    },
    class_weekdays: {
        type: 'weekdays',
        name: 'class_weekdays',
        label: 'Days for the class',
        get validate() {
            return [
                ValidatorFactory.requiredValidatorCheckbox(),
            ];
        },
        description: 'Select all of the days the class will occur',
        options: weekDays.reduce((resultArray, weekdayItem) => {
            resultArray.push(Object.assign({}, weekdayItem, {
                label: weekdayItem.label.substring(0, 3),
            }));
            return resultArray;
        }, []),
    },
    class_image: {
        type: 'image_loader',
        uploadComponent: ClassImageUpload,
        name: 'class_image',
    },
    start_date: {
        type: 'date_disable_past',
        name: 'start_date',
        label: 'Start Date',
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.minDateValueValidator(),
                ValidatorFactory.dateValueValidator('start date field'),
                ValidatorFactory.pastDateValidator('start date field')
            ];
        }
    },
    start_date_training: {
        type: 'date_disable_past',
        name: 'start_date',
        label: 'Start Date',
    }
    ,
    preferred_date: {
        type: 'date_disable_past',
        name: 'preferred_date',
        label: 'Preferred Dates & Times',
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.minDateValueValidator(),
                ValidatorFactory.dateValueValidator('start date field'),
                ValidatorFactory.pastDateValidator('start date field')
            ];
        }
    }
    ,
    end_date: {
        type: 'date_disable_past',
        name: 'end_date',
        label: 'End Date',
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.minDateValueValidator(),
                ValidatorFactory.dateValueValidator('end date field'),
                ValidatorFactory.pastDateValidator('end date field')
            ];
        }
    },
    end_date_training: {
        type: 'date_disable_past',
        name: 'end_date',
        label: 'End Date',
    },
    start_date_observed: {
        type: 'date_observed',
        name: 'start_date',
        label: 'Start Date',
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.minDateValueValidator(),
                ValidatorFactory.dateValueValidator('start date field'),
                ValidatorFactory.pastDateValidator('start date field')
            ];
        }
    },
    end_date_observed: {
        type: 'date_observed',
        name: 'end_date',
        label: 'End Date',
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.minDateValueValidator(),
                ValidatorFactory.dateValueValidator('end date field'),
                ValidatorFactory.pastDateValidator('end date field')
            ];
        }
    },
    date_interval: {
        type: 'date_interval',
        name: 'date_interval',
        startProps: {
            name: 'start_date',
            label: 'Start Date',
        },
        endProps: {
            name: 'end_date',
            label: 'End Date',
        },
        disablePast: true,
        noEndDateCheckbox: true,
        get validate() {
            return [
                ValidatorFactory.requiredDateInteralValidator('Start date', 'End date'),
                ValidatorFactory.dateIntervalValueValidator('start date field', 'end date field'),
                ValidatorFactory.minDateIntervalValueValidator('Start date', 'End date'),
            ];
        }
    },
    trainer_autocomplete: {
        type: 'select_autocomplete',
        name: 'trainer_autocomplete',
        label: 'Instructor',
        optionRenderer: (data) => {
            return (
                <div className='select-control__overflow'>
                    <div>{data.label}</div>
                </div>
            );
        },
        searchOptions: searchTrainersByName,
        optionsParser: (response) => {
            return response.data.results.reduce((resultArray, trainerItem) => {
                resultArray.push({
                    value: trainerItem.id,
                    label: trainerItem.full_name,
                });
                return resultArray;
            }, []);
        },
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    class_name_autocomplete: {
        type: 'select_creatable_autocomplete',
        name: 'class_name_autocomplete',
        label: 'Name',
        optionRenderer: (data) => {
            return (
                <div className='select-control__overflow'>
                    <div>{data.label}</div>
                </div>
            );
        },
        searchOptions: searchClassesByName,
        optionsParser: (response) => {
            return response.data.results.reduce((resultArray, classItem) => {
                resultArray.push({
                    value: classItem.id,
                    label: classItem.name,
                });
                return resultArray;
            }, []);
        },
        ignoreCase: false,
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    description: {
        type: 'text_area',
        name: 'description',
        label: 'Description',
        className: 'modal-dialog__form_description',
        get validate() {
            return [
                ValidatorFactory.maxTextareaValueValidator(),
                ValidatorFactory.requiredValidator(),
            ];
        }
    },
    class_price: {
        type: 'number_input',
        name: 'class_price',
        label: 'Price',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
            ];
        }
    },
    class_commission: {
        type: 'number_input',
        name: 'class_commission',
        label: 'Commission',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
            ];
        }
    },
    class_spots: {
        type: 'number_input',
        name: 'class_spots',
        label: 'Spots',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minNumberValueValidator()
            ];
        }
    },
    spots: {
        type: 'number_input',
        name: 'spots',
        label: 'Spots',
        get validate() {
            return [
//                 ValidatorFactory.requiredValidator(),
                ValidatorFactory.minNumberValueValidator()
            ];
        }
    },
    class_event_dog_search: {
        type: 'search_add',
        name: 'dog',
        label: 'Attending Dogs',
        options: 'getClassEventDogOptions',
        noResultsText: <span className='no-results-message'>Please choose an existing dog</span>,
        optionRenderer: classEventDogOptionRenderer,
        header: ['Dog', 'Access Level']
    },
    training_event_dog_search: {
        type: 'search_add',
        name: 'dog',
        label: 'Attending Dogs',
        options: 'getTrainingEventDogOptions',
        noResultsText: <span className='no-results-message'>Please choose an existing dog</span>,
        optionRenderer: trainingEventDogOptionRenderer,
        header: ['Dog', 'Sessions Remaining']
    },
    class_pickup_start_time: {
        type: 'creatable',
        name: 'pickup_start_time',
        label: 'From',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.timeValidator(),
                ValidatorFactory.startEndTimeValidator({
                    endField: 'pickup_end_time',
                    startName: 'Pickup Start',
                    endName: 'Pickup End'
                })
            ];
        }
    },
    class_pickup_end_time: {
        type: 'creatable',
        name: 'pickup_end_time',
        label: 'To',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.timeValidator(),
                ValidatorFactory.startEndTimeValidator({
                    startField: 'pickup_start_time',
                    startName: 'Pickup Start',
                    endName: 'Pickup End'
                }),
                ValidatorFactory.startEndTimeValidator({
                    endField: 'dropoff_start_time',
                    startName: 'Pickup End',
                    endName: 'Dropoff Start'
                })
            ];
        }
    },
    class_dropoff_start_time: {
        type: 'creatable',
        name: 'dropoff_start_time',
        label: 'From',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.timeValidator(),
                ValidatorFactory.startEndTimeValidator({
                    endField: 'dropoff_end_time',
                    startName: 'Dropoff Start',
                    endName: 'Dropoff End'
                }),
                ValidatorFactory.startEndTimeValidator({
                    startField: 'pickup_end_time',
                    startName: 'Pickup End',
                    endName: 'Dropoff start'
                })
            ];
        }
    },
    class_dropoff_end_time: {
        type: 'creatable',
        name: 'dropoff_end_time',
        label: 'To',
        options: 'getTimeOptions',
        formatInput: timeFieldInputNormalizer,
        normalize: timeFieldValueNormalizer,
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.timeValidator(),
                ValidatorFactory.startEndTimeValidator({
                    startField: 'dropoff_start_time',
                    startName: 'Dropoff Start',
                    endName: 'Dropoff End'
                })
            ];
        }
    },
    package_expiration_date: {
        type: 'date_disable_past',
        name: 'package_expiration_date',
        label: 'Package expiration date',
    },
    package_credits_spinner: {
        type: 'number_input',
        name: 'package_credits_spinner',
        label: 'Package credits remaining',
    },
    pickup_dropoff_address: {
        type: 'pickup_dropoff_address',
        name: 'pickup_dropoff_address',
    },
    payment_information: {
        type: 'payment_information',
        name: 'payment_information',
    },
    total_charge: {
        type: 'total_charge',
        name: 'total_charge',
    },
    training_program: {
        type: 'select',
        name: 'training_program',
        label: 'Select the training program',
        options: 'getTrainingProgramsOptions',
    },
    training_instructor: {
        type: 'training_instructor',
        disabled: isTrainingInstructorFieldDisabled,
        label: 'Instructor',
        options: 'getCalendarTrainerOptions',
    },
    class_occurrences_location: {
        type: 'select',
        name: 'class_occurrences_location',
        label: 'Neighborhood',
        options: 'getCalendarLocationOptions',
    },
    class_occurrences_training_class: {
        type: 'select',
        name: 'class_occurrences_training_class',
        label: 'Class Name',
        options: 'getCalendarClassOptions',
    },
    package_name: {
        type: 'input',
        name: 'package_name',
        label: 'Package name',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    package_image: {
        type: 'image_loader',
        uploadComponent: ClassImageUpload,
        name: 'package_image',
    },
    program_image: {
        type: 'image_loader',
        uploadComponent: ClassImageUpload,
        name: 'program_image',
    },
    package_size: {
        type: 'number_input',
        name: 'package_size',
        label: 'Package size (how many classes to include in the package)',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                // ValidatorFactory.minNumberValueValidator()
            ];
        }
    },
    package_expires_after: {
        type: 'number_input',
        name: 'package_expires_after',
        label: 'Expires after (enter number in days)',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                // ValidatorFactory.minNumberValueValidator()
            ];
        }
    },
    package_price: {
        type: 'number_input',
        name: 'package_price',
        label: 'Price',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
            ];
        }
    },
    discount_price: {
        type: 'number_input',
        name: 'discount_price',
        label: 'Price (with discount)',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
            ];
        }
    },
    package_classes: {
        type: 'package_multibox',
        name: 'package_classes',
        label: 'Classes included in package',
        options: 'getPackageClassesOptions',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
            ];
        }
    },
    program_name: {
        type: 'input',
        name: 'program_name',
        label: 'Program name',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
    program_link_to_website: {
        type: 'input',
        name: 'program_link_to_website',
        label: 'Link to website page',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.linkValueValidator()
            ];
        }
    },
    program_sessions_size: {
        type: 'number_input',
        name: 'program_sessions_size',
        label: 'How many sessions are in this program?',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                // ValidatorFactory.minNumberValueValidator()
            ];
        }
    },
    program_expires_after: {
        type: 'number_input',
        name: 'program_expires_after',
        label: 'Expires after (enter number in days)',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                // ValidatorFactory.minNumberValueValidator()
            ];
        }
    },
    program_price: {
        type: 'number_input',
        name: 'program_price',
        label: 'Price',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                // ValidatorFactory.minNumberValueValidator()
            ];
        }
    },
    program_comission: {
        type: 'number_input',
        name: 'program_comission',
        label: 'Comission (in %)',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                // ValidatorFactory.minNumberValueValidator()
            ];
        }
    },
    package_description: {
        type: 'text_area',
        name: 'package_description',
        label: 'Description',
        className: 'modal-dialog__form_description',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.maxTextareaValueValidator(),
            ];
        }
    },
    program_description: {
        type: 'text_area',
        name: 'program_description',
        label: 'Description',
        className: 'modal-dialog__form_description',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.maxTextareaValueValidator(),
            ];
        }
    },
    sell_package_customer: {
        type: 'input',
        fieldType: 'text',
        name: 'customer',
        label: 'Customer',
        disabled: true
    },
    sell_package_select: {
        type: 'select',
        name: 'package',
        label: 'Select the package',
        options: 'getAvailablePackagesOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect('Package');
        }
    },
    sell_package_payment_select: {
        type: 'sell_payment_information',
        name: 'card',
        label: 'Payment information',
        optionRenderer: paymentCardsOptionRenderer
    },
    sell_training_dogs: {
        type: 'select',
        name: 'dog',
        label: 'Select the dog',
        options: 'getCustomerNotDeceasedDogsOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect('Dog');
        }
    },
    sell_training_discount: {
        type: 'select',
        name: 'discount',
        label: 'Discount',
        options: 'getDiscountOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect('Discount');
        }
    },
    sell_training_instructor: {
        type: 'select',
        name: 'trainer',
        label: 'Select the instructor',
        options: 'getTrainerOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect('Instructor');
        }
    },
    sell_training_programs_select: {
        type: 'select',
        name: 'training_program',
        label: 'Select the training program',
        options: 'getAvailableTrainingProgramsOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect('Training Program');
        }
    },
    sell_training_charge_info: {
        type: 'sell_charge_info',
        name: 'charge_info',
        formName: 'TabModalDialogForm',
        purchase: 'training_program'
    },
    trainer_sell_training_charge_info: {
        type: 'sell_charge_info',
        name: 'charge_info',
        formName: 'EditCreateModalDialogForm',
        purchase: 'training_program'
    },
    sell_training_total_charge: {
        type: 'sell_total_charge',
        name: 'total_charge',
        formName: 'TabModalDialogForm',
        purchase: 'training_program'
    },
    trainer_sell_training_total_charge: {
        type: 'sell_total_charge',
        name: 'total_charge',
        formName: 'EditCreateModalDialogForm',
        purchase: 'training_program'
    },
    sell_package_total_charge: {
        type: 'sell_total_charge',
        name: 'total_charge',
        formName: 'TabModalDialogForm',
        purchase: 'package'
    },
    training_goals: {
        type: 'text_area',
        name: 'training_goals',
        label: 'What are your training goals',
        className: 'modal-dialog__form_medical-notes',
        get validate() {
            return [
                ValidatorFactory.requiredValidator(),
                ValidatorFactory.maxTextareaValueValidator(),
            ];
        }
    },
    training_dogs: {
        type: 'dog_selector',
        name: 'dogs',
        label: 'Which dogs would you like to train?',
        get validate() {
            return ValidatorFactory.requiredValidator();
        },
    },
    customer_phone: {
        type: 'creatable',
        name: 'phone',
        label: 'Phone',
        options: 'getCustomerPhonesOptions',
        className: 'modal-dialog__form_customer-phone',
        get validate() {
            return [
                ValidatorFactory.requiredValidatorSelect(),
                ValidatorFactory.patternCreatablePhoneValidator()
            ];
        }
    },
    can_contact_veterinarian: {
        type: 'radio',
        name: 'can_contact_veterinarian',
        label: 'May we contact your vet to get your dog’s vaccination records?',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        },
        options: [
            {
                value: 'Yes',
                label: 'Yes'
            },
            {
                value: 'No',
                label: 'No'
            }
        ]
    },
    dog_delete_select: {
        type: 'select',
        name: 'dog_delete_select',
        label: 'Select',
        options: 'getDeleteDogOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        },
    },
    edit_create_form_name: {
        type: 'input',
        name: 'name',
        label: 'Form Name',
        get validate() {
            return [
                ValidatorFactory.requiredValidator('Form Name'),
                ValidatorFactory.maxTextValueValidator('Form Name')
            ];
        }
    },
    edit_create_form_recipient: {
        type: 'autocomplete',
        name: 'notify_users',
        label: 'Customer',
        options: 'getFormRecipientsOptions',
        optionRenderer: formRecipientOptionRenderer,
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    dogs_choose_field_detail: {
        type: 'autocomplete',
        name: 'choose_users_dogs',
        label: 'Choose dogs',
        options: 'getDogOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },
    customer_dog: {
        type: 'select_dogs',
        name: 'customer_dog',
        label: 'Choose dog',
        options: 'getDogOptions',
        get validate() {
            return ValidatorFactory.requiredValidatorSelect();
        }
    },

    edit_create_form_text: {
        type: 'text_editor',
        name: 'text',
        label: 'Text of Agreement',
        get validate() {
            return [
                ValidatorFactory.agreementTextRequiredValidator('Text of Agreement'),
                ValidatorFactory.agreementTextMaxValueValidator('Text of Agreement')
            ];
        }
    },
    relationship: {
        type: 'input',
        name: 'relationship',
        label: 'Relationship',
        get validate() {
            return [
                ValidatorFactory.requiredValidator('Form Name'),
                ValidatorFactory.maxTextValueValidator('Form Name')
            ];
        }
    },
    credit_card: {
        type: 'credit_card',
        name: 'credit_card',
        label: 'Credit card number'
    },
    message_input: {
        type: 'message_input',
        name: 'message_input',
        normalize: messageInputNormalize,
    },
    message_photo: {
        type: 'message_photo',
        name: 'message_photo'
    },
    overview_date_interval: {
        type: 'date_interval',
        name: 'overview_date_interval',
        startProps: {
            name: 'start_date',
            label: 'From',
        },
        endProps: {
            name: 'end_date',
            label: 'To',
        },
        disableFuture: true,
        get validate() {
            return [
                ValidatorFactory.requiredDateInteralValidator('Start date', 'End date'),
                ValidatorFactory.dateIntervalValueValidator('start date field', 'end date field'),
                ValidatorFactory.minDateIntervalValueValidator('Start date', 'End date'),
            ];
        }
    },
    save_as_campaign_name: {
        type: 'input',
        name: 'name',
        label: 'Enter name to the custom group that is being created',
        get validate() {
            return [
                ValidatorFactory.minTextValueValidator(),
                ValidatorFactory.maxTextValueValidator()
            ];
        }
    },
};
const getCommonProps = fieldDefinition => {
    return {
        key: fieldDefinition.name,
        name: fieldDefinition.name,
        label: fieldDefinition.label,
        className: fieldDefinition.className || '',
        validate: fieldDefinition.validate ? fieldDefinition.validate : undefined,
        normalize: fieldDefinition.normalize ? fieldDefinition.normalize : undefined,
        warn: fieldDefinition.warn ? fieldDefinition.warn : undefined,
        formatInput: fieldDefinition.formatInput ? fieldDefinition.formatInput : undefined,
        disabled: fieldDefinition.disabled ? fieldDefinition.disabled : undefined,
    };
};

const fieldComponents = {
    select: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        let withInput;
        let disabled = false;
        if (fieldDefinition.name === 'breed' || fieldDefinition.name === 'veterinarian') {
            withInput = true;
        }
        if (fieldDefinition.name === 'trainer' && isTrainer()) {
            disabled = true;
        }

        return (
            <Field
                {...commonProps}
                type='text'
                async
                onChange={(e, fieldDefinition, commonProps) => {
                    if (!formProps.onFieldChange) {
                        return;
                    }
                    formProps.onFieldChange(e, fieldDefinition);
                }}
                disabled={disabled}
                getOptions={formProps.onGetOptions(fieldDefinition)}
                selectedValue={fieldDefinition.value}
                component={renderSelectControl}
                withInput={withInput} />
        );
    },
    select_dogs: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const { formValues } = formProps;
        const formValuesJS = formValues ? formValues.toJS() : {};
        const dogs = formValuesJS.customer_dog

        return (
            <div className='input-control'>
                <Field
                    {...commonProps}
                    dogs={dogs}
                    onChange={(commonProps) => {
                    }}
                    component={DogSelector} />
            </div>
        );
    },
    location_array: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='text'
                async
                multibox
                onChange={(e, fieldDefinition) => {
                    if (!formProps.onFieldChange) {
                        return;
                    }
                    formProps.onFieldChange(e, fieldDefinition);
                }}
                getOptions={formProps.onGetOptions(fieldDefinition)}
                selectedValue={fieldDefinition.value}
                component={renderSelectControl} />
        );
    },
    training_instructor: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='text'
                async
                onChange={(e, fieldDefinition) => {
                    if (!formProps.onFieldChange) {
                        return;
                    }
                    formProps.onFieldChange(e, fieldDefinition);
                }}
                disabled={isTrainer()}
                getOptions={formProps.onGetOptions(fieldDefinition)}
                selectedValue={fieldDefinition.value}
                component={renderSelectControl} />
        );
    },
    select_sync: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='text'
                onChange={(e, fieldDefinition) => {
                    if (!formProps.onFieldChange) {
                        return;
                    }
                    formProps.onFieldChange(e, fieldDefinition);
                }}
                options={fieldDefinition.options}
                selectedValue={fieldDefinition.value}
                component={renderSelectControl} />
        );
    },

    veterinarian_with_create: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const fieldProps = Object.assign(
            {},
            formProps.data.fieldProps ? formProps.data.fieldProps[commonProps.name] : {},
        );

        return (
            <VeterinarianControl
                {...commonProps}
                {...fieldProps}
                formName={formName}
                value={fieldDefinition.value}
                isOpened={isOpened} />
        );
    },

    allergies: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <AllergiesControl
                {...commonProps}
                {...fieldDefinition}
                additional={fields['intolerance_list_requaired']} //eslint-disable-line
                isOpened={isOpened} />
        );
    },

    medical_notes_section: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <MedicalNotesControl
                {...commonProps}
                {...fieldDefinition}
                additional={fields['medical_notes_requaired']} //eslint-disable-line
                isOpened={isOpened} />
        );
    },

    creatable: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='text'
                creatableSelect
                getOptions={formProps.onGetOptions(fieldDefinition)}
                selectedValue={fieldDefinition.value}
                component={renderSelectControl} />
        );
    },

    autocomplete: ({ fieldDefinition, formProps, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='select-multiple'
                autocomplete
                autoload
                getOptions={_.debounce(formProps.onGetOptions(fieldDefinition), inputChangeDebounceTime)}
                onChange={(e, newValue, oldValue) => {
                    if (fieldDefinition.name === 'notify_users') {
                        const selector = newValue
                        const options = createDogsProgramsOptions({ data: selector })
                        store.dispatch(change('editCreateFormForm', 'customer_dog', options))
                    }
                    if (!formProps.onFieldChange) {
                        return;
                    }
                    e.field = fieldDefinition;
                    e.oldValue = oldValue;
                    formProps.onFieldChange(e, newValue, oldValue);
                }}
                isLoading={formProps.busyFields && formProps.busyFields.includes(fieldDefinition.name)}
                optionRenderer={fieldDefinition.optionRenderer}
                noResultsText={fieldDefinition.noResultsText}
                selectedValue={fieldDefinition.value}
                component={renderSelectControl} />
        );
    },

    trainer_by_training_program: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const { formValues } = formProps;
        const formValuesJS = formValues && formValues.toJS ? formValues.toJS() : null;
        let getOptions = (value, callback) => {
            return callback(null, []);
        };
        if (formValuesJS && formValuesJS.program && !isTrainer()) {
            getOptions = _.debounce(
                formProps.onGetOptions(fieldDefinition, {
                    programId: formValuesJS.program.value
                }),
                inputChangeDebounceTime
            );
        }

        const programId = (formValuesJS && formValuesJS.program) ? formValuesJS.program.value : undefined;

        return (
            <Field
                {...commonProps}
                // rerendering this field when new program is selected so getOptions fires
                key={`program_${programId}`}
                type='text'
                autoload
                autocomplete
                disabled={((formValuesJS && formValuesJS.program === null) === null) || fieldDefinition.disabled}
                getOptions={getOptions}
                onChange={(e, newValue, oldValue) => {
                    if (!formProps.onFieldChange) {
                        return;
                    }
                    e.field = fieldDefinition;
                    e.oldValue = oldValue;
                    formProps.onFieldChange(e, newValue, oldValue);
                }}
                isLoading={formProps.busyFields && formProps.busyFields.includes(fieldDefinition.name)}
                optionRenderer={fieldDefinition.optionRenderer}
                noResultsText={fieldDefinition.noResultsText}
                selectedValue={fieldDefinition.value}
                component={renderSelectControl} />
        );
    },

    input: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        if (fieldDefinition.fieldType === 'tel') {
            commonProps.mask = PHONE_MASK;
            commonProps.maskChar = PHONE_MASK_CHAR;
            commonProps.normalize = phoneNumberNormalizer;
            commonProps.isMasked = true;
        }
        return (
            <Field
                {...commonProps}
                type={fieldDefinition.fieldType !== undefined ? fieldDefinition.fieldType : 'input'}
                component={renderInputControl} />
        );
    },

    text_area: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type={'text'}
                isTextarea
                component={renderInputControl} />
        );
    },

    creatable_multi: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <div className='modal-dialog__form_dog-creation' key={fieldDefinition.name}>
                <Field
                    {...commonProps}
                    type={'text'}
                    className='select-control__is_intoleranced'
                    multi
                    creatable
                    component={renderSelectControl} />
            </div>
        );
    },

    report_form_photo: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <DogReportEditControl
                commonProps={commonProps}
                fieldDefinition={fieldDefinition}
                formProps={formProps}
                isOpened={isOpened} />
        );
    },

    report_form_icon_checkbox: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='checkbox'
                icons={fieldDefinition.icons}
                validate={fieldDefinition.validate}
                maxCheckedNum={3}
                component={renderCheckboxGroupControl} />
        );
    },

    date: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='text'
                component={renderDateControl} />
        );
    },

    date_disable_past: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='text'
                disablePast
                component={renderDateControl} />
        );
    },

    date_observed: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                disablePast
                type='text'
                onChange={(e, newValue) => {
                    e.field = fieldDefinition;
                    e.value = newValue;
                    formProps.onFieldChange(e);
                }}
                component={renderDateControl} />
        );
    },

    report_form_selectable: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const { icons } = fieldDefinition;
        const { formValues } = formProps;
        const formValuesJS = formValues ? formValues.toJS() : {};
        const fields = [];

        const getIconIndex = iconValue => {
            return icons.findIndex(icon => {
                return icon.type === iconValue;
            });
        };

        const getIconLabel = iconValue => {
            return icons[getIconIndex(iconValue)].label;
        };

        const renderFields = () => {
            for (let i = 0; i < 3; i++) { //eslint-disable-line
                if (formValuesJS.icon_select && i in formValuesJS.icon_select) {
                    fields.push(
                        <Field
                            key={formValuesJS.icon_select[i]}
                            name={formValuesJS.icon_select[i]}
                            label={getIconLabel(formValuesJS.icon_select[i])}
                            async
                            shouldAlwaysDisplayError
                            type='text'
                            getOptions={formProps.onGetOptions(fieldDefinition, {
                                iconType: formValuesJS.icon_select[i]
                            })}
                            validate={fieldDefinition.validate}
                            component={renderSelectControl} />
                    );
                } else {
                    fields.push(
                        <Field
                            key={'icon_select_' + i}
                            name={'icon_select_disabled_' + i}
                            label='Select icon'
                            type='text'
                            disabled
                            component={renderSelectControl} />
                    );
                }
            }

            return fields;
        };

        return (
            <div className='form__aligner form__aligner_33-33-33' key='report_form_selectable'>
                {renderFields()}
            </div>
        );
    },

    radio: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <div className='form__select-aligner' key={fieldDefinition.name}>
                {fieldDefinition.title && <div className='form__section-title'>{fieldDefinition.title}</div>}
                <div className='radio-control'>
                    <div className='radio-control__description'>
                        <label className='radio-control__description-label'>
                            {fieldDefinition.label}
                        </label>
                        <Field name='is_altered' component={renderError} />
                        <Field name='is_altered' component={renderWarning} />
                    </div>
                    <div className='radio-control__controls'>
                        {fieldDefinition.options.map((option, i) => {
                            return (
                                <label
                                    className='radio-control__controls-label'
                                    key={`${fieldDefinition.name}_${i}`}>
                                    <Field
                                        name={fieldDefinition.name}
                                        component='input'
                                        type='radio'
                                        value={option.value}
                                        warn={fieldDefinition.warn ? fieldDefinition.warn : undefined}
                                        className='radio-control__controls-input' />
                                    <span className='radio-control__controls-text'>{option.label}</span>
                                </label>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    },

    search_add: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const fieldProps = Object.assign(
            {},
            formProps.data.fieldProps ? formProps.data.fieldProps[commonProps.name] : {},
        );

        const { currentEvent } = formProps.data;
        const { eventFormValues } = formProps;

        const currentEventJS = currentEvent.toJS();
        const { type } = currentEventJS.event;
        currentEventJS.formValues = eventFormValues;

        const eventAccessLevel = eventFormValues && eventFormValues.access_level
            ? eventFormValues.access_level.value
            : undefined;
        const eventProgramTrainer = type === 'training' && eventFormValues && eventFormValues.program && eventFormValues.trainer
            ? `${eventFormValues.program.value}${eventFormValues.trainer.value}`
            : '';

        return (
            <Field
                {...commonProps}
                {...fieldProps}
                key={`search_add_${eventAccessLevel}_${eventProgramTrainer}`}
                type='text'
                formProps={formProps}
                autoload={!!eventProgramTrainer}
                modalData={currentEvent}
                getOptions={_.debounce(formProps.onGetOptions(
                    fieldDefinition, currentEventJS),
                    inputChangeDebounceTime
                )}
                callbackFunctionName={currentEvent.getIn(['event', 'program']) !== undefined
                    ? 'handleTrainingBookingModalDialog'
                    : 'handleClassBookingModalDialog'}
                optionRenderer={fieldDefinition.optionRenderer}
                header={fieldDefinition.header}
                noResultsText={fieldDefinition.noResultsText}
                selectedValue={fieldDefinition.value}
                component={renderSearchAddControl} />
        );
    },

    search_add_agreement_recipient: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='text'
                formProps={formProps}
                getOptions={_.debounce(formProps.onGetOptions(
                    fieldDefinition, formProps.formValues
                ), inputChangeDebounceTime)}
                optionRenderer={fieldDefinition.optionRenderer}
                noResultsText={fieldDefinition.noResultsText}
                selectedValue={fieldDefinition.value}
                component={renderSearchAddTagControl} />
        );
    },

    separator: () => {
        return (
            <div className='form__separator' />
        );
    },

    weekdays: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='checkbox'
                description={fieldDefinition.description}
                options={fieldDefinition.options}
                component={renderCheckboxListControl} />
        );
    },

    image_loader: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        let uploadComponent = FormImageUpload;

        if (fieldDefinition.uploadComponent) {
            uploadComponent = fieldDefinition.uploadComponent;
        }

        const fieldProps = Object.assign(
            {},
            formProps.data.fieldProps ? formProps.data.fieldProps[commonProps.name] : {},
        );

        return (
            <Field
                {...commonProps}
                {...fieldProps}
                type='file'
                component={renderImageControl}
                uploadComponent={uploadComponent}
                image={typeof fieldDefinition.value === 'string' ? fieldDefinition.value : null}
                isOpened={isOpened} />
        );
    },

    select_autocomplete: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const { searchOptions, optionsParser } = fieldDefinition;

        let searchMethod = () => {
        };

        let optionsParserMethod = () => {
            return [];
        };

        if (searchOptions) {
            searchMethod = searchOptions;
        }

        if (optionsParser) {
            optionsParserMethod = optionsParser;
        }

        const searchCaller = _.debounce((value, callback) => {
            searchMethod(value)
                .then(response => {
                    callback(null, {
                        options: optionsParserMethod(response),
                    });
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);
                    callback(error);
                });
        }, 500);

        return (
            <Field
                {...commonProps}
                autoload
                autocomplete
                getOptions={searchCaller}
                optionRenderer={fieldDefinition.optionRenderer}
                noResultsText={<span className='no-results-message'>Please choose an existing instructor</span>}
                type='text'
                component={renderSelectControl} />
        );
    },

    select_creatable_autocomplete: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const { searchOptions, optionsParser } = fieldDefinition;

        let searchMethod = () => {
            console.log('default search');
        };

        let optionsParserMethod = () => {
            console.log('default option parser');
            return [];
        };

        if (searchOptions) {
            searchMethod = searchOptions;
        }

        if (optionsParser) {
            optionsParserMethod = optionsParser;
        }

        const searchCaller = _.debounce((value, callback) => {
            searchMethod(value)
                .then(response => {
                    callback(null, {
                        options: optionsParserMethod(response),
                    });
                })
                .catch(error => {
                    console.log(error);
                    console.log(error.response);
                    callback(error);
                });
        }, 500);

        return (
            <Field
                {...commonProps}
                async
                creatable
                getOptions={searchCaller}
                optionRenderer={fieldDefinition.optionRenderer}
                ignoreCase={fieldDefinition.ignoreCase}
                noResultsText={<span className='no-results-message'>Please choose an existing instructor</span>}
                type='text'
                component={renderSelectControl} />
        );
    },

    date_interval: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const { onChange } = formProps.data;
        return (
            <Field
                {...commonProps}
                startProps={fieldDefinition.startProps}
                endProps={fieldDefinition.endProps}
                onChange={onChange}
                type='text'
                disablePast={fieldDefinition.disablePast}
                disableFuture={fieldDefinition.disableFuture}
                noEndDateCheckbox={fieldDefinition.noEndDateCheckbox}
                component={renderDateIntervalControl} />
        );
    },

    number_input: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const fieldProps = Object.assign(
            {},
            formProps.data.forms
                ? formProps.data.forms[formProps.data.activeForm].fieldProps[commonProps.name]
                || {}
                : formProps.data.fieldProps
                    ? formProps.data.fieldProps[commonProps.name]
                    : {}
        );

        return (
            <Field
                {...commonProps}
                {...fieldProps}
                component={renderNumberInputControl} />
        );
    },

    pickup_dropoff_address: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const fieldProps = Object.assign(
            {},
            formProps.data.fieldProps ? formProps.data.fieldProps[commonProps.name] : {},
        );

        return (
            <PickupDropoffControl
                {...commonProps}
                {...fieldProps}
                formProps={formProps}
                isOpened={isOpened} />
        );
    },

    payment_information: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const fieldProps = Object.assign(
            {},
            formProps.data.fieldProps ? formProps.data.fieldProps[commonProps.name] : {},
        );
        return (
            <PaymentControl
                {...commonProps}
                {...fieldProps}
                isOpened={isOpened} />
        );
    },

    sell_payment_information: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const { data } = formProps;
        const fieldProps = Object.assign(
            {
                ownerId: data.activeForm !== undefined ? data.forms[data.activeForm].initialValues.customerId : data.currentCustomerId,
                optionRenderer: fieldDefinition.optionRenderer || null
            },
            formProps.data.fieldProps ? formProps.data.fieldProps[commonProps.name] : {},
        );
        return (
            <PaymentControl
                {...commonProps}
                {...fieldProps}
                isOpened={isOpened} />
        );
    },

    total_charge: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const fieldProps = Object.assign(
            {},
            formProps.data.fieldProps ? formProps.data.fieldProps[commonProps.name] : {},
        );

        return (
            <div
                key={fieldDefinition.name}
                className='form__middle-text'>
                Total to charge: {fieldProps.value}
            </div>
        );
    },

    sell_charge_info: ({ fieldDefinition, formProps, isOpened, commonProps }) => {
        const { data } = formProps;
        const { name, formName, purchase } = fieldDefinition;
        return (
            <DiscountControl key={name} formName={formName} purchaseType={purchase} />
        );
    },

    sell_total_charge: ({ fieldDefinition, formProps, isOpened, commonProps }) => {
        const { data } = formProps;
        const { name, formName, purchase } = fieldDefinition;
        return (
            <TotalChargeControl key={name} formName={formName} purchaseType={purchase} />
        );
    },

    package_multibox: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        const fieldProps = Object.assign(
            {},
            formProps.data.fieldProps ? formProps.data.fieldProps[commonProps.name] : {},
        );
        return (
            <Field
                {...commonProps}
                {...fieldProps}
                multibox
                options={formProps.onGetOptions(fieldDefinition)}
                component={renderSelectControl} />
        );
    },

    dog_selector: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                type='hidden'
                component={DogSelectorControl} />
        );
    },
    text_editor: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        return (
            <Field
                {...commonProps}
                formProps={formProps}
                component={renderTextEditorControl} />
        );
    },
    credit_card: () => {
        return (
            <CreditCardControl />
        );
    },

    message_input: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        if (isMobile) {
            return (
                <Field
                    {...commonProps}
                    isTextarea
                    label='Create SMS message...'
                    component={renderInputControl}
                    placeholder='Create SMS message...' />
            );
        }
        return (
            <Field
                {...commonProps}
                component='input'
                placeholder='Create SMS message...' />);
    },

    message_photo: ({ fieldDefinition, formProps, formName, isOpened, commonProps }) => {
        let loadedPhotosAmount;
        const { formState } = formProps.data;
        const isMessagePhotoUploaded = (
            formState &&
            formState.values !== undefined &&
            formState.values.message_photo &&
            formState.values.message_photo.length
        );
        if (isMessagePhotoUploaded) {
            loadedPhotosAmount = formState.values.message_photo.length;
        }
        return (
            <Field
                {...commonProps}
                multiple
                maxFiles={5}
                type='file'
                disabled={loadedPhotosAmount >= 5}
                isOpened={isOpened}
                uploadComponent={MessagesImageUpload}
                component={renderImageControl}
                image={typeof fieldDefinition.value === 'string' ? fieldDefinition.value : null}
                dataState={formProps.data.dataState} />
        );
    },
};

export const getField = ({
    key,
    fieldName,
    initialValues,
    formProps,
    isOpened,
    isDisabled,
}) => {
    const fieldDefinition = fields[fieldName];
    // if there were no initialValues in the corresponding action in openActions
    if (initialValues !== undefined) {
        fieldDefinition.value = initialValues[fieldName];
    }

    if (isDisabled !== undefined) {
        fieldDefinition.disabled = isDisabled;
    }

    key = `${formProps.form}-${fieldName}-${key}`;
    const FieldComponent = fieldComponents[fieldDefinition.type];
    return (
        <FieldComponent
            commonProps={getCommonProps(fieldDefinition)}
            key={key}
            fieldDefinition={fieldDefinition}
            formProps={formProps}
            isOpened={isOpened} />
    );
};
