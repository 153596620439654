/* eslint-disable import/no-extraneous-dependencies */
import 'babel-polyfill';

import React from 'react';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';
import browserHistory from 'react-router/lib/browserHistory';
import './assets/styles/blocks/dashboard/calendar/react-big-calendar/styles.scss';
import './assets/styles/critical.scss';
import './assets/styles/index.scss';
import { Routes } from './routes';
import { createRoot } from 'react-dom/client';
import configureHttpService from './services/http';
import { setAuthorizationHeader } from './services/oauth';
import configureStore from './store/configureStore';
import { unregister } from './service-worker';

require('./assets/imgs/watermark.png');
require('./assets/imgs/employee-login.jpg');
require('./assets/imgs/employee-login_tablet.jpg');
require('./assets/imgs/employee-login_mobile.jpg');
require('./assets/imgs/dashboard-pattern.png');
require('./assets/imgs/customer/featured-class-desktop.jpg');
require('./assets/imgs/customer/welcome-screen-1.jpg');
require('./assets/imgs/customer/welcome-screen-2.jpg');
require('./assets/imgs/icon-location-default.png');
require('./assets/imgs/icon-location-orange.png');
require('./assets/imgs/icon-location-lightblue.png');
require('./assets/imgs/campaigns/templates/290-1.jpg');
require('./assets/imgs/campaigns/templates/290-2.jpg');
require('./assets/imgs/campaigns/templates/290-3.png');
require('./assets/imgs/campaigns/templates/290-4.jpg');
require('./assets/imgs/campaigns/templates/900_645_01.jpg');
require('./assets/imgs/campaigns/templates/900_645_02.jpg');
require('./assets/imgs/campaigns/templates/1830_960_01.jpg');
require('./assets/imgs/campaigns/templates/1830_960_02.jpg');
require('./assets/imgs/campaigns/templates/1830_960_03.jpg');

export const store = configureStore();

configureHttpService(store);

const history = syncHistoryWithStore(browserHistory, store, {
    selectLocationState(state) {
        return state.get('routing').toJS();
    }
});

setAuthorizationHeader(localStorage.accessToken);

const rootElement = createRoot(document.getElementById("root"));

const renderApp = () => rootElement.render(
  <Provider store={store}>
      <Routes store={store} history={history}/>
  </Provider>
);

if (module.hot) {
    module.hot.accept(['./routes', './reducers'], () => {
        store.replaceReducer(require('./reducers').default);
        renderApp();
    });
}

renderApp();
unregister();
