import classNames from 'classnames';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Field, getFormSubmitErrors, reduxForm } from 'redux-form/immutable';
import { setAuthorizationHeader } from '../../../../../services/oauth';
import renderInputControl from '../../../../common/Form/InputControl';
import validate from './validate';
import { phoneNumberNormalizer, PHONE_MASK, PHONE_MASK_CHAR } from '../../../../../helpers/normalize';

class FinishSignUpForm extends React.PureComponent {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
        change: PropTypes.func,
        userData: PropTypes.shape({
            signUpData: PropTypes.shape({}),
        }),
        onSubmit: PropTypes.func,
        submitting: PropTypes.bool,
        invalid: PropTypes.bool,
        error: PropTypes.oneOfType([
            PropTypes.shape({}),
            PropTypes.string
        ])
    };

    componentDidMount() {
        setAuthorizationHeader();
        // deleteAccessToken();
    }

    // componentDidUpdate(prevProps) {
    //     const { change, userData } = this.props;
    //     const userDataJS = userData.toJS();
    //     console.log('userDataJS: ', userDataJS);
    //     const prevUserDataJS = prevProps.userData.toJS();
    //     console.log('prevUserDataJS: ', prevUserDataJS);
    //     // console.log('PREV PROPS', Object.keys(userDataJS.signUpData).length,
    //     // Object.keys(prevUserDataJS.signUpData).length);
    //     if (Object.keys(userDataJS.signUpData).length && !Object.keys(prevUserDataJS.signUpData).length) {
    //         // if user not signed up
    //         change('full_name', userDataJS.full_name || '');
    //         change('email', userDataJS.email || '');
    //         change('phone', userDataJS.phone || '');
    //     }
    // }

    renderErrors = () => {
        const { error } = this.props;
        return error && <div className='form__error'>{error}</div>;
    };

    render() {
        const { handleSubmit, submitting, invalid, error, userData } = this.props;
        const SubmitButtonClassNames = classNames({
            'signup__submit-button': true,
            'signup__submit-button_disabled': (invalid || submitting)
        });
        const userDataJS = userData.toJS();
        const { socialName } = userDataJS.signUpData;
        return (
            <form onSubmit={handleSubmit} className='signup__form'>
                <input
                    type='email'
                    value=''
                    component='input'
                    name='_email'
                    style={{ width: '0', height: '0', border: 'none' }}/>
                <input
                    type='password'
                    value=''
                    component='input'
                    name='_password'
                    style={{ width: '0', height: '0', border: 'none' }}/>
                <Field name='full_name' type='text' component={renderInputControl} formError={error} label='Full Name'/>
                <Field disabled={Boolean(userDataJS.email)} name='email' type='email' component={renderInputControl} formError={error} label='Email'/>
                <Field
                    name='phone'
                    type='tel'
                    component={renderInputControl}
                    formError={error}
                    mask={PHONE_MASK}
                    maskChar={PHONE_MASK_CHAR}
                    isMasked
                    normalize={phoneNumberNormalizer}
                    label='Phone Number'/>
                {this.renderErrors()}
                <button className={SubmitButtonClassNames} type='submit' disabled={submitting}>
                    Sign Up With {socialName === 'fb' ? 'Facebook' : 'Google'}
                </button>
            </form>
        );
    }
}

const form = reduxForm({
    form: 'finishSignUpForm',
    destroyOnUnmount: false,
    validate
});

const mapStateToProps = state => ({
    initialValues: fromJS({
        full_name: state.getIn(['user', 'full_name']),
        email: state.getIn(['user', 'email']),
        phone: state.getIn(['user', 'phoneId', 'phone_number']),
    }),
    userData: state.get('user'),
    submitErrors: getFormSubmitErrors('signUpForm')(state)
});

export default connect(mapStateToProps, null)(form(FinishSignUpForm));
