import { push } from 'react-router-redux';
import { createAction } from 'redux-actions';
import * as AgreementsServices from '../services/agreements';
import * as AgreementsTypes from '../types/AgreementsTypes';
import { isCustomer } from '../helpers/userRoles';

export const markAgreementsLoaded = createAction(AgreementsTypes.MARK_AGREEMENTS_LOADED);
export const unmarkAgreementsLoaded = createAction(AgreementsTypes.UNMARK_AGREEMENTS_LOADED);
export const setAgreements = createAction(AgreementsTypes.SET_AGREEMENTS);
export const removeAgreement = createAction(AgreementsTypes.REMOVE_AGREEMENT);

export const getAgreements = () => {
    return async dispatch => {
        dispatch(unmarkAgreementsLoaded());
        const getProfileAgreements = await dispatch(AgreementsServices.getProfileAgreements())
            .then(response => {
                dispatch(setAgreements(response.data.results));
                dispatch(markAgreementsLoaded());
                return response;
            });
        return getProfileAgreements;
    };
};

export const checkAgreements = (id = null) => {
    return dispatch => {
        dispatch(getAgreements())
            .then(response => {
                const { results, count } = response.data;
                if (count === 0 && id !== null) {
                    if (isCustomer()) {
                        dispatch(push('/home'));
                    } else {
                        dispatch(push('/dashboard'));
                    }
                } else if (count > 0) {
                    const data = results.find((item) => {
                        return item.id !== id ? item : false;
                    });
                    if (data !== false) dispatch(push(`/form-update/${results[0].id}`));
                }
            });
    };
};

export const signAgreements = (id, nextUrl = null) => {
    return dispatch => {
        dispatch(AgreementsServices.signAgreements(id)).then(() => {
            if (nextUrl) dispatch(push(nextUrl));
            dispatch(removeAgreement(id));
        });
        if (nextUrl === '/welcome' || nextUrl === '/home') {
            window._dcq.push(['track', 'Signed agreement']);
        }
    };
};
