/* eslint-disable camelcase,array-callback-return,consistent-return */
import * as axios from 'axios';
import { fromJS } from 'immutable';
import { uniqBy } from 'lodash';
import moment from 'moment';
import { addNewVeterinarianOption, setVetInfoModalClosed } from '../actions/miscActions';

const createOptions = (data) => {
    const { values, description } = data;
    return fromJS(values.map(value => {
        return {
            value,
            label: description[value],
        };
    }));
};

export const createUserGroupingOptions = data => {
    return data.map(item => {
        return {
            value: item[0],
            label: item[1].toLowerCase(),
        };
    });
};

const createIconOptions = data => {
    Object.keys(data).forEach(key => {
        data[key] = data[key].map(option => {
            return {
                label: option,
                value: option,
            };
        });
    });
    return data;
};

const createUserOptions = ({ data, isAllOptionsEnable }) => {
    const options = data.results.map(user => {
        let disabled = false;

        if (user.is_available !== undefined && user.is_available === false) {
            disabled = true;
        }

        return { label: user.full_name, value: user.id, query: 'trainer', disabled };
    });

    if (isAllOptionsEnable) {
        options.unshift({ label: 'All', value: '', query: 'trainer' });
    }

    return options;
};

const createRemovalReasonOptions = (data) => {
    return data.map(item => ({
        value: item[0],
        label: item[1],
    }));
};

export const createEventOptions = ({ data, isOptionsUniqueByName }) => {
    let dataArray;
    if (data.results === undefined) {
        dataArray = data;
    } else {
        dataArray = data.results;
    }
    const options = dataArray.map(event => {
        return {
            label: event.name,
            value: event.id,
            query: 'training_class',
            extra: {
                price: event.price,
                zipCodes: event.valid_zip_codes_array,
            },
        };
    });
    if (isOptionsUniqueByName) {
        return uniqBy(options, 'label');
    }

    return options;
};

export const createCalendarEventOptions = ({ data }) => {
    let dataArray;
    if (data.results === undefined) {
        dataArray = data;
    } else {
        dataArray = data.results;
    }
    const options = dataArray.map(event => {
        return {
            label: event.name,
            value: event.name,
            query: 'name',
        };
    });

    options.unshift({ label: 'All', value: '', query: 'name' });
    return uniqBy(options, 'label');
};

const createClassEventDogOptions = (data, currentEvent) => {
    console.log('createClassEventDogOptions')
    console.log(data)
    console.log(currentEvent)
    const eventAccessLevel = currentEvent.formValues.access_level.value === undefined
        ? currentEvent.formValues.access_level
        : currentEvent.formValues.access_level.value;
    const eventName = currentEvent.event.training_class_detail.name;
    let is_puppy_class = false;
    if (eventName.toLowerCase().includes('puppy')) {
        is_puppy_class = true;
    }

    return data.results.map(dog => {
        const { name, id, is_available, birthday, access_level, owner_name, owner, photo_processed, access_level_detail } = dog;
        let is_puppy = false
        const today = moment();
        const dog_age_months = moment.duration(today.diff(moment(birthday))).asMonths();
        if (is_puppy_class) {
            if (dog_age_months > 3 & dog_age_months < 6) {
                is_puppy = true;
            }
        }

        return {
            label: name,
            value: id,
            disabled: !is_available || (access_level < eventAccessLevel) || (is_puppy_class & !is_puppy) || (dog_age_months < 3),
            extra: {
                owner: owner_name,
                ownerId: owner,
                photo: photo_processed,
                accessLevel: access_level,
                accessLevelDetail: access_level_detail,
                eventAccessLevel,
                isAvailable: is_available,
                is_puppy_class: is_puppy_class,
                is_puppy: is_puppy,
                dog_age_months: dog_age_months,
            },
        };
    });
};

const createTrainingEventDogOptions = (data, currentEventJS) => {
    return data.results.map(program => {
        let isScheduledAlready;

        if (!currentEventJS.products.length) {
            isScheduledAlready = false;
        } else {
            isScheduledAlready = Boolean(
                // new training case
                currentEventJS.products.find(product => {
                    return product.dog_detail.id === program.dog;
                }),
            );
        }

        const {
            customer, customer_full_name, dog_photo_processed, quantity_remaining, dog, dog_detail, customer_detail,
            id,
        } = program;

        return {
            label: dog_detail.name,
            value: dog,
            disabled: isScheduledAlready || quantity_remaining === 0,
            extra: {
                ownerId: customer,
                owner: customer_full_name,
                photo: dog_photo_processed,
                sessions: quantity_remaining,
                product: {
                    purchased: id,
                    status: 0,
                    dog_detail,
                    owner_detail: customer_detail,
                    status_detail: 'Scheduled',
                },
                isScheduled: isScheduledAlready,
            },
        };
    });
};

const createTrainingProgramsOptions = ({ data, isAllOptionsEnable }) => {
    const normalizedData = data.results ? data.results : data;
    const options = normalizedData.map(training => {
        return { label: training.name, value: training.id, query: 'program' };
    });

    if (isAllOptionsEnable) {
        options.unshift({ label: 'All', value: '', query: 'program' });
    }

    return options;
};


const createAvailableTrainingProgramsOrPackagesOptions = data => {
    const items = (data && Array.isArray(data)) ? data : data.results;
    return items.map(({ price, quantity, name, id }) => ({
        label: `${name} - ${quantity} Pack - $${price}`,
        value: id,
        price,
    }));
};


export const createDogsProgramsOptions = ({ data }) => {

    const normalizedData = () => data != null && data.extra != null && data.extra.dogs != null ? data.extra.dogs : [];
    const options = normalizedData().map(dog => {
        return { label: dog.name, value: dog.pk };
    });
    return options;
};

export const createLocationOptions = ({ data, isAllOptionsEnable }) => {
    const { values, description } = data;
    const options = values.map(value => {
        return {
            value,
            label: description[value],
            query: 'location',
            clearableValue: false
        };
    });

    if (isAllOptionsEnable) {
        options.unshift({ label: 'All', value: '', query: 'location' });
    }

    return fromJS(options);
};

// Breed and Veterinatian cases
// those values are created from BD so the response is just [{}];
const createDBOptions = (DBdata, context) => {
    let data;
    if (DBdata.results) {
        data = DBdata.results;
    } else {
        data = DBdata;
    }

    return fromJS(data.map(value => {
        const { id, name, phone, address } = value;
        if (context === 'veterinarian') {
            return {
                value: id,
                label: name,
                // Need these extra fields to update the UI when editing veterinarian on the Dog Details page.
                phone,
                address,
            };
        }

        return {
            value: id,
            label: name,
        };
    }));
};

export const createFormRecipientsOptions = (data, userGroupingOptions, currentNotifyUsersValue) => {
    const isAlreadySelected = recipientId => {
        if (!currentNotifyUsersValue) {
            return false;
        }

        return currentNotifyUsersValue.some(item => item.id === recipientId);
    };

    return data.map(item => {
        const groupingType = item.grouping_type;
        const isGroup = userGroupingOptions.find(option => {
            return option.get('value') === groupingType;
        }).get('label') === 'group';
        return {
            label: item.name,
            value: item.id,
            disabled: isAlreadySelected(item.id),
            extra: {
                email: item.email,
                role: item.role,
                photo: item.photo,
                isGroup,
                groupingType,
                dogs: item.dogs,
            },
        };
    });
};

export const createCustomersOptions = data => {
    return fromJS(data.results.map(item => {
        return {
            value: item.id,
            label: item.full_name,
        };
    }));
};

const createAttendanceByZoneFilterOptions = (data) => {
    const { values, description } = data;
    return fromJS(values.map(value => {
        return {
            value,
            label: description[value],
        };
    }));
};

export const getOptions = (args) => {
    const {
        url, optionContext, actionCB, fieldCB, iconType, extra, requestData, method = 'get',
        isAllOptionsEnable = true,
    } = args;
    return (dispatch, getState) => {
        return axios[method](url, requestData)
            .then((response) => {
                if (optionContext === 'breed' || optionContext === 'veterinarian') {
                    if (fieldCB) {
                        fieldCB(null, {
                            options: createDBOptions(response.data, optionContext).toJS(),
                        });
                    }
                    return dispatch(actionCB(createDBOptions(response.data)));
                }
                if (optionContext === 'weight' || optionContext === 'sex' || optionContext === 'accessLevel') {
                    const options = createOptions(response.data);
                    if (fieldCB) {
                        fieldCB(null, { options: options.toJS() });
                    }

                    if (actionCB) {
                        return dispatch(actionCB(createOptions(response.data)));
                    }
                }
                if (optionContext === 'icons') {
                    const options = createIconOptions(response.data);
                    if (fieldCB) {
                        fieldCB(null, { options: options[iconType] });
                    }
                    return dispatch(actionCB(options));
                }
                if (optionContext === 'user') {
                    const options = createUserOptions({ data: response.data, isAllOptionsEnable: false });
                    if (fieldCB) {
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'discount') {
                    const options = createOptions(response.data);
                    if (fieldCB) {
                        fieldCB(null, { options: options.toJS() });
                    }
                }
                if (optionContext === 'calendarTrainer') {
                    const options = createUserOptions({ data: response.data, isAllOptionsEnable: true });
                    if (fieldCB) {
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'event') {
                    const options = createEventOptions({ data: response.data });
                    if (fieldCB) {
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'uniqueEvent') {
                    const options = createEventOptions({
                        data: response.data, isOptionsUniqueByName: true,
                    });
                    if (fieldCB) {
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'calendarEvent') {
                    const options = createCalendarEventOptions({ data: response.data });
                    if (fieldCB) {
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'classDog') {
                    const options = createClassEventDogOptions(response.data, extra);
                    if (fieldCB) {
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'trainingDog') {
                    const options = createTrainingEventDogOptions(response.data, extra);
                    if (fieldCB) {
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'trainingPrograms') {
                    const options = createTrainingProgramsOptions({ data: response.data, isAllOptionsEnable });
                    if (fieldCB) {
                        fieldCB(null, { options });
                        console.log(options)
                    }
                }
                if (optionContext === 'availableTrainingPrograms' || optionContext === 'availablePackages') {
                    const options = createAvailableTrainingProgramsOrPackagesOptions(response.data);
                    if (fieldCB) {
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'location') {
                    const options = createLocationOptions({ data: response.data, isAllOptionsEnable: false });
                    if (fieldCB) {
                        fieldCB(null, { options: options.toJS(), complete: true });
                    }

                    if (actionCB) {
                        return dispatch(
                            actionCB(
                                createLocationOptions({ data: response.data, isAllOptionsEnable: false })));
                    }
                }
                if (optionContext === 'calendarLocation') {
                    const options = createLocationOptions({ data: response.data, isAllOptionsEnable: true });
                    if (fieldCB) {
                        fieldCB(null, { options: options.toJS() });
                    }

                    if (actionCB) {
                        return dispatch(
                            actionCB(createLocationOptions({ data: response.data, isAllOptionsEnable: true })));
                    }
                }
                if (optionContext === 'packagesClasses') {
                    return dispatch(
                        actionCB(createEventOptions({ data: response.data })));
                }
                if (optionContext === 'removalReason') {
                    if (fieldCB) {
                        const options = createRemovalReasonOptions(response.data);
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'immunization') {
                    return dispatch(actionCB(createOptions(response.data)));
                }
                if (optionContext === 'formRecipients') {
                    if (fieldCB) {
                        const userGroupingOptions = getState().getIn(['misc', 'options', 'userGrouping']);
                        const options = createFormRecipientsOptions(
                            response.data.results,
                            userGroupingOptions,
                            extra,
                        );
                        fieldCB(null, { options });
                    }
                }
                if (optionContext === 'customers') {
                    const options = createCustomersOptions(response.data);
                    if (fieldCB) {
                        fieldCB(null, { options: options.toJS() });
                    }

                    if (actionCB) {
                        return dispatch(actionCB(options));
                    }
                }
                if (optionContext === 'attendance-by-zone-filter') {
                    const options = createAttendanceByZoneFilterOptions(response.data);
                    if (fieldCB) {
                        fieldCB(null, { options: options.toJS() });
                    }

                    if (actionCB) {
                        return dispatch(actionCB(options));
                    }
                }
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response);
            });
    };
};

export const postVeterinarianOption = (values) => {
    return (dispatch) => {
        const request = new FormData();
        request.append('name', values.get('name'));
        request.append('phone_number', values.get('phone'));

        return axios.post('/v1/customers/me/add-custom-veterinarian/', request)
            .then((response) => {
                dispatch(setVetInfoModalClosed());
                const { id, name } = response.data;
                dispatch(addNewVeterinarianOption({ value: id, label: name }));
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response);
            });
    };
};
