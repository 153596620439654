import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import Icon from '../../../../common/Icon';
import { setDogEvaluationModalOpened } from '../../../../../actions/miscActions';
import { bool2Char } from '../../../../../helpers/data';

const questions = [
    ['assessment', 'Did the dog pass the Fitdog assessment?'],
    ['come_command', 'What’s your dog’s command for “come”?'],
    ['breed_reactive', 'Is your dog reactive to certain breeds?'],
    ['onleash_reactive', 'Is your dog reactive on-leash?'],
    ['triggers', 'Does your dog have any triggers such as skateboarders?'],
    ['ball_aggressive', 'Is your dog ball aggressive?'],
    ['car_sick', 'Does your dog experience car sickness?'],
    ['treats_word', 'What is your dog’s word for treats?'],
    ['last_flea', 'When was the last time your dog was given flea/tick medication?'],
    ['other', 'Other: exchange keys (if necessary); check tags or put paper tag on dog'],
    ['additional', 'General notes']
];

class EvaluationPage extends React.PureComponent {
    static propTypes = {
        dog: PropTypes.shape({}),
        isLoading: PropTypes.bool.isRequired,
        routeParams: PropTypes.shape({}).isRequired,
        setDogEvaluationModalOpened: PropTypes.func,
    };

    getSurvey() {
        const { dog, isLoading } = this.props;

        if (isLoading) {
            return (
                <div className='dog-evaluation__item'>Loading...</div>
            );
        }

        if (!dog) {
            return null;
        }

        const survey = dog.get('survey').toJS();
        return questions.map((item) => {
            const answer = survey[item[0]];
            return (
                <div key={item[0]} className='dog-evaluation__item'>
                    <div className='dog-evaluation__question'>
                        { item[1] }
                    </div>
                    <div className='dog-evaluation__answer'>
                        { (typeof (answer) === 'boolean') ? bool2Char(answer) : answer }
                    </div>
                </div>
            );
        });
    }

    handleEditButtonClick = e => {
        e.preventDefault();
        this.props.setDogEvaluationModalOpened();
    };
    
    render() {
        return (
            <div className='dog-evaluation'>
                <div className='dog-evaluation__content'>
                    <div className='dog-evaluation__header'>
                        Evaluation Questions
                         {/*eslint-disable-next-line*/}
                        {<a onClick={this.handleEditButtonClick} className='dashboard__header-action'>
                            <Icon glyph={IconEdit} className={'icon_dashboard-edit'}/>
                        </a>}
                    </div>
                    <div className='dog-evaluation__items'>
                        { this.getSurvey() }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dog: state.getIn(['currentDog', 'instance']),
        isLoading: state.getIn(['currentDog', 'isLoading'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    setDogEvaluationModalOpened: bindActionCreators(setDogEvaluationModalOpened, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationPage);
