import TemplateTextInputField from '../Form/Fields/TemplateTextInput';
import TemplateTextAreaField from '../Form/Fields/TemplateTextArea';
import ButtonEditableField from '../Form/Fields/ButtonEditable';
import LinkEditorField from '../Form/Fields/LinkEditor';
import ImageSelectorField from '../Form/Fields/ImageSelector';
import TemplateBlockArrayField from '../Form/Fields/TemplateBlockArray';
import HiddenTextInputField from '../Form/Fields/HiddenTextInput';
import ViewOnFacebook from './ViewOnFacebook';
import * as MainForm from '../MarketingEditorPage/form';
import * as Validators from '../../helpers/validate';

const getBlockIDField = (arrayFieldName, specs) => {
    const { name, ...other } = specs;
    return {
        ...other,
        name: `${arrayFieldName}.${name}`,
        component: HiddenTextInputField
    };
};

const getTemplateIDField = () => {
    return {
        name: 'template_id',
        component: HiddenTextInputField
    };
};

const getWeeklyPhotosFacebookField = (arrayFieldName) => {
    return {
        name: `${arrayFieldName}.weekly_photos`,
        component: ViewOnFacebook
    };
};

const getTitleField = (arrayFieldName, specs) => {
    const { name, ...other } = specs;
    return {
        ...other,
        name: `${arrayFieldName}.${name}`,
        component: TemplateTextInputField,
        shouldUseWarning: true,
        warn: [
            Validators.requiredValidator(),
            Validators.maxTextValueValidator()
        ]
    };
};

const getBodyField = (arrayFieldName, specs) => {
    const { name, ...other } = specs;
    return {
        ...other,
        name: `${arrayFieldName}.${name}`,
        component: TemplateTextAreaField,
        shouldUseWarning: true,
        warn: [
            Validators.requiredValidator(),
            Validators.maxTextValueValidator(undefined, { maxValue: 2000 })
        ]
    };
};

const getImageField = (arrayFieldName, specs) => {
    const { name, ...other } = specs;
    return {
        ...other,
        name: `${arrayFieldName}.${name}`,
        component: ImageSelectorField,
        shouldUseWarning: true,
        warn: [
            Validators.requiredValidatorImageSelector(),
            Validators.needsCropValidatorImageSelector()
        ]
    };
};

const getButtonLinkGroup = (arrayFieldName, specs) => {
    return {
        ...specs,
        fields: [
            {
                component: ButtonEditableField,
                name: `${arrayFieldName}.button_text`,
                label: 'Button Text',
                shouldUseWarning: true,
                warn: [
                    Validators.requiredValidator(),
                    Validators.maxTextValueValidator(undefined, { maxValue: 32 })
                ]
            },
            {
                component: LinkEditorField,
                name: `${arrayFieldName}.link`,
                label: 'Link Destination for Button',
                shouldUseWarning: true,
                warn: [
                    Validators.requiredValidator(),
                    Validators.schemeURLValidator()
                ]
            }
        ],
    };
};


const getTemplate3Group1 = (arrayFieldName, specs) => {
    return {
        ...specs,
        fields: [
            getImageField(arrayFieldName, {
                dimensions: [300, 215],
                name: 'photo',
                label: 'Drag and Drop Image for Email\nOr Click to Browse Files',
                className: 'template3-image-selector',
                shouldUseWarning: true
            }),
        ]
    };
};

const getTemplate3Group2 = (arrayFieldName, specs) => {
    return {
        ...specs,
        fields: [
            getTitleField(arrayFieldName, {
                name: 'title',
                label: 'Enter Title Here',
                className: 'template3-title',
                shouldUseWarning: true,
            }),
            getTitleField(arrayFieldName, {
                name: 'subtitle',
                label: 'Enter Date Here',
                className: 'template3-subtitle',
                get warn() {
                    return [
                        Validators.requiredValidator(),
                        Validators.maxTextValueValidator()
                    ];
                }
            }),
            getTitleField(arrayFieldName, {
                name: 'title2',
                label: 'Enter Location Here',
                className: 'template3-title2',
            }),
            getBodyField(arrayFieldName, {
                name: 'body',
                label: 'Enter Text Here',
                className: 'template3-body',
                get warn() {
                    return [
                        Validators.requiredValidator(),
                    ];
                }
            })
        ]
    };
};

const blockFields = {
    get 1() {
        return arrayFieldName => {
            return [
                getImageField(arrayFieldName, {
                    name: 'photo',
                    label: 'Drag and Drop Image Here\nOr Click to Browse Local Files',
                    className: 'template1-image-selector',
                    dimensions: [610, 320],
                    shouldUseWarning: true
                }),
                getTitleField(arrayFieldName, {
                    name: 'title',
                    label: 'Enter Title Here',
                    className: 'template1-title',
                    get warn() {
                        return [
                            Validators.requiredValidator()
                        ];
                    }
                }),
                getBodyField(arrayFieldName, {
                    name: 'body',
                    label: 'Enter Text Here',
                    className: 'template1-body',
                    get warn() {
                        return [
                            Validators.requiredValidator()
                        ];
                    }
                }),
                getButtonLinkGroup(arrayFieldName, {
                    className: 'template1-button-link-editor',
                    get warn() {
                        return [
                            Validators.requiredValidator()
                        ];
                    }
                }),
                getBlockIDField(arrayFieldName, {
                    name: 'block_id'
                })
            ];
        };
    },
    get 2() {
        return arrayFieldName => {
            return [
                getImageField(arrayFieldName, {
                    name: 'photo',
                    label: 'Drag and Drop Image for Email\nOr Click to Browse Files',
                    className: 'block2-image-selector',
                    dimensions: [610, 320],
                    shouldUseWarning: true
                }),
                getButtonLinkGroup(arrayFieldName, {
                    className: 'block2-button-link-editor',
                    shouldUseWarning: true
                }),
                getTitleField(arrayFieldName, {
                    name: 'title',
                    label: 'Enter Title Here',
                    className: 'template1-title',
                    shouldUseWarning: true
                }),
                getBodyField(arrayFieldName, {
                    name: 'body',
                    label: 'Enter Text Here',
                    className: 'template1-body',
                    shouldUseWarning: true
                }),
                getBlockIDField(arrayFieldName, {
                    name: 'block_id'
                })
            ];
        };
    },
    get 3() {
        return arrayFieldName => {
            return [
                {
                    className: 'template-block-flex-container',
                    fields: [
                        getTemplate3Group1(arrayFieldName, {
                            className: 'template3-group50'
                        }),
                        getTemplate3Group2(arrayFieldName, {
                            className: 'template3-group50'
                        }),
                    ]
                },
                getBlockIDField(arrayFieldName, {
                    name: 'block_id'
                })
            ];
        };
    },
    get 4() {
        return arrayFieldName => {
            return [
                {
                    className: 'template-block-flex-container',
                    fields: [
                        getImageField(arrayFieldName, {
                            name: 'photo1',
                            label: 'Drag and Drop\nOr Click',
                            className: 'block4-image-selector',
                            dimensions: [145, 145],
                            shouldUseWarning: true
                        }),
                        getImageField(arrayFieldName, {
                            name: 'photo2',
                            label: 'Drag and Drop\nOr Click',
                            className: 'block4-image-selector',
                            dimensions: [145, 145],
                            shouldUseWarning: true
                        }),
                        getImageField(arrayFieldName, {
                            name: 'photo3',
                            label: 'Drag and Drop\nOr Click',
                            className: 'block4-image-selector',
                            dimensions: [145, 145],
                            shouldUseWarning: true
                        }),
                        getImageField(arrayFieldName, {
                            name: 'photo4',
                            label: 'Drag and Drop\nOr Click',
                            className: 'block4-image-selector',
                            dimensions: [145, 145],
                            shouldUseWarning: true
                        }),
                    ]
                },
                getWeeklyPhotosFacebookField(arrayFieldName),
                getBlockIDField(arrayFieldName, {
                    name: 'block_id'
                })
            ];
        };
    }
};

const getBlockArray = ({ blockID, arrayFieldName }) => {
    return blockFields[blockID](arrayFieldName);
};

export const arrayField = [
    getTemplateIDField(),
    {
        component: TemplateBlockArrayField,
        name: 'blocks',
        getArrayFields: getBlockArray
    }
];

const initialValues = {
    get 1() {
        return {
            template_id: 1,
            blocks: [
                {
                    block_id: 3,
                    content_id: 1
                }
            ]
        };
    },
    get 2() {
        return {
            template_id: 2,
            blocks: [
                {
                    block_id: 1,
                    content_id: 2
                }
            ]
        };
    },
    get 3() {
        return {
            template_id: 3,
            blocks: [
                {
                    block_id: 1,
                    content_id: 3
                },

                {
                    block_id: 4,
                    content_id: 4
                }
            ]
        };
    },
    get 4() {
        return {
            template_id: 4,
            blocks: [
                {
                    block_id: 2,
                    content_id: 5
                }
            ]
        };
    }
};

export const getInitialValues = templateID => {
    return initialValues[templateID];
};

export const getFormConfig = ({ isPreview = false }) => {
    const {
        FORM_NAME,
        submitAction,
        submitNormalizer,
        updateAction } = MainForm;

    const form = isPreview ? FORM_NAME + 'Preview' : FORM_NAME;

    return {
        form,
        shouldInitialise: false,
        className: 'template-editor__form',
        fields: arrayField,
        submitAction,
        submitNormalizer,
        updateAction,
    };
};
