export const APP_ROOT = process.env.REACT_APP__APP_ROOT__;
export const API_URL = process.env.REACT_APP__API_URL__;
export const FB_APP_ID = process.env.REACT_APP__FB_APP_ID__;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP__GOOGLE_CLIENT_ID__;
export const OAUTH2_FITDOG_CLIENT_ID = process.env.REACT_APP__OAUTH2_FITDOG_CLIENT_ID__;
export const OAUTH2_FITDOG_CLIENT_SECRET = process.env.REACT_APP__OAUTH2_FITDOG_CLIENT_SECRET__;
export const STRIPE_PUBLIC = process.env.REACT_APP__PAYMENT_PUBLIC__;

export const DEFAULT_TIMEZONE = 'America/Los_Angeles';
export const DEFAULT_TIMEZONE_DATA = 'America/Los_Angeles|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 5Wp1 1VaX 3dA0 WM0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1o00 11A0 1qM0 WM0 1qM0 1cM0 1cM0 1cM0 1cM0 1cM0 1cM0 1fA0 1a00 1fA0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0|15e6';

// export const API_URL = 'https://stage.api.fitdogsportsclub.com/api';
// export const OAUTH2_FITDOG_CLIENT_ID = 'wuNuuAsOd8iemfAgjMLfbnh4PkIo3TmIpzt2tdSR';
// export const OAUTH2_FITDOG_CLIENT_SECRET = 'mSQaKOgyJUj6to65ksD2jsAOPaG3UjISwR9Z6X31ESxUC5QtDew2ZbjwY6XDaRMmSww19MEDNCABe8mdnieU8hWgDYWe5mPv7uVnrsbUbhIxv5tmvfCtBjilY8MHMuaE';

/**
 *  APP DEFAULTS
 */
export const ADMIN_ONLY_PACKAGE_ID = 2147483647;
export const ADMIN_ONLY_PACKAGE_NAME = 'Fitdog Gift Credit';
export const FILE_SIZE_MAX = 25 * 1024 * 1024;
export const FILE_SIZE_SPINNER = 1.5 * 1024 * 1024;
export const FILE_IMAGE_TYPES = 'image/jpeg, image/png, image/jpg, image/tiff';
export const FILE_IMAGE_SIZE_MAX = 10 * 1024 * 1024;
export const FILE_IMAGE_WIDTH_MAX = 6000;
export const FILE_IMAGE_HEIGHT_MAX = 6000;
