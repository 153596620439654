/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconCancel } from '../../../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCheckMark } from '../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconDots } from '../../../assets/icons/icon-dots.svg';
import { ReactComponent as IconNoShow } from '../../../assets/icons/icon-no-show.svg';
import { formatTrainingDateTimeToAMPM, formatDateToSlashes } from '../../../helpers/date';
import AvatarPlaceholder from '../../common/AvatarPlaceholder';
import Dropdown from '../../common/Dropdown';
import Icon from '../../common/Icon/Icon';
import AttendedIcon from '../../common/AttendedIcon';
import { shouldDisplayEventProductDropdown } from '../../../helpers/dropdownItems';

class TrainingCard extends React.PureComponent {
    static propTypes = {
        trainingData: PropTypes.shape({}).isRequired,
        trainingActions: PropTypes.shape({}),
        isPrevious: PropTypes.bool,
    };

    state = {
        expanded: false,
    };

    ATTENDED_STATES = {
        'cancelled': <Icon glyph={IconCancel} className='icon_cancel'/>,
        'attended': <Icon glyph={IconCheckMark} className='icon_check-mark'/>,
        'no show': <Icon glyph={IconNoShow} className='icon_no-show'/>,
        'scheduled': <div className='no-info'>—</div>,
    };

    handleExpandButtonClick = e => {
        e.preventDefault();
        this.setState({
            expanded: !this.state.expanded,
        });
    };

    renderContent = () => {
        if (!this.state.expanded) {
            return null;
        }

        const { trainingData } = this.props;
        const {
            status, dog_detail: { name }, event_detail: { start, end, trainer_name},
        } = trainingData;

        return (
            <div className='attending-dogs-card__content'>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Dog</div>
                    <div className='attending-dogs-card__item-content'>{name || 'Name not set'}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Trainer</div>
                    <div className='attending-dogs-card__item-content'>{trainer_name || 'Trainer not set'}</div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Pick Up</div>
                    <div className='attending-dogs-card__item-content'>
                        {(!start)
                            ? 'Pick Up time not set'
                            : (`${formatTrainingDateTimeToAMPM(start)}`)}
                    </div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Drop Off</div>
                    <div className='attending-dogs-card__item-content'>
                        {(!end)
                            ? 'Drop Off time not set'
                            : (`${formatTrainingDateTimeToAMPM(end)}`)}
                    </div>
                </div>
                <div className='attending-dogs-card__item'>
                    <div className='attending-dogs-card__item-header'>Attended / Cancelled</div>
                    <div className='attending-dogs-card__item-content'>
                        <AttendedIcon status={status}/>
                    </div>
                </div>
            </div>
        );
    };

    renderExpandButton = () => {
        const text = this.state.expanded ? 'See Less' : 'See More';

        return (
            <div className='attending-dogs-card__expand-button-wrapper'>
                {/*eslint-disable-next-line*/}
                <a
                    href=''
                    onClick={this.handleExpandButtonClick}
                    className='attending-dogs-card__expand-button'>
                    {text}
                </a>
            </div>
        );
    };

    renderDropdown = () => {
        const { trainingActions, trainingData, isPrevious } = this.props;

        if (isPrevious && !shouldDisplayEventProductDropdown(trainingData)) {
            return null;
        }

        return (
            <Dropdown
                data={trainingData}
                classNames='dropdown_attending-dogs-card'
                icon={IconDots}
                iconClass='icon_chevron_down'
                items={trainingActions}/>
        );
    };

    render() {
        const { trainingData } = this.props;
        const { dog_detail: { name }, event_detail: { program_name, start }, dog_photo_processed } = trainingData;

        const headerBackground = dog_photo_processed !== null
            ? ({
                backgroundColor: '#98999d',
                background: `url(${dog_photo_processed}) center center / cover no-repeat`,
            })
            : ({
                backgroundColor: '#98999d',
            });
        return (
            <div className='attending-dogs-card-container'>
                <div className='attending-dogs-card'>

                    {this.renderDropdown()}

                    <div className='classes-schedule-card__header' style={headerBackground}>
                        {dog_photo_processed === null && <AvatarPlaceholder type='dog' size='card'/>}
                        <div className='classes-schedule-card__header-title'>
                            <div className='classes-schedule-card__header-location'>
                                {name || 'Name not set'}
                            </div>
                        </div>
                    </div>
                    <div className='classes-schedule-card__name-info'>
                        <div className='classes-schedule-card__class-name'>
                            {program_name || 'Name not set'}
                        </div>
                        <div className='classes-schedule-card__weekdays'>
                            {start ? `${formatDateToSlashes(start, true)}` : 'Date not set, '}
                        </div>
                    </div>


                    {this.renderContent()}
                    {this.renderExpandButton()}

                </div>
            </div>
        );
    }
}

export default TrainingCard;
