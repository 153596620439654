import * as axios from 'axios';
import { fromJS } from 'immutable';
import * as ClassesActions from '../actions/dashboard/classesActions';
import { updateEventProduct } from '../actions/dashboard/eventActions';
import { updateDashboardClassProductLocations } from '../actions/dashboard/dashboardPageActions';
import { setGlobalError } from '../actions/errorHandlerActions';
import { getUserNameSpace } from '../helpers/userRoles';
import { getScope } from './oauth';

export const getPreviousClasses = ({ context, id }) => {
    return (dispatch, getState) => {
        if (context === 'customers') {
            const isLoading = getState().getIn(['currentCustomer', 'previous_classes', 'isLoading']);
            if (isLoading) {
                return;
            } else {
                dispatch(ClassesActions.startIsLoadingCustomerPreviousClasses());
            }
        }
        if (context === 'dogs') {
            const isLoading = getState().getIn(['currentDog', 'previous_classes', 'isLoading']);
            if (isLoading) {
                return;
            } else {
                dispatch(ClassesActions.startIsLoadingDogPreviousClasses());
            }
        }

        return axios.get(`/v1/${getUserNameSpace(context + '.previous-classes')}/${context}/${id}/previous-classes/`)
                    .then((response) => {
                        if (context === 'customers') {
                            dispatch(ClassesActions.setCustomersPreviousClasses(response.data));
                            dispatch(ClassesActions.markCustomersPreviousClassesLoaded());
                        }
                        if (context === 'dogs') {
                            dispatch(ClassesActions.setDogsPreviousClasses(response.data));
                            dispatch(ClassesActions.markDogsPreviousClassesLoaded());
                        }
                    })
                    .catch((error) => {
                        if (context === 'customers') {
                            dispatch(ClassesActions.finishIsLoadingCustomerPreviousClasses());
                        }
                        if (context === 'dogs') {
                            dispatch(ClassesActions.finishIsLoadingDogPreviousClasses());
                        }
                        dispatch(setGlobalError(error));
                    });
    };
};

export const getNextPreviousClasses = ({ context }) => {
    return (dispatch, getState) => {
        let nextUrl;
        if (context === 'customers') {
            const isLoading = getState().getIn(['currentCustomer', 'previous_classes', 'isLoading']);
            if (isLoading) {
                return;
            } else {
                dispatch(ClassesActions.startIsLoadingCustomerPreviousClasses());
                nextUrl = getState().getIn(['currentCustomer', 'previous_classes', 'nextUrl']);
            }
        }
        if (context === 'dogs') {
            const isLoading = getState().getIn(['currentDog', 'previous_classes', 'isLoading']);
            if (isLoading) {
                return;
            } else {
                dispatch(ClassesActions.startIsLoadingDogPreviousClasses());
                nextUrl = getState().getIn(['currentDog', 'previous_classes', 'nextUrl']);
            }
        }

        return axios.get(nextUrl)
            .then((response) => {
                if (context === 'customers') {
                    dispatch(ClassesActions.setCustomersPreviousClasses(response.data));
                }
                if (context === 'dogs') {
                    dispatch(ClassesActions.setDogsPreviousClasses(response.data));
                }
            })
            .catch((error) => {
                if (context === 'customers') {
                    dispatch(ClassesActions.finishIsLoadingCustomerPreviousClasses());
                }
                if (context === 'dogs') {
                    dispatch(ClassesActions.finishIsLoadingDogPreviousClasses());
                }
                dispatch(setGlobalError(error));
            });
    };
};

export const getUpcomingClasses = ({ context, id }) => {
    return (dispatch, getState) => {
        if (context === 'customers') {
            const isLoading = getState().getIn(['currentCustomer', 'upcoming_classes', 'isLoading']);
            if (isLoading) {
                return;
            } else {
                dispatch(ClassesActions.startIsLoadingCustomerUpcomingClasses());
            }
        }
        if (context === 'dogs') {
            const isLoading = getState().getIn(['currentDog', 'upcoming_classes', 'isLoading']);
            if (isLoading) {
                return;
            } else {
                dispatch(ClassesActions.startIsLoadingDogUpcomingClasses());
            }
        }

        return axios.get(`/v1/${getUserNameSpace(context + '.upcoming-classes')}/${context}/${id}/upcoming-classes/`)
                    .then((response) => {
                        if (context === 'customers') {
                            dispatch(ClassesActions.setCustomersUpcomingClasses(response.data));
                            dispatch(ClassesActions.markCustomersUpcomingClassesLoaded());
                        }
                        if (context === 'dogs') {
                            dispatch(ClassesActions.setDogsUpcomingClasses(response.data));
                            dispatch(ClassesActions.markDogsUpcomingClassesLoaded());
                        }
                    })
                    .catch((error) => {
                        if (context === 'customers') {
                            dispatch(ClassesActions.finishIsLoadingCustomerUpcomingClasses());
                        }
                        if (context === 'dogs') {
                            dispatch(ClassesActions.finishIsLoadingDogUpcomingClasses());
                        }
                        dispatch(setGlobalError(error));
                    });
    };
};

export const getNextUpcomingClasses = ({ context }) => {
    return (dispatch, getState) => {
        let nextUrl;
        if (context === 'customers') {
            const isLoading = getState().getIn(['currentCustomer', 'upcoming_classes', 'isLoading']);
            if (isLoading) {
                return;
            } else {
                dispatch(ClassesActions.startIsLoadingCustomerUpcomingClasses());
                nextUrl = getState().getIn(['currentCustomer', 'upcoming_classes', 'nextUrl']);
            }
        }
        if (context === 'dogs') {
            const isLoading = getState().getIn(['currentDog', 'upcoming_classes', 'isLoading']);
            if (isLoading) {
                return;
            } else {
                dispatch(ClassesActions.startIsLoadingDogUpcomingClasses());
                nextUrl = getState().getIn(['currentDog', 'upcoming_classes', 'nextUrl']);
            }
        }

        return axios.get(nextUrl)
            .then((response) => {
                if (context === 'customers') {
                    dispatch(ClassesActions.setCustomersUpcomingClasses(response.data));
                }
                if (context === 'dogs') {
                    dispatch(ClassesActions.setDogsUpcomingClasses(response.data));
                }
            })
            .catch((error) => {
                if (context === 'customers') {
                    dispatch(ClassesActions.finishIsLoadingCustomerUpcomingClasses());
                }
                if (context === 'dogs') {
                    dispatch(ClassesActions.finishIsLoadingDogUpcomingClasses());
                }
                dispatch(setGlobalError(error));
            });
    };
};

export const getCustomerUpcomingClasses = () => {
    return dispatch => {
        return axios.get('/v1/customers/me/upcoming-classes/')
                    .then((response) => {
                        console.log(response.data);
                        dispatch(ClassesActions.setCustomersUpcomingClasses(response.data));
                        dispatch(ClassesActions.markCustomersUpcomingClassesLoaded());
                    })
                    .catch((error) => {
                        console.log(error);
                        console.log(error.response);
                        dispatch(setGlobalError(error));
                    });
    };
};

export const patchUpcomingClasses = ({ context, contextId, classId, classData }) => {
    return (dispatch, getState) => {
        return axios.patch(
            `/v1/${getUserNameSpace(context + '.upcoming-classes')}/${context}/${contextId}/upcoming-classes/${classId}/`,
            classData)
                    .then(response => {
                        console.log('patchUpcomingClasses response: ', response);
                        if (context === 'customers') {
                            dispatch(ClassesActions.setCustomersUpcomingClassDetails(response.data));
                        }
                        if (context === 'dogs') {
                            if (getState().getIn(['currentEvent', 'products'])) {
                                dispatch(updateEventProduct(fromJS(response.data)));
                            }
                            if (getState().getIn(['dashboardPage', 'glanceClassesProducts', 'items'])) {
                                dispatch(updateDashboardClassProductLocations(fromJS(response.data)));
                            }
                            dispatch(ClassesActions.setDogsUpcomingClassDetails(response.data));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};

export const patchUpcomingClassesAsync = ({ context, classData, queryParams }) => {
    return async dispatch => {
        const contextId = classData.contextId;
        const classId = classData.classId;
        const result = await axios.patch(
            `/v1/${getUserNameSpace(context + '.upcoming-classes')}/${context}/${contextId}/upcoming-classes/${classId}/`,
            classData,
            { params: queryParams }
            );
        return result;
    };
};

export const patchPreviousClassesAsync = ({ context, classData }) => {
    return async dispatch => {
        const contextId = classData.contextId;
        const classId = classData.classId;
        const result = await axios.patch(
            `/v1/${getUserNameSpace(context + '.previous-classes')}/${context}/${contextId}/previous-classes/${classId}/`,
            classData);
        return result;
    };
};

export const patchPreviousClasses = ({ context, classData }) => {
    return (dispatch) => {
        const contextId = classData.contextId;
        const classId = classData.classId;
        console.log(classData);
        console.log('patchPreviousClasses fired');
        const request = new FormData();
        Object.keys(classData).map((classInfo) => {
            return request.append(classInfo, classData[classInfo]);
        });
        request.append('scope', getScope());

        return axios.patch(`/v1/${getUserNameSpace(context + '.previous-classes')}/${context}/${contextId}/` +
            `previous-classes/${classId}/`, request)
                    .then(response => {
                        if (context === 'customers') {
                            dispatch(ClassesActions.updateCustomerPreviousClass(response.data));
                        }
                        if (context === 'dogs') {
                            dispatch(ClassesActions.updateDogPreviousClass(response.data));
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        dispatch(setGlobalError(err));
                    });
    };
};
