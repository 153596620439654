import { reducer as formReducer } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import calendarReducer from './calendarReducer';
import classesListReducer from './classesListReducer';
import classOccurrencesListReducer from './classOccurrencesReducer';
import currentCustomerReducer from './currentCustomerReducer';
import currentDogReducer from './currentDogReducer';
import currentEventReducer from './currentEventReducer';
import currentImmunizationExpirationDatesReducer from './currentImmunizationExpirationDatesReducer';
import currentInstructorEmployeeReducer from './currentInstructorEmployeeReducer';
import customersListReducer from './customersListReducer';
import directoryListReducer from './directoryListReducer';
import dogCreationReducer from './dogCreationReducer';
import dogsListReducer from './dogsListReducer';
import miscReducer from './miscReducer';
import modalReducer from './modalReducer';
import modalSidebarReducer from './modalSidebarReducer';
import onBoardingStepsReducer from './onBoardingStepsReducer';
import packageListReducer from './packageListReducer';
import paymentsReducer from './payments';
import routeReducer from './routeReducer';
import searchBarReducer from './searchBarReducer';
import trainingsListReducer from './trainingsListReducer';
import customerReducer from './userReducer';
import veterinarianReducer from './veterinarianReducer';
import steppedFormReducer from './steppedFormReducer';
import dashboardPageReducer from './dashboardPageReducer';
import formsPageReducer from './formsPageReducer';
import errorHandlerReducer from './errorHandlerReducer';
import scheduleListReducer from './scheduleReducer';
import classesHistoryListReducer from './classesHistoryReducer';
import messagesReducer from './messagesReducer';
import alertReducer from '../components/Alert/reducer';
import overviewReducer from './overviewReducer';
import reportsReducer from './reportsReducer';
import customReportsReducer from './customReportsReducer';
import marketingEditorPageReducer from '../components/MarketingEditorPage/reducer';
import marketingPageReducer from '../components/MarketingPage/reducer';
import dailyNotificationsReducer from '../components/containers/Dashboard/DailyNotifications/reducer';
import originalPathReducer from './originalPathReducer';
import formUpdateReducer from "./formUpdateReducer";
import uploadVaccineErrorReducer from "./uploadVaccineErrorReducer";

const appReducer = combineReducers({
    routing: routeReducer,
    misc: miscReducer,
    form: formReducer,
    user: customerReducer,
    steps: onBoardingStepsReducer,
    dog: dogCreationReducer,
    veterinarian: veterinarianReducer,
    packageList: packageListReducer,
    customersList: customersListReducer,
    currentCustomer: currentCustomerReducer,
    dogsList: dogsListReducer,
    currentDog: currentDogReducer,
    directoryList: directoryListReducer,
    currentEmployee: currentInstructorEmployeeReducer,
    modalSidebar: modalSidebarReducer,
    modal: modalReducer,
    currentImmunizationExpirationDates: currentImmunizationExpirationDatesReducer,
    classesList: classesListReducer,
    search: searchBarReducer,
    currentEvent: currentEventReducer,
    formUpdate: formUpdateReducer,
    uploadVaccineError: uploadVaccineErrorReducer,
    calendar: calendarReducer,
    trainingsList: trainingsListReducer,
    classOccurrencesList: classOccurrencesListReducer,
    steppedForm: steppedFormReducer,
    dashboardPage: dashboardPageReducer,
    formsPage: formsPageReducer,
    errorHandler: errorHandlerReducer,
    schedule: scheduleListReducer,
    classesHistory: classesHistoryListReducer,
    messages: messagesReducer,
    alert: alertReducer,
    overview: overviewReducer,
    reports: reportsReducer,
    customReports: customReportsReducer,
    dailyNotifications: dailyNotificationsReducer,
    [marketingEditorPageReducer.__name]: marketingEditorPageReducer,
    marketingPage: marketingPageReducer,
    [marketingPageReducer.__name]: marketingPageReducer,
    originalPath: originalPathReducer,
    payments: paymentsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
