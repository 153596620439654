import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import Accordion from '../../../../common/Accordion/';
import Section from '../../../../common/Accordion/Section/';
import AddressHeader from './AddressHeader/';
import AddressListForm from './AddressListForm/';
import { changeScheduleAddress } from '../../../../../actions/customer/scheduleActions';
import { openEditCustomerScheduleInfo } from '../../../../../actions/modal/openActions';

const PickupDropoffAdresses = ({ data, addresses, changeScheduleAddress, openEditCustomerScheduleInfo }) => {
    const pickupAddress = data.pickupLocation.string;
    const dropoffAddress = data.dropoffLocation.string;
    const classOccurrenceId = data.id;
    const startTime = moment(data.event.start);
    const startDay = startTime.format('Do');

    const handlePickupSubmit = (formData) => {
        if (startDay === moment().format('Do') && Number(moment().format('H')) <= 7) {
            openEditCustomerScheduleInfo({
                title: 'Change Location',
                subtitle: 'Edits to locations are no longer available. Please call (310) 828-3647 for assistance.',
                buttonText: 'Ok'
            });
        } else {
            changeScheduleAddress({
                location: formData.get('location'),
                locationType: 'pickup_location',
                dogs: data.dogs
            });
        }
    };
    const handleDropoffSubmit = (formData) => {
        return changeScheduleAddress({
            location: formData.get('location'),
            locationType: 'drop_off_location',
            dogs: data.dogs
        });
    };

    return (
        <Accordion>
            <Section
                header={
                    <AddressHeader
                      title='Pick up address'
                      subtitle={pickupAddress}
                      startDay={startDay}
                    />
                }
                startDay={startDay}
                content={
                    <AddressListForm
                        form='pickup_location'
                        addresses={addresses}
                        activeAddress={data.pickupLocation}
                        onSubmit={handlePickupSubmit}
                        classOccurrenceId={classOccurrenceId} />
                } />
            <Section
                header={
                    <AddressHeader
                      title='Drop off address'
                      subtitle={dropoffAddress}
                      startDay={startDay}
                    />
                }
                startDay={startDay}
                content={
                    <AddressListForm
                        form='drop_off_location'
                        addresses={addresses}
                        activeAddress={data.dropoffLocation}
                        onSubmit={handleDropoffSubmit}
                        classOccurrenceId={classOccurrenceId} />
                } />
        </Accordion>
    );
};

PickupDropoffAdresses.propTypes = {
    data: PropTypes.shape({}),
    changeScheduleAddress: PropTypes.func,
    openEditCustomerScheduleInfo: PropTypes.func,
    addresses: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
    addresses: state.getIn(['currentCustomer', 'instance', 'available_addresses']).toJS()
});

const mapDispatchToProps = {
    changeScheduleAddress,
    openEditCustomerScheduleInfo
};
export default connect(mapStateToProps, mapDispatchToProps)(PickupDropoffAdresses);
