import moment from 'moment';
import { addZero } from './date';
import { ReactComponent as IconBehavior } from '../assets/icons/icon-behavior.svg';
import { ReactComponent as IconFitness } from '../assets/icons/icon-fitness.svg';
import { ReactComponent as IconHealth } from '../assets/icons/icon-health.svg';
import { ReactComponent as IconNewSkill } from '../assets/icons/icon-new-skill.svg';
import { ReactComponent as IconPaw } from '../assets/icons/icon-paw.svg';
import { ReactComponent as IconPotty } from '../assets/icons/icon-potty.svg';
import { ReactComponent as IconRecall } from '../assets/icons/icon-recall.svg';
import { ReactComponent as IconTricks } from '../assets/icons/icon-tricks.svg';
import { ReactComponent as IconAgility } from '../assets/icons/icon-agility.svg';
import { ReactComponent as IconScent } from '../assets/icons/icon-scent.svg';
import { ReactComponent as IconBasicCommands } from '../assets/icons/icon_basic_commands.svg';
import { ReactComponent as IconPreschool } from '../assets/icons/preschool_coloring.svg';
import { ReactComponent as IconLeashSkills } from '../assets/icons/icon_leash_skills.svg';
import { ReactComponent as IconSwim } from '../assets/icons/icon_swim.svg';
import { ReactComponent as IconFirstClassComments } from '../assets/icons/icon_first_class_comments.svg';
import { ReactComponent as IconLureCoursing } from '../assets/icons/icon_lure_coursing.svg';

const currentYear = moment().year();

export const month = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
];

export const expirationMonth = [];
for (let i = 1; i <= 12; i += 1) {
    expirationMonth.push({
        label: addZero(i),
        value: i
    });
}

export const year = new Array(25).fill(currentYear).map((el, i) => {
    const current = (el - i);
    return {
        label: current,
        value: current
    };
});

export const expirationYear = new Array(50).fill(currentYear).map((el, i) => {
    const current = (el + i);
    return {
        label: current.toString().slice(2, 4),
        value: current
    };
});

export const usStates = [
    {
        value: 'AL',
        label: 'AL',
    },
    {
        value: 'AK',
        label: 'AK',
    },
    {
        value: 'AS',
        label: 'AS',
    },
    {
        value: 'AZ',
        label: 'AZ',
    },
    {
        value: 'AR',
        label: 'AR',
    },
    {
        value: 'CA',
        label: 'CA',
    },
    {
        value: 'CO',
        label: 'CO',
    },
    {
        value: 'CT',
        label: 'CT',
    },
    {
        value: 'DE',
        label: 'DE',
    },
    {
        value: 'DC',
        label: 'DC',
    },
    {
        value: 'FM',
        label: 'FM',
    },
    {
        value: 'FL',
        label: 'FL',
    },
    {
        value: 'GA',
        label: 'GA',
    },
    {
        value: 'GU',
        label: 'GU',
    },
    {
        value: 'HI',
        label: 'HI',
    },
    {
        value: 'ID',
        label: 'ID',
    },
    {
        value: 'IL',
        label: 'IL',
    },
    {
        value: 'IN',
        label: 'IN',
    },
    {
        value: 'IA',
        label: 'IA',
    },
    {
        value: 'KS',
        label: 'KS',
    },
    {
        value: 'KY',
        label: 'KY',
    },
    {
        value: 'LA',
        label: 'LA',
    },
    {
        value: 'ME',
        label: 'ME',
    },
    {
        value: 'MH',
        label: 'MH',
    },
    {
        value: 'MD',
        label: 'MD',
    },
    {
        value: 'MA',
        label: 'MA',
    },
    {
        value: 'MI',
        label: 'MI',
    },
    {
        value: 'MN',
        label: 'MN',
    },
    {
        value: 'MS',
        label: 'MS',
    },
    {
        value: 'MO',
        label: 'MO',
    },
    {
        value: 'MT',
        label: 'MT',
    },
    {
        value: 'NE',
        label: 'NE',
    },
    {
        value: 'NV',
        label: 'NV',
    },
    {
        value: 'NH',
        label: 'NH',
    },
    {
        value: 'NJ',
        label: 'NJ',
    },
    {
        value: 'NM',
        label: 'NM',
    },
    {
        value: 'NY',
        label: 'NY',
    },
    {
        value: 'NC',
        label: 'NC',
    },
    {
        value: 'ND',
        label: 'ND',
    },
    {
        value: 'MP',
        label: 'MP',
    },
    {
        value: 'OH',
        label: 'OH',
    },
    {
        value: 'OK',
        label: 'OK',
    },
    {
        value: 'OR',
        label: 'OR',
    },
    {
        value: 'PW',
        label: 'PW',
    },
    {
        value: 'PA',
        label: 'PA',
    },
    {
        value: 'PR',
        label: 'PR',
    },
    {
        value: 'RI',
        label: 'RI',
    },
    {
        value: 'SC',
        label: 'SC',
    },
    {
        value: 'SD',
        label: 'SD',
    },
    {
        value: 'TN',
        label: 'TN',
    },
    {
        value: 'TX',
        label: 'TX',
    },
    {
        value: 'UT',
        label: 'UT',
    },
    {
        value: 'VT',
        label: 'VT',
    },
    {
        value: 'VI',
        label: 'VI',
    },
    {
        value: 'VA',
        label: 'VA',
    },
    {
        value: 'WA',
        label: 'WA',
    },
    {
        value: 'WV',
        label: 'WV',
    },
    {
        value: 'WI',
        label: 'WI',
    },
    {
        value: 'WY',
        label: 'WY',
    },
];

export const employeeTypes = [
    {
        value: 'trainer',
        label: 'Instructor',
    },
    {
        value: 'employee',
        label: 'Employee',
    },
];

export const permissionsTypes = [
    {
        value: 'trainer',
        label: 'Instructor',
    },
    {
        value: 'employee',
        label: 'Employee',
    },
    {
        value: 'admin',
        label: 'Management',
    },
    {
        value: 'super_admin',
        label: 'Super Admin',
    },
];

export const permissionsTypesManager = [
    {
        value: 'trainer',
        label: 'Instructor',
    },
    {
        value: 'employee',
        label: 'Employee',
    }
];

export const weekDays = [
    {
        value: 'monday',
        label: 'Monday',
        weekday: true,
    },
    {
        value: 'tuesday',
        label: 'Tuesday',
        weekday: true,
    },
    {
        value: 'wednesday',
        label: 'Wednesday',
        weekday: true,
    },
    {
        value: 'thursday',
        label: 'Thursday',
        weekday: true,
    },
    {
        value: 'friday',
        label: 'Friday',
        weekday: true,
    },
    {
        value: 'saturday',
        label: 'Saturday',
        weekday: true,
    },
    {
        value: 'sunday',
        label: 'Sunday',
        weekday: true,
    },
];

export const locations = [
    {
        value: 0,
        label: 'Westside',
        details: '(West LA*, Brentwood, Pacific Palisades)',
    },
    {
        value: 1,
        label: 'Playa Vista',
        details: '(Marina Del Rey / Culver City / Venice)',
    },
    {
        value: 2,
        label: 'Beverly Hills',
        details: '(Bel Air, Century City, Holmby Hills, Westwood)',
    },
    {
        value: 3,
        label: 'West LA',
        details: '(Century City, Beverlywood, Sawtelle, Culver City, West LA)',
    },
    {
        value: 4,
        label: 'West Hollywood',
        details: '(La Brea, The Birds)',
    },
    {
        value: 5,
        label: 'Silver Lake',
        details: '(Los Feliz, Glendale, Atwater Village)',
    },
    {
        value: 6,
        label: 'Pacific Palisades/Malibu',
        details: '(Coastal areas)',
    },
    {
        value: 7,
        label: 'Beach Cities',
        details: '(El Segundo, Hermosa Beach)',
    },
    {
        value: 8,
        label: 'Downtown',
        details: '(Downtown LA)',
    },
    {
        value: 9,
        label: 'Mulholland',
        details: '(Mulholland)',
    },
    {
        value: 1000,
        label: 'All Neighborhoods',
        details: '(All Neighborhoods)',
    },
];

// Order is important for the Dog`s report form!
export const reportCardIcons = [
    {
        type: 'recall_reliability',
        label: 'Recall Reliability',
        icon: IconRecall,
        iconClass: 'icon_recall',
    },
    {
        type: 'fitness_level',
        label: 'Fitness',
        icon: IconFitness,
        iconClass: 'icon_fitness',
    },
    {
        type: 'potty',
        label: 'Potty',
        icon: IconPotty,
        iconClass: 'icon_potty',
    },
    {
        type: 'new_skill',
        label: 'New Skill',
        icon: IconNewSkill,
        iconClass: 'icon_new-skill',
    },
    {
        type: 'behavior',
        label: 'Behavior',
        icon: IconBehavior,
        iconClass: 'icon_behavior',
    },
    {
        type: 'distance',
        label: 'Distance',
        icon: IconPaw,
        iconClass: 'icon_paw',
    },
    {
        type: 'health',
        label: 'Health',
        icon: IconHealth,
        iconClass: 'icon_health',
    },
    {
        type: 'tricks',
        label: 'Tricks',
        icon: IconTricks,
        iconClass: 'icon_tricks',
    },
    {
        type: 'agility',
        label: 'Agility',
        icon: IconAgility,
        iconClass: 'icon_agility',
    },
    {
        type: 'scent',
        label: 'Scent',
        icon: IconScent,
        iconClass: 'icon_scent',
    },
    {
        type: 'basic_commands',
        label: 'Cues',
        icon: IconBasicCommands,
        iconClass: 'icon_basic_commands',
    },
    {
        type: 'preschool',
        label: 'Preschool',
        icon: IconPreschool,
        iconClass: 'icon_preschool',
    },
    {
        type: 'leash_skills',
        label: 'Leash Skills',
        icon: IconLeashSkills,
        iconClass: 'icon_leash_skills',
    },
    {
        type: 'swim',
        label: 'Swim',
        icon: IconSwim,
        iconClass: 'icon_swim',
    },
    {
        type: 'first_class_comments',
        label: 'First Class Comments',
        icon: IconFirstClassComments,
        iconClass: 'icon_first_class_comments',
    },
    {
        type: 'lure_coursing',
        label: 'Lure Coursing',
        icon: IconLureCoursing,
        iconClass: 'icon_lure_coursing',
    },
];

export const time = [
    { value: '12:00AM', label: '12:00AM' },
    { value: '12:30AM', label: '12:30AM' },
    { value: '01:00AM', label: '01:00AM' },
    { value: '01:30AM', label: '01:30AM' },
    { value: '02:00AM', label: '02:00AM' },
    { value: '02:30AM', label: '02:30AM' },
    { value: '03:00AM', label: '03:00AM' },
    { value: '03:30AM', label: '03:30AM' },
    { value: '04:00AM', label: '04:00AM' },
    { value: '04:30AM', label: '04:30AM' },
    { value: '05:00AM', label: '05:00AM' },
    { value: '05:30AM', label: '05:30AM' },
    { value: '06:00AM', label: '06:00AM' },
    { value: '06:30AM', label: '06:30AM' },
    { value: '07:00AM', label: '07:00AM' },
    { value: '07:30AM', label: '07:30AM' },
    { value: '08:00AM', label: '08:00AM' },
    { value: '08:30AM', label: '08:30AM' },
    { value: '09:00AM', label: '09:00AM' },
    { value: '09:30AM', label: '09:30AM' },
    { value: '10:00AM', label: '10:00AM' },
    { value: '10:30AM', label: '10:30AM' },
    { value: '11:00AM', label: '11:00AM' },
    { value: '11:30AM', label: '11:30AM' },
    { value: '12:00PM', label: '12:00PM' },
    { value: '12:30PM', label: '12:30PM' },
    { value: '01:00PM', label: '01:00PM' },
    { value: '01:30PM', label: '01:30PM' },
    { value: '02:00PM', label: '02:00PM' },
    { value: '02:30PM', label: '02:30PM' },
    { value: '03:00PM', label: '03:00PM' },
    { value: '03:30PM', label: '03:30PM' },
    { value: '04:00PM', label: '04:00PM' },
    { value: '04:30PM', label: '04:30PM' },
    { value: '05:00PM', label: '05:00PM' },
    { value: '05:30PM', label: '05:30PM' },
    { value: '06:00PM', label: '06:00PM' },
    { value: '06:30PM', label: '06:30PM' },
    { value: '07:00PM', label: '07:00PM' },
    { value: '07:30PM', label: '07:30PM' },
    { value: '08:00PM', label: '08:00PM' },
    { value: '08:30PM', label: '08:30PM' },
    { value: '09:00PM', label: '09:00PM' },
    { value: '09:30PM', label: '09:30PM' },
    { value: '10:00PM', label: '10:00PM' },
    { value: '10:30PM', label: '10:30PM' },
    { value: '11:00PM', label: '11:00PM' },
    { value: '11:30PM', label: '11:30PM' }
];

export const eventTypes = [
    { value: 'Classes', label: 'Classes' },
    { value: 'Training', label: 'Training' }
];

export const PDLocationFields = [
    { name: 'name', label: 'Nickname' },
    { name: 'address1', label: 'Location' },
    { name: 'address2', label: 'Location 2 (Apt or Unit Number)' },
    {
        aligned: true,
        type: '39-28-29',
        items: [
            { name: 'city', label: 'City' },
            { name: 'state', label: 'State' },
            { name: 'zip_code', label: 'ZIP Code' }
        ]
    },
    { name: 'location_notes', label: 'Location Notes' }
];

export const vipOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
];
