import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form/immutable';
import { usStates } from '../../../../helpers/options';
import renderInputControl from '../../../common/Form/InputControl';
import renderPlacesAutocompleteControl from '../../../common/Form/PlacesAutocompleteControl/PlacesAutocompleteControl';
import renderSelectControl from '../../../common/Form/SelectControl';
import validate from './validate';

const PickupDropoffAddModalDialogForm = (props) => {
    const { handleSubmit, invalid, submitting, handleAutocompleteSelectOrEnter } = props;
    const submitButtonClassNames = classNames({
        'button modal-dialog__button': true,
        'button_disabled': (invalid || submitting)
    });
    return (
        <form className='modal-dialog__form' onSubmit={handleSubmit}>
            <div className='modal-dialog__form-content'>
                <div className='modal-dialog__form-title'>Location 1</div>
                <Field
                    name='name'
                    type='text'
                    component={renderInputControl}
                    label='Nickname'/>
                <Field
                    name='address1'
                    type='text'
                    component={renderPlacesAutocompleteControl}
                    handleSelectOrEnter={address => {
                        handleAutocompleteSelectOrEnter(address, 'PickupDropoffAddModalDialogForm');
                    }}
                    label='Location'/>
                <Field
                    name='address2'
                    type='text'
                    component={renderInputControl}
                    label='Location 2 (Apt or Unit number)'/>
                <div className='form__aligner form__aligner_39-28-29'>
                    <Field
                        name='city'
                        type='text'
                        component={renderInputControl}
                        label='City'/>
                    <Field
                        name='state'
                        type='text'
                        component={renderSelectControl}
                        options={usStates}
                        className='select-control__is-state'
                        searchable
                        returnSelectValue
                        label='State'/>
                    <Field
                        name='zip_code'
                        type='text'
                        component={renderInputControl}
                        label='ZIP Code'/>
                </div>
                <Field
                    name='location_notes'
                    type='text'
                    component={renderInputControl}
                    label='Location Notes'/>
            </div>
            <div className='modal-dialog__form-footer modal-dialog__form-footer_row'>
                <button
                    className={submitButtonClassNames}
                    type='submit'
                    disabled={submitting}>
                    {submitting
                        ? 'Creating...'
                        : 'Save New Location'
                    }
                </button>
            </div>
        </form>
    );
};

PickupDropoffAddModalDialogForm.propTypes = {
    initialValues: PropTypes.shape({}),
    handleSubmit: PropTypes.func,
    handleDefaultValues: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    PDLocations: PropTypes.arrayOf(PropTypes.shape({})),
    changeFieldValue: PropTypes.func,
    handleAutocompleteSelectOrEnter: PropTypes.func,
    fields: PropTypes.shape({}),
};

export default reduxForm({
    form: 'PickupDropoffAddModalDialogForm',
    validate
})(PickupDropoffAddModalDialogForm);
