/* eslint-disable react/prop-types */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { isAfterDropoffEnd, isInPast } from '../../../../../helpers/date';
import GoBackButton from '../../../../common/GoBackButton';
import FormContainer from '../../../FormContainer';
import AttendingDogsGrid from '../AttendingDogsGrid';
import AttendingDogsTable from '../AttendingDogsTable';
import { isTrainer } from '../../../../../helpers/userRoles';

class EventDetailPage extends PureComponent {
    static propTypes = {
        currentEvent: PropTypes.shape({}),
        onGetOptions: PropTypes.func,
        formState: PropTypes.shape({}),
        submit: PropTypes.func,
        changeField: PropTypes.func,
        goBack: PropTypes.func,
        openProgramChangeWarning: PropTypes.func,
        openEventCancellationModalDialog: PropTypes.func,
        checkInstructorAvailability: PropTypes.func,
        routeParams: PropTypes.shape({}),
        attendingDogsActions: PropTypes.shape({}),
        handleSettingCurrentEventProductsAmount: PropTypes.func,
    };

    state = {
        areProductsPristine: true,
    };


    onFieldChange = (e, newValue) => {
        const fieldChangeMap = {
            start_date: this.handleDateChange,
            end_date: this.handleDateChange,
            program: this.handleProgramChange,
        };

        if (!e.field) {
            return;
        }

        if (e.field.name in fieldChangeMap) {
            fieldChangeMap[e.field.name](e, newValue);
        }
    };

    onNewTrainingFieldChange = (e, newValue) => {
        const fieldChangeMap = {};
        if (!isTrainer()) {
            fieldChangeMap.program = this.handleTrainingProgramChange;
        }

        if (!e.field) {
            return;
        }

        if (e.field.name in fieldChangeMap && !isTrainer()) {
            fieldChangeMap[e.field.name](e, newValue);
        }
    };

    getAvailableSpots = () => {
        const { currentEvent } = this.props;
        const spots = currentEvent.getIn(['event', 'available_spots']);
        let text = 'No spots';

        if (spots === undefined) {
            return null;
        }

        if (spots) {
            text = `*${spots} More ${spots > 1 ? 'spots' : 'spot'} available`;
        }

        return <div className='event-detail__spots'>{text}</div>;
    };

    getContent = () => {
        const {
            currentEvent,
            formState,
            routeParams,
            attendingDogsActions,
            currentUser,
            permissionLevel,
        } = this.props;

        const { id, events } = routeParams;

        if (id !== 'new' && currentEvent.getIn(['event', 'id']) === undefined) {
            return <div className='event-detail__empty-content'>Loading...</div>;
        }

        const currentEventJS = currentEvent.toJS();
        const { end, dropoff_end_time } = currentEventJS.event;
        const { isPristine } = formState[currentEventJS.formConfig.form];
        let productsJS = currentEvent.get('products');

        const shouldDisableDogSearch = () => {
            const eventTypeTest = {
                class: () => {
                    return !isPristine || isAfterDropoffEnd(end, dropoff_end_time);
                },
                training: () => {
                    const eventFormValues = formState[currentEventJS.formConfig.form].values;

                    if (!eventFormValues || isInPast(currentEventJS.event.start)) {
                        return true;
                    }

                    return !eventFormValues.program ||
                        !eventFormValues.trainer ||
                        !eventFormValues.program.value ||
                        !eventFormValues.trainer.value;
                }
            };
            return eventTypeTest[currentEventJS.event.type]();
        };

        const isDogSearchDisabled = currentEventJS.event.type === undefined ? false : shouldDisableDogSearch();
        const noSpots = currentEventJS.event.type === 'class' && currentEventJS.event.available_spots < 1;

        const getDogSearchWarning = () => {
            const eventTypeWarningMessage = {
                class: 'You must save changes before you can add dogs or change their status.',
            };

            return (
                <div className='event-detail__save-changes-warning'>
                    {eventTypeWarningMessage[currentEventJS.event.type]}
                </div>
            );
        };

        if (productsJS !== undefined) {
            productsJS = productsJS.toJS();
        }

        if (isTrainer() && id === 'new') {
            currentEventJS.formConfig.initialValues = {
                trainer: currentUser
            };
            currentEventJS.formConfig.trainer = currentUser;
        }

        const preventDisablingFields = permissionLevel === 'super_admin' || permissionLevel === 'admin';

        return (
            <div className='event-detail__content'>
                <div className='form__divider'>
                    {id === 'new'
                        ? (<FormContainer
                            form={currentEventJS.formConfig}
                            onFieldChange={this.onNewTrainingFieldChange} />)
                        : (<FormContainer
                            form={currentEventJS.formConfig}
                            eventData={currentEventJS.event}
                            busyFields={currentEventJS.formConfig.busyFields}
                            onFieldChange={this.onFieldChange}
                            preventDisablingFields={preventDisablingFields}/>)
                    }
                    {(isDogSearchDisabled && !isPristine) && getDogSearchWarning()}
                    <FormContainer
                        form={{
                            form: events === 'classes' ? 'classEventDogSearch' : 'trainingEventDogSearch',
                            submitClickHandler: 'addDogToEvent',
                            className: 'event-detail__form event-dog-search',
                            currentEvent,
                            eventFormValues: formState[currentEventJS.formConfig.form].values
                        }}
                        eventFormValues={formState[currentEventJS.formConfig.form].values}
                        disabled={isDogSearchDisabled || noSpots}/>
                    {this.getAvailableSpots()}
                </div>
                <AttendingDogsGrid
                    eventData={currentEventJS.event}
                    items={productsJS}
                    attendingDogsActions={attendingDogsActions}/>
                <AttendingDogsTable
                    data={productsJS}
                    defaultPageSize={productsJS.length}
                    eventData={currentEventJS.event}
                    isDisabled={isDogSearchDisabled}
                    attendingDogsActions={attendingDogsActions}/>
            </div>
        );
    };

    getCancelButtonText = () => {
        const { routeParams } = this.props;
        return `Cancel ${routeParams.events === 'classes' ? 'Class' : 'Training'}`;
    };

    getControls = () => {
        const {
            currentEvent,
            formState,
            submit,
            goBack,
            openEventCancellationModalDialog,
            routeParams
        } = this.props;
        const formConfigJS = currentEvent.get('formConfig').toJS();
        const form = formConfigJS.form;
        const isLoading = (routeParams.id !== 'new' && currentEvent.getIn(['event', 'id']) === undefined);
        // const hasStarted = isInPast(currentEvent.getIn(['event', 'start']));
        const eventFinished = isInPast(currentEvent.getIn(['event', 'end']));
        const hasBusyFields = formConfigJS.busyFields && formConfigJS.busyFields.length;
        const { isSubmitting } = formState[form];
        const isSubmitDisabled = this.isControlDisabled('Submit');

        const submitButtonClassNames = classNames({
            'button event-detail__control-button button_dark': true,
            'button_disabled': isSubmitDisabled,
        });
        const cancelButtonClassNames = classNames({
            'button event-detail__control-button button_dark event-detail__cancel': true,
            'button_disabled': isSubmitting || hasBusyFields || eventFinished || isLoading || routeParams.id === 'new',
        });

        return (
            <div className='event-detail__controls'>
                <GoBackButton text='Back to Calendar' onClickHandler={goBack}/>
                <div className='event-detail__form-buttons'>
                    <button
                        className={cancelButtonClassNames}
                        onClick={() => openEventCancellationModalDialog(currentEvent.toJS())}
                        disabled={this.isControlDisabled('Cancel')}>
                        {this.getCancelButtonText()}
                    </button>
                    <button
                        className={submitButtonClassNames}
                        disabled={isSubmitDisabled}
                        onClick={() => {
                            this.setState({ areProductsPristine: true });
                            submit(form);
                        }}>
                        {isSubmitting ? 'Submitting' : 'Save'}
                    </button>
                </div>
            </div>
        );
    };

    isControlDisabled = (type) => {
        const { currentEvent, formState, routeParams } = this.props;
        const formConfigJS = currentEvent.get('formConfig').toJS();
        const form = formConfigJS.form;
        const { isSubmitting, isInvalid, isPristine } = formState[form];
        const isLoading = (routeParams.id !== 'new' && currentEvent.getIn(['event', 'id']) === undefined);
        // const hasStarted = isInPast(currentEvent.getIn(['event', 'start']));
        const eventFinished = isInPast(currentEvent.getIn(['event', 'end']));
        const hasBusyFields = formConfigJS.busyFields && formConfigJS.busyFields.length;

        // const productsAmount = this.props.currentEvent.get('products').size;
        // const initialProductAmount = this.props.currentEvent.get('productsAmount');

        if (type === 'Submit') {
            if (routeParams.events === 'trainings') {
                if (routeParams.id === 'new') {
                    return isSubmitting || hasBusyFields || isLoading;
                }

            }
            return isInvalid || isSubmitting || hasBusyFields || isLoading || isPristine;
        }

        if (type === 'Cancel') {
            return isSubmitting || hasBusyFields || eventFinished || isLoading || routeParams.id === 'new';
        }

        return null;
    };

    handleDateChange = e => {
        const { changeField, currentEvent } = this.props;
        const fieldToChange = e.field.name === 'start_date' ? 'end_date' : 'start_date';
        changeField(currentEvent.toJS().formConfig.form, fieldToChange, e.value);
    };

    handleProgramChange = (e, newValue) => {
        const { openProgramChangeWarning, currentEvent } = this.props;
        e.preventDefault();
        openProgramChangeWarning({
            form: currentEvent.getIn(['formConfig', 'form']),
            name: e.field.name,
            value: newValue
        });
    };

    handleTrainingProgramChange = (e, newValue) => {
        const { checkInstructorAvailability, currentEvent } = this.props;
        e.preventDefault();
        checkInstructorAvailability({
            form: currentEvent.getIn(['formConfig', 'form']),
            name: e.field.name,
            value: newValue
        });
    };

    render() {
        return (
            <div className='dashboard__main'>
                <div className='event-detail'>
                    {this.getControls()}
                    <div className='event-detail__header'>
                        Calendar Details
                    </div>
                    {this.getContent()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: {
        value: state.getIn(['user', 'id']),
        label: state.getIn(['user', 'full_name'])
    },
    permissionLevel: state.getIn(['user', 'permission_level']),
});

export default connect(mapStateToProps)(EventDetailPage);
