import { createAction } from 'redux-actions';
import { deleteAvailableAddress, patchAvailableAddress, postAvailableAddress, patchCustomerAvailableAddress } from '../../services/availableAddresses';
import { getCustomerById } from '../../services/customers';
import * as AvailableAddressesTypes from '../../types/availableAddressesTypes';
import { setPDLocationsAddModalClosed, setPDLocationsEditModalClosed, setAddAddressModalClosed } from '../miscActions';
import { generateAlertMeta } from '../../components/Alert/actions';

export const addCustomersAvailableAddress = createAction(AvailableAddressesTypes.ADD_CUSTOMERS_AVAILABALE_ADDRESS);
export const updateAvailableAddress = createAction(AvailableAddressesTypes.UPDATE_AVAILABALE_ADDRESS);
export const removeAvailableAddress = createAction(AvailableAddressesTypes.REMOVE_AVAILABALE_ADDRESS);

const dispatchAlert = (dispatch, data) => {
    dispatch(generateAlertMeta({
        event: 'CUSTOMER_EDIT',
        data
    }));
};

export const updateCustomersAvailableAddressesData = (customerId, data) => {
    return dispatch => {
        return Object.keys(data).map((address) => {
            console.log(data[address]);
            if (data[address].state.value !== undefined) {
                data[address].state = data[address].state.value;
            }
            if (data[address].name === 'deleted') {
                return dispatch(deleteAvailableAddress(customerId, data[address]));
            }
            // skip address id1 because it's the Fitdog default address
            if ('id' in data[address] && data[address].id !== 1) {
                return dispatch(patchAvailableAddress(customerId, data[address]));
            }
            return dispatch(postAvailableAddress(customerId, data[address]));
        });
    };
};

export const processCustomersAvailableAddresses = (customerId, data) => {
    return (dispatch, getState) => {
        return Promise.all(dispatch(updateCustomersAvailableAddressesData(customerId, data))).then(() => {
            const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
            dispatch(getCustomerById(customerId)).then(() => {
                dispatch(setPDLocationsEditModalClosed());
                dispatchAlert(
                    dispatch,
                    { name }
                );
            });
        });
    };
};

export const createCustomersAvailableAddress = (customerId, data) => {
    return (dispatch, getState) => {
        return dispatch(postAvailableAddress(customerId, data)).then(() => {
            const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
            dispatch(getCustomerById(customerId)).then(() => {
                dispatch(setPDLocationsAddModalClosed());
                dispatchAlert(
                    dispatch,
                    { name }
                );
            });
        });
    };
};

export const editCustomersAvalibleAddress = (formData) => {
    return (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
            dispatch(patchCustomerAvailableAddress(formData, formData.id)).then(response => {
                dispatch(setAddAddressModalClosed());
                dispatch(updateAvailableAddress(formData));
                dispatchAlert(
                    dispatch,
                    { name }
                );
                resolve();
            });
        });
    };
};
