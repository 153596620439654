/* eslint-disable jsx-a11y/anchor-has-content,react/forbid-prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import BigCalendar from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { addTimeDiff, calculateTimeDiff, convertToNativeDate } from '../../../../../helpers/date';
import Loader from '../../../../common/Loader/Loader';
import CustomToolbar from '../CustomToolbar';

// remember the browser's local timezone, as it might by used later on
BigCalendar.tz = moment.tz.guess();
// format all dates in BigCalendar as they would be rendered in browser's local timezone (even if later on they won't)
const m = (...args) => moment.tz(...args, BigCalendar.tz);
m.localeData = moment.localeData;

BigCalendar.setLocalizer(
    BigCalendar.momentLocalizer(m),
);

const convertDateTimeToDate = (datetime, timeZoneName) => {
    const m = moment.tz(datetime, timeZoneName);
    return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0);
};

const convertDateToDateTime = (date) => {
    const dateM = moment.tz(date, BigCalendar.tz);
    const m = moment.tz({
        year: dateM.year(),
        month: dateM.month(),
        date: dateM.date(),
        hour: dateM.hour(),
        minute: dateM.minute(),
    }, BigCalendar.tz);
    return m;
};

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

const CustomDayHeader = () => {
    return null;
};

const CustomWeekHeader = (weekHeader) => {
    const labelData = weekHeader.label.split(' ');
    return (
        <div className='rbc-custom-header'>
            <div className='rbc-custom-header__week-day'>{labelData[0]}</div>
            <div className='rbc-custom-header__date'>{labelData[1]}</div>
        </div>
    );
};

const CustomMonthHeader = (monthHeader) => {
    return (
        <div className='rbc-custom-header'>
            <div className='rbc-custom-header__week-day'>{monthHeader.label}</div>
        </div>
    );
};

const CustomMonthEvent = (event) => {
    return (
        <div className='rbc-custom-month-event'>
            <div><strong>{event.event.formattedStart}</strong> - {event.title}
                {event.event.location_array 
                    ? <span> @ {Object.values(event.event.location_array_detail).join(', ')}</span>
                    : ''
                }
            </div>
        </div>
    );
};

const CustomDayWeekEvent = (event) => {
    const {
        title,
        isClass,
        formattedStart,
        formattedEnd,
        trainer_detail,
        training_class_detail,
        products,
        available_spots: availableSpots,
    } = event.event;

    const get_dogs_list = products => {
        let dogs_list = [];
        for (let i = 0; i < products.length; i += 1) {
            if (i === 8) {
                dogs_list.push('...');
                break;
            } else if (i === products.length-1) {
                let url = "/dashboard/dog/" + products[i].dog_detail.id + "/details";
                console.log(url);
                dogs_list.push(<a href={url}>{products[i].dog_detail.name}</a>);
            } else {
               let url = "/dashboard/dog/" + products[i].dog_detail.id + "/details";
               dogs_list.push(<a href={url}>{products[i].dog_detail.name}</a>);
               dogs_list.push(', ');
            }

        }
        return dogs_list;
    }

    return (
        <div className='rbc-custom-day-week-event'>
            <strong className='rbc-custom-day-week-event__program'>{title}</strong>
            {event.event.location_array
                ? <strong className='rbc-custom-day-week-event__program'>{Object.values(event.event.location_array_detail).join(', ')}</strong>
                : ''
            }
            {isClass
                ? <div className='rbc-custom-day-week-event__propgram-info'>
                    {formattedStart} - {formattedEnd} <br />
                    {trainer_detail.full_name} <br />
                    {training_class_detail.spots - availableSpots}/{training_class_detail.spots} Spots
                </div>
                : <div className='rbc-custom-day-week-event__propgram-info'>
                    {event.event.trainer_name} <br />
                    Number of dogs: {products.length} <br />
                </div>
            }
            {isClass
                ? ''
                : <div className='rbc-custom-day-week-event__propgram-info' style={{ fontSize: '16px' }}>
                    {get_dogs_list(products)}
                </div>
            }
        </div>
    );
};

// eslint-disable-next-line react/no-multi-comp
class CalendarComponent extends React.PureComponent {
    static propTypes = {
        events: PropTypes.arrayOf(PropTypes.shape({})),
        eventsColors: PropTypes.shape({}),
        onSelectEvent: PropTypes.func.isRequired,
        isLoading: PropTypes.bool.isRequired,
        type: PropTypes.string.isRequired,
        view: PropTypes.string,
        filters: PropTypes.shape({}),
        patchTrainingEvent: PropTypes.func.isRequired,
        patchClassOccurrenceEvent: PropTypes.func.isRequired,
        setClassOccurrenceEventInfo: PropTypes.func.isRequired,
        setTrainingEventInfo: PropTypes.func.isRequired,
        handleGoingToEventPage: PropTypes.func.isRequired,
        getTrainingsList: PropTypes.func,
        getClassOccurrencesList: PropTypes.func,
        getCalendarSelectedRange: PropTypes.func.isRequired,
        calendarSelectedDays: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({}),
        ]),
        setDaypickerShown: PropTypes.func,
        setDaypickerHidden: PropTypes.func,
        setRangeStart: PropTypes.func,
        setRangeEnd: PropTypes.func,
        getDateInterval: PropTypes.func,
        selectedDay: PropTypes.any,
    };

    state = {
        events: [],
        selectedDay: this.props.selectedDay === null
            ? convertToNativeDate()
            : convertToNativeDate(this.props.selectedDay, true),
    };

    componentWillReceiveProps(nextProps) {
        const { events, view } = nextProps;
        const { getCalendarSelectedRange } = this.props;

        this.setState({ events });

        // if (type !== this.props.type) {
        //     this.setState({ events });
        // } else {
        //     this.setState({ events: _.unionBy(this.state.events, events, 'id') });
        // }

        if (this.props.view !== view) {
            if (view === 'week' || view === 'month') {
                getCalendarSelectedRange(this.state.selectedDay);
            }
        }
    }

    eventColorGetter = (event) => {
        const { eventsColors } = this.props;
        return { style: { backgroundColor: `${eventsColors[event.trainer]}` } };
    };

    moveEvent = ({ event, start, end }) => {
        const { type, patchTrainingEvent, patchClassOccurrenceEvent } = this.props;
        const { events } = this.state;
        const idx = events.indexOf(event);
        const updatedEvent = { ...event, start, end };

        const nextEvents = [...events];
        nextEvents.splice(idx, 1, updatedEvent);

        this.setState({
            events: nextEvents,
        });

        if (type === 'Training') {
            const serverFormattedStart = moment(start).utc().format();
            const serverFormattedEnd = moment(end).utc().format();

            patchTrainingEvent({ id: event.id, start: serverFormattedStart, end: serverFormattedEnd });
        }

        if (type === 'Classes') {
            const formattedInitialPickupStart = moment(event.start).format('HH:mm:ss');
            const formattedPickupStart = moment(start).format('HH:mm:ss');
            const formattedPickupEnd = addTimeDiff({
                initialTime: event.start,
                changedTime: start,
                differedTime: event.pickup_end_time,
                diff: calculateTimeDiff({
                    firstDate: formattedInitialPickupStart,
                    secondDate: formattedPickupStart,
                }),
            });
            const formattedInitialDropoffStart = moment(event.end).format('HH:mm:ss');
            const formattedDropoffEnd = moment(end).format('HH:mm:ss');
            const formattedDropoffStart = addTimeDiff({
                initialTime: event.start,
                changedTime: start,
                differedTime: event.dropoff_start_time,
                diff: calculateTimeDiff({
                    firstDate: formattedInitialDropoffStart,
                    secondDate: formattedDropoffEnd,
                }),
            });
            const formattedDate = moment(start).format('YYYY-MM-DD');

            patchClassOccurrenceEvent({
                id: event.id,
                pickup_start_time: formattedPickupStart,
                pickup_end_time: formattedPickupEnd,
                dropoff_start_time: formattedDropoffStart,
                dropoff_end_time: formattedDropoffEnd,
                date: formattedDate,
            });
        }
    };

    handleNavigation = (date, view, navigateType) => {
        const { type, getTrainingsList, getClassOccurrencesList, setDaypickerHidden, getDateInterval, filters } =
            this.props;
        let queryArr = [];
        let queryString = '';
        const filterMap = {
            training: [
                'trainingInstructorQuery',
                'trainingProgramQuery'
            ],
            classes: [
                'classOccurrencesLocationQuery',
                'classOccurrencesTrainingClassQuery'
            ]
        };

        queryArr = filterMap[type.toLowerCase()].reduce((result, value) => {
            const query = filters[value];
            if (query) {
                result.push(query);
            }
            return result;
        }, queryArr);

        if (queryArr.length) {
            queryString = queryArr.join('&');
        }

        setDaypickerHidden();
        this.setState({ selectedDay: date });

        if (view === 'day') {
            getDateInterval({ date, type: 'day' });
        }
        if (view === 'week') {
            getDateInterval({ date, type: 'week' });
        }
        if (view === 'month') {
            getDateInterval({ date, type: 'month' });
        }

        if (type === 'Training') {
            getTrainingsList(queryString);
        }
        if (type === 'Classes') {
            if (navigateType === 'TODAY') {
                const formattedToday = moment().format('YYYY-MM-DD');
                getClassOccurrencesList({ startDate: formattedToday, endDate: formattedToday, queryString });
            } else {
                getClassOccurrencesList({ queryString });
            }
        }
    };

    renderNoDataBlock = () => {
        return (<div className='calendar__loading'>
            <div className='loader__text'>No Data for selected filters</div>
        </div>);
    };

    render() {
        const { events, selectedDay } = this.state;
        const {
            isLoading, type, setClassOccurrenceEventInfo, setTrainingEventInfo, handleGoingToEventPage, onSelectEvent,
            view,
        } = this.props;
        const formats = {
            // Format for the day of the month heading in the Month view. e.g. '01', '02', '03', etc
            dateFormat: 'D',
            // A day of the week format for Week and Day headings, e.g. 'Wed 01/04'
            dayFormat: (date, culture, localizer) =>
                localizer.format(date, 'dddd MM/DD', culture),
            // Week day name format for the Month week day headings, e.g: 'Sun', 'Mon', 'Tue', etc
            weekdayFormat: 'dddd',
            // The timestamp cell formats in Week and Time views, e.g. '4:00 AM'
            timeGutterFormat: 'h:mm A',
            // Toolbar header format for the Month view, e.g '2015 April'
            monthHeaderFormat: 'MMMM YYYY',
            // Toolbar header format for the Week views, e.g. 'Mar 29 - Apr 04'
            dayRangeHeaderFormat: ({ start, end }, culture, local) => (
                local.format(start, 'MM/DD', culture) + ' - ' + local.format(end, 'MM/DD', culture)
            ),
            // Toolbar header format for the Day view, e.g. 'Wednesday Apr 01'
            dayHeaderFormat: 'dddd MM/DD',
        };
        const components = {
            toolbar: CustomToolbar,
            day: {
                header: CustomDayHeader,
                event: CustomDayWeekEvent,
            },
            week: {
                header: CustomWeekHeader,
                event: CustomDayWeekEvent,
            },
            month: {
                header: CustomMonthHeader,
                event: CustomMonthEvent,
            },
        };
        const bigCalendarProps = {
            ...this.props,
            events: events.map(event => ({
                ...event,
                start: convertDateTimeToDate(event.start),
                end: convertDateTimeToDate(event.end),
            })),
            onSelectSlot: ({ start, end }) => {
                if (type === 'Training') {
                    setTrainingEventInfo({
                        start: convertDateToDateTime(start),
                        end: convertDateToDateTime(end),
                    });
                }
                if (type === 'Classes') {
                    setClassOccurrenceEventInfo({
                        date: convertDateToDateTime(start),
                        pickup_start_time: convertDateToDateTime(start),
                        dropoff_end_time: convertDateToDateTime(end),
                    });
                }
                handleGoingToEventPage({
                    context: type === 'Training' ? 'trainings' : 'classes',
                    id: 'new',
                });
            },
            onEventDrop: this.moveEvent,
        };
        return (
            <div className='calendar__view'>
                <div className='calendar__header'>
                    Calendar {(!events.length && !isLoading) && ' (No Events found for selected filters)'}
                </div>
                {isLoading
                    ? (<div className='calendar__loading'>
                        <Loader
                            isVisible={isLoading}
                            loadingText='Loading Calendar Data...'
                            colorClassName='icon_loader-black'
                            modifierClassName='calendar__loader'/>
                    </div>)
                    : (<DragAndDropCalendar
                        {...bigCalendarProps}
                        selectable
                        popup
                        showMultiDayTimes
                        defaultView={view}
                        date={selectedDay}
                        onNavigate={(date, view, navigateType) => this.handleNavigation(date, view, navigateType)}
                        culture='en-US'
                        views={['month', 'week', 'day']}
                        onView={(date, view, navigateType) => this.handleNavigation(date, view, navigateType)}
                        formats={formats}
                        components={components}
                        events={events}
                        eventPropGetter={this.eventColorGetter}
                        onSelectEvent={onSelectEvent}
                        scrollToTime={moment().startOf('day').add(6, 'h')} />)
                }
            </div>
        );
    }
}

export default DragDropContext(HTML5Backend)(CalendarComponent);
