import PropTypes from 'prop-types';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { formatTo12HourFormatWithDate } from '../../../../helpers/date';
import DailyNotesForm from './DailyNotesForm';
import AvatarPlaceholder from '../../../common/AvatarPlaceholder';
import Loader from '../../../common/Loader/Loader';
import DateSwitcher from '../DashboardPage/DateSwitcher';
import { isInFutureOrToday } from '../../../../helpers/date';

const DailyNote = ({ data }) => {
    const renderAvatar = () => {
        if (!data.author_detail.photo_processed) {
            return (
                <div className='notes-incidents__item-img'>
                    <AvatarPlaceholder size={36}/>
                </div>
            );
        }
        
        return (
            <img
                src={data.author_detail.photo_processed}
                alt={data.author_detail.full_name || 'Avatar'}
                className='notes-incidents__item-img'/>
        );
    };
    
    return (
        <div className='notes-incidents__item'>
            <div className='notes-incidents__item-header'>
                {renderAvatar()}
                <div>
                    <div className='notes-incidents__item-name'>
                        {data.author_detail.full_name}
                    </div>
                    <div>
                        <span className='notes-incidents__item-position'>{data.author_detail.position}</span>
                        <span className='notes-incidents__item-time'>{formatTo12HourFormatWithDate(data.modified_at)}</span>
                    </div>
                </div>
            </div>
            <div className='notes-incidents__item-text'>
                {data.body}
            </div>
        </div>
    );
};

DailyNote.propTypes = {
    data: PropTypes.shape({}).isRequired
};

const DailyNotesPage = ({ data, onSubmit, onLoadMore, onDateChange, currentDate }) => {
    const renderNotes = () => {
        const items = data.get('items');
        
        if (!(items && items.size)) {
            return null;
        }
        
        const notes = items.toJS().map((item, i) => {
            return <DailyNote data={item} key={'note-' + i}/>;
        });
        
        return (
            <InfiniteScroll
                loadMore={(nextPage) => onLoadMore({'nextPage' : nextPage, 'date' : currentDate})}
                hasMore={!!data.get('next')}
                loader={
                    <Loader
                        isVisible
                        loadingText='Loading More Notes...'
                        colorClassName='icon_loader-black'
                        modifierClassName='notes__loader'/>
                }
                pageStart={1}>
                {notes}
            </InfiniteScroll>
        );
    };
    
    return (
        <div className='notes-incidents'>
            <div className='notes-incidents__content'>
                <div className='notes-incidents__header'>
                    Daily Notes
                </div>
                <DateSwitcher
                        currentDate={currentDate}
                        onDateChange={onDateChange}/>
                {isInFutureOrToday(currentDate) ?
                <DailyNotesForm
                    isLoading={data.get('isLoading')}
                    onSubmit={onSubmit}
                    currentDate={currentDate}/>
                : null}

                {renderNotes()}
            </div>
        </div>
    );
};

DailyNotesPage.propTypes = {
    onDateChange: PropTypes.func.isRequired,
    currentDate: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    data: PropTypes.shape({})
};

export default DailyNotesPage;
