import classNames from 'classnames';
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { isDesktop } from '../../../helpers/device';
import Select from '../Select/Select';

class Table extends React.PureComponent {
    static propTypes = {
        data: PropTypes.shape({}).isRequired,
        classNameModifier: PropTypes.string,
        defaultPageSize: PropTypes.number,
        isManual: PropTypes.bool,
        isPaginated: PropTypes.bool,
        isFiltrable: PropTypes.bool,
        autoHeight: PropTypes.bool,
        isPaginationOptional: PropTypes.bool,
        loading: PropTypes.bool,
        count: PropTypes.number,
        pages: PropTypes.number,
        onDogClick: PropTypes.func,
        onCustomerClick: PropTypes.func,
        onFiltering: PropTypes.func,
        onSorting: PropTypes.func,
        isSortable: PropTypes.bool,
        accessLevelOptions: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.shape({})),
            PropTypes.shape({}),
        ]),
        getNext: PropTypes.func,
        nextUrl: PropTypes.string,
    };

    state = {
        isScrollable: false,
    };

    componentDidMount() {
        const { autoHeight } = this.props;
        const tableBodyElement = document.getElementsByClassName('rt-tbody');
        if (tableBodyElement[0] !== undefined) {
            const hasHorizontalScrollbar = tableBodyElement[0].offsetWidth <= 850;
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ isScrollable: hasHorizontalScrollbar });
        }

        setTimeout(() => {
            if (tableBodyElement[0] !== undefined) {
                tableBodyElement[0].addEventListener('scroll', this.tableScrollListener);
                if (autoHeight) {
                    document.addEventListener('scroll', this.pageScrollCheck);
                }
            }
        }, 1000);
    }

    componentWillUnmount() {
        const tableBodyElement = document.getElementsByClassName('rt-tbody');
        if (tableBodyElement[0] !== undefined) {
            tableBodyElement[0].removeEventListener('scroll', this.tableScrollListener);
            document.removeEventListener('scroll', this.pageScrollCheck);
        }
    }

    onSorting = value => {
        const { onSorting } = this.props;
        const sort = `${value[0].desc ? '-' : ''}${value[0].id}`;
        // if we don't have pagination on backend we might use default tables one
        if (onSorting !== undefined) {
            onSorting(sort);
        }
    };

    tableScrollListener = () => {
        const { getNext, nextUrl } = this.props;
        const tableBodyElement = document.getElementsByClassName('rt-tbody');
        // px to top of the element
        const scrollTop = tableBodyElement[0].scrollTop;
        // element's total scrollable area
        const scrollHeight = tableBodyElement[0].scrollHeight;
        // visible element's area
        const offsetHeight = tableBodyElement[0].offsetHeight;

        const contentHeight = scrollHeight - offsetHeight;
        if (contentHeight <= scrollTop && nextUrl !== null) {
            getNext();
        }
    };

    pageScrollCheck = () => {
        const { getNext, nextUrl, loading } = this.props;
        const tableBodyElement = document.getElementsByClassName('rt-table');
        const windowScrollHeight = window.pageYOffset + window.innerHeight;
        const tableScrollTop = tableBodyElement[0].getBoundingClientRect().top + window.pageYOffset;
        const tableOffsetHeight = tableBodyElement[0].offsetHeight;

        if (windowScrollHeight >= tableScrollTop + tableOffsetHeight && nextUrl !== null && !loading) {
            getNext();
        }
    };

    makeInputPlaceholderFilter = (placeholder, filterType) => {
        const { onFiltering } = this.props;
        return ({ filter, onChange }) => (
            <input
                className='filter__input'
                type='text'
                placeholder={placeholder}
                style={{
                    width: '100%',
                }}
                value={filter ? filter.value : ''}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                onKeyPress={
                    (e) => {
                        if (e.key === 'Enter') {
                            if (e.target.value.length) {
                                onFiltering(`${filterType}${e.target.value}`);
                            } else {
                                onFiltering();
                            }
                        }
                    }
                }/>
        );
    };

    makeInputPlaceholderFilterREF = ({ placeholder, filterType }) => {
        const { onFiltering } = this.props;
        return ({ filter, onChange }) => (
            <input
                className='filter__input'
                type='text'
                placeholder={placeholder}
                style={{
                    width: '100%',
                }}
                value={filter ? filter.value : ''}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                onKeyPress={
                    (e) => {
                        if (e.key === 'Enter') {
                            if (e.target.value.length) {
                                onFiltering(`${filterType}${e.target.value}`);
                            } else {
                                onFiltering();
                            }
                        }
                    }
                }/>
        );
    };

    makeSelectFilter = ({ valueName, placeholder, state, options, filterType }) => {
        const { onFiltering } = this.props;
        return ({ filter, onChange }) => (
            <Select
                className='filter__select'
                name='filter-select'
                searchable={false}
                clearable={false}
                placeholder={placeholder}
                value={state}
                options={options}
                onChange={(e) => { this.updateSelectFilter(e, onFiltering, filterType, valueName); }}/>
        );
    };

    filterTypes = {
        'input': this.makeInputPlaceholderFilterREF,
        'select': this.makeSelectFilter,
    };

    createColumns = (columns, valueClickers) => {
        return Object.keys(columns).map(column => {
            const actualColumn = columns[column];
            if (typeof actualColumn === 'function') {
                const filter = this.filterTypes[actualColumn().filterType];

                if (valueClickers && valueClickers[column]) {
                    return actualColumn(filter, valueClickers[column]);
                }

                return actualColumn(filter);
            }
            return actualColumn;
        });
    };

    renderTable = ({ isScrollable, columns, valueClickers }) => {
        const {
            data, defaultPageSize = 50, isPaginated = false, isPaginationOptional = false, loading, isManual,
            isFiltrable, pages, isSortable = true, classNameModifier,
        } = this.props;

        if (!Array.isArray(columns)) columns = this.createColumns(columns, valueClickers);

        const tableClassNames = classNames({
            '-with-scroll-bar': isScrollable,
            [`${classNameModifier}`]: classNameModifier
        });
        return (
            <ReactTable
                className={tableClassNames}
                manual={isManual}
                resizable={isDesktop}
                showPagination={isPaginated}
                showPageSizeOptions={isPaginationOptional}
                defaultPageSize={defaultPageSize}
                pageSize={data.length}
                filterable={isFiltrable}
                sortable={isSortable}
                onSortedChange={this.onSorting}
                data={data}
                pages={pages}
                loading={loading}
                columns={columns}/>
        );
    };

}

export default Table;
