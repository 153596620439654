import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as EmployeeTypes from '../types/dashboard/employeeTypes';

const INITIAL_STATE = fromJS({
    customers: [],
    count: null,
    pageSize: null,
    pages: null,
    nextUrl: null,
    previousUrl: null,
    isLoading: true
});

const customersListReducer = handleActions({
    [EmployeeTypes.SET_CUSTOMERS]: (state, action) => {
        const { results,  count, next, previous } = action.payload;
        // eslint-disable-next-line camelcase
        // const { active, inactive, all } = count_total;

        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        // let customersList;
        // if (results) {
        //     customersList = results.map((customer) => {
        //         return customer;
        //     });
        // } else {
        //     customersList = [];
        // }

        const pagesCount = Math.ceil(count / results.length);

        return state.update((state) => {
            return state
                .setIn(['customers'], fromJS(results))
                .setIn(['count'], count)
                .setIn(['pageSize'], results.length)
                .setIn(['pages'], pagesCount)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [EmployeeTypes.SET_NEXT_CUSTOMERS]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        // eslint-disable-next-line camelcase
        // const { active, inactive, all } = count_total;

        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);

        const pagesCount = Math.ceil(count / results.length);

        return state.update((state) => {
            return state
                .setIn(['customers'], state.get('customers').concat(fromJS(results)))
                .setIn(['count'], count)
                .setIn(['pageSize'], results.length)
                .setIn(['pages'], pagesCount)
                .setIn(['nextUrl'], nextUrl)
                .setIn(['previousUrl'], previousUrl);
        });
    },
    [EmployeeTypes.START_CUSTOMERS_LOADING]: (state, action) => (
        state.setIn(['isLoading'], true)
    ),
    [EmployeeTypes.FINISH_CUSTOMERS_LOADING]: (state, action) => (
        state.setIn(['isLoading'], false)
    ),
    [EmployeeTypes.ADD_CUSTOMER_TO_CUSTOMERS_LIST]: (state, action) => {
        return state.set('customers', state.get('customers').insert(0, action.payload));
    },
    [EmployeeTypes.REMOVE_CUSTOMER_FROM_LIST]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['customers'],
                customer => customer.filter(customer => customer.get('id') !== action.payload));
        });
    }
}, INITIAL_STATE);

export default customersListReducer;
