import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { signIn } from '../../../../actions/onboarding/userActions';
import Step from '../../../common/Step';
import SignInFormContainer from './SignInForm';

const EmployeeSignInPage = ({ stepName = 'signin', handleSignIn }) => {
    return (
        <Step
            logoClassName='signin__logo'
            contentClassName='signin__content'
            contentsContainerClassName='signin__container'
            stepName={stepName}
            nameComponent={
                <p className='signin__title'>WELCOME TO FITDOG SPORTS!</p>
            }
            componentsImage={<div className='signin__img signin__img_employee'/>}
            formComponent={
                <SignInFormContainer
                    onSubmit={values => {
                        const updatedValues = values.set('authFor', 'employee');
                        return handleSignIn(updatedValues);
                    }}/>
            }/>
    );
};

EmployeeSignInPage.propTypes = {
    stepName: PropTypes.string,
    handleSignIn: PropTypes.func
};

const mapDispatchToProps = (dispatch) => ({
    handleSignIn: bindActionCreators(signIn, dispatch)
});

export default connect(null, mapDispatchToProps)(EmployeeSignInPage);
