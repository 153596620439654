import { push } from 'react-router-redux';
import { initialize, reset, change } from 'redux-form/immutable';
import * as CustomerActions from '../../actions/customer/CustomerActions';
import * as MessagesActions from '../../actions/messagesActions';
import { addNewVeterinarianOption } from '../../actions/miscActions';
import * as ModalActions from '../../actions/modal/modalActions';
import * as OpenActions from '../../actions/modal/openActions';
import * as ModalSidebarActions from '../../actions/modal/modalSidebarActions';
import { getUserNameSpace } from '../../helpers/userRoles';
import { purchasedEventDataNormalizer } from '../../reducers/currentCustomerReducer';
import { getClasses } from '../../services/classesSchedule';
import * as ScheduleServices from '../../services/schedule';
import * as ScheduleActions from '../customer/scheduleActions';
import { removeAvailableAddress } from '../dashboard/availableAddressesActions';
import * as ClassesActions from '../dashboard/classesActions';
import * as ClassesScheduleActions from '../dashboard/classesScheduleActions';
import { removeDirectoryEmployeeById } from '../dashboard/directoryActions';
import * as DogReportsActions from '../dashboard/dogReportsActions';
import * as EmployeeActions from '../dashboard/employeeActions';
import * as EventActions from '../dashboard/eventActions';
import * as FormsActions from '../dashboard/formsActions';
import * as FormsPageActions from '../dashboard/formsPageActions';
import * as PackagesActions from '../dashboard/packagesActions';
import * as PackagesTrainingsProductsActions from '../dashboard/packagesTrainingsProductsActions';
import * as PaymentCardActions from '../dashboard/paymentCardsAction';
import * as VaccinationRecordsActions from '../dashboard/vaccinationRecordsActions';
import * as MarketingPageActions from '../../components/MarketingPage/actions';
import * as CustomersServices from '../../services/customers';
import * as trainingsActions from '../../actions/dashboard/trainingActions';
import * as dashboardPageActions from '../../actions/dashboard/dashboardPageActions';

export const updateFormsTable = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(FormsActions.removeCurrentCustomerForm(normalizer(formConfig, getState()).itemId));
    };
};

export const updateFormsTableEmployee = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(FormsPageActions.removeFormById(normalizer(formConfig, getState()).itemId));
    };
};

export const updateFormEditCreateFormEmployee = ({ responseData }) => {
    return dispatch => {
        const id = responseData.get('id');
        dispatch(FormsPageActions.updateCurrentForm({
            isPublished: responseData.get('is_published'),
            formConfig: {
                itemId: id,
                submitAction: 'editFormEditCreateFormEmployee',
                url: `/v1/${getUserNameSpace('agreements')}/agreements/${id}/`
            }
        }));
        dispatch(push('/dashboard/forms/'));
    };
};

export const updateCurrentCustomerPaymentCards = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(PaymentCardActions.removeCurrentCustomerPaymentCard(normalizer(formConfig, getState()).itemId));
    };
};

export const updateCurrentCustomerVaccinationRecords = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(VaccinationRecordsActions.removeCurrentCustomerVaccination(normalizer(formConfig, getState()).itemId));
    };
};

export const updateCurrentCustomerClasses = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(ClassesActions.removeCustomersUpcomingClass(normalizer(formConfig, getState()).classData.id));
        dispatch(ClassesActions.getCustomersPreviousClasses(normalizer(formConfig, getState()).classData
            .dog_detail.owner));
    };
};

export const updateClasses = ({ formConfig, normalizer }) => {
    return dispatch => {
        dispatch(getClasses());
    };
};

export const updateCurrentDogClasses = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        // if there are products in currentEvent we're changing them
        // hardcoded status/status_details because can't get the response here
        if (getState().getIn(['currentEvent', 'products']).size) {
            dispatch(EventActions.changeProductStatus({
                id: normalizer(formConfig, getState()).classData.id,
                status: 2,
                status_detail: 'Cancelled'
            }));
        }
        if (getState().getIn(['dashboardPage', 'glanceClassesProducts', 'items'])) {
            dispatch(dashboardPageActions.changeClassProductStatus({
                id: normalizer(formConfig, getState()).classData.id,
                status: 2,
                status_detail: 'Cancelled'
            }));
        }
        dispatch(ClassesActions.removeDogsUpcomingClass(normalizer(formConfig, getState()).classData.id));
        dispatch(ClassesActions.getDogsPreviousClasses(normalizer(formConfig, getState()).classData.dog));
    };
};

export const updateCurrentDog = ({ formData, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(EmployeeActions.updateCurrentDogData(normalizer(formData, getState())));
    };
};

export const updateCurrentDogVetFromResponse = ({ responseData, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(EmployeeActions.updateCurrentDogData(normalizer(responseData, getState())));
    };
};

export const updateCurrentDogReports = ({ responseData, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(DogReportsActions.prependCurrentDogReports(normalizer(responseData, getState())));
    };
};

export const updateCurrentDogReportData = ({ responseData, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(DogReportsActions.updateCurrentDogReportData(normalizer(responseData, getState())));
    };
};

export const deleteCurrentDogReportCard = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(DogReportsActions.deleteCurrentDogReportCard(normalizer(formConfig, getState()).itemId));
    };
};

export const updateCustomersList = ({ responseData, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(EmployeeActions.addCustomerToCustomersList(normalizer(responseData, getState())));
    };
};

export const switchToDogsList = ({ formConfig, normalizer }) => {
    return dispatch => {
        dispatch(EmployeeActions.removeDogFromList(normalizer(formConfig).itemId));
        dispatch(push('/dashboard/dogs/'));
    };
};

export const updateCustomerDogsList = ({ formConfig, normalizer }) => {
    return dispatch => {
        dispatch(EmployeeActions.removeDogFromList(normalizer(formConfig).itemId));
        dispatch(ModalSidebarActions.onModalClose());
        dispatch(push('/dogs/'));
    };
};

export const switchToCalendar = () => {
    return dispatch => {
        dispatch(push('/dashboard/calendar/'));
    };
};

// export const addNewClassToClassesSchedule = ({ responseData }) => {
//     return dispatch => {
//         dispatch(ClassesScheduleActions.setNewClassToClassesSchedule(responseData));
//     };
// };

export const updateClassForClassesSchedule = ({ responseData }) => {
    return dispatch => {
        dispatch(ClassesScheduleActions.updateClassForClassesSchedule(responseData));
    };
};

export const addNewPackageToPackageList = ({ responseData }) => {
    return dispatch => {
        dispatch(PackagesActions.setNewPackageToPackageList(responseData));
    };
};

export const updatePackageForPackageList = ({ responseData }) => {
    return dispatch => {
        dispatch(PackagesActions.updatePackageForPackageList(responseData));
    };
};

export const updatePackageList = (responseData) => {
    return dispatch => {
        dispatch(PackagesActions.updatePackageList(responseData));
    };
};

export const updateCustomersPackageOrTrainingProductExpiresAt = ({ responseData }) => {
    return dispatch => {
        dispatch(PackagesTrainingsProductsActions.updateCustomersPackageOrTrainingProductExpiresAt(responseData));
    };
};

export const updateCustomersPackageOrTrainingProductQuantityRemaining = ({ responseData }) => {
    return dispatch => {
        dispatch(
            PackagesTrainingsProductsActions.updateCustomersPackageOrTrainingProductQuantityRemaining(responseData)
        );
    };
};

export const updateCustomersPackageOrTrainingProductTrainer = ({ responseData }) => {
    return dispatch => {
        dispatch(PackagesTrainingsProductsActions.updateCustomersPackageOrTrainingProductTrainer(responseData));
    };
};

export const removeCustomerPackageOrTrainingProduct = ({ formConfig }) => {
    return dispatch => {
        dispatch(PackagesTrainingsProductsActions.removeCustomerPackageOrTrainingProduct(formConfig));
    };
};

export const updateVeterinarianOptions = ({ responseData }) => {
    return dispatch => {
        const { id, name } = responseData.toJS();
        dispatch(addNewVeterinarianOption({ value: id, label: name }));
    };
};

export const updateCustomerDogs = ({ responseData }) => {
    return dispatch => {
        dispatch(EmployeeActions.appendDogs(responseData));
        dispatch(push(`/dogs/${responseData.get('id')}/`));
    };
};

export const addCustomerPackageOrTrainingProduct = ({ responseData }) => {
    return dispatch => {
        dispatch(
            PackagesTrainingsProductsActions.addCustomerPackageOrTrainingProduct(
                purchasedEventDataNormalizer(responseData)
            )
        );
    };
};

export const goToCalendarPage = () => {
    return dispatch => {
        dispatch(push('/dashboard/calendar/'));
    };
};

export const addDogToEventProducts = ({ responseData }) => {
    return dispatch => {
        dispatch(EventActions.decreaseAvailableSpots());
        console.log('responseData: ', responseData);
        responseData.status_detail = 'Scheduled';
        dispatch(EventActions.addDogToEventProducts(responseData));
    };
};

export const updatePreviousClassReports = ({ responseData }) => {
    return dispatch => dispatch(ClassesActions.updatePreviousClassReports(responseData));
};

export const updateReports = ({ responseData }) => {
    return dispatch => {
        dispatch(ClassesActions.updateReport(responseData));
    }
};

export const updateCustomerSchedule = ({ formConfig, normalizer }) => {
    return dispatch => {
        dispatch(ScheduleActions.removeScheduleEvent(formConfig.eventId));
        dispatch(ScheduleServices.getSchedule());
        dispatch(ModalActions.onModalClose());
        dispatch(ModalSidebarActions.onModalClose());
        setTimeout(() => { // because it works
            dispatch(OpenActions.openEditCustomerScheduleInfo({
                title: 'Cancel Class',
                subtitle: 'Your reservation has been cancelled. Go to "Classes" page to book another class',
                hasCancel: true,
                updateAction: 'switchToClasses',
                buttonText: 'Go to classes'
            }));
        }, 200);
    };
};

export const switchToClasses = () => {
    return dispatch => {
        dispatch(push('/classes'));
    };
};

export const closeSidebarModal = () => {
    return dispatch => {
        dispatch(ModalSidebarActions.onModalClose());
    };
};

export const updateCustomerScheduleEvent = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        const currentIndex = getState()
            .getIn(['schedule', 'items'])
            .findKey(item => item.get('id') === formConfig.eventId);
        const dogsCount = getState().getIn(['schedule', 'items', currentIndex, 'products']).size;
        dispatch(ScheduleActions.removeDogFromScheduleEvent({
            eventId: formConfig.eventId,
            dogId: formConfig.itemId
        }));
        if (dogsCount === 1) {
            dispatch(ScheduleActions.removeScheduleEvent(formConfig.eventId));
            dispatch(ModalSidebarActions.onModalClose());
        }
        dispatch(ModalActions.onModalClose());
        setTimeout(() => { // because it works
            dispatch(OpenActions.openEditCustomerScheduleInfo({
                title: formConfig.headline.title,
                subtitle: 'Your reservation has been cancelled. Go to "Classes" page to book another class',
                hasCancel: true,
                updateAction: 'switchToClasses',
                buttonText: 'Go to classes'
            }));
        }, 200);
    };
};

export const updateDirectoryEmployees = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(removeDirectoryEmployeeById(normalizer(formConfig, getState()).itemId));
    };
};

export const updateCurrentTrainingEventAndProductsAmount = ({ responseData }) => {
    return dispatch => {
        dispatch(EventActions.updateCurrentTrainingEvent(responseData));
        dispatch(EventActions.setCurrentEventProducts(responseData.get('products').toJS()));
    };
};

export const reinitializeEventForm = ({ formConfig }) => {
    // console.log('REINITIALIZE');
    // console.log('formConfig.form: ', formConfig);
    return dispatch => {
        dispatch(initialize(formConfig.parentFormData.formConfig.form, formConfig.parentFormData.formData));
    };
};

export const updateClassCurrentEvent = ({ responseData }) => {
    const responseDataJS = responseData.toJS();
    responseDataJS.type = 'class';
    return dispatch => {
        dispatch(EventActions.setCurrentEvent(responseDataJS));
    };
};

export const updateCustomersAdditionalOwner = ({ responseData }) => {
    return dispatch => {
        dispatch(CustomerActions.updateAdditionalOwner(responseData));
    };
};

export const updateCustomersEmergencyContact = ({ responseData }) => {
    return dispatch => {
        dispatch(CustomerActions.updateEmergencyContact(responseData));
    };
};

export const addCustomersAdditionalOwner = ({ responseData }) => {
    return dispatch => {
        dispatch(CustomerActions.addAdditionalOwner(responseData));
    };
};

export const addCustomersEmergencyContact = ({ responseData }) => {
    return dispatch => {
        dispatch(CustomerActions.addEmergencyContact(responseData));
    };
};

export const deleteCustomersAdditionalOwner = ({ formConfig }) => {
    return dispatch => {
        dispatch(CustomerActions.deleteAdditionalOwner(formConfig));
    };
};

export const deleteCustomersEmergencyContact = ({ formConfig }) => {
    return dispatch => {
        dispatch(CustomerActions.deleteEmergencyContact(formConfig));
    };
};

export const handleRemoveCustomersAvailableAddress = ({ formConfig }) => {
    return (dispatch, getState) => {
        const pickup = getState().getIn(['currentCustomer', 'instance', 'pickup_location']);
        const dropoff = getState().getIn(['currentCustomer', 'instance', 'drop_off_location']);
        if (pickup === formConfig.id || dropoff === formConfig.id) {
            dispatch(CustomersServices.getCurrentCustomer());
        } else {
            dispatch(removeAvailableAddress(formConfig.id));
        }
    };
};

export const updateEventProductLocations = ({ formData }) => {
    return dispatch => {
        dispatch(EventActions.updateEventProductLocations(formData));
    };
};

export const updateUpcomingTrainingProductLocations = ({ formData }) => {
    const context = formData.get("context")
    if (context === "customers") {
        return dispatch => {
            dispatch(trainingsActions.setCustomersUpcomingTrainingDetails(formData));
        };
    } else {
        return dispatch => {
            dispatch(trainingsActions.setDogsUpcomingTrainingDetails(formData));
        };
    }
};

export const updateEventProduct = ({ responseData }) => {
    return (dispatch, getState) => {
        dispatch(EventActions.updateEventProduct(responseData));
        if (getState().getIn(['dashboardPage', 'glanceTrainingsProducts', 'items'])) {
            dispatch(dashboardPageActions.updateDashboardTrainingProductLocations(responseData));
        }
    };
};

export const deleteCustomerPaymentCard = ({ formConfig }) => {
    return dispatch => {
        dispatch(PaymentCardActions.removeCurrentCustomerPaymentCard(formConfig.itemId));
    };
};

export const addMessageToMessagesItems = ({ formConfig, responseData }) => {
    return dispatch => {
        dispatch(MessagesActions.setMessagesLoaded());
        dispatch(reset(formConfig.form));
        return dispatch(MessagesActions.addMessageToMessagesItems(responseData));
    };
};

export const goToNewCampaignWithPreselectedNewTarget = ({ responseData }) => {
    const { id, name } = responseData.toJS();
    return dispatch => {
        dispatch(push('/dashboard/marketing/new'));
        dispatch(change('CampaignEditorForm', 'target_array', [{ id, grouping_type: 1, name }]));
    };
};

export const updateMarketingTable = ({ formConfig, normalizer }) => {
    return (dispatch, getState) => {
        dispatch(MarketingPageActions.removeItemByID(normalizer(formConfig, getState()).itemId));
    };
};

export const goToMarketing = () => {
    return dispatch => {
        dispatch(push('/dashboard/marketing/'));
    };
};

export const updateDashboardClassProductLocations = ({ responseData }) => {
    return dispatch => {
        dispatch(dashboardPageActions.updateDashboardClassProductLocations(responseData))
    };
};
