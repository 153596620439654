import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as IconCar } from '../../../../../assets/icons/icon-car.svg';
import { ReactComponent as IconLock } from '../../../../../assets/icons/icon-lock.svg';
import AvatarPlaceholder from '../../../../common/AvatarPlaceholder';
import Button from '../../../../common/Button/';
import ViewLink from '../../../../common/ViewLink/';

const formatTime = (dateString, timeString) => {
    const time = moment(`${dateString} ${timeString}`);
    const hours = time.format('h');
    const minutes = time.minutes() ? time.format(':mm') : '';
    const ampm = time.format('a');
    return hours + minutes + ampm;
};

const CustomerClassesCard = ({ data, dogs }) => {
    data = data.toJS();
    dogs = dogs.toJS();
    const access = data.access_level_detail;
    const accessLevel = data.access_level;
    const spots = data.available_spots;
    const title = data.title;
    const location = Object.keys(data.location_array_detail).map((key) =>
        data.location_array_detail[key]).join(', ');
    const price = data.training_class_detail.price;
    const pickUpStart = data.pickup_start_time ? formatTime(data.date, data.pickup_start_time) : false;
    const pickUpEnd = data.pickup_end_time ? formatTime(data.date, data.pickup_end_time) : false;
    const dropOffStart = data.dropoff_start_time ? formatTime(data.date, data.dropoff_start_time) : false;
    const dropOffEnd = data.dropoff_end_time ? formatTime(data.date, data.dropoff_end_time) : false;
    const photo = data.training_class_detail.photo_processed ||
        data.training_class_detail.photo;
    const start = data.startOriginal;
    let link = data.training_class_detail.link_to_website;
    const { date, pickup_start_time } = data; //eslint-disable-line
    let dogsAccessLevel = 0;

    if (link) link = `${link}?class_id=${data.id}`;

    const getButton = () => {
        if (dogsAccessLevel < accessLevel) {
            const tooltipText = 'Your dog does not have the access level required for this class. Increase your access level by improving recall or signing up for a training program.';
            return (
                <Button
                    modifierClassName='button_locked customer-class-card__button'
                    tooltip={tooltipText}
                    icon={IconLock}
                    iconClassName='icon_lock'/>
            );
        }

        const startTime = moment(start).unix();
        const deadLine = moment(`${date}T${pickup_start_time}`).subtract(0.5, 'hours').unix();
        const now = moment().unix();

        if (startTime <= now) {
            const tooltipText = 'Online booking is closed because the class has already started.  Please call to book: (310) 828-3647.';
            return (
                <Button
                    modifierClassName='button_locked customer-class-card__button'
                    tooltip={tooltipText}
                    icon={IconLock}
                    iconClassName='icon_lock' />
            );
        }

        if (deadLine <= now) {
            const tooltipText = 'Online booking is closed because the class is starting soon.  Please call to book: (310) 828-3647.';
            return (
                <Button
                    text='Book now'
                    modifierClassName='button_blocked customer-class-card__button'
                    tooltip={tooltipText}/>
            );
        }

        if (spots < 1) {
            const tooltipText = 'There aren\'t spots left';
            return (
                <Button
                    modifierClassName='button_locked customer-class-card__button'
                    tooltip={tooltipText}
                    icon={IconLock}
                    iconClassName='icon_lock'/>
            );
        }
    
        if (moment(start).format('l') === moment().format('l') && moment().format('H') >= 7) {
            console.log(moment().format('H'), moment(start).format('l'), moment().format('l'))
            const tooltipText = 'Online booking is closed.  Please call to book: (310) 828-3647.';
            return (
              <Button
                modifierClassName='button_locked customer-class-card__button'
                tooltip={tooltipText}
                icon={IconLock}
                iconClassName='icon_lock'/>
            );
        }
        
        return (
            <Button
                link={`/classes/${data.id}/book/`}
                text='Book now'
                modifierClassName='customer-class-card__button'/>
        );
    };

    const accessClass = classNames({
        'customer-class-card__access': true,
        'customer-class-card__access_full-access': true
    });

    for (let i = 0; i < dogs.length; i += 1) {
        const dog = dogs[i];
        dogsAccessLevel = dog.access_level > dogsAccessLevel ? dog.access_level : dogsAccessLevel;
    }

    return (
        <div className='customer-class-card'>
            <div className='customer-class-card__header' style={{ backgroundImage: `url("${photo}")` }}>
                {!photo && <AvatarPlaceholder type='dog' size='customer-card'/>}
                {
                    spots <= 3 &&
                    <div className='customer-class-card__spots'>
                        {spots} Spot{spots === 1 ? '' : 's'} Left!
                    </div>
                }
            </div>
            <div className='customer-class-card__content'>
                <div className='customer-class-card__info'>
                    <div className={accessClass}>{access}</div>
                    <div className='customer-class-card__title'>{title}</div>
                    <div className='customer-class-card__loaction'>{location}</div>
                    <div className='customer-class-card__price'>${price}</div>
                </div>
                <div className='customer-class-card__time'>
                    <IconCar className='customer-class-card__car'/>
                    <div className='customer-class-card__time-list'>
                        <div className='customer-class-card__time-item'>Pickup: {pickUpStart} - {pickUpEnd}</div>
                        <div className='customer-class-card__time-item'>Dropoff: {dropOffStart} - {dropOffEnd}</div>
                    </div>
                </div>
                <div className='customer-class-card__action'>
                    {getButton()}
                    {link && <ViewLink text='View class' link={link} targetBlank/>}
                </div>
            </div>
        </div>
    );
};

CustomerClassesCard.propTypes = {
    data: PropTypes.shape({}).isRequired,
    dogs: PropTypes.shape({}).isRequired,
};

export default CustomerClassesCard;
