import React, {PureComponent} from 'react';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import {ReactComponent as IconClose} from '../../assets/icons/icon-remove.svg';
import {ReactComponent as IconArrowRight} from '../../assets/icons/icon-chevron-long-right.svg';
import Icon from '../common/Icon';
import {setAlertAsShown} from '../../services/oauth';
import _ from 'lodash';

class CustomerAlert extends PureComponent {
    state = {
        height: 0,
    };

    componentDidMount() {
        this.slideLeft();
        setAlertAsShown();
        this.scrollListenerThrottled = _.throttle(this.scrollListener, 50);
        window.addEventListener('scroll', this.scrollListenerThrottled);
        this.scrollListener();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollListenerThrottled);
    }

    scrollListener = () => {
        const customer_alert_obj = document.getElementsByClassName('customer-alert')[0];
        const customer_alert_obj_height = customer_alert_obj.getBoundingClientRect().height
        if (window.scrollY <= customer_alert_obj_height) {
            customer_alert_obj.style.position = 'fixed';
            if (window.innerWidth < 1300 && window.innerWidth > 601) {
                customer_alert_obj.style.marginTop = '120px';
            } else  if (window.innerWidth < 600) {
                customer_alert_obj.style.marginTop = '0';
            } else  {
                customer_alert_obj.style.marginTop = '60px';
            }
        } else {
            customer_alert_obj.style.marginTop = '0';
        }
    }

    slideLeft = () => {
        const customer_alert_obj = document.getElementsByClassName('customer-alert')[0];
        customer_alert_obj.classList.add("customer-alert__enter");
        customer_alert_obj.classList.add("customer-alert__enter-active");
    };

    slideRight = () => {
        const customer_alert_obj = document.getElementsByClassName('customer-alert')[0];
        customer_alert_obj.classList.add("customer-alert__leave");
        customer_alert_obj.classList.add("customer-alert__leave-active");
    };

    handleCloseClick = (e) => {
        e.preventDefault();
        this.slideRight();
    }

    generateLink = () => {
        const {data} = this.props;
        let url = null;
        switch (data.alert_type) {
            case 0: // "0": "Expired Vaccination"
                url = `/dogs/${data.dog_id}`;
                break;
            case 1: // "1": "Expired Credit Card"
                url = '/payment-methods';
                break;
            case 2: // "2": "No Complete Evaluation"
                url = '/classes';
                break;
            case 3: // "3": "No Pickup/Dropoff Address"
                url = '/profile';
                break;
            case 4: // "4": "No Credit Card"
                url = '/payment-methods';
                break;
            default:
                break;
        }
        return url;
    };

    render() {
        const {generateLink, handleCloseClick} = this;
        const {data} = this.props;
        const link = generateLink();

        return (
            <Link to={link} className='customer-alert' >
                <div className='customer-alert__inner'>
                    <div className='customer-alert__text'>{data.message}</div>
                    <div className='customer-alert__arrow'>{"Go to "}
                        <Icon glyph={IconArrowRight} className='customer-alert__arrow-icon'/>
                    </div>

                <div className='customer-alert__close' onClick={handleCloseClick}>
                    <Icon glyph={IconClose} className='customer-alert__close-icon'/>
                </div>
            </div>
            </Link>
        );
    }
}

CustomerAlert.propTypes = {
    data: PropTypes.shape({}),
};

export default CustomerAlert;
