export const RESET_DASHBOARD_PAGE_REDUCER = 'RESET_DASHBOARD_PAGE_REDUCER';
export const START_GLANCE_DATA_ALL_LOADING = 'START_GLANCE_DATA_ALL_LOADING';
export const START_GLANCE_TRAINING_DATA_ALL_LOADING = 'START_GLANCE_TRAINING_DATA_ALL_LOADING';
export const FINISH_GLANCE_DATA_ALL_LOADING = 'FINISH_GLANCE_DATA_ALL_LOADING';
export const FINISH_GLANCE_TRAINING_DATA_ALL_LOADING = 'FINISH_GLANCE_TRAINING_DATA_ALL_LOADING';
export const START_GLANCE_DATA_LOCATION_LOADING = 'START_GLANCE_DATA_LOCATION_LOADING';
export const START_GLANCE_TRAINING_DATA_LOCATION_LOADING = 'START_GLANCE_TRAINING_DATA_LOCATION_LOADING';
export const FINISH_GLANCE_DATA_LOCATION_LOADING = 'FINISH_GLANCE_DATA_LOCATION_LOADING';
export const FINISH_GLANCE_TRAINING_DATA_LOCATION_LOADING = 'FINISH_GLANCE_TRAINING_DATA_LOCATION_LOADING';
export const FILTER_LINEAR_DASHBOARD_DATA = 'FILTER_LINEAR_DASHBOARD_DATA';
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE';
export const SET_CURRENT_NOTES_DATE = 'SET_CURRENT_NOTES_DATE';
export const SET_CURRENT_FILTER = 'SET_CURRENT_FILTER';
export const SET_SELECTED_ZONE_CARD = 'SET_SELECTED_ZONE_CARD';
export const SET_SELECTED_TRAINING_ZONE_CARD = 'SET_SELECTED_TRAINING_ZONE_CARD';
export const SET_GLANCE_ALL_ITEMS = 'SET_GLANCE_ALL_ITEMS';
export const SET_GLANCE_TRAINING_ALL_ITEMS = 'SET_GLANCE_TRAINING_ALL_ITEMS';
export const SET_GLANCE_LOCATION_ITEMS = 'SET_GLANCE_LOCATION_ITEMS';
export const SET_GLANCE_TRAINING_LOCATION_ITEMS = 'SET_GLANCE_TRAINING_LOCATION_ITEMS';
export const START_GLANCE_CLASSES_PRODUCTS_DATA_ALL_LOADING = 'START_GLANCE_CLASSES_PRODUCTS_DATA_ALL_LOADING';
export const FINISH_GLANCE_CLASSES_PRODUCTS_DATA_ALL_LOADING = 'FINISH_GLANCE_CLASSES_PRODUCTS_DATA_ALL_LOADING';
export const START_GLANCE_TRAININGS_PRODUCTS_DATA_ALL_LOADING = 'START_GLANCE_TRAININGS_PRODUCTS_DATA_ALL_LOADING';
export const FINISH_GLANCE_TRAININGS_PRODUCTS_DATA_ALL_LOADING = 'FINISH_GLANCE_TRAININGS_PRODUCTS_DATA_ALL_LOADING';
export const SET_GLANCE_CLASSES_PRODUCTS_DATA = 'SET_GLANCE_CLASSES_PRODUCTS_DATA';
export const SET_GLANCE_TRAININGS_PRODUCTS_DATA = 'SET_GLANCE_TRAININGS_PRODUCTS_DATA';
export const SET_GLANCE_VIEW = 'SET_GLANCE_VIEW'
export const START_DAILY_NOTES_LOADING = 'START_DAILY_NOTES_LOADING';
export const FINISH_DAILY_NOTES_LOADING = 'FINISH_DAILY_NOTES_LOADING';
export const SET_DAILY_NOTES_ITEMS = 'SET_DAILY_NOTES_ITEMS';
export const PREPEND_DAILY_NOTES_ITEMS = 'PREPEND_DAILY_NOTES_ITEMS';
export const APPEND_DAILY_NOTES_ITEMS = 'APPEND_DAILY_NOTES_ITEMS';
export const EDIT_CLASS_PRODUCT_LOCATIONS = 'EDIT_CLASS_PRODUCT_LOCATIONS';
export const EDIT_TRAINING_PRODUCT_LOCATIONS = 'EDIT_TRAINING_PRODUCT_LOCATIONS';
export const CHANGE_CLASS_PRODUCT_STATUS = 'CHANGE_CLASS_PRODUCT_STATUS';
export const CHANGE_TRAINING_PRODUCT_STATUS = 'CHANGE_TRAINING_PRODUCT_STATUS';
export const UPDATE_DASHBOARD_PRODUCT_REPORTS = 'UPDATE_DASHBOARD_PRODUCT_REPORTS';
export const REMOVE_DASHBOARD_PRODUCT_REPORT_CARD = 'REMOVE_DASHBOARD_PRODUCT_REPORT_CARD';
