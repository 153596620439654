import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';
import * as DirectoryActions from '../../../../../actions/dashboard/directoryActions';
import { openAddInstructorEmployeeModal } from '../../../../../actions/miscActions';
import { ReactComponent as IconGrid } from '../../../../../assets/icons/icon-grid-view.svg';
import { ReactComponent as IconList } from '../../../../../assets/icons/icon-list-view.svg';
import AddNewItem from '../../../../../components/common/AddNewItem';
import * as DirectoryService from '../../../../../services/directory';
import FilterTags from '../../../../common/FilterTags';
import FilterForm from '../../../../common/FiterForm';
import Icon from '../../../../common/Icon';
import LinkButton from '../../../../common/LinkButton';
import DirectoryGrid from './DirectoryGrid';
import DirectoryTable from './DirectoryTable';
import { isTrainer, isSuperadmin } from '../../../../../helpers/userRoles';

class DirectoryPage extends React.PureComponent {
    static propTypes = {
        directoryList: PropTypes.shape({}),
        getDirectoryList: PropTypes.func.isRequired,
        setDirectoryFilterOptions: PropTypes.func.isRequired,
        resetFilterForm: PropTypes.func.isRequired,
        handleInstructorEmployeeModalOpened: PropTypes.func.isRequired,
        getNextDirectoryList: PropTypes.func
    };

    state = {
        filterTags: [],
        view: 'grid',
        query: ''
    };

    componentDidMount() {
        const { getDirectoryList } = this.props;
        getDirectoryList();
    }

    getView() {
        const { directoryList, getNextDirectoryList, getDirectoryList } = this.props;

        if (!directoryList.get('items').size && !directoryList.get('isLoading')) {
            return <div className='directory-table__empty-content'>No data</div>;
        }

        return this.state.view === 'grid'
            ? (<DirectoryGrid
                readOnly={isTrainer()}
                data={directoryList}
                isLoading={directoryList.get('isLoading')}
                getNext={getNextDirectoryList}/>)
            : (<DirectoryTable
                readOnly={isTrainer()}
                isManual
                defaultPageSize={directoryList.get('pageSize')}
                pages={directoryList.get('pages')}
                count={directoryList.get('count')}
                data={directoryList.get('items')}
                onSorting={value => getDirectoryList(`o=${value}`)}
                loading={directoryList.get('isLoading')}
                nextUrl={directoryList.get('nextUrl')}
                getNext={getNextDirectoryList}/>);
    }

    getClearButton = () => {
        if (this.state.filterTags.length) {
            return (
                <LinkButton
                    className='filter-tags__link-button'
                    text='Clear'
                    onClickHandler={this.filterTagsClearHandler}/>
            );
        }

        return null;
    };

    getViewToggle = () => {
        const { view } = this.state;
        const current = view === 'grid'
            ? <Icon glyph={IconList} className='icon_list-view directory__view-icon'/>
            : <Icon glyph={IconGrid} className='icon_grid-view directory__view-icon'/>;
        // eslint-disable-next-line
        return <a href='' onClick={this.viewToggleClickHandler}>{current}</a>;
    };

    getFilterForm = () => {
        const { directoryList } = this.props;

        if (directoryList.get('isLoading') && !directoryList.get('choices').size) {
            return <div>Loading filter options...</div>;
        }

        return (
            <FilterForm
                onChangeHandler={this.filterFormChangeHandler}
                options={directoryList.get('choices')}/>
        );
    };

    buildQueryString = (filterTags) => {
        const { directoryList } = this.props;
        const query = {};
        const params = [];

        filterTags.forEach(tag => {
            directoryList.get('choices').toJS().forEach((group) => {
                group.items.forEach((item) => {
                    if (item.value === tag) {
                        if (!(item.query in query)) {
                            query[item.query] = [tag];
                        } else {
                            query[item.query].push(tag);
                        }
                    }
                });
            });
        });

        Object.keys(query).forEach(key => {
            const value = query[key].join(',');
            params.push(`${key}=${value}`);
        });

        return params.join('&');
    };

    viewToggleClickHandler = (e) => {
        e.preventDefault();
        const { view } = this.state;
        this.setState({
            view: view === 'list' ? 'grid' : 'list'
        });
    };

    filterFormChangeHandler = (value) => {
        const { directoryList, setDirectoryFilterOptions, getDirectoryList } = this.props;
        const { filterTags } = this.state;
        console.log('filterFormChangeHandler', value);

        if (filterTags.indexOf(value.value) > -1) {
            return;
        }

        const newFilterOptions = directoryList.get('choices').toJS();
        const newFilterTags = filterTags.concat([value.value]);
        const valueIndex = newFilterOptions[value.group].items.findIndex((item) => {
            return item.value === value.value;
        });
        newFilterOptions[value.group].items[valueIndex].disabled = true;

        this.setState({
            filterTags: newFilterTags,
        });
        setDirectoryFilterOptions(newFilterOptions);
        getDirectoryList(this.buildQueryString(newFilterTags));
    };

    filterTagsRemoveHandler = (tagIndex) => {
        const { directoryList, setDirectoryFilterOptions, getDirectoryList } = this.props;
        const { filterTags } = this.state;
        const newFilterTags = Array.from(filterTags);
        const newFilterOptions = directoryList.get('choices').toJS();
        const value = newFilterTags[tagIndex];
        newFilterTags.splice(tagIndex, 1);

        newFilterOptions.forEach((group) => {
            group.items.forEach((item) => {
                if (item.value === value) {
                    console.log(item.query);
                    item.disabled = false;
                }
            });
        });

        this.setState({
            filterTags: newFilterTags
        });
        setDirectoryFilterOptions(newFilterOptions);
        getDirectoryList(this.buildQueryString(newFilterTags));
    };

    filterTagsClearHandler = () => {
        const { directoryList, setDirectoryFilterOptions, getDirectoryList, resetFilterForm } = this.props;
        const newFilterOptions = directoryList.get('choices').toJS();

        newFilterOptions.forEach((group) => {
            group.items.forEach((item) => {
                item.disabled = false;
            });
        });

        this.setState({
            filterTags: [],
        });
        resetFilterForm('filterForm');
        setDirectoryFilterOptions(newFilterOptions);
        getDirectoryList();
    };

    renderFilterTags = () => {
        return (
            <FilterTags
                tags={this.state.filterTags}
                tagsRemoveHandler={this.filterTagsRemoveHandler}
                tagsClearHandler={this.filterTagsClearHandler}/>
        );
    };

    renderAddNew = () => {
        const { handleInstructorEmployeeModalOpened } = this.props;
        return (
            <AddNewItem
                className='directory__add-new'
                text='New'
                onClickHandler={() => {
                    handleInstructorEmployeeModalOpened();
                }}/>
        );
    };

    render() {
        const { directoryList } = this.props;
        const { filterTags } = this.state;
        return (
            <div className='directory'>
                <div className='directory__top'>
                    {this.getFilterForm()}
                    {this.getViewToggle()}
                    {isSuperadmin() && this.renderAddNew()}
                </div>
                {filterTags.length > 0 &&
                    <div className='directory__filter-tags'>
                        {this.renderFilterTags()}
                        {this.getClearButton()}
                    </div>
                }
                <div className='directory__header'>
                    EMPLOYEE AND INSTRUCTOR DIRECTORY{' '}
                    {directoryList.get('count') !== null && `(${directoryList.get('count')})`}
                </div>
                {this.getView()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        directoryList: state.getIn(['directoryList'])
    };
};

const mapDispatchToProps = (dispatch) => ({
    getDirectoryList: bindActionCreators(DirectoryService.getDirectoryList, dispatch),
    getNextDirectoryList: bindActionCreators(DirectoryService.getNextDirectoryList, dispatch),
    setDirectoryFilterOptions: bindActionCreators(DirectoryActions.setDirectoryFilterOptions, dispatch),
    handleInstructorEmployeeModalOpened: bindActionCreators(openAddInstructorEmployeeModal, dispatch),
    resetFilterForm: bindActionCreators(reset, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DirectoryPage);
