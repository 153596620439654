import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { completeSignUp, goToWaiverStep } from '../../../../actions/onboarding/onBoardingStepsActions';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import StepName from '../../../common/StepName';
import WaiverForm from './WaiverForm';

class WaiverPage extends React.PureComponent {
    constructor() {
        super();

        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const { isWaiverComplete, goToWaiver } = this.props;
        if (!isWaiverComplete) {
            goToWaiver();
        }
    }

    submit() {
        const { handleWaiver } = this.props;
        window._dcq.push(['track', 'Completed onboarding']);
        return handleWaiver();
    }

    render() {
        const { stepName = 'waiver' } = this.props;
        return (
            <Step
                logoClassName='waiver__logo'
                contentClassName='waiver__content'
                contentsContainerClassName='waiver__container'
                stepName={stepName}
                componentsImage={<div className='waiver__img'/>}
                nameAndDescriptionTopped
                nameComponent={
                    <StepName
                        className='waiver__step-name'
                        numberText='Step 4'
                        nameText='Waiver & Release'/>
                }
                descriptionComponent={
                    <StepDescription
                        className='waiver__step-description'
                        text='This is it.'
                        disclaimer={'We know you want to skim over this, but it\'s important that you read through it.'}
                        imageClassName='waiver__step-description-image'/>
                }
                formComponent={
                    <WaiverForm
                        onSubmit={this.submit}/>
                }/>
        );
    }
}

WaiverPage.propTypes = {
    stepName: PropTypes.string,
    handleWaiver: PropTypes.func.isRequired,
    goToWaiver: PropTypes.func,
    isWaiverComplete: PropTypes.bool
};

const mapStateToProps = (state) => ({
    isWaiverComplete: state.getIn(['steps', 'isWaiverComplete']),
    email: state.getIn(['form', 'signUpForm', 'values', 'user_email']),
});
const mapDispatchToProps = (dispatch) => ({
    handleWaiver: bindActionCreators(completeSignUp, dispatch),
    goToWaiver: bindActionCreators(goToWaiverStep, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(WaiverPage);
