import PropTypes from 'prop-types';
import React from 'react';
import Dropdown from '../common/Dropdown';
import Table from '../common/Table';
import { formatDateToSlashes } from '../../helpers/date';
import { ReactComponent as IconDots } from '../../assets/icons/icon-dots.svg';

const propTypes = {
    isVisible: PropTypes.bool,
    openCampaignDeleteModal: PropTypes.func,
    gotoCampaignEdit: PropTypes.func,
    dropdownItems: PropTypes.arrayOf(PropTypes.shape({}))
};

const EMPTY_VALUE = 'n/a';

class MarketingTable extends Table {
    static propTypes = propTypes;

    columns = [
        {
            Header: 'Name',
            headerClassName: 'forms-table__th forms-table__form-name',
            className: 'forms-table__td forms-table__form-name',
            minWidth: 150,
            id: 'name',
            accessor: d => d.name || EMPTY_VALUE
        },
        {
            id: 'is_sent',
            Header: 'Status',
            headerClassName: 'forms-table__th',
            className: 'forms-table__td',
            minWidth: 120,
            accessor: d => d.is_sent ? 'Sent' : 'Not Sent',
        },
        {
            id: 'recipient_count',
            Header: 'Recipients',
            headerClassName: 'forms-table__th',
            className: 'forms-table__td',
            minWidth: 120,
            accessor: d => d.recipient_count || EMPTY_VALUE
        },
        {
            id: 'created_at',
            Header: 'Created Date',
            headerClassName: 'forms-table__th',
            className: 'forms-table__td',
            minWidth: 120,
            accessor: d => formatDateToSlashes(d.created_at),
        },
        {
            Header: 'Created By',
            id: 'created_by',
            minWidth: 165,
            headerClassName: 'forms-table__th',
            className: 'forms-table__td',
            accessor: 'created_by_detail.full_name',
        },
        {
            id: 'dropdown',
            Header: '',
            headerClassName: 'forms-table__th',
            className: 'forms-table__td table__td_last-of-type',
            width: 50,
            accessor: d => d,
            Cell: row => {
                const { dropdownItems } = this.props;
                return (
                    <Dropdown
                        classNames='dropdown dropdown_forms-table-employee'
                        icon={IconDots}
                        iconClass='icon_chevron_down'
                        data={row.value}
                        items={row.value.is_sent ? [dropdownItems[1]] : dropdownItems}/>
                );
            }
        }
    ];

    render() {
        const { state: { isScrollable }, columns } = this;
        return (
            <div className='forms-table-employee marketing-table'>
                <div className='forms-table__header'>Marketing</div>
                {this.renderTable({ isScrollable, columns })}
            </div>
        );
    }
}

export default MarketingTable;
