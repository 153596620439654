import React from 'react';
import Swiper from 'react-id-swiper';
import PropTypes from 'prop-types';
import DogRenderer from './DogRenderer';

class DogsSlider extends React.PureComponent {
    componentDidMount() {
        window.addEventListener('load', () => {
            console.log('swiper', this.swiper);
            if (this.swiper) this.swiper.update(true);
        });
    }

    swiper = null;

    renderDogs = (dogs) => {
        const { selectedDogs, onItemClick, itemRenderer } = this.props;
        const selectedDogsList = selectedDogs ? selectedDogs.split(',') : [];
        const DogItemRenderer = itemRenderer || DogRenderer;
        return dogs.map((dog, index) => {
            return (
                <DogItemRenderer
                    dogInfo={dog}
                    className='dogs-slider__item'
                    isSelected={!!(selectedDogsList.length && selectedDogsList.indexOf(dog.id.toString()) !== -1)}
                    key={index}
                    onClick={() => onItemClick(dog.id)}/>
            );
        });
    }

    render() {
        const { dogs, slidesPerPage, spaceBetween } = this.props;
        const isMobile = window.innerWidth <= 736;
        const params = {
            containerClass: 'dogs-slider',
            pagination: {},
            autoHeight: true,
            slidesPerView: slidesPerPage || 3,
            spaceBetween: spaceBetween || 20,
            centeredSlides: isMobile,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            onSlideChangeEnd: (swiper) => {
                swiper.update(true);
            },
            runCallbackOnInit: true,
            onInit: (swiper) => {
                this.swiper = swiper;
            },
        };

        if (dogs.length && dogs.length > 2) {
            return <Swiper {...params}>{this.renderDogs(dogs)}</Swiper>;
        } else if (dogs.length && dogs.length <= 2) {
            return (
                <div className='dogs-slider dogs-slider_no-swiper'>
                    <div className='dogs-slider__content'>
                        {this.renderDogs(dogs)}
                    </div>
                </div>
            );
        }
        return null;
    }
}

DogsSlider.propTypes = {
    dogs: PropTypes.arrayOf(PropTypes.shape({})),
    selectedDogs: PropTypes.string,
    onItemClick: PropTypes.func,
    slidesPerPage: PropTypes.number,
    spaceBetween: PropTypes.number,
    itemRenderer: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.node
    ])
};

export default DogsSlider;
