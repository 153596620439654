/* eslint-disable camelcase */

import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from '../../../../../../helpers/currency';
import Table from '../../../../../common/Table';
import { getTransactionDisplayStatus } from '../../../../../../helpers/transactions';

class TransactionsTable extends Table {
    static propTypes = {
        data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
        dataName: PropTypes.string.isRequired,
        loading: PropTypes.bool,
    };

    render() {
        const { isScrollable } = this.state;
        const { data, dataName, loading } = this.props;

        // eslint-disable-next-line consistent-return
        const getPaymentType = model => {
            if (model === 'packagepayment') {
                return (<div>
                    <div>Package</div>
                    <div>Credit</div>
                </div>);
            }
            if (model === 'trainingpayment') {
                return (<div>
                    <div>Training Program</div>
                    <div>Credit</div>
                </div>);
            }
            if (model === 'packagerefund') {
                return (<div>
                    <div>Package</div>
                    <div>Refund</div>
                </div>);
            }
            if (model === 'trainingrefund') {
                return (<div>
                    <div>Training Program</div>
                    <div>Refund</div>
                </div>);
            }
        };

        const rowAmountCapturedTransformer = (modelType, amountCaptured, price, dogs) => {
            if (modelType !== 'stripepayment') {
                return <div>&#8211;</div>;
            };
            if (amountCaptured !== null) {
                if ((Number(amountCaptured) / (dogs.length === 0 ? 1 : dogs.length)) === Number(price)) {
                    return formatMoney(amountCaptured);
                } else {
                    return (
                        <div>
                            <div>{`Price: ${formatMoney(price)}`}</div>
                            <div style={{ marginBottom: '5px' }}>{`Discount: ${formatMoney(price - amountCaptured)}`}</div>
                            <div>{`Sale: ${formatMoney(amountCaptured)}`}</div>
                        </div>
                    );
                };
            };
            return <div>&#8211;</div>;
        };

        const columns = [
            {
                id: 'created',
                Header: props => (
                    <div>
                        <div>Time</div>
                        <div>Created</div>
                    </div>
                ),
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => moment(d.get('created_at')).format('MM/DD/YYYY HH:mm A'),
            },
            {
                id: 'modified',
                Header: props => (
                    <div>
                        <div>Last</div>
                        <div>Modified</div>
                    </div>
                ),
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => moment(d.get('modified_at')).format('MM/DD/YYYY HH:mm A'),
            },
            {
                id: 'type',
                sortable: false,
                Header: props => (
                    <div>
                        <div>Class</div>
                        <div>Name</div>
                    </div>
                ),
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                 accessor: d =>  d.get('target_id')
                   ? <a href={`/dashboard/calendar/classes/${d.get('target_id')}`}>
                       {d.get('type')}
                   </a>
                   : (d.get('event_id')
                       ? <a href={`/dashboard/calendar/trainings/${d.get('event_id')}`}>
                         {d.get('type')}
                     </a>
                       : d.get('type')
                   ),
            },
            {
                id: 'dogs',
                sortable: false,
                Header: 'Dog(s)',
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => d.get('dogs').toJS(),
                Cell: row => (
                    <div>
                        {row.value.length
                            ? row.value.map(dog => <div key={Math.random()}>{dog}</div>)
                            : <div>N/A</div>
                        }
                    </div>
                ),
            },
            {
                id: 'amount',
                Header: props => (
                    <div>
                        <div>Amount</div>
                        <div>Captured</div>
                    </div>
                ),
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => d.toJS(),
                Cell: row => rowAmountCapturedTransformer(
                    row.value.model_type,
                    row.value.amount_captured,
                    row.value.price,
                    row.value.dogs
                ),
            },
            {
                id: 'amount_refunded',
                Header: props => (
                    <div>
                        <div>Amount</div>
                        <div>Refunded</div>
                    </div>
                ),
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => d.toJS(),
                Cell: row => (
                    <div>
                        {row.value.model_type !== 'stripepayment'
                            ? <div>&#8211;</div>
                            : (row.value.amount_refunded !== null
                                ? formatMoney(row.value.amount_refunded)
                                : <div>&#8211;</div>)
                        }
                    </div>
                ),
            },
            {
                id: 'amount_auth',
                Header: props => (
                    <div>
                        <div>Amount</div>
                        <div>Authorized</div>
                    </div>
                ),
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => d.toJS(),
                Cell: row => (
                    <div>
                        {row.value.model_type !== 'stripepayment'
                            ? <div>&#8211;</div>
                            : (row.value.amount_authorized !== null
                                ? formatMoney(row.value.amount_authorized)
                                : <div>&#8211;</div>)
                        }
                    </div>
                ),
            },
            {
                id: 'card',
                sortable: false,
                Header: props => (
                    <div>
                        <div>Payment</div>
                        <div>Method</div>
                    </div>
                ),
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => d.toJS(),
                Cell: row => (
                    <div>
                        {row.value.card !== null
                            ? (<div>
                                <div>Credit Card</div>
                                <div>{row.value.card.first6.substr(0, 4)}</div>
                            </div>)
                            : getPaymentType(row.value.model_type)
                        }
                    </div>
                ),
            },
            {
                id: 'status',
                sortable: false,
                Header: 'Status',
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => getTransactionDisplayStatus(d.toJS()),
            },
            {
                id: 'transaction_id',
                Header: props => (
                    <div>
                        <div>Transaction</div>
                        <div>Number</div>
                    </div>
                ),
                headerClassName: 'transactions-table__th',
                className: 'transactions-table__td',
                accessor: d => d.get('transaction_id'),
                Cell: row => (
                    <div className='transactions-table__td_transaction-id'>
                        {row.value === null
                            ? <div>&#8211;</div>
                            : row.value
                        }
                    </div>
                ),
            },
        ];
        
        return (
            <div className='transactions-table'>
                <div className='transactions-table__header'>
                    <div className='transactions-table__header-content'>
                        {dataName}
                    </div>
                </div>
                {this.renderTable({ isScrollable, columns })}
                {data.size === 0 
                    ? <div className='transactions-table__empty-content'>
                        {!loading ? `No data for ${dataName}` : 'Loading...'}
                    </div> 
                    : null
                }
            </div>
        );
    }
}

export default TransactionsTable;
