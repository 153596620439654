import * as axios from 'axios';
import { getScope } from './oauth';
import { generateAlertMeta } from '../components/Alert/actions';

const dispatchAlert = (dispatch, responseData, isDog) => {
    const event = isDog ? 'DOG_EDIT' : 'CUSTOMER_EDIT';
    dispatch(generateAlertMeta({ event, responseData }));
};

export const update = ({ url, data, actionCB }) => {
    return (dispatch) => {
        return axios.patch(url, data)
                    .then(response => {
                        if (actionCB) {
                            actionCB(dispatch, response);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                        actionCB(dispatch, null, true);
                    });
    };
};

export const updateInfo = ({ url, infoChunks, actionCB, context }) => {
    return (dispatch, getState) => {
        let request = new FormData();
        let vipUpdate = null;
        let activeUpdate = null;
        // eslint-disable-next-line
        let deceasedUpdate = null;
        infoChunks.forEach((chunk) => {
            console.log(chunk);
            const { name, content } = chunk;
            if (name === 'is_vip') {
                vipUpdate = content;
            }
    
            if (name === 'is_banned') {
                activeUpdate = content;
            }
            
            if (name === 'is_deceased') {
                // eslint-disable-next-line
                deceasedUpdate = content;
            }
            
            if (name === 'secondary_phone' && content === null) {
                request = {
                    'secondary_phone': null,
                    'scope': getScope(),
                };
            } else {
                request.append(chunk.name, chunk.content);
                request.append('scope', getScope());
            }
        });
        const isDog = context === 'dogs';
        const currentInstanceKey = 'current' + (isDog ? 'Dog' : 'Customer');
        const nameKey = isDog ? 'name' : 'full_name';
        const name = getState().getIn([currentInstanceKey, 'instance', nameKey]);
        return axios.patch(url, request)
                    .then(response => {
                        if (vipUpdate !== null) {
                            dispatch(generateAlertMeta({
                                event: vipUpdate === false ?
                                    'CUSTOMER_NOT_VIP' :
                                    'CUSTOMER_VIP',
                                responseData: { name }
                            }));
                        } else if (activeUpdate !== null) {
                            dispatch(generateAlertMeta({
                                event: activeUpdate === false ?
                                    'CUSTOMER_DOG_ACTIVE' :
                                    'CUSTOMER_DOG_NOT_ACTIVE',
                                responseData: { name }
                            }));
                        } else {
                            dispatchAlert(
                                dispatch,
                                { name },
                                isDog
                            );
                        }
                        
                        if (actionCB) {
                            actionCB(dispatch, response);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                    });
    };
};

export const updateAvailableInfo = ({ url, infoChunks, actionCB }) => {
    return (dispatch, getState) => {
        console.log(infoChunks);
        const request = new FormData();
        infoChunks.forEach((chunk) => {
            const key = Object.keys(chunk)[0];
            request.append(key, chunk[key]);
        });
        const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
        return axios.patch(url, request)
                    .then(response => {
                        dispatchAlert(
                            dispatch,
                            { name }
                        );
                        console.log('Server response: ', response);
                        if (actionCB) {
                            actionCB(dispatch, response);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                    });
    };
};

export const addNewInfo = ({ type, url, infoChunks, actionCB }) => {
    return (dispatch, getState) => {
        const request = new FormData();
        if (type === 'address') {
            const infoChunksJS = infoChunks.toJS();
            infoChunksJS.forEach((chunk) => {
                const key = Object.keys(chunk)[0];
                request.append(key, chunk[key]);
            });
        } else {
            request.append(`${type}`, infoChunks.getIn(['data', '0', `${type}`]));
        }
        const name = getState().getIn(['currentCustomer', 'instance', 'full_name']);
        return axios.post(url, request)
                    .then(response => {
                        dispatchAlert(
                            dispatch,
                            { name }
                        );
                        console.log('Server response: ', response);
                        if (actionCB) {
                            actionCB(dispatch, response);
                        }
                    })
                    .catch(err => {
                        console.log(err);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                            }
                        }
                    });
    };
};
