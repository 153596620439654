import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import DayPicker from 'react-day-picker';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CalendarActions from '../../../../../actions/dashboard/calendarActions';
import { ReactComponent as ChevronLeft } from '../../../../../assets/icons/icon-chevron-left.svg';
import { ReactComponent as ChevronRight } from '../../../../../assets/icons/icon-chevron-right.svg';
import Icon from '../../../../common/Icon/Icon';
import { convertToNativeDate, convertNativeDateToDateString, isToday } from '../../../../../helpers/date';

class CustomToolbar extends React.PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/forbid-prop-types
        date: PropTypes.any,
        onNavigate: PropTypes.func,
        onViewChange: PropTypes.func,
        label: PropTypes.string,
        calendar: PropTypes.shape({
            view: PropTypes.string,
            calendarSelectedDays: PropTypes.oneOfType([
                PropTypes.arrayOf(PropTypes.shape({})),
                PropTypes.shape({})
            ]),
            isDayPickerShown: PropTypes.bool,
        }),
        getCalendarSelectedRange: PropTypes.func,
        setCalendarView: PropTypes.func,
        setDaypickerShown: PropTypes.func,
        setDaypickerHidden: PropTypes.func,
        setRangeStart: PropTypes.func,
        setRangeEnd: PropTypes.func,
        setRangeStartEnd: PropTypes.func,
        shouldNotDisplayViewControls: PropTypes.bool
    };

    componentDidMount() {
        window.addEventListener('click', this.toggleDayPicker);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.toggleDayPicker);
    }

    toggleDayPicker = (e) => {
        const { setDaypickerShown, setDaypickerHidden } = this.props;
        const target = e.target;
        const root = this.dayPickerToggle;
        if ((target === root || (typeof e.target.className === 'string' &&
                e.target.className.indexOf('DayPicker-NavButton') !== -1))) {
            return setDaypickerShown();
        }
        return setDaypickerHidden();
    };

    changeView = (date, view) => {
        const { onNavigate, getCalendarSelectedRange, setCalendarView } = this.props;
        getCalendarSelectedRange(date);
        setCalendarView(view);
        onNavigate('DATE', date, view);
    };

    render() {
        const {
            date,
            onNavigate,
            label,
            getCalendarSelectedRange,
            setRangeStartEnd,
            shouldNotDisplayViewControls = false
        } = this.props;
        const { calendarSelectedDays, view, isDayPickerShown } = this.props.calendar;
        const isDateToday = isToday(convertNativeDateToDateString({ dateObj: date }));
        const dayClassNames = classNames({
            'rbc-active': view === 'day'
        });
        const weekClassNames = classNames({
            'rbc-active': view === 'week'
        });
        const monthClassNames = classNames({
            'rbc-active': view === 'month'
        });
        const todayClassNames = classNames({
            'rbc-custom-today': true,
            'rbc-custom-today_disabled': view === 'day' && date && isDateToday
        });
        // const goToBack = () => onNavigate('PREV');
        // const goToNext = () => onNavigate('NEXT');
        const goToBack = () => {
            if (view === 'day') {
                onNavigate('DATE', moment(date).subtract(1, 'day').toDate());
            } else {
                onNavigate('PREV');
            }
        };
        const goToNext = () => {
            if (view === 'day') {
                onNavigate('DATE', moment(date).add(1, 'day').toDate());
            } else {
                onNavigate('NEXT');
            }
        };
        const goToCurrent = () => {
            if (view === 'day' && isDateToday) {
                return null;
            }

            if (view === 'day') {
                return onNavigate('DATE', convertToNativeDate());
            }

            const formattedToday = moment().format('YYYY-MM-DD');
            this.changeView(convertToNativeDate(), 'day');
            return setRangeStartEnd({ start: formattedToday, end: formattedToday });
        };
        const goToDayView = () => this.changeView(convertToNativeDate(), 'day');
        const goToWeekView = () => this.changeView(convertToNativeDate(), 'week');
        const goToMonthView = () => this.changeView(convertToNativeDate(), 'month');
        const goToSelectedDate = date => {
            if (view === 'month') {
                this.changeView(convertToNativeDate(date), 'day');
            }
            onNavigate('DATE', convertToNativeDate(date));
        };

        return (
            <div className='rbc-toolbar'>
                {/*eslint-disable-next-line*/}
                <a
                    onClick={() => {
                        goToBack();
                    }}
                    className='rbc-custom-back'>
                    <Icon glyph={ChevronLeft} className='icon_chevron-left'/>
                </a>
                <span
                    className='rbc-toolbar-label'
                    ref={(dayPicker) => { this.dayPickerToggle = dayPicker; }}
                    onClick={e => {
                        this.toggleDayPicker(e);
                        getCalendarSelectedRange(date);
                    }}>
                    {label}
                </span>
                {isDayPickerShown &&
                <DayPicker
                    onDayClick={(date) => goToSelectedDate(date)}
                    month={convertToNativeDate(date)}
                    selectedDays={view === 'week' ? calendarSelectedDays : [date]}
                    enableOutsideDays/>
                }
                {/*eslint-disable-next-line*/}
                <a onClick={() => goToNext()} className='rbc-custom-next'>
                    <Icon glyph={ChevronRight} className='icon_chevron-right'/>
                </a>
                {/*eslint-disable-next-line*/}
                <a
                    onClick={() => { goToCurrent(); }}
                    className={todayClassNames}>
                    Go to Today
                </a>
                {!shouldNotDisplayViewControls &&
                    <span className='rbc-btn-group'>
                        <button onClick={() => goToDayView()} className={dayClassNames} type='button'>day</button>
                        <button onClick={() => goToWeekView()} className={weekClassNames} type='button'>week</button>
                        <button onClick={() => goToMonthView()} className={monthClassNames} type='button'>month</button>
                    </span>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        calendar: state.get('calendar').toJS(),
    };
};

const mapDispatchToProps = (dispatch) => ({
    getCalendarSelectedRange: bindActionCreators(CalendarActions.getCalendarSelectedRange, dispatch),
    setCalendarView: bindActionCreators(CalendarActions.setCalendarView, dispatch),
    setDaypickerShown: bindActionCreators(CalendarActions.setDaypickerShown, dispatch),
    setDaypickerHidden: bindActionCreators(CalendarActions.setDaypickerHidden, dispatch),
    setRangeStartEnd: bindActionCreators(CalendarActions.setRangeStartEnd, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomToolbar);
