import React  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { usStates } from '../../../../helpers/options';
import renderInputControl from '../../../common/Form/InputControl';
import renderPlacesAutocompleteControl from '../../../common/Form/PlacesAutocompleteControl';
import renderSelectControl from '../../../common/Form/SelectControl';
import validate from './validate';
import renderPasswordControl from '../../../common/Form/PasswordControl/PasswordControl';
import { phoneNumberNormalizer, PHONE_MASK, PHONE_MASK_CHAR } from '../../../../helpers/normalize';


const EditCustomerContactInfoForm = ({
    handleSubmit,
    invalid,
    submitting,
    onClose,
    handleAutocompleteSelectOrEnter,
    email,
    hasUsablePassword,
    newEmailValue
}) => {
    const submitButtonClassNames = classNames({
        'button modal-dialog__button': true,
        'button_disabled': (invalid || submitting)
    });
    const hiddenStyle = { width: '0', height: '0', border: 'none' };

    return (
        <form className='modal-dialog__form' onSubmit={handleSubmit}>
            <div className='modal-dialog__form-content'>
                <Field
                    name='full_name'
                    type='text'
                    label='Full name'
                    component={renderInputControl} />
                <Field
                    name='primary_phone'
                    type='text'
                    label='Phone 1'
                    mask={PHONE_MASK}
                    maskChar={PHONE_MASK_CHAR}
                    isMasked
                    normalize={phoneNumberNormalizer}
                    component={renderInputControl} />
                <Field
                    name='secondary_phone'
                    type='text'
                    label='Phone 2'
                    mask={PHONE_MASK}
                    maskChar={PHONE_MASK_CHAR}
                    isMasked
                    normalize={phoneNumberNormalizer}
                    component={renderInputControl} />
                <div className='form__separator' />
                <div className='form__section-title'>Email</div>
                <div className='form__section-subtitle'>Current Email: {email}</div>
                <input type='email' value='' name='_email' style={hiddenStyle} />
                <input type='password' value='' name='_password' style={hiddenStyle} />
                <Field
                    name='new_email'
                    type='text'
                    component={renderInputControl}
                    label='New Email'/>
                {newEmailValue && newEmailValue.length > 0 && hasUsablePassword &&
                    <Field
                        name='current_password'
                        type='password'
                        component={renderPasswordControl}
                        label='Current password' />}
                <div className='form__separator' />
                <Field
                    name='address1'
                    type='text'
                    component={renderPlacesAutocompleteControl}
                    handleSelectOrEnter={address => {
                        handleAutocompleteSelectOrEnter(address, 'EditCustomerInfoForm');
                    }}
                    label='Address' />
                <Field
                    name='address2'
                    type='text'
                    component={renderInputControl}
                    label='Address 2 (Apt or Unit number)' />
                <div className='form__aligner form__aligner_39-28-29'>
                    <Field
                        name='city'
                        type='text'
                        component={renderInputControl}
                        label='City' />
                    <Field
                        name='state'
                        type='text'
                        component={renderSelectControl}
                        options={usStates}
                        className='select-control__is-state'
                        searchable
                        returnSelectValue
                        label='State' />
                    <Field
                        name='zip_code'
                        type='text'
                        component={renderInputControl}
                        label='ZIP Code' />
                </div>
                <Field
                    name='location_notes'
                    type='text'
                    component={renderInputControl}
                    label='Location Notes'/>
            </div>
            <div className='modal-dialog__form-footer modal-dialog__form-footer_column'>
                <button
                    className={submitButtonClassNames}
                    type='submit'
                    disabled={submitting}>
                    {submitting
                        ? 'Submitting...'
                        : 'Save'
                    }
                </button>
                <div className='modal-dialog__cancel' onClick={onClose}>Cancel</div>
            </div>
        </form>
    );
};

EditCustomerContactInfoForm.propTypes = {
    handleSubmit: PropTypes.func,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    hasUsablePassword: PropTypes.bool,
    onClose: PropTypes.func,
    handleAutocompleteSelectOrEnter: PropTypes.func,
    email: PropTypes.string,
    newEmailValue: PropTypes.string,
};


const mapStateToProps = state => {
    const currentCustomer = state.getIn(['currentCustomer', 'instance']).toJS();
    const availableAddresses = currentCustomer.available_addresses;
    const primaryPhone = currentCustomer.primaryPhoneId ? currentCustomer.primary_phone : '';
    const secondaryPhone = currentCustomer.secondaryPhoneId ? currentCustomer.secondary_phone : '';
    let homeAddress = {};
    availableAddresses.find(address => {
        if (address.id === currentCustomer.addressId) {
            homeAddress = address;
            homeAddress.address2 = homeAddress.address2 === 'null' ? null : homeAddress.address2;
            return true;
        }
        return false;
    });
    return {
        isAddAddressModalOpened: state.getIn(['misc', 'modals', 'addAddress']),
        email: state.getIn(['currentCustomer', 'instance', 'email']),
        hasUsablePassword: state.getIn(['currentCustomer', 'instance', 'has_usable_password']),
        initialValues: {
            email: state.getIn(['currentCustomer', 'instance', 'email']),
            full_name: currentCustomer.full_name,
            primary_phone: primaryPhone,
            secondary_phone: secondaryPhone,
            ...homeAddress
        },
        newEmailValue: state.getIn(['form', 'EditCustomerInfoForm', 'values', 'new_email'])
    };
};

const form = reduxForm({
    form: 'EditCustomerInfoForm',
    enableReinitialize: true,
    validate
});

export default connect(mapStateToProps, null)(form(EditCustomerContactInfoForm));
