import React from 'react';
import { getMonthFromDate } from '../../../../../../helpers/date';
import { month as monthOptions } from '../../../../../../helpers/options';
import Table from '../../../../../common/Table';

class DogsTable extends Table {
    state = {
        genderValue: '',
        birthdayValue: '',
        alteredValue: '',
        accessLevelValue: '',
        isScrollable: false
    };

    updateSelectFilter = (e, onFiltering, filterType, valueName) => {
        if (valueName === 'genderValue') {
            this.setState({ genderValue: e });
            onFiltering(`${filterType}${e.value}`);
        }
        if (valueName === 'alteredValue') {
            this.setState({ alteredValue: e });
            onFiltering(`${filterType}${e.value}`);
        }
        if (valueName === 'accessLevelValue') {
            this.setState({ accessLevelValue: e });
            onFiltering(`${filterType}${e.label}`);
        }
        if (valueName === 'birthdayValue') {
            this.setState({ birthdayValue: e });
            onFiltering(`${filterType}${e.value}`);
        }
    };

    render() {
        const { isScrollable, genderValue, alteredValue, accessLevelValue, birthdayValue } = this.state;
        const { accessLevelOptions } = this.props;
        const genderOptions = [
            { value: '', label: 'All' },
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
        ];
        const alteredOptions = [
            { value: '', label: 'All' },
            { value: 'Yes', label: 'Yes' },
            { value: 'No', label: 'No' },
        ];
        const columns = [
            {
                Header: 'Name',
                headerClassName: 'dogs-table__th',
                className: 'dogs-table__td',
                id: 'name',
                accessor: d => d.get('name'),
                Cell: row => (
                    <div
                        className='dogs-table__dogs-link'
                        onClick={() => this.props.onDogClick(row.original)}>
                        {row.value.toUpperCase()}
                    </div>
                ),
                Filter: this.makeInputPlaceholderFilter('Search', '?name=')
            },
            {
                Header: 'Breed',
                headerClassName: 'dogs-table__th',
                className: 'dogs-table__td',
                id: 'breed',
                accessor: d => d.get('breed_detail'),
                Filter: this.makeInputPlaceholderFilter('Search', '?breed=')
            },
            {
                Header: 'Veterinarian',
                headerClassName: 'dogs-table__th',
                className: 'dogs-table__td',
                id: 'vet',
                accessor: d => d.getIn(['veterinarian_detail', 'name']),
                Cell: row => (
                    <div>
                        {row.value === undefined
                            ? <span>-</span>
                            : <span>{row.value}</span>
                        }
                    </div>
                ),
                Filter: this.makeInputPlaceholderFilter('Search', '?vet=')
            },
            {
                Header: 'Birthday',
                headerClassName: 'dogs-table__th dogs-table__th_select',
                className: 'dogs-table__td',
                width: 100,
                id: 'birthday',
                accessor: d => d.get('birthday'),
                Cell: row => (
                    <div>
                        {row.value ? getMonthFromDate(row.value) : '-'}
                    </div>
                ),
                Filter: this.makeSelectFilter({
                    valueName: 'birthdayValue',
                    placeholder: 'Month',
                    state: birthdayValue,
                    options: monthOptions,
                    filterType: '?month='
                })
            },
            {
                Header: 'Gender',
                headerClassName: 'dogs-table__th dogs-table__th_select',
                className: 'dogs-table__td',
                width: 80,
                id: 'gender',
                accessor: d => d.get('gender_detail'),
                Cell: row => (
                    <div>
                        {row.value === undefined
                            ? <span>-</span>
                            : <span>{row.value}</span>
                        }
                    </div>
                ),
                Filter: this.makeSelectFilter({
                    valueName: 'genderValue',
                    placeholder: 'Gender',
                    state: genderValue,
                    options: genderOptions,
                    filterType: '?gender='
                })
            },
            {
                Header: props => (
                    <div>
                        <div>Spayed/</div>
                        <div>Neutered</div>
                    </div>
                ),
                headerClassName: 'dogs-table__th dogs-table__th_select',
                className: 'dogs-table__td dogs-table__td_email',
                width: 80,
                id: 'is_altered',
                accessor: d => d.get('is_altered'),
                Cell: row => (
                    <div>
                        {row.value
                            ? <span>Yes</span>
                            : <span>No</span>
                        }
                    </div>
                ),
                Filter: this.makeSelectFilter({
                    valueName: 'alteredValue',
                    placeholder: 'Spay/Neuter',
                    state: alteredValue,
                    options: alteredOptions,
                    filterType: '?is_altered='
                })
            },
            {
                Header: 'Incident',
                headerClassName: 'dogs-table__th',
                className: 'dogs-table__td',
                width: 90,
                id: 'incidents',
                accessor: d => d.get('incident_count'),
                Cell: row => (
                    <div>
                        {row.value === 0
                            ? <span>None</span>
                            : <span>{row.value}</span>
                        }
                    </div>
                ),
                Filter: this.makeInputPlaceholderFilter('Search', '?incidents=')
            },
            {
                Header: props => (
                    <div>
                        <div>Access</div>
                        <div>Level</div>
                    </div>
                ),
                headerClassName: 'dogs-table__th dogs-table__th_select',
                className: 'dogs-table__td',
                width: 150,
                id: 'access',
                accessor: d => d.get('access_level_detail'),
                Filter: this.makeSelectFilter({
                    valueName: 'accessLevelValue',
                    placeholder: 'Access Level',
                    state: accessLevelValue,
                    options: accessLevelOptions,
                    filterType: '?access='
                })
            },
            {
                Header: 'Owner',
                headerClassName: 'dogs-table__th dogs-table__th_last-of-type',
                className: 'dogs-table__td',
                id: 'owner',
                accessor: d => d.get('owner_detail'),
                Cell: row => (
                    <div
                        className='customers-table__customer-link'
                        onClick={() => this.props.onCustomerClick(row.original.get('owner'))}>
                        {row.value.get('full_name')}
                    </div>
                ),
                Filter: this.makeInputPlaceholderFilter('Search', '?owner=')
            }
        ];
        const { classNameModifier, count } = this.props;
        return (
            <div className={`dogs-table ${classNameModifier}`}>
                <div className='dogs-table__header'>
                    <div className='dogs-table__header-content'>
                        Dogs {count !== null && `(${count})`}
                    </div>
                </div>
                {this.renderTable({ isScrollable, columns })}
            </div>
        );
    }
}

export default DogsTable;
