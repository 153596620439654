/* eslint-disable max-len */
import { push } from 'react-router-redux';
import { change, reset, submit } from 'redux-form';
import axios from 'axios';
import * as MessagesActions from '../../actions/messagesActions';
import { getUserNameSpace } from '../../helpers/userRoles';
import { removeBookingDog } from '../../services/dogs';
import * as EventService from '../../services/event';
import * as ModalService from '../../services/modals';
import * as PaymentCardsServices from '../../services/paymentCards';
import { sendTrainingRequest } from '../../services/trainings';
import * as ClassesActions from '../dashboard/classesActions';
import * as EventActions from '../dashboard/eventActions';
import { onModalClose } from './modalActions';
import * as ModalOpenActions from './openActions';
import { ADMIN_ONLY_PACKAGE_ID } from '../../config';
import * as UpdateActions from '../../actions/modal/updateActions';

export const submitForm = ({ formConfig }) => {
    return dispatch => {
        return dispatch(submit(formConfig.form));
    };
};

export const deleteItem = ({ formConfig }) => {
    return dispatch => {
        return dispatch(ModalService.deleteRequest(formConfig.url));
    };
};

export const cancelWaivingCustomersUpcomingClass = ({ formConfig }) => {
    return dispatch => {
        console.log('WAIVE');
        return dispatch(ClassesActions.patchCustomersUpcomingClassStatus(formConfig.classData, 2));
    };
};

export const cancelWaivingDogsUpcomingClass = ({ formConfig }) => {
    return dispatch => {
        console.log('WAIVE');
        return dispatch(ClassesActions.patchDogsUpcomingClassStatus(formConfig.classData, 2));
    };
};

export const editItem = ({ formConfig, submitData }) => {
    const { packageId, updateAction } = formConfig;
    const { price } = submitData;

    if (packageId === ADMIN_ONLY_PACKAGE_ID && updateAction === 'updatePackageForPackageList' && price === 0) {
        delete submitData['price'];
        delete submitData['training_classes'];
    }

    return dispatch => {
        return dispatch(ModalService.editRequest(formConfig.url, submitData));
    };
};

export const postItem = ({ formConfig, submitData }) => {
    return dispatch => {
        return dispatch(ModalService.postRequest(formConfig.url, submitData));
    };
};

export const setDogVeterinarian = ({ formConfig, submitData }) => {
    return dispatch => {
        return new Promise((resolve) => {
            return dispatch(postItem({ formConfig, submitData }))
                .then(response => {
                    console.log(response);
                    return dispatch(ModalService.editRequest(formConfig.dogUrl, {
                        veterinarian: response.data.id
                    }))
                        .then(() => {
                            resolve(response);
                        })
                        .catch(err => {
                            console.log(err.response);
                        });
                })
                .catch(err => {
                    console.log(err.response.status);
                });
        });
    };
};

const removeDog = (url, submitData) => {
    return () => {
        return axios({
            method: 'DELETE',
            url,
            data: submitData
        });
    };
};

export const customerDeleteDog = ({ formConfig, submitData }) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return dispatch(removeDog(formConfig.url, submitData))
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(err => {
                    console.log(err.response.status);
                    if (err.response.status === 409) {
                        // if response has warnings send again with suppress.
                        submitData.suppress = err.response.data.map((item) => item[0]);
                        dispatch(removeDog(formConfig.url, submitData))
                            .then((response) => {
                                console.log(response);
                                resolve(response);
                            })
                            .catch(err => reject(err));
                    }
                });
        });
    };
};

export const markDogDeceased = ({ formConfig }) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            return dispatch(ModalService.editRequest(formConfig.url, formConfig.updateData))
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(err => {
                    if (err.response.status === 409) {
                        // if response has warnings send again with suppress.
                        formConfig.updateData.suppress = err.response.data.map((item) => item[0]);
                        dispatch(ModalService.editRequest(formConfig.url, formConfig.updateData))
                            .then((response) => {
                                console.log(response);
                                resolve(response);
                            })
                            .catch(err => reject(err));
                    }
                });
        });
    };
};

export const updatePhoto = ({ url, photo }) => {
    return dispatch => {
        const request = new FormData();
        request.append('photo', photo);
        return dispatch(ModalService.editRequest(url, request));
    };
};

export const tryEditClassEvent = ({ formConfig, submitData }) => {
    // eslint-disable-next-line consistent-return
    return dispatch => {
        return dispatch(editItem({ formConfig, submitData }));
    };
};

export const tryEditTrainingEvent = ({ formConfig, submitData }) => {
    return dispatch => {
        return dispatch(editItem({ formConfig, submitData }));
    };
};

export const tryPostTrainingEvent = ({ formConfig, submitData }) => {
    return dispatch => {
        return dispatch(postItem({ formConfig, submitData }));
    };
};

export const forceClassEventEditSubmit = ({ formConfig }) => {
    return dispatch => {
        const eventEditFormConfig = formConfig.parentFormData.formConfig;
        const eventEditSubmitData = formConfig.parentFormData.submitData;
        eventEditFormConfig.shouldForceSubmit = true;
        eventEditSubmitData.cancel_inappropriate_products = true;
        return dispatch(tryEditClassEvent({
            formConfig: eventEditFormConfig,
            submitData: eventEditSubmitData
        }));
    };
};

export const forceTrainingEventEditSubmit = ({ formConfig }) => {
    return dispatch => {
        const eventEditFormConfig = formConfig.parentFormData.formConfig;
        const eventEditSubmitData = formConfig.parentFormData.submitData;
        const suppress = formConfig.parentFormData.errorData.map(error => {
            return error[0];
        });
        if (eventEditSubmitData.suppress) {
            eventEditSubmitData.suppress = eventEditSubmitData.suppress.concat(suppress);
        } else {
            eventEditSubmitData.suppress = suppress;
        }

        return dispatch(tryEditTrainingEvent({
            formConfig: eventEditFormConfig,
            submitData: eventEditSubmitData
        }));
    };
};

export const forceTrainingEventPostSubmit = ({ formConfig }) => {
    return dispatch => {
        const eventEditFormConfig = formConfig.parentFormData.formConfig;
        const eventEditSubmitData = formConfig.parentFormData.submitData;
        const suppress = Object.keys(formConfig.parentFormData.errorData).map((key, i) => {
            return key;
        });

        if (eventEditSubmitData.suppress) {
            eventEditSubmitData.suppress = eventEditSubmitData.suppress.concat(suppress);
        } else {
            eventEditSubmitData.suppress = suppress;
        }

        return dispatch(tryPostTrainingEvent({
            formConfig: eventEditFormConfig,
            submitData: eventEditSubmitData
        }))
            .then(response => {
                dispatch(UpdateActions.goToCalendarPage());
                return response;
            })
    };
};

export const forceTrainingEventProductPostSubmit = ({ formConfig }) => {
    return dispatch => {
        const eventEditFormConfig = formConfig.parentFormData.formConfig;
        const eventEditSubmitData = formConfig.parentFormData.submitData;
        const suppress = formConfig.parentFormData.errorData.map(error => {
            return error[0];
        });
        const { product } = eventEditFormConfig.additionalData;
        eventEditSubmitData.purchased = product.purchased;
        eventEditSubmitData.dog_detail = product.dog_detail;

        if (eventEditSubmitData.suppress) {
            eventEditSubmitData.suppress = eventEditSubmitData.suppress.concat(suppress);
        } else {
            eventEditSubmitData.suppress = suppress;
        }

        return dispatch(ModalService.postRequest(eventEditFormConfig.url, eventEditSubmitData))
            .then(response => {
                dispatch(UpdateActions.addDogToEventProducts({ responseData: product }));
                return response;
            })
    };
};

export const changeCurrentEventTrainingProgram = ({ formConfig }) => {
    return (dispatch, getState) => { // eslint-disable-line
        if (getState().getIn(['form', formConfig.field.form]).toJS().values === undefined) {
            return dispatch(change(formConfig.field.form, formConfig.field.name, formConfig.field.value));
        }
        const programId = formConfig.field.value.value;
        const trainerId = getState().getIn(['form', formConfig.field.form]).toJS().values.trainer.value;

        dispatch(EventActions.setCurrentEventProducts([]));
        dispatch(change(formConfig.field.form, formConfig.field.name, formConfig.field.value));
        dispatch(EventActions.addBusyField('trainer'));

        return dispatch(EventService.getPurchasedTrainingPrograms({
            role: 'employees',
            data: {
                program: programId,
                trainer: trainerId
            }
        }))
            .then(response => {
                dispatch(EventActions.removeBusyField('trainer'));
                // If no purchased training programs found it means that this trainer has not sold the selected trainer
                // and we should clear the field.
                if (!response.data.results.length) {
                    dispatch(change(formConfig.field.form, 'trainer', ''));
                }
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
                dispatch(EventActions.removeBusyField('trainer'));
            });
    };
};

export const clearCurrentEventProducts = ({ formConfig }) => {
    return (dispatch, getState) => {
        if (getState().getIn(['form', formConfig.field.form]).toJS().values === undefined) {
            return dispatch(change(formConfig.field.form, formConfig.field.name, formConfig.field.value));
        }

        dispatch(EventActions.setCurrentEventProducts([]));
        return dispatch(change(formConfig.field.form, formConfig.field.name, formConfig.field.value));
    };
};

export const changeNewEventTrainingProgram = (data) => {
    return (dispatch, getState) => {
        if (getState().getIn(['form', data.form]).toJS().values === undefined) {
            return dispatch(change(data.form, data.name, data.value));
        }
        let programId;
        let trainerId;
        if (data.value !== null) {
            programId = data.value.value;
        }
        const stateTrainerValue = getState().getIn(['form', data.form]).toJS().values.trainer;
        if (stateTrainerValue !== undefined) {
            trainerId = getState().getIn(['form', data.form]).toJS().values.trainer.value;
        }

        dispatch(EventActions.setCurrentEventProducts([]));
        dispatch(change(data.form, data.name, data.value));
        dispatch(reset('trainingEventDogSearch'));
        dispatch(EventActions.addBusyField('trainer'));

        return dispatch(EventService.getPurchasedTrainingPrograms({
            role: 'employees',
            data: {
                program: programId,
                trainer: trainerId
            }
        }))
            .then(response => {
                dispatch(EventActions.removeBusyField('trainer'));

                // If no purchased training programs found it means that this trainer has not sold the selected trainer
                // and we should clear the field.
                if (!response.data.results.length) {
                    dispatch(change(data.form, 'trainer', ''));
                }
            })
            .catch(err => {
                console.log(err);
                console.log(err.response);
                dispatch(EventActions.removeBusyField('trainer'));
            });
    };
};

export const sellPackageOrTraining = ({ submitData }) => {
    return (dispatch, getState) => { // eslint-disable-line
        const {
            dog,
            trainer,
            trainingProgram,
            packageId,
            card,
            newCardNumber,
            newCardExpirationMonth,
            newCardExpirationYear,
            newCardCvc,
            discount
        } = submitData;

        let url;
        const request = {};
        const customerId = getState().getIn(['currentCustomer', 'instance', 'id']);
        if (packageId !== undefined) {
            url = `/v1/${getUserNameSpace('customers.packages')}/customers/${customerId}/packages/${packageId}/sell/`;
            request.packageId = packageId;
        }

        if (trainingProgram !== undefined) {
            url = `/v1/${getUserNameSpace('customers.training-programs')}/customers/${customerId}/training-programs/` +
                `${trainingProgram}/sell/`;
            request.dog = dog;
            request.trainer = trainer;
            request.trainingId = trainingProgram;
        }

        if (discount !== undefined) {
            request.discount = discount;
        }

        request.card = card;

        return new Promise((sellResolve, sellReject) => { // eslint-disable-line
            if (card === 123456) {
                const cardData = {
                    number: newCardNumber,
                    expiration_month: newCardExpirationMonth,
                    expiration_year: newCardExpirationYear,
                    cvc: newCardCvc
                };
                return new Promise(() => {
                    dispatch(PaymentCardsServices.createStripeCardToken(customerId, cardData, true))
                        .then(response => {
                            request.card = response.id;
                            sellResolve(dispatch(ModalService.postRequest(url, request)));
                        })
                        .catch(error => {
                            console.log(error);
                            console.log(error.response);
                            sellReject(error);
                        });
                });
            } else { // eslint-disable-line
                sellResolve(dispatch(ModalService.postRequest(url, request)));
            }
        });
    };
};

export const removeIrrelevantEventProducts = () => {
    return dispatch => {
        dispatch(EventActions.setCurrentEventProducts([]));
    };
};

export const switchToCalendar = () => {
    return dispatch => {
        dispatch(push('/dashboard/calendar/'));
    };
};

export const addDogToEvent = ({ formConfig, formData }) => {
    return dispatch => {
        const eventType = formConfig.currentEvent.getIn(['event', 'type']);

        if (eventType === 'class') {
            return dispatch(ModalOpenActions.openClassBookingModalDialog(formConfig, formData));
        }
        return dispatch(ModalOpenActions.openTrainingBookingModalDialog(formConfig, formData));
    };
};

export const postTrainingRequest = ({ formConfig, submitData }) => {
    return dispatch => {
        console.log(formConfig);
        const requestData = {
            id: formConfig.trainingId,
            dogs: submitData.get('dogs').split(','),
            goals: submitData.get('training_goals'),
            email: submitData.get('email'),
            phone_number: submitData.get('phone')
        };
        return new Promise((resolve, reject) => {
            dispatch(sendTrainingRequest(requestData))
                .then(response => {
                    dispatch(ModalOpenActions.openCustomerSuccessTrainingModal());
                })
                .catch(error => {
                    reject(error);
                });
        });
    };
};

export const addDogToCurrentEventProducts = (formConfig, formData) => {
    return dispatch => {
        const { product } = formConfig.additionalData;
        product.drop_off_location = formData.get('drop_off_location').value;
        product.drop_off_location_detail = formData.get('drop_off_location').detail;
        product.pickup_location = formData.get('pickup_location').value;
        product.pickup_location_detail = formData.get('pickup_location').detail;

        dispatch(EventActions.addDogToEventProducts(product));
    };
};

export const goToUrl = ({ formConfig }) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            dispatch(push(formConfig.url));
            resolve();
        });
    };
};

export const editFormEditCreateFormEmployee = ({ formConfig, submitData }) => {
    return dispatch => {
        return dispatch(editItem({ formConfig, submitData }));
    };
};

export const createFormEditCreateFormEmployee = ({ formConfig, submitData }) => {
    return dispatch => {
        return dispatch(postItem({ formConfig, submitData }));
    };
};

export const cancelClass = ({ formConfig }) => {
    return dispatch => {
        return dispatch(ModalService.postRequest(formConfig.url));
    };
};

export const removeDogFromScheduleEvent = ({ formConfig }) => {
    return (dispatch, getState) => {
        return dispatch(ModalService.editRequest(formConfig.url, {
            status: 2 // cancel status
        }));
    };
};

export const removeBookingDogFromList = ({ formConfig: { dogId } }) => {
    return dispatch => {
        return dispatch(removeBookingDog(dogId));
    };
};

// FIT-1721
export const forceClassScheduleEditSubmit = ({ formConfig }) => {
    const submitData = formConfig.parentFormData.submitData;
    submitData.cancel_inappropriate_products = true;
    return dispatch => {
        return dispatch(editItem({
            formConfig: formConfig.parentFormData.formConfig,
            submitData
        }));
    };
};

export const bookDogToEvent = ({ formConfig, submitData }) => {
    return dispatch => {
        return dispatch(EventActions.bookDogToEvent({ formConfig, eventData: submitData }));
    };
};

export const editUpcomingClass = ({ submitData }) => {
    return dispatch => {
        return dispatch(ClassesActions.editUpcomingClass({ classData: submitData }));
    };
};

export const patchTrainingProduct = ({ submitData }) => {
    return dispatch => {
        const { eventId, productId } = submitData;
        const event_id = typeof eventId === "object" ? eventId.id : eventId
        return dispatch(EventActions.editTrainingProduct({ productId, eventId: event_id, eventData: submitData }));
    };
};

export const sendCustomersPaymentCard = ({ submitData }) => {
    return dispatch => {
        return dispatch(PaymentCardsServices.createStripeCardToken(null, submitData.toJS(), false));
    };
};

export const sendMessage = ({ formConfig, submitData }) => {
    return dispatch => {
        dispatch(MessagesActions.setMessagesSubmitting());
        return dispatch(postItem({ formConfig, submitData }));
    };
};

export const forceDeleteEmployee = ({ formConfig }) => {
    return dispatch => {
        const { url, request } = formConfig;
        return axios({
            method: 'DELETE',
            url,
            data: request
        })
            .then((response) => {
                dispatch(onModalClose(formConfig));
                response.data = {
                    full_name: formConfig.full_name
                };
                return response;
            });
    };
};
