import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import { handleCurrentDogNotesIncidentsSubmit } from '../../../../../actions/dashboard/notesIncidentsActions';
import { formatDateToSlashes } from '../../../../../helpers/date';
import NotesForm from './NotesForm';

const NotesPage = ({ notesIncidents, handleSubmit, location }) => {
    return (
        <div className='notes-incidents'>
            <div className='notes-incidents__content'>
                <div className='notes-incidents__header'>
                    Notes
                </div>
                <NotesForm onSubmit={data => handleSubmit(location.pathname.split('/')[3], data)}/>
                {notesIncidents.map((item) => {
                    const badgeClassnames = classNames({
                        'badge': true,
                        'badge_incident': item.tag === 0,
                        'badge_health': item.tag === 1,
                        'badge_behavioral': item.tag === 2,
                        'badge_employee': item.tag === 3
                    });
                    return (
                        <div className='notes-incidents__item' key={'note_' + item.id}>
                            <div className='notes-incidents__item-header'>
                                <span className={badgeClassnames}>{item.tag_detail}</span>
                                <img
                                    src={item.author.photo || 'https://mir-s3-cdn-cf.behance.net/project_modules/disp/8e427328807052.56053ef96e121.jpg'}
                                    alt={item.author.full_name || item.author.email}
                                    className='notes-incidents__item-img'/>
                                <div>
                                    <div
                                        className='notes-incidents__item-name'>{item.author.full_name || item.author.email}</div>
                                    <div className='notes-incidents__item-date'>
                                        {formatDateToSlashes(item.modified_at, true)}
                                    </div>
                                </div>
                            </div>
                            <div className='notes-incidents__item-text'>
                                {item.body}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

NotesPage.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    notesIncidents: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const mapStateToProps = state => {
    return {
        notesIncidents: state.getIn(['currentDog', 'notes_incidents', 'items']).toJS()
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleSubmit: bindActionCreators(handleCurrentDogNotesIncidentsSubmit, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotesPage));
