import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, reduxForm } from 'redux-form/immutable';
import AddNewItem from '../../../../../common/AddNewItem';
import { setAddAddressModalOpened } from '../../../../../../actions/miscActions';
import Tooltip from '../../../../../common/Tooltip/';
import { dispatchReset } from '../../../../../../actions/form/formActions';

class AddressListFrom extends PureComponent {
    componentWillUpdate(nextProps) {
        const { addresses, upcomingClassesList, classOccurrenceId, form, change } = this.props;
        if (addresses.length < nextProps.addresses.length) {
            const currentClass = upcomingClassesList.find((classItem) => {
                return classItem.class_occurrence === classOccurrenceId;
            });
            let isAvaliableZipCode = false;
            let zipCodes = [];

            if (currentClass) {
                zipCodes = currentClass.class_occurrence_detail.valid_zip_codes_array;
                isAvaliableZipCode = zipCodes.indexOf(nextProps.addresses[0].zip_code) !== -1;
            }
            if (isAvaliableZipCode) {
                change(form, 'location', nextProps.addresses[0].id.toString());
            }
        }
    }

    renderTolltip(zipCodes) {
        return (
            <Tooltip
                className='location-change__tooltip'
                content={
                    <div>
                        <div><strong>This location is not available for pickup or dropoff for this class.</strong></div>
                        Locations within the following zipcodes are available: {zipCodes.sort().join(', ')}
                    </div>
                }>
                Why can’t I use this location?
            </Tooltip>
        );
    }

    renderAddresses() {
        const { addresses, upcomingClassesList, classOccurrenceId } = this.props;

        return addresses.map(({ id, name, address2, address1, city, state, ...otherParams }, index) => {
            const currentClass = upcomingClassesList.find((classItem) => {
                return classItem.class_occurrence === classOccurrenceId;
            });
            let isAvaliableZipCode = false;
            let zipCodes = [];

            if (currentClass) {
                zipCodes = currentClass.class_occurrence_detail.valid_zip_codes_array;
                isAvaliableZipCode = zipCodes.indexOf(otherParams.zip_code) !== -1;
            }
            address1 = address1 !== null ? address1 : '';
            address2 = address2 !== null ? address2 : '';

            if (id === 0) isAvaliableZipCode = true;

            const labelClassName = classNames({
                'radio-control__controls-label location-change__radio': true,
                'location-change__radio_disabled': !isAvaliableZipCode
            });

            return (
                <label className={labelClassName} key={index}>
                    <Field
                        name='location'
                        component='input'
                        type='radio'
                        disabled={!isAvaliableZipCode}
                        value={id.toString()}
                        className='radio-control__controls-input' />
                    <div className='radio-control__controls-text location-change__radio__text'>
                        <div className='location-change__radio__content'>
                            <strong className='location-change__radio__title'> {name} </strong>
                            {!isAvaliableZipCode && this.renderTolltip(zipCodes)}
                            <span className='location-change__radio__address'>{`${address2} ${address1}, ${city}, ${state} ${otherParams.zip_code}`}</span>
                        </div>
                    </div>
                </label>
            );
        });
    }

    render() {
        const { handleToggle, submitting, isLoading, invalid, handleSubmit, addresses, setAddAddressModalOpened } = this.props; // eslint-disable-line max-len
        const submitButtonClassNames = classNames({
            'button modal-dialog__button': true,
            'modal-dialog__button_customer-order': true,
            'location-change__button': true,
            'button_disabled': (invalid || submitting || isLoading)
        });

        return (
            <form
                className='modal-dialog__form location-change'
                onSubmit={handleSubmit}>
                {!!addresses.length &&
                    <div className='location-change__locations-list'>
                        {this.renderAddresses()}
                    </div>
                }
                <AddNewItem
                    className='location-change__add-btn'
                    text='Add New Location'
                    onClickHandler={() => {
                        setAddAddressModalOpened({
                            subtitle: 'Add a new address to be used as a pick up and drop off location.',
                        });
                    }} />
                <div className='location-change__footer'>
                    <button
                        className={submitButtonClassNames}
                        type='submit'
                        disabled={submitting || isLoading}>
                        {(submitting || isLoading)
                            ? 'Submitting...'
                            : 'Save'
                        }
                    </button>
                    <span className='modal-dialog__cancel-button' onClick={handleToggle}>Cancel</span>
                </div>
            </form>
        );
    }
}

AddressListFrom.propTypes = {
    handleToggle: PropTypes.func,
    change: PropTypes.func,
    setAddAddressModalOpened: PropTypes.func,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    isLoading: PropTypes.bool,
    invalid: PropTypes.bool,
    classOccurrenceId: PropTypes.number,
    form: PropTypes.string,
    addresses: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        zip_code: PropTypes.string,
    })),
    upcomingClassesList: PropTypes.arrayOf(PropTypes.shape({})),
    initialValues: PropTypes.shape({}),
};

const mapStateToProps = (state, ownProps) => ({
    initialValues: {
        location: ownProps.activeAddress.origin.id.toString()
    },
    upcomingClassesList: state.getIn(['currentCustomer', 'upcoming_classes', 'classes']).toJS(),
});

const mapDispatchToProps = {
    setAddAddressModalOpened,
    dispatchReset,
    change
};

const form = reduxForm({
    enableReinitialize: true
});

export default connect(mapStateToProps, mapDispatchToProps)(form(AddressListFrom));
