import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import NotificationsItem from './NotificationsItem/';
import { resetActivationCode } from '../../../../../services/resetPassword';
import { setSmsVerificationModalOpened } from '../../../../../actions/miscActions';
import { ReactComponent as IconCheck } from '../../../../../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconSMS } from '../../../../../assets/icons/icon-sms.svg';
import { ReactComponent as IconMail } from '../../../../../assets/icons/icon-mail.svg';
import Icon from '../../../../common/Icon';
import { updateSubscribedToEmail } from '../../../../../actions/dashboard/employeeActions';

class Notifications extends PureComponent {
    state = {
        isEmailSent: false,
        isUpdatingSubscribedToEmail: false,
    }

    sendActivationCode = () => {
        this.props.resetActivationCode().then(() => {
            this.setState({
                isEmailSent: true
            });
        });
    }

    handleUpdateSubscribedToEmail = () => {
        const { subscribedToEmail, updateSubscribedToEmail } = this.props;

        this.setState({
            isUpdatingSubscribedToEmail: true
        });

        updateSubscribedToEmail(!subscribedToEmail)
            .then(() => {
                this.setState({
                    isUpdatingSubscribedToEmail: false
                });
            });
    }
renderSendEmailContent() {
        const { resetActiovationCode } = this.props;
        const { isEmailSent } = this.state;
        if (isEmailSent) {
            return (
                <div className='sent-block notifications__sent-block'>
                    <div className='sent-block__check'>
                        <Icon glyph={IconCheck} className='sent-block__icon-check' />
                    </div>
                    <div className='sent-block__title'>Email Notifications</div>
                    <div className='sent-block__subtitle'><b>Verification email sent.</b> Once you click on&nbsp;the link, refresh this page to&nbsp;change your notifications. Don&rsquo;t see the <nobr>e-mail?</nobr></div>
                    {/*eslint-disable-next-line*/}
                    <a className='sent-block__link' onClick={resetActiovationCode}>Click here and we’ll send you another one.</a>
                </div>
            );
        }
        return (
            <div className='sent-block notifications__sent-block'>
                <div className='sent-block__icon'>
                    <Icon glyph={IconMail} className='sent-block__icon-mail' />
                </div>
                <div className='sent-block__title'>Email Notifications</div>
                <div className='sent-block__subtitle'><b>It looks like you haven’t verified your e-mail address yet.</b> To receive e-mail notifications, verify your email address by clicking on the link in the email we’ve sent you.</div>
                {/*eslint-disable-next-line*/}
                <a className='sent-block__link' onClick={this.sendActivationCode}>Don’t see the e-mail? Click here to have a new one sent.</a>
            </div>
        );
    }
    renderContent() {
        const {
            settings,
            setSmsVerificationModalOpened,
            isPrimaryPhoneVerifed,
            subscribedToEmail,
            isEmailVerified,
        } = this.props;

        const { isUpdatingSubscribedToEmail } = this.state;

        const emailItems = [
            {
                id: 'email_appointment_moved',
                title: 'Appointment Changes:',
                subtitle: 'Receive emails any time an appointment has been changed or cancelled.',
                isActive: settings.get('email_appointment_moved'),
            },
            {
                id: 'email_report_card',
                title: 'Report Cards:',
                subtitle: 'Get your furry friend’s report card delivered directly to your inbox every time they take a class.',
                isActive: settings.get('email_report_card'),
            },
            {
                id: 'email_expiring_package',
                title: 'Expiring Package:',
                subtitle: 'Packages are a great way to save on bulk classes. Let us tell you when you are running out.',
                isActive: settings.get('email_expiring_package'),
            },
            {
                id: 'email_vaccination_reminder',
                title: 'Vaccination Reminder',
                isActive: settings.get('email_vaccination_reminder'),
            },
            {
                id: 'email_appointment_confirmation',
                title: 'Appointment Confirmation',
                isActive: settings.get('email_appointment_confirmation'),
            },
            {
                id: 'email_appointment_cancellation',
                title: 'Appointment Cancellation',
                isActive: settings.get('email_appointment_cancellation'),
            },
        ];

        const smsItems = [
            {
                id: 'sms_pick_up',
                title: 'Pick Up:',
                subtitle: 'Always know when your loved one has been picked up for class or training.',
                isActive: settings.get('sms_pick_up'),
            },
            {
                id: 'sms_drop_off',
                title: 'Report Card and Drop off:',
                subtitle: 'Always know when your loved one is on their way home.',
                isActive: settings.get('sms_drop_off'),
            },
            {
                id: 'sms_class_reminder',
                title: 'Reminders:',
                subtitle: 'Let us remind you about when you are scheduled for class the next day.',
                isActive: settings.get('sms_class_reminder'),
            },
        ];

        const emailSubButtonText = subscribedToEmail ? 'Unsubscribe' : 'Subscribe';

        return (
            <div className='notifications__content'>
                {isEmailVerified
                    ? <div className='notifications__block'>
                        <div className='notifications__title'>Email Notifications</div>
                        <ul className='notifications__list'>
                            {emailItems.map((item, index) => <NotificationsItem key={index} {...item}
                                                                                disabled={!subscribedToEmail}/>)}
                        </ul>
                        <button
                            className={classNames({
                                'notifications__button': true,
                                'notifications__button--loading': isUpdatingSubscribedToEmail
                            })}
                            disabled={isUpdatingSubscribedToEmail}
                            onClick={this.handleUpdateSubscribedToEmail}
                        >
                            {emailSubButtonText}
                        </button>
                    </div>
                    : this.renderSendEmailContent()
                }
                {isPrimaryPhoneVerifed
                    ? <div className='notifications__block'>
                        <div className='notifications__title'>SMS Notifications</div>
                        <ul className='notifications__list'>
                            {smsItems.map((item, index) => <NotificationsItem key={index} {...item} />)}
                        </ul>
                    </div>
                    : <div className='sent-block notifications__sent-block'>
                        <div className='sent-block__icon'>
                            <Icon glyph={IconSMS} className='sent-block__icon-sms' />
                        </div>
                        <div className='sent-block__title'>SMS Notifications</div>
                        <div className='sent-block__subtitle'>Verify your phone number to receive SMS notifications.</div>
                      {/*eslint-disable-next-line*/}
                        <a className='sent-block__link' onClick={setSmsVerificationModalOpened}>Click here to verify your phone.</a>
                    </div>
                }
            </div>
        );
    }

    render() {
        const { settings } = this.props;
        return (
            <div className='profile__block notifications'>
                <div className='profile__headline'>
                    <div className='profile__title'>Always know where your loved one is!</div>
                    <div className='profile__subtitle'>Make sure to turn on notifications to get real time updates.</div>
                </div>
                { settings && this.renderContent() }
            </div>
        );
    }
}

Notifications.propTypes = {
    settings: PropTypes.shape({}),
    setSmsVerificationModalOpened: PropTypes.func,
    resetActivationCode: PropTypes.func,
    user: PropTypes.shape({}),
    isPrimaryPhoneVerifed: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
};

const mapStateToProps = state => {
    const {
        subscribed_to_email: subscribedToEmail,
        primaryPhoneId,
        available_phones: availablePhones,
    } = state.getIn(['currentCustomer', 'instance']).toJS();
    const currentPhone = availablePhones && availablePhones.find((phone) => phone.id === primaryPhoneId);
    const isPrimaryPhoneVerifed = currentPhone && currentPhone.verified_at;

    return {
        settings: state.getIn(['currentCustomer', 'instance', 'settings']),
        user: state.getIn(['user']).toJS(),
        isPrimaryPhoneVerifed,
        subscribedToEmail,
        isEmailVerified: state.getIn(['currentCustomer', 'instance', 'is_email_verified']),

    };
};

const mapDispatchToProps = {
    setSmsVerificationModalOpened,
    resetActivationCode,
    updateSubscribedToEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
