import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextAccordion from '../../../../../common/TextAccordion/';
import ContentBlock from '../../../../../common/ContentBlock';
import * as OpenActions from '../../../../../../actions/modal/openActions';

const renderMedicalInfo = (medicalNotes) => {
  if (!medicalNotes) return 'None';

  return (
    <div>
      <TextAccordion maxLength={150}>{medicalNotes}</TextAccordion>
    </div>
  );
};

const DogInfo = (props) => {
  const {
    dog,
    veterinarianOptions,
    openEditMedicalInfoModalDialog,
  } = props;
  
  const dgInfo = dog.instance;
  const veterinarianId = dgInfo.veterinarian;
  const veterinarian = veterinarianOptions.find((item) => {
    return item.value === veterinarianId;
  });
  const medicalNotes = dgInfo.medical_notes;
  const allergies = dgInfo.intolerance_list.length
    ? dgInfo.intolerance_list.join(', ')
    : 'None';
  const items = [
    {
      header: 'Veterinarian',
      content: (veterinarian && veterinarian.label) || 'None',
      column: true,
    },
    {
      header: 'Allergies',
      content: allergies,
      column: true,
    },
    {
      header: 'Medical Notes',
      content: renderMedicalInfo(medicalNotes),
      column: true,
    },
  ];

  return (
    <ContentBlock
      title='Medical Information'
      editAction={() => {
        openEditMedicalInfoModalDialog(dog);
      }}
      items={items}
    />
  );
};

DogInfo.propTypes = {
  dog: PropTypes.shape({}),
  immunizationOptions: PropTypes.arrayOf(PropTypes.shape({})),
  veterinarianOptions: PropTypes.arrayOf(PropTypes.shape({})),
  openEditMedicalInfoModalDialog: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    immunizationOptions: state
      .getIn(['misc', 'options', 'immunization'])
      .toJS(),
    veterinarianOptions: state
      .getIn(['misc', 'options', 'veterinarian'])
      .toJS(),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openEditMedicalInfoModalDialog: bindActionCreators(
    OpenActions.openEditMedicalInfoModalDialog,
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(DogInfo);
