import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const renderCheckboxGroupControl = ({
    input,
    meta,
    icons,
    label,
    maxCheckedNum,
}) => {
    const { name, onChange } = input;
    const { error } = meta;
    const inputValue = input.value;
    
    const checkboxes = icons.map(({ label, type }, index) => {
        const handleChange = (event) => {
            const arr = [...inputValue];
            if (event.target.checked) {
                if (maxCheckedNum && arr.length === maxCheckedNum) {
                    return onChange(arr);
                }
                
                arr.push(type);
                return onChange(arr);
            }
            
            arr.splice(arr.indexOf(type), 1);
            return onChange(arr);
        };
        const checked = inputValue.includes(type);
        const cn = classNames({
            'dog-report-form__icon-label': true
        });
        return (
            <label key={`checkbox-${index}`} className={cn}>
                <input
                    className='dog-report-form__icon-checkbox'
                    type='checkbox'
                    name={`${name}[${index}]`}
                    value={type}
                    checked={checked}
                    onChange={handleChange}/>
                <div className='dog-report-form__icon-container'>
                    <div className={`dog-report-form__icon_${type}`}/>
                </div>
                <span className='dog-report-form__icon-text'>{label}</span>
            </label>
        );
    });
    
    return (
        <div className='dog-report-form__icons-container'>
            <span className='dog-report-form__icons-placeholder'>{label}</span>
            <div className='dog-report-form__icons-content'>{checkboxes}</div>
            {error && <p className='dog-report-form__icons-error'>{error}</p>}
        </div>
    );
};

renderCheckboxGroupControl.propTypes = {
    icons: PropTypes.arrayOf(PropTypes.shape({})),
    input: PropTypes.shape({}),
    meta: PropTypes.shape({}),
    label: PropTypes.string,
    maxCheckedNum: PropTypes.number
};

export default renderCheckboxGroupControl;
