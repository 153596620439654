import { fromJS } from 'immutable';
import { update } from '../../services/update';
import { setDogEvaluationModalClosed } from '../miscActions';
import { setCurrentDog, setErrorSubmittingDogEvaluation } from './employeeActions';
import { getUserNameSpace } from '../../helpers/userRoles';

export const updateDogSurvey = (dogId, data) => {
    const survey = data.toJS();
    const assessment = typeof survey.assessment === 'object' ? survey.assessment.value : survey.assessment;
    survey.assessment = assessment === 'Yes';

    const payload = { survey };
    return update(
        {
            url: `/v1/${getUserNameSpace('dogs')}/dogs/${dogId}/`,
            data: payload,
            actionCB: (dispatch, response, error) => {
                if (error) {
                    dispatch(setErrorSubmittingDogEvaluation(true));
                    return;
                }
                dispatch(setCurrentDog(fromJS(response.data)));
                dispatch(setDogEvaluationModalClosed());
            }
        }
    );
};
