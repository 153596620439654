import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fromJS } from 'immutable';
import moment from 'moment';
import queryString from 'query-string';
import CustomerClassesGrid from '../CustomerClassesGrid';
import DateSlider from '../DateSlider/';
import * as ClassOccurrencesService from '../../../../../services/classOccurrences';
import * as DogsService from '../../../../../services/dogs';
import FilterForm from '../../../../common/FiterForm';
import FilterTags from '../../../../common/FilterTags';
import {
    getPackagesClassOptions,
    getLocationOptions
} from '../../../../../actions/miscActions';

class ClassesPage extends React.PureComponent {
    static propTypes = {
        getClassOccurrencesList: PropTypes.func,
        getNextClassOccurrencesList: PropTypes.func,
        getDogsList: PropTypes.func,
        classOccurrencesList: PropTypes.shape({}),
        dogsList: PropTypes.shape({}),
        options: PropTypes.shape({}),
        handleLoadingClassOptions: PropTypes.func,
        handleLoadingLocationOptions: PropTypes.func,
        location: PropTypes.shape({ search: PropTypes.string }),
    }

    constructor(props) {
        super(props);

        const queryParams = queryString.parse(props.location.search);
        const classToShow = queryParams.class;
        const showAssessment = queryParams.show_assessment || false;
        console.log('queryParams:', queryParams);

        this.state = { classToShow, showAssessment };
    }

    componentDidMount() {
        const { getDogsList, handleLoadingClassOptions, handleLoadingLocationOptions } = this.props;
        const today = moment().format('YYYY-MM-DD');
        this.filterQuery = '';
        this.currentDay = today;
        this.updateClassList(); 
        getDogsList('?is_deceased=false&is_banned=false');
        handleLoadingClassOptions();
        handleLoadingLocationOptions();
    }

    componentDidUpdate(prevProps) {
        const prevOptions = prevProps.options;
        const { options } = this.props;
        const { showAssessment } = this.state;

        const classToShow = showAssessment ? 'assessment' : this.state.classToShow;

        const prevOptionsClass = prevOptions.get('class');
        const optionsClass = options.get('class');

        const receivedClassOptions = !prevOptionsClass.length && optionsClass.length;

        if (receivedClassOptions && classToShow) {
            const classesFilterData = this.getSearchFilters(optionsClass)[0];
            const classesFilterDataOptions = classesFilterData.options;
            const classToShowRegExp = new RegExp(classToShow, 'i');
            const filteredClass = classesFilterDataOptions.find(classObj => {
                return classToShowRegExp.test(classObj.value);
            });
            this.filterForm.filterOnChange(filteredClass, fromJS(classesFilterData));
        }
    }

    getOptions(optionsList) {
        return optionsList.map((option) => {
            return {
                value: option.value,
                label: option.label
            };
        });
    }

    getClassesOptions(classes) {
        const uniqueNames = new Set();
        const result = [];
        classes.forEach(cls => uniqueNames.add(cls.label));
        uniqueNames.forEach(name => result.push({ value: name, label: name }));
        return result;
    }

    getSearchFilters(classes) {
        return [{
            array: true,
            name: 'Classes',
            options: this.getClassesOptions(classes),
            query: 'name',
            type: 'select'
        },
        {
            array: true,
            name: 'Enter Zip Code',
            query: 'zip_code',
            type: 'input'
        }];
    }

    getFilterForm() {
        const { options } = this.props;
        const classes = options.get('class');
        const locations = options.get('location').toJS();

        if (!classes.length && !locations.length) {
            return <div className='customer-classes__filter-loading'>Loading filter options...</div>;
        }

        return (
            <div className='customer-classes__filter'>
                <div className='customer-classes__filter-form'>
                    <FilterForm
                        onChangeHandler={this.filterFormChangeHandler.bind(this)}
                        options={fromJS(this.getSearchFilters(classes))}
                        filterTags={this.filterTags}
                        filtersRef={element => {
                            this.filterForm = element;
                        }}
                        newVersion/>
                </div>
                <FilterTags
                    filterForm={this.filterForm}
                    ref={element => {
                        this.filterTags = element;
                    }}
                    hideIfEmpty
                    newVersion/>
            </div>
        );
    }

    filterFormChangeHandler(value) {
        const tags = value.tags.toJS();
        const params = {};
        let queryString = '';

        for (let i = 0; i < tags.length; i += 1) {
            const tag = tags[i];
            if (!(tag.query in params)) {
                params[tag.query] = [];
            }
            params[tag.query].push(tag.value);
        }
        Object.keys(params).forEach((key, index) => {
            const value = params[key].join(',');
            queryString += `&${key}=${value}`;
        });

        this.filterQuery = queryString;

        this.updateClassList();
    }

    updateClassList = (date = this.currentDay, query = '') => {
        const { getClassOccurrencesList } = this.props;
        this.currentDay = date;
        getClassOccurrencesList({
            startDate: date,
            endDate: date,
            queryString: 'o=pickup_start_time&' + query + this.filterQuery,
            pageSize: 20
        });
    }

    render() {
        const { classOccurrencesList, getNextClassOccurrencesList, dogsList } = this.props;
        const isNextClassesLoading = classOccurrencesList.get('isClassOccurrencesNextLoading');
        const isClassLoading = classOccurrencesList.get('isClassOccurrencesLoading');
        const isDogsLoading = dogsList.get('isLoading');

        return (
            <div className='inner-page customer-classes'>
                <div className='inner-page__container'>
                    <div className='inner-page__title'>Classes</div>
                    {this.getFilterForm()}
                    <DateSlider
                        daysBefore={10}
                        daysAfter={14}
                        onClickHandler={this.updateClassList}/>
                    <CustomerClassesGrid
                        data={classOccurrencesList}
                        isLoading={isClassLoading || isDogsLoading}
                        isNextLoading={isNextClassesLoading}
                        dogs={dogsList.get('dogs')}
                        getNext={getNextClassOccurrencesList}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        classOccurrencesList: state.getIn(['classOccurrencesList']),
        dogsList: state.getIn(['dogsList']),
        options: state.getIn(['misc', 'options']),
    };
};

const mapDispatchToProps = dispatch => ({
    getClassOccurrencesList: bindActionCreators(ClassOccurrencesService.getClassOccurrencesList, dispatch),
    getNextClassOccurrencesList: bindActionCreators(ClassOccurrencesService.getNextClassOccurrencesList, dispatch),
    getDogsList: bindActionCreators(DogsService.getDogs, dispatch),
    handleLoadingClassOptions: bindActionCreators(getPackagesClassOptions, dispatch),
    handleLoadingLocationOptions: bindActionCreators(getLocationOptions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassesPage);
