import * as axios from 'axios';
import { push } from 'react-router-redux';
import { SubmissionError } from 'redux-form/immutable';
import { setUserForgottenInfo } from '../actions/onboarding/userActions';
import { OAUTH2_FITDOG_CLIENT_ID, OAUTH2_FITDOG_CLIENT_SECRET } from '../config';
import { isCustomer } from '../helpers/userRoles';
import { setAccessToken, setRefreshToken, setScope } from './oauth';
import { generateAlertMeta } from '../components/Alert/actions';

export const sendForgottenPassword = (values) => {
    return (dispatch) => {
        const request = new FormData();
        request.append('email', values.get('email'));

        return axios.post('/v1/users/send-reset-password-code/', request)
                    .then(() => {
                        dispatch(setUserForgottenInfo(values.get('email')));
                        dispatch(push('/forgot-password-sent'));
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                                throw new SubmissionError({
                                    email: err.response.data[0]
                                });
                            }
                        }
                    });
    };
};

export const baseResetPassword = (request) => {
    return (dispatch, getState) => {
        return axios.post('/v1/users/send-reset-password-code/', request)
                    .then(() => {
                        dispatch(generateAlertMeta({
                            event: 'USER_PASSWORD_RESET',
                            alertData: { name: getState().getIn([
                                'misc', 'modals', 'resetPasswordData', 'fullName']) }
                        }));
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                                throw new SubmissionError({
                                    email: err.response.data[0]
                                });
                            }
                        }
                    });
    };
};

export const resendForgottenPassword = () => {
    return (dispatch, getState) => {
        const request = new FormData();
        request.append('email', getState().getIn(['user', 'email']));
        dispatch(baseResetPassword(request));
    };
};

export const resetPassword = (values) => {
    return (dispatch) => {
        const requestData = {
            password: values.get('password'),
            code: values.get('code'),
            grant_type: 'password',
            client_id: OAUTH2_FITDOG_CLIENT_ID,
            client_secret: OAUTH2_FITDOG_CLIENT_SECRET,
        };

        return axios.post('/v1/users/reset-password/', requestData, {
            headers: {
                'content-type': 'application/json'
            }
        })
                    .then(response => {
                        setAccessToken(response.data);
                        setRefreshToken(response.data);
                        setScope(response.data);
                        if (isCustomer()) {
                            return dispatch(push('/home'));
                        }
                        return dispatch(push('/dashboard'));
                    })
                    .catch(err => {
                        console.log(err);
                        console.log(err.response);
                        if (err.response) {
                            if (err.response.status !== 200) {
                                console.log('Unexpected error code from the API server: ', err.response.status);
                                throw new SubmissionError({
                                    password: err.response.data[0]
                                });
                            }
                        }
                    });
    };
};

export const resetActivationCode = () => {
    return (dispatch, getState) => {
        const state = getState();
        const email = state.getIn(['user', 'email']) || state.getIn(['currentCustomer', 'instance', 'email']);
        return axios.post('/v1/users/resend-activation-code/', { email });
    };
};

export const activateEmail = (code) => {
    return () => {
        return axios.get('/v1/users/activate/?code=' + encodeURIComponent(code));
    };
};
