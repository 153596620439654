import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import DropZone from 'react-dropzone';
import File from './FileControlFile';
import Preview from './FileControlPreview';
import SendDocs from './FileControlSendDocs';

export default class renderFileControl extends React.PureComponent {
    static propTypes = {
        name: PropTypes.string,
        input: PropTypes.shape({}),
        label: PropTypes.string,
        type: PropTypes.string,
        meta: PropTypes.shape({}),
        descriptionTop: PropTypes.string.isRequired,
        descriptionBottom: PropTypes.string,
        disclaimer: PropTypes.string,
        warning: PropTypes.string,
        handleVetInfoFiles: PropTypes.func
    };

    constructor(props) {
        super(props);
        const { value } = props.input;
        this.state = {
            files: value || [],
            isFaxChecked: !(value && value.length),
            isUploadable: true
        };
    }

    componentDidMount() {
        this.onCheckBoxClick();
    }
    componentDidUpdate(prevState) {
        const { files } = this.state;
        if (!files.length) {
            this.setState({
                isUploadable: true
            });
        }
        console.log(this.state.isUploadable);
    }

    onDrop = (acceptedFiles) => {
        const { files } = this.state;
        const { input: { onChange } } = this.props;

        this.setState({
            files: this.state.files.concat(acceptedFiles),
            isUploadable: false
        }, () => {
            return onChange(files.concat(acceptedFiles));
        });
    };

    onChange = async (changingFile) => {
        await this.onDelete(changingFile);
        this.onUploadClick();
    };

    onDelete = () => {
        const { input: { onChange } } = this.props;
        this.setState({
            files: this.state.files.filter(file => file.name !== this.file.innerText),
            isUploadable: false
        }, () => {
            return onChange(this.state.files);
        });
    };

    onDnDClick = (e) => {
        const { isUploadable } = this.state;
        if (!isUploadable) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.setState({
            isUploadable: false
        });
    };

    onUploadClick = () => {
        this.setState({
            isUploadable: true
        }, () => {
            this.dropzone.open();
        });
    };

    onCheckBoxClick = () => {
        const { isFaxChecked, files } = this.state;
        const { input: { onChange } } = this.props;
        if (!isFaxChecked) {
            onChange(null);
        } else {
            onChange(files);
        }
        this.setState({
            isFaxChecked: !isFaxChecked
        });
    };

    render() {
        // eslint-disable-next-line max-len
        const { name,  meta: { touched, error }, descriptionTop, descriptionBottom, disclaimer } = this.props;
        const { files, isFaxChecked } = this.state;
        const DnDZoneClassNames = classNames({
            'dnd-control__zone': true,
            'dnd-control__zone_empty': !files.length,
            'dnd-control__zone_disabled': isFaxChecked,
            'dnd-control__zone_failed': (touched && error)
        });
        const UploadClassNames = classNames({
            'dnd-control__controls-upload': true,
            'dnd-control__controls-upload_disabled': isFaxChecked
        });
        return (
            <div className='dnd-control'>
                <DropZone
                    name={name}
                    className={DnDZoneClassNames}
                    maxSize={25 * 1024 * 1024}
                    accept='image/jpeg, image/png, image/jpeg, image/tiff, application/pdf'
                    disablePreview
                    disableClick={isFaxChecked}
                    ref={(node) => { this.dropzone = node; }}
                    onClick={this.onDnDClick}
                    onDrop={this.onDrop}>
                    {({ isDragActive, isDragReject }) => {
                        if (isFaxChecked) {
                            return (
                                <SendDocs
                                    emailText='Email your dog’s vaccination records to'
                                    email='contact@fitdog.com'
                                    faxText='or have your veterinarian fax them directly to'
                                    fax='(310) 828-3646'/>
                            );
                        }
                        if (files.length > 0) {
                            return (
                                <div className='zone-files'>
                                    {this.state.files.map((file) => (
                                        <File
                                            key={file.name}
                                            file={file}
                                            refLink={(node) => { this.file = node; }}
                                            onDeleteClick={this.onDelete}
                                            onChangeClick={this.onChange}/>
                                    ))}
                                </div>
                            );
                        }
                        if (isDragActive) {
                            return (
                                <Preview
                                    descriptionTop={'Drop it like it\'s hot!'}
                                    disclaimer={disclaimer}/>
                            );
                        }
                        if (isDragReject) {
                            return (
                                <Preview
                                    descriptionTop='Acceptable file formats: jpeg, png, jpg, tiff, pdf'
                                    disclaimer={disclaimer}/>
                            );
                        }
                        return (
                            <Preview
                                descriptionTop={descriptionTop}
                                descriptionBottom={descriptionBottom}
                                disclaimer={disclaimer}/>
                        );
                    }}
                </DropZone>
                {touched && error && <span className='dnd-control__error'>{error}</span>}
                <div className='dnd-control__controls'>
                    <label className='checkbox-control__label dnd-control__controls-checkbox'>
                        <input
                            type='checkbox'
                            onClick={this.onCheckBoxClick}
                            checked={isFaxChecked}
                            className='checkbox-control__input'/>
                        <span className='checkbox-control__text'>I will do this later.</span>
                    </label>
                    <button
                        className={UploadClassNames}
                        type='button'
                        disabled={files[0] === 'will fax or e-mail my dogs records' || isFaxChecked}
                        onClick={this.onUploadClick}>
                        Upload files
                    </button>
                </div>
            </div>
        );
    }
}
