import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import { phoneNumberNormalizer } from '../helpers/normalize';
import * as AvailableAddressesTypes from '../types/availableAddressesTypes';
import * as ClassesTypes from '../types/dashboard/classesTypes';
import * as TrainingsTypes from '../types/dashboard/trainingsTypes';
import * as EmployeeTypes from '../types/dashboard/employeeTypes';
import * as FormsTypes from '../types/dashboard/formsTypes';
import * as NotesIncidentsTypes from '../types/dashboard/notesIncidentsTypes';
import * as PackagesTrainingsProductsTypes from '../types/dashboard/packagesTrainingsProductsTypes';
import * as PaymentCardsTypes from '../types/dashboard/paymentCardsTypes';
import * as PurchaseHistoryTypes from '../types/dashboard/purchaseHistoryTypes';
import * as VaccinationRecordsTypes from '../types/dashboard/vaccinationRecordsTypes';
import * as CustomerTypes from '../types/customer/customerTypes';

const creditCardType = require('credit-card-type');

const INITIAL_STATE = fromJS({
    isLoading: true,
    initialInstance: {
        address: 0,
        available_addresses: [
            {
                id: 0,
                name: 'none',
                address1: 'none',
                address2: 'none',
                city: 'none',
                state: 'NA',
                zip_code: 'none',
                location_notes: 'none'
            }
        ],
        additional_owners: [],
        emergency_contacts: []
    },
    instance: {},
    alert: {},
    payment_cards: {
        cards: [],
        areLoaded: false
    },
    previous_classes: {
        classes: [],
        nextUrl: null,
        previousUrl: null,
        areLoaded: false,
        count: 0,
        pageSize: 0,
        pages: 0,
        isLoading: false
    },
    upcoming_classes: {
        classes: [],
        nextUrl: null,
        previousUrl: null,
        areLoaded: false,
        count: 0,
        pageSize: 0,
        pages: 0,
        isLoading: false
    },
    previous_trainings: {
        trainings: [],
        areLoaded: false,
        count: 0
    },
    upcoming_trainings: {
        trainings: [],
        areLoaded: false
    },
    notes_incidents: {
        items: [],
        areLoaded: false
    },
    forms: {
        items: [],
        areLoaded: false
    },
    vaccinations: {
        items: [],
        areLoaded: false
    },
    packagesTrainingProducts: {
        items: [],
        areLoaded: false
    },
    purchaseHistory: {
        items: [],
        count: 0,
        pageSize: 0,
        pages: 0,
        nextUrl: null,
        previousUrl: null,
        areLoaded: false,
        isLoading: false
    },
    subscribed_to_email: null,
});

export const CustomerDataNormalizer = (formData) => {
    formData = formData.toJS();
    const newInstance = {};

    if ('email' in formData && 'full_name' in formData && 'phone' in formData) {
        newInstance.password = 'qwerty123456';
    }

    Object.keys(formData).forEach(key => {
        if (key === 'phone_number' || key === 'phone') {
            newInstance[key] = phoneNumberNormalizer(formData[key]);
            return;
        }

        newInstance[key] = formData[key];
    });

    return newInstance;
};

export const purchasedEventDataNormalizer = (eventData) => {
    const eventDataJS = eventData.toJS();
    if (eventDataJS.name === undefined) {
        eventDataJS.name = eventDataJS.package_detail.name;
    }

    if (eventDataJS.package_detail !== undefined) {
        eventDataJS.model_type = 'purchasedpackage';
    } else {
        eventDataJS.model_type = 'purchasedtrainingprogram';
    }

    if (eventDataJS.dog === undefined) {
        eventDataJS.dog = null;
        eventDataJS.dog_detail = { name: null };
    }

    if (eventDataJS.trainer === undefined) {
        eventDataJS.trainer = null;
        eventDataJS.trainer_detail = { full_name: null };
    }

    return fromJS(eventDataJS);
};

const currentCustomerReducer = handleActions({
    [EmployeeTypes.START_CURRENT_CUSTOMER_LOADING]: (state) => {
        return state.setIn(['isLoading'], true);
    },
    [EmployeeTypes.FINISH_CURRENT_CUSTOMER_LOADING]: (state) => {
        return state.setIn(['isLoading'], false);
    },
    [EmployeeTypes.SET_CURRENT_CUSTOMER_DATA]: (state, action) => {
        const primaryPhoneId = action.payload.get('primary_phone');
        const secondaryPhoneId = action.payload.get('secondary_phone');
        const availablePhones = action.payload.get('available_phones');

        const addressId = action.payload.get('address');
        const availableAddresses = action.payload.get('available_addresses');
        const availableAddressesFirst = action.payload.getIn(['available_addresses', '0']);
        const availableAddressesFirstStreet = action.payload.getIn(['available_addresses', '0', 'address1']);
        const availableAddressesFirstBuilding =
            action.payload.getIn(['available_addresses', '0', 'address2']) === null
                ? ''
                : ` ${action.payload.getIn(['available_addresses', '0', 'address2'])}`;
        const availableAddressesFirstCity = action.payload.getIn(['available_addresses', '0', 'city']);
        const availableAddressesFirstState = action.payload.getIn(['available_addresses', '0', 'state']);
        const availableAddressesFirstZipCode = action.payload.getIn(['available_addresses', '0', 'zip_code']);

        const hasAdditionalOwners = action.payload.get('additional_owners').size;
        const hasEmergencyContacts = action.payload.get('emergency_contacts').size;
        const hasDogs = action.payload.get('dogs') && action.payload.get('dogs').size;
        const veterinarian = action.payload.get('veterinarian');
        const signupDate = action.payload.get('signup_date');

        const photo = action.payload.get('photo') ? action.payload.get('photo_processed') : action.payload.get('photo');

        const subscribed_to_email = action.payload.get('subscribed_to_email')

        const customersPrimaryPhone = primaryPhoneId !== null
            ? (availablePhones.find((phone) => {
                return phone.get('id') === primaryPhoneId;
            }).get('phone_number'))
            : 'None';
        let customersSmsNotifications = 'No';
        availablePhones.forEach((phone) => {
            if (phone.get('verified_at')) {
                customersSmsNotifications = 'Yes';
            }
        });

        const customersSecondaryPhone = action.payload.get('secondary_phone') !== null
            ? (availablePhones.find((phone) => {
                return phone.get('id') === secondaryPhoneId;
            }).get('phone_number'))
            : 'None';

        const customersAddress = addressId !== null
            ? (() => {
                const matchedAddress = availableAddresses.find((phone) => {
                    return phone.get('id') === addressId;
                });
                const matchedAddressAddress2 = matchedAddress.get('address2') === null
                    ? ''
                    : ` ${matchedAddress.get('address2')}`;
                return `${matchedAddress.get('address1')}${matchedAddressAddress2}, ${matchedAddress.get(
                    'city')} ${matchedAddress.get('state')} ${matchedAddress.get('zip_code')}${matchedAddress.get('location_notes') ? `, ${matchedAddress.get('location_notes')}` : ''}`;
            })()
            : availableAddressesFirst !== undefined
                                     // eslint-disable-next-line max-len
                                     ? `${availableAddressesFirstStreet} ${availableAddressesFirstBuilding} ${availableAddressesFirstCity}, ${availableAddressesFirstState} ${availableAddressesFirstZipCode}`
                                     : 'None';

        const customersAdditionalOwners = hasAdditionalOwners
            ? action.payload.get('additional_owners')
            : fromJS([{ relationship: 'None' }]);

        const customersEmergencyContacts = hasEmergencyContacts
            ? action.payload.get('emergency_contacts')
            : fromJS([{ relationship: 'None' }]);

        const customersEmailNotifications = action.payload.get('is_banned') ? 'No' : 'Yes';

        const customersDogs = hasDogs
            ? action.payload.get('dogs')
            : fromJS([{ name: 'None' }]);

        const isCustomerVip = action.payload.get('is_vip') ? 'Yes' : 'No';
        const isCustomerBanned = action.payload.get('is_banned') ? 'Yes' : 'No';

        const normalizedAvailableAddresses = fromJS(
            action.payload.get('available_addresses').reduce((result, address) => {
                if (address.get('id') === addressId) {
                    address.set('name', 'Home');
                    result.unshift(address.toJS());
                } else {
                    result.push(address.toJS());
                }
                return result;
            }, []));

        if (addressId) {
            const homeAddress = availableAddresses.toJS().find((availableAddress) => {
                return availableAddress.id === addressId;
            });
            if (homeAddress) {
                window._dcq.push(['identify', {
                    zip_code: homeAddress.zip_code,
                }]);
            }
        }

        return state
        .set('initialInstance', action.payload)
        .setIn(['instance', 'subscribed_to_email'], subscribed_to_email)
        .setIn(['instance', 'photo'], photo)
        .setIn(['instance', 'id'], action.payload.get('id'))
        .setIn(['instance', 'full_name'], action.payload.get('full_name'))
        .setIn(['instance', 'primaryPhoneId'], primaryPhoneId)
        .setIn(['instance', 'primary_phone'], customersPrimaryPhone)
        .setIn(['instance', 'sms_notifications'], customersSmsNotifications)
        .setIn(['instance', 'secondaryPhoneId'], secondaryPhoneId)
        .setIn(['instance', 'secondary_phone'], customersSecondaryPhone)
        .setIn(['instance', 'email'], action.payload.get('email'))
        .setIn(['instance', 'addressId'], addressId)
        .setIn(['instance', 'address'], customersAddress)
        .setIn(['instance', 'available_phones'], availablePhones)
        .setIn(['instance', 'available_addresses'], availableAddresses)
        .setIn(['instance', 'normalized_available_addresses'], normalizedAvailableAddresses)
        .setIn(['instance', 'additional_owners'], customersAdditionalOwners)
        .setIn(['instance', 'emergency_contacts'], customersEmergencyContacts)
        .setIn(['instance', 'email_notifications'], customersEmailNotifications)
        .setIn(['instance', 'dogs'], customersDogs)
        .setIn(['instance', 'is_banned'], isCustomerBanned)
        .setIn(['instance', 'is_vip'], isCustomerVip)
        .setIn(['instance', 'veterinarian'], veterinarian)
        .setIn(['instance', 'signup_date'], signupDate)
        .setIn(['instance', 'alert'], action.payload.get('alert'))
        .setIn(['instance', 'drop_off_location'], action.payload.get('drop_off_location'))
        .setIn(['instance', 'pickup_location'], action.payload.get('pickup_location'))
        .setIn(['instance', 'has_usable_password'], action.payload.get('has_usable_password'))
        .setIn(['instance', 'settings'], action.payload.get('settings'))
        .setIn(['instance', 'is_email_verified'], action.payload.get('is_email_verified'));
    },
    [EmployeeTypes.SET_CUSTOMER_NOTIFICATION]: (state, action) => {
        const { id, isActive } = action.payload;
        return state.setIn(['instance', 'settings', id], isActive);
    },
    [EmployeeTypes.SET_SUBSCRIBED_TO_EMAIL]: (state, action) => {
        const { subscribedToEmail } = action.payload;
        return state.setIn(['instance', 'subscribed_to_email'], subscribedToEmail);
    },
    [EmployeeTypes.CLEAR_CURRENT_CUSTOMER_DATA]: (state) => {
        return state
            .set('instance', fromJS({}))
            .set('initialInstance', fromJS({
                address: 0,
                available_addresses: [
                    {
                        id: 0,
                        name: 'none',
                        address1: 'none',
                        address2: 'none',
                        city: 'none',
                        state: 'NA',
                        zip_code: 'none',
                        location_notes: 'none'
                    }
                ],
                additional_owners: [],
                emergency_contacts: []
            }))
            .setIn(['payment_cards', 'cards'], fromJS([]))
            .setIn(['previous_trainings', 'trainings'], fromJS([]))
            .setIn(['previous_trainings', 'trainings'], fromJS([]))
            .setIn(['previous_classes', 'classes'], fromJS([]))
            .setIn(['upcoming_classes', 'classes'], fromJS([]))
            .setIn(['notes_incidents', 'items'], fromJS([]))
            .setIn(['vaccinations', 'items'], fromJS([]))
            .setIn(['forms', 'items'], fromJS([]))
            .setIn(['packagesTrainingProducts', 'items'], fromJS([]))
            .setIn(['purchaseHistory', 'items'], fromJS([]));
    },
    [PaymentCardsTypes.MARK_PAYMENT_CARDS_LOADED]: (state) => {
        return state.setIn(['payment_cards', 'areLoaded'], true);
    },
    [PaymentCardsTypes.UNMARK_PAYMENT_CARDS_LOADED]: (state) => {
        return state.setIn(['payment_cards', 'areLoaded'], false);
    },
    [PaymentCardsTypes.SET_CURRENT_CUSTOMER_PAYMENT_CARDS]: (state, action) => {
        const paymentCardsWithTypes = action.payload.map((card) => {
            card.type = creditCardType(card.first6)[0].niceType;
            return card;
        });

        return state
            .setIn(['payment_cards', 'cards'],
                state.getIn(['payment_cards', 'cards']).merge(paymentCardsWithTypes));
    },
    [ClassesTypes.MARK_CUSTOMER_PREVIOUS_CLASSES_LOADED]: (state) => {
        return state.setIn(['previous_classes', 'areLoaded'], true);
    },
    [ClassesTypes.UNMARK_CUSTOMER_PREVIOUS_CLASSES_LOADED]: (state) => {
        return state.setIn(['previous_classes', 'areLoaded'], false);
    },
    [ClassesTypes.START_ISLOADING_CUSTOMER_PREVIOUS_CLASSES]: (state) => {
        return state.setIn(['previous_classes', 'isLoading'], true);
    },
    [ClassesTypes.FINISH_ISLOADING_CUSTOMER_PREVIOUS_CLASSES]: (state) => {
        return state.setIn(['previous_classes', 'isLoading'], false);
    },
    [ClassesTypes.SET_CUSTOMER_PREVIOUS_CLASSES]: (state, action) => {
        const nextUrl = action.payload.next === null ? action.payload.next : action.payload.next.slice(4);
        const previousUrl = action.payload.previous === null ? action.payload.previous : action.payload.previous.slice(4);
        const pagesCount = Math.ceil(action.payload.count / action.payload.results.length);
        return state
            .setIn(['previous_classes', 'count'], action.payload.count)
            .setIn(['previous_classes', 'pageSize'], action.payload.results.length)
            .setIn(['previous_classes', 'pages'], pagesCount)
            .setIn(['previous_classes', 'previousUrl'], previousUrl)
            .setIn(['previous_classes', 'nextUrl'], nextUrl)
            .setIn(['previous_classes', 'classes'],
                state.getIn(['previous_classes', 'classes']).concat(fromJS(action.payload.results)))
            .setIn(['previous_classes', 'isLoading'], false);
    },
    [ClassesTypes.SET_CUSTOMER_PREVIOUS_CLASS_DETAILS]: (state, action) => {
        const idOfClassToUpdate = state.getIn(['previous_classes', 'classes'])
                                       .findIndex((previousClass) => {
                                           return previousClass.get('id') === action.payload.id;
                                       });

        return state.setIn(['previous_classes', 'classes', idOfClassToUpdate, 'details'], fromJS(action.payload));
    },
    [ClassesTypes.UPDATE_CUSTOMER_PREVIOUS_CLASS]: (state, action) => {
        const currentKey = state.getIn(['previous_classes', 'classes'])
            .findKey(product => product.get('id') === action.payload.id);
        return state.setIn(['previous_classes', 'classes', currentKey], fromJS(action.payload));
    },
    [ClassesTypes.MARK_CUSTOMER_UPCOMING_CLASSES_LOADED]: (state) => {
        return state.setIn(['upcoming_classes', 'areLoaded'], true);
    },
    [ClassesTypes.UNMARK_CUSTOMER_UPCOMING_CLASSES_LOADED]: (state) => {
        return state.setIn(['upcoming_classes', 'areLoaded'], false);
    }, 
    [ClassesTypes.START_ISLOADING_CUSTOMER_UPCOMING_CLASSES]: (state) => {
        return state.setIn(['upcoming_classes', 'isLoading'], true);
    },
    [ClassesTypes.FINISH_ISLOADING_CUSTOMER_UPCOMING_CLASSES]: (state) => {
        return state.setIn(['upcoming_classes', 'isLoading'], false);
    },
    [ClassesTypes.SET_CUSTOMER_UPCOMING_CLASSES]: (state, action) => {
        const nextUrl = action.payload.next === null ? action.payload.next : action.payload.next.slice(4);
        const previousUrl = action.payload.previous === null ? action.payload.previous : action.payload.previous.slice(4);
        const pagesCount = Math.ceil(action.payload.count / action.payload.results.length);
        return state
            .setIn(['upcoming_classes', 'count'], action.payload.count)
            .setIn(['upcoming_classes', 'pageSize'], action.payload.results.length)
            .setIn(['upcoming_classes', 'pages'], pagesCount)
            .setIn(['upcoming_classes', 'previousUrl'], previousUrl)
            .setIn(['upcoming_classes', 'nextUrl'], nextUrl)
            .setIn(['upcoming_classes', 'classes'],
                state.getIn(['upcoming_classes', 'classes']).concat(fromJS(action.payload.results)))
            .setIn(['upcoming_classes', 'isLoading'], false);
    },
    [ClassesTypes.SET_CUSTOMER_UPCOMING_CLASS_DETAILS]: (state, action) => {
        const idOfClassToUpdate = state.getIn(['upcoming_classes', 'classes'])
                                       .findIndex((upcomingClass) => {
                                           return upcomingClass.get('id') === action.payload.id;
                                       });
        return state.setIn(['upcoming_classes', 'classes', idOfClassToUpdate], fromJS(action.payload));
    },
    [ClassesTypes.REMOVE_CUSTOMERS_UPCOMING_CLASS]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['upcoming_classes', 'classes'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
    [NotesIncidentsTypes.MARK_CUSTOMER_NOTES_INCIDENTS_LOADED]: (state, action) => {
        return state.setIn(['notes_incidents', 'areLoaded'], true);
    },
    [NotesIncidentsTypes.UNMARK_CUSTOMER_NOTES_INCIDENTS_LOADED]: (state) => {
        return state.setIn(['notes_incidents', 'areLoaded'], false);
    },
    [NotesIncidentsTypes.SET_CURRENT_CUSTOMER_NOTES_INCIDENTS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['notes_incidents', 'items'], fromJS(action.payload));
        });
    },
    [NotesIncidentsTypes.ADD_CURRENT_CUSTOMER_NOTES_INCIDENTS_ITEM]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['notes_incidents', 'items'],
                state.getIn(['notes_incidents', 'items']).insert(0, action.payload));
        });
    },
    [VaccinationRecordsTypes.MARK_CUSTOMER_VACCINATION_RECORDS_LOADED]: (state) => {
        return state.setIn(['vaccinations', 'areLoaded'], true);
    },
    [VaccinationRecordsTypes.UNMARK_CUSTOMER_VACCINATION_RECORDS_LOADED]: (state) => {
        return state.setIn(['vaccinations', 'areLoaded'], false);
    },
    [FormsTypes.MARK_FORMS_LOADED]: (state) => {
        return state.setIn(['forms', 'areLoaded'], true);
    },
    [FormsTypes.UNMARK_FORMS_LOADED]: (state) => {
        return state.setIn(['forms', 'areLoaded'], false);
    },
    [FormsTypes.SET_CURRENT_CUSTOMER_FORMS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['forms', 'items'], fromJS(action.payload));
        });
    },
    [FormsTypes.REMOVE_CURRENT_CUSTOMER_FORM]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['forms', 'items'],
                items => {
                    return fromJS(items.filter(item => item.get('id') !== action.payload));
                });
        });
    },
    [VaccinationRecordsTypes.SET_CURRENT_CUSTOMER_VACCINATION_RECORDS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['vaccinations', 'items'], fromJS(action.payload));
        });
    },
    [AvailableAddressesTypes.ADD_CUSTOMERS_AVAILABALE_ADDRESS]: (state, action) => {
        return state.update((state) => {
            state.setIn(['instance', 'normalized_available_addresses'],
                state.getIn(['instance', 'normalized_available_addresses']).insert(0, fromJS(action.payload)));
            return state.setIn(['instance', 'available_addresses'],
                state.getIn(['instance', 'available_addresses']).insert(0, fromJS(action.payload)));
        });
    },
    [AvailableAddressesTypes.REMOVE_AVAILABALE_ADDRESS]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['instance', 'available_addresses'], items => {
                    return items.filter(item => item.get('id') !== action.payload);
                }).setIn(['instance', 'addressId'], null);
        });
    },
    [AvailableAddressesTypes.UPDATE_AVAILABALE_ADDRESS]: (state, action) => {
        const currentKey = state.getIn(['instance', 'available_addresses'])
            .findKey(item => item.get('id') === action.payload.id);
        return state.update((state) => {
            return state.setIn(['instance', 'available_addresses', currentKey], fromJS(action.payload));
        });
    },
    [PaymentCardsTypes.ADD_CUSTOMERS_PAYMENT_CARD]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['payment_cards', 'cards'],
                state.getIn(['payment_cards', 'cards']).insert(0, fromJS(action.payload)));
        });
    },
    [PaymentCardsTypes.REMOVE_CURRENT_CUSTOMER_PAYMENT_CARD]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['payment_cards', 'cards'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
    [PaymentCardsTypes.SET_DEFAULT_CUSTOMER_PAYMENT_CARD]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['payment_cards', 'cards'],
                items => items.map(item => {
                    const isDefault = item.get('id') === action.payload;
                    return item.setIn(['is_default'], isDefault);
                }));
        });
    },
    [VaccinationRecordsTypes.REMOVE_CURRENT_CUSTOMER_VACCINATION]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['vaccinations', 'items'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
    [VaccinationRecordsTypes.PREPEND_CURRENT_CUSTOMER_VACCINATION]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['vaccinations', 'items'], state.getIn(['vaccinations', 'items'])
                                                               .insert(0, fromJS(action.payload.data)));
        });
    },
    [PackagesTrainingsProductsTypes.MARK_CUSTOMER_PACKAGES_TRAINING_PRODUCTS_LOADED]: (state) => {
        return state.setIn(['packagesTrainingProducts', 'areLoaded'], true);
    },
    [PackagesTrainingsProductsTypes.UNMARK_CUSTOMER_PACKAGES_TRAINING_PRODUCTS_LOADED]: (state) => {
        return state.setIn(['packagesTrainingProducts', 'areLoaded'], false);
    },
    [PackagesTrainingsProductsTypes.SET_CUSTOMER_PACKAGES_TRAINING_PRODUCTS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['packagesTrainingProducts', 'items'], fromJS(action.payload.data));
        });
    },
    [PackagesTrainingsProductsTypes.UPDATE_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT_EXPIRES_AT]: (state, action) => {
        const { payload } = action;
        const currentKey = state.getIn(['packagesTrainingProducts', 'items'])
                                .findKey(item => item.get('id') === payload.get('id'));
        return state.setIn(['packagesTrainingProducts', 'items', currentKey, 'expires_at'], payload.get('expires_at'));
    },
    [PackagesTrainingsProductsTypes.UPDATE_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT_QUANTITY_REMAINING]: (state,
                                                                                                      action) => {
        const { payload } = action;
        const currentKey = state.getIn(['packagesTrainingProducts', 'items'])
                                .findKey(item => item.get('id') === payload.get('id'));
        return state.setIn(['packagesTrainingProducts', 'items', currentKey, 'available_quantity'], payload.get('available_quantity'))
                    .setIn(['packagesTrainingProducts', 'items', currentKey, 'quantity_remaining'], payload.get('quantity_remaining'));
    },
    [PackagesTrainingsProductsTypes.UPDATE_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT_TRAINER]: (state, action) => {
        const { payload } = action;
        if (payload.get('current_purchased_training_pk') !== undefined){
            const currentId = parseInt(payload.get('current_purchased_training_pk'));
            const purchased_training = payload.get('purchased_training');
            const productKey = state.getIn(['packagesTrainingProducts', 'items'])
                                  .findKey(item => item.get('id') === purchased_training.get('id'));

            return state.update((state) => {
                return state.setIn(['packagesTrainingProducts', 'items', productKey, 'available_quantity'], purchased_training.get('quantity_remaining'))
                            .setIn(['packagesTrainingProducts', 'items', productKey, 'quantity_remaining'], purchased_training.get('quantity_remaining'))
                            .setIn(['packagesTrainingProducts', 'items', productKey, 'quantity_purchased'], purchased_training.get('quantity_purchased'))
                            .setIn(['packagesTrainingProducts', 'items', productKey, 'expires_at'], purchased_training.get('expires_at'))
                            .updateIn(['packagesTrainingProducts', 'items'],
                                        items => items.filter(item => item.get('id') !== currentId));
            });
        } else if (payload.get('current_purchased_training') !== undefined) {
            const current_purchased_training = payload.get('current_purchased_training');
            const purchased_training = payload.get('purchased_training');
            const productKey = state.getIn(['packagesTrainingProducts', 'items'])
                                  .findKey(item => item.get('id') === purchased_training.get('id'));
            const currentKey = state.getIn(['packagesTrainingProducts', 'items'])
                                  .findKey(item => item.get('id') === current_purchased_training.get('id'));
                if (productKey === undefined) {
                    return state.update((state) => {
                        return state.setIn(['packagesTrainingProducts', 'items'], state.getIn(['packagesTrainingProducts', 'items']).push(purchased_training))
                                    .setIn(['packagesTrainingProducts', 'items', currentKey, 'available_quantity'], current_purchased_training.get('quantity_remaining'))
                                    .setIn(['packagesTrainingProducts', 'items', currentKey, 'quantity_remaining'], current_purchased_training.get('quantity_remaining'))
                                    .setIn(['packagesTrainingProducts', 'items', currentKey, 'quantity_purchased'], current_purchased_training.get('quantity_purchased'))
                                    .setIn(['packagesTrainingProducts', 'items', currentKey, 'expires_at'], current_purchased_training.get('expires_at'));
                    });
                } else {
                    return state.update((state) => {
                        console.log(state);
                        return state.setIn(['packagesTrainingProducts', 'items', currentKey, 'available_quantity'], current_purchased_training.get('quantity_remaining'))
                                    .setIn(['packagesTrainingProducts', 'items', currentKey, 'quantity_remaining'], current_purchased_training.get('quantity_remaining'))
                                    .setIn(['packagesTrainingProducts', 'items', currentKey, 'quantity_purchased'], current_purchased_training.get('quantity_purchased'))
                                    .setIn(['packagesTrainingProducts', 'items', currentKey, 'expires_at'], current_purchased_training.get('expires_at'))
                                    .setIn(['packagesTrainingProducts', 'items', productKey, 'available_quantity'], purchased_training.get('quantity_remaining'))
                                    .setIn(['packagesTrainingProducts', 'items', productKey, 'quantity_remaining'], purchased_training.get('quantity_remaining'))
                                    .setIn(['packagesTrainingProducts', 'items', productKey, 'quantity_purchased'], purchased_training.get('quantity_purchased'))
                                    .setIn(['packagesTrainingProducts', 'items', productKey, 'expires_at'], purchased_training.get('expires_at'));
                    });
                }
        } else {
            const currentKey = state.getIn(['packagesTrainingProducts', 'items'])
                                .findKey(item => item.get('id') === payload.get('id'));
            return state.setIn(['packagesTrainingProducts', 'items', currentKey, 'trainer_detail'], payload.get('trainer_detail'));
        }
    },
    [PackagesTrainingsProductsTypes.REMOVE_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT]: (state, action) => {
        const currentId = action.payload.itemId;
        return state.update((state) => {
            return state.updateIn(['packagesTrainingProducts', 'items'],
                items => items.filter(item => item.get('id') !== currentId));
        });
    },
    [PackagesTrainingsProductsTypes.ADD_CUSTOMER_PACKAGE_OR_TRAINING_PRODUCT]: (state, action) => {
        const { payload } = action;
        let productKey;
        if (payload.get('model_type') === 'purchasedpackage') {
            productKey = state.getIn(['packagesTrainingProducts', 'items'])
                              .findKey(item => item.get('name') === payload.get('name'));
        }

        if (payload.get('model_type') === 'purchasedtrainingprogram') {
            productKey = state.getIn(['packagesTrainingProducts', 'items'])
                              .findKey(item => item.get('name') === payload.get('name') &&
                                  item.get('dog') === payload.get('dog') &&
                                  item.get('trainer') === payload.get('trainer'));
        }

        if (productKey === undefined) {
            return state.update((state) => {
                return state.setIn(['packagesTrainingProducts', 'items'],
                    state.getIn(['packagesTrainingProducts', 'items']).insert(0, payload));
            });
        }

        return state.setIn(['packagesTrainingProducts', 'items', productKey], payload);
    },
    [ClassesTypes.DELETE_PREVIOUS_CLASS_REPORTS]: (state, action) => {
        const currentKey = state.getIn(['previous_classes', 'classes'])
                                .findKey(item => item.get('id') === action.payload);
        return state.setIn(['previous_classes', 'classes', currentKey, 'reports'], fromJS([]));
    },
    [ClassesTypes.UPDATE_PREVIOUS_CLASS_REPORTS]: (state, action) => {
        const currentKey = state.getIn(['previous_classes', 'classes'])
                                .findKey(item => item.get('id') === action.payload.get('class_product'));
        if (currentKey !== undefined) {
            return state.setIn(['previous_classes', 'classes', currentKey, 'reports'],
                state.getIn(['previous_classes', 'classes', currentKey, 'reports'])
                     .insert(0, fromJS({ id: action.payload.get('id') })));
        }
        return state;
    },
    [PurchaseHistoryTypes.MARK_CUSTOMER_PURCHASE_HISTORY_LOADED]: (state) => {
        return state.setIn(['purchaseHistory', 'areLoaded'], true);
    },
    [PurchaseHistoryTypes.UNMARK_CUSTOMER_PURCHASE_HISTORY_LOADED]: (state) => {
        return state.setIn(['purchaseHistory', 'areLoaded'], false);
    },
    [PurchaseHistoryTypes.START_ISLOADING_CUSTOMER_PURCHASE_HISTORY]: (state) => {
        return state.setIn(['purchaseHistory', 'isLoading'], true);
    },
    [PurchaseHistoryTypes.FINISH_ISLOADING_CUSTOMER_PURCHASE_HISTORY]: (state) => {
        return state.setIn(['purchaseHistory', 'isLoading'], false);
    },
    [PurchaseHistoryTypes.SET_CUSTOMER_PURCHASE_HISTORY]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);

        return state.update((state) => {
            return state.setIn(['purchaseHistory', 'items'], fromJS(results))
                        .setIn(['purchaseHistory', 'count'], count)
                        .setIn(['purchaseHistory', 'pageSize'], results.length)
                        .setIn(['purchaseHistory', 'pages'], pagesCount)
                        .setIn(['purchaseHistory', 'nextUrl'], nextUrl)
                        .setIn(['purchaseHistory', 'previousUrl'], previousUrl)
                        .setIn(['purchaseHistory', 'isLoading'], false);
        });
    },
    [PurchaseHistoryTypes.SET_NEXT_CUSTOMER_PURCHASE_HISTORY]: (state, action) => {
        const { results, count, next, previous } = action.payload;
        const nextUrl = next === null ? next : next.slice(4);
        const previousUrl = previous === null ? previous : previous.slice(4);
        const pagesCount = Math.ceil(count / results.length);

        return state.update((state) => {
            return state.setIn(['purchaseHistory', 'items'],
                state.getIn(['purchaseHistory', 'items']).concat(fromJS(results)))
                        .setIn(['purchaseHistory', 'count'], count)
                        .setIn(['purchaseHistory', 'pageSize'], results.length)
                        .setIn(['purchaseHistory', 'pages'], pagesCount)
                        .setIn(['purchaseHistory', 'nextUrl'], nextUrl)
                        .setIn(['purchaseHistory', 'previousUrl'], previousUrl)
                        .setIn(['purchaseHistory', 'isLoading'], false);
        });
    },
    [CustomerTypes.UPDATE_ADDITIONAL_OWNER]: (state, action) => {
        const currentKey = state.getIn(['instance', 'additional_owners'])
            .findKey(item => item.get('id') === action.payload.get('id'));
        return state.setIn(['instance', 'additional_owners', currentKey], action.payload);
    },
    [CustomerTypes.UPDATE_EMERGENCY_CONTACT]: (state, action) => {
        const currentKey = state.getIn(['instance', 'emergency_contacts'])
            .findKey(item => item.get('id') === action.payload.get('id'));
        return state.setIn(['instance', 'emergency_contacts', currentKey], action.payload);
    },
    [CustomerTypes.ADD_ADDITIONAL_OWNER]: (state, action) => {
        return state.setIn(['instance', 'additional_owners'], state.getIn(['instance', 'additional_owners']).push(action.payload));
    },
    [CustomerTypes.ADD_EMERGENCY_CONTACT]: (state, action) => {
        return state.setIn(['instance', 'emergency_contacts'], state.getIn(['instance', 'emergency_contacts']).push(action.payload));
    },
    [CustomerTypes.DELETE_ADDITIONAL_OWNER]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['instance', 'additional_owners'],
                items => items.filter(item => item.get('id') !== action.payload.itemId));
        });
    },
    [CustomerTypes.DELETE_EMERGENCY_CONTACT]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['instance', 'emergency_contacts'],
                items => items.filter(item => item.get('id') !== action.payload.itemId));
        });
    },
    [EmployeeTypes.SET_DEFAULT_PICKUP_ADDRESS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['instance', 'pickup_location'], action.payload);
        });
    },
    [EmployeeTypes.SET_DEFAULT_DROPOFF_ADDRESS]: (state, action) => {
        return state.update((state) => {
            return state.setIn(['instance', 'drop_off_location'], action.payload);
        });
    },
    [CustomerTypes.VERIFY_PRIMARY_PHONE]: (state, action) => {
        const currentKey = state.getIn(['instance', 'available_phones'])
            .findKey(item => item.get('id') === action.payload);
        return state.update((state) => {
            return state.setIn(['instance', 'available_phones', currentKey, 'verified_at'], true);
        });
    },
    [TrainingsTypes.MARK_CUSTOMER_PREVIOUS_TRAININGS_LOADED]: (state) => {
        return state.setIn(['previous_trainings', 'areLoaded'], true);
    },
    [TrainingsTypes.UNMARK_CUSTOMER_PREVIOUS_TRAININGS_LOADED]: (state) => {
        return state.setIn(['previous_trainings', 'areLoaded'], false);
    },
    [TrainingsTypes.SET_CUSTOMER_PREVIOUS_TRAININGS]: (state, action) => {
        return state
            .setIn(['previous_trainings', 'count'], action.payload.count)
            .setIn(['previous_trainings', 'trainings'],
                state.getIn(['previous_trainings', 'trainings']).merge(action.payload.results));
    },
    [TrainingsTypes.SET_CUSTOMER_PREVIOUS_TRAINING_DETAILS]: (state, action) => {
        const idOfTrainingToUpdate = state.getIn(['previous_trainings', 'trainings'])
                                       .findIndex((previousTraining) => {
                                           return previousTraining.get('id') === action.payload.id;
                                       });

        return state.setIn(['previous_trainings', 'trainings', idOfTrainingToUpdate, 'details'], fromJS(action.payload));
    },
    [TrainingsTypes.MARK_CUSTOMER_UPCOMING_TRAININGS_LOADED]: (state) => {
        return state.setIn(['upcoming_trainings', 'areLoaded'], true);
    },
    [TrainingsTypes.UNMARK_CUSTOMER_UPCOMING_TRAININGS_LOADED]: (state) => {
        return state.setIn(['upcoming_trainings', 'areLoaded'], false);
    },
    [TrainingsTypes.SET_CUSTOMER_UPCOMING_TRAININGS]: (state, action) => {
        return state.setIn(['upcoming_trainings', 'trainings'],
            state.getIn(['upcoming_trainings', 'trainings']).merge(action.payload));
    },
    [TrainingsTypes.SET_CUSTOMER_UPCOMING_TRAINING_DETAILS]: (state, action) => {
        const pickupLocationDetail = action.payload.get('pickup_location').detail;
        const dropoffLocationDetail = action.payload.get('drop_off_location').detail;

        const isPickupLocationInitial = pickupLocationDetail === undefined;
        const isDropOffLocationInitial = dropoffLocationDetail === undefined;

        if (isPickupLocationInitial && isDropOffLocationInitial) {
            return state;
        }

        const currentKey = state.getIn(['upcoming_trainings', 'trainings'])
                                .findKey(product => {
                                    return product.getIn(['dog_detail', 'id']) === action.payload.getIn(['dogId']);
                                });

        if (isPickupLocationInitial) {
            return state.setIn(['upcoming_trainings', 'trainings', currentKey, 'drop_off_location_detail', 'name'], dropoffLocationDetail.name);
        };

        if (isDropOffLocationInitial) {
            return state.setIn(['upcoming_trainings', 'trainings', currentKey, 'pickup_location_detail', 'name'], pickupLocationDetail.name);
        };

        return state.setIn(['upcoming_trainings', 'trainings', currentKey, 'pickup_location_detail', 'name'], pickupLocationDetail.name)
                    .setIn(['upcoming_trainings', 'trainings', currentKey, 'drop_off_location_detail', 'name'], dropoffLocationDetail.name);
    },
    [TrainingsTypes.REMOVE_CUSTOMERS_UPCOMING_TRAINING]: (state, action) => {
        return state.update((state) => {
            return state.updateIn(['upcoming_trainings', 'trainings'],
                items => items.filter(item => item.get('id') !== action.payload));
        });
    },
}, INITIAL_STATE);

export default currentCustomerReducer;
