import React from 'react';
import { Link } from 'react-router';
import { isDesktop } from '../../../../../../helpers/device';
import { prettifyPhone } from '../../../../../../helpers/normalize';
import { vipOptions } from '../../../../../../helpers/options';

export const Name = (filter, valueClick) => {
    if (!filter || !valueClick) {
        return { filterType: 'input', };
    }
    const column = {
        Header: 'Name',
        headerClassName: 'customers-table__th',
        className: 'customers-table__td',
        width: 250,
        id: 'name',
        accessor: d => d.get('full_name'),
        Cell: row => (
            <div
                className='customers-table__customer-link'
                onClick={() => valueClick(row.original)}>
                {row.value.toUpperCase()}
            </div>
        ),
        Filter: filter && filter({ placeholder: 'Search', filterType: '?name=' }),
    };
    return column;
};

export const StreetLocation = filter => {
    if (!filter) {
        return { filterType: 'input', };
    }
    const column = {
        Header: props => (
            <div>
                <div>Street</div>
                <div>Location</div>
            </div>
        ),
        headerClassName: 'customers-table__th',
        className: 'customers-table__td',
        id: 'address',
        accessor: d => d,
        Cell: row => (
            <div>
                {row.value.getIn(['available_addresses', '0', 'address2']) === null
                    ? <span>{row.value.getIn(['available_addresses', '0', 'address1'])}</span>
                    : (<span>
                        {`${row.value.getIn(['available_addresses', '0', 'address1'])} ${row.value.getIn(
                            ['available_addresses', '0', 'address2'])}`}
                    </span>)
                }
            </div>
        ),
        Filter: filter && filter({ placeholder: 'Search', filterType: '?address=' }),
    };
    if (!isDesktop) {
        column.width = 142;
    }
    return column;
};

export const City = filter => {
    if (!filter) {
        return { filterType: 'input', };
    }
    const column = {
        Header: 'City',
        headerClassName: 'customers-table__th',
        className: 'customers-table__td',
        width: 84,
        id: 'city',
        accessor: d => d.getIn(['available_addresses', '0', 'city']),
        Cell: row => (
            <div>
                {row.value === undefined
                    ? <span>-</span>
                    : <span>{row.value}</span>
                }
            </div>
        ),
        Filter: filter && filter({ placeholder: 'Search', filterType: '?city=' }),
    };
    return column;
};

export const State = filter => {
    if (!filter) {
        return { filterType: 'input', };
    }
    const column = {
        Header: 'State',
        headerClassName: 'customers-table__th',
        className: 'customers-table__td',
        width: 58,
        id: 'state',
        accessor: d => d.getIn(['available_addresses', '0', 'state']),
        Cell: row => (
            <div>
                {row.value === undefined
                    ? <span>-</span>
                    : <span>{row.value}</span>
                }
            </div>
        ),
        Filter: filter && filter({ placeholder: 'Search', filterType: '?state=' }),
    };
    return column;
};

export const Zipcode = filter => {
    if (!filter) {
        return { filterType: 'input', };
    }
    const column = {
        Header: 'Zipcode',
        headerClassName: 'customers-table__th',
        className: 'customers-table__td',
        width: 80,
        id: 'zip_code',
        accessor: d => d.getIn(['available_addresses', '0', 'zip_code']),
        Cell: row => (
            <div>
                {row.value === undefined
                    ? <span>-</span>
                    : <span>{row.value}</span>
                }
            </div>
        ),
        Filter: filter && filter({ placeholder: 'Search', filterType: '?zip_code=' }),
    };
    return column;
};

export const Email = filter => {
    if (!filter) {
        return { filterType: 'input', };
    }
    const column = {
        Header: 'Email',
        headerClassName: 'customers-table__th',
        className: 'customers-table__td customers-table__td_email',
        id: 'email',
        accessor: d => d.get('email'),
        Cell: row => (
            <div>
                {row.value === undefined
                    ? <span>-</span>
                    : <span>{row.value}</span>
                }
            </div>
        ),
        Filter: filter && filter({ placeholder: 'Search', filterType: '?email=' }),
    };
    if (!isDesktop) {
        column.width = 130;
    }
    return column;
};

export const PhoneNumber = filter => {
    if (!filter) {
        return { filterType: 'input', };
    }
    const column = {
        Header: props => (
            <div>
                <div>Phone</div>
                <div>Number</div>
            </div>
        ),
        headerClassName: 'customers-table__th',
        className: 'customers-table__td',
        id: 'phone',
        accessor: d => d.getIn(['available_phones', '0', 'phone_number']),
        Cell: row => (
            <div>
                {row.value === undefined
                    ? <span>-</span>
                    : <span>{prettifyPhone(row.value)}</span>
                }
            </div>
        ),
        Filter: filter({ placeholder: 'Search', filterType: '?phone=' }),
    };
    if (!isDesktop) {
        column.width = 130;
    }
    return column;
};

export const Vip = filter => {
    if (!filter) {
        return { filterType: 'select', };
    }
    const column = {
        Header: 'VIP',
        headerClassName: 'customers-table__th customers-table__th_select',
        className: 'customers-table__td',
        width: 60,
        id: 'is_vip',
        accessor: d => d.get('is_vip'),
        Cell: row => (
            <div>
                {row.value
                    ? <span>Yes</span>
                    : <span>No</span>
                }
            </div>
        ),
        Filter: filter && filter({ placeholder: 'VIP', options: vipOptions, filterType: '?is_vip=' }),
    };
    return column;
};

export const Dog = filter => {
    if (!filter) {
        return { filterType: 'input', };
    }
    const column = {
        Header: 'Dog',
        headerClassName: 'customers-table__th customers-table__th_last-of-type',
        className: 'customers-table__td',
        width: 100,
        id: 'dog',
        accessor: d => d.get('dogs'),
        Cell: row => (
            <div>
                {(row.value !== undefined && row.value.size)
                    ? row.value.map(dog => (
                        <Link
                            key={dog.get('id')}
                            to={`/dashboard/dog/${dog.get('id')}/details`}
                            className='customers-table__customer-link'>
                            {dog.get('name')}
                        </Link>))
                    : <div>-</div>}
            </div>
        ),
        Filter: filter && filter({ placeholder: 'Search', filterType: '?dog=' }),
    };
    return column;
};
