import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form/immutable';
import classNames from 'classnames';
import renderInputControl from '../../../../common/Form/InputControl';
import validate from './validate';

const SmsVerificationStepForm = ({ 
    invalid,
    submitting,
    onCancelHandler,
    submitButtonClasses,
    handleSubmit,
    sendVerificationRequest
}) => {
    const submitButtonClassNames = classNames({
        'button modal-dialog__button modal-dialog__button_sms': true,
        'button_disabled': (invalid || submitting),
        [submitButtonClasses]: true,
    });
    return (
        <form onSubmit={handleSubmit} className='sms-modal__form'>
            <div className='sms-modal__content sms-modal__form-content'>
                <Field
                    type='text'
                    name='code'
                    label='Identification Code'
                    className='sms-modal__field'
                    component={renderInputControl}/>
                <p className='sms-modal__form-notice'>
                    {/*eslint-disable-next-line*/}
                    Didn’t get the code? <a onClick={sendVerificationRequest}>Click Here</a> to have a new one sent.
                </p>
            </div>
            <div className='sms-modal__form-footer'>
                <button
                    className={submitButtonClassNames}
                    type='submit'
                    disabled={submitting}>
                    {(submitting)
                        ? 'Submitting...'
                        : 'Submit'
                    }
                </button>
                <span className='sms-modal__cancel-button' onClick={onCancelHandler}>Cancel</span>
            </div>
        </form>
    );
};

SmsVerificationStepForm.propTypes = {
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    onCancelHandler: PropTypes.func,
    sendVerificationRequest: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    submitButtonClasses: PropTypes.string,
};

const form = reduxForm({
    form: 'SmsVerificationForm',
    validate
});

export default form(SmsVerificationStepForm);
