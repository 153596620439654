import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import classNames from 'classnames';
import BaseField from '../';

class ButtonEditable extends BaseField {
    static propTypes = { ...BaseField.propTypes, link: PropTypes.string };
    
    fieldClassName = 'button-editable';
    
    handleClick = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    
    renderValue = () => {
        const { formProps: { isPreview }, label } = this.props;
        let { input: { value } } = this.props;
    
        if (!value) {
            value = label;
        }
        
        if (isPreview) {
            return (
              // eslint-disable-next-line
                <a href='' className='button-editable__text'>
                    {value}
                </a>
            );
        }
        
        return (
            <span className='button-editable__text'>
                {value}
            </span>
        );
    };
    
    renderInput = () => {
        const { formProps: { isPreview }, className, input, label } = this.props;
        
        if (isPreview) {
            return null;
        }
        
        const inputClassNames = classNames([
            'button-editable__input',
            className
        ]);
        
        return (
            <input
                {...input}
                placeholder={label}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
                onClick={this.handleClick}
                className={inputClassNames}/>
        );
    };
    
    render() {
        const { formProps: { isPreview } } = this.props;
        const containerClassNames = classNames(
            this.getCombinedFieldClassName(),
            {
                'button-editable_preview': isPreview
            }
        );
        
        return (
            <div
                className={containerClassNames}>
                {this.renderValue()}
                {this.renderInput()}
                {this.renderError()}
            </div>
        );
    }
    
}

const ButtonEditableField = ({ fieldConfig, formProps }) => {
    return (
        <Field
            {...fieldConfig}
            formProps={formProps}
            component={ButtonEditable}/>
    );
};

ButtonEditableField.propTypes = BaseField.defaultFieldProps;

export default ButtonEditableField;
