/* eslint-disable dot-notation */
import moment from 'moment';
import { ReactComponent as IconCancel } from '../assets/icons/icon-cancel.svg';
import { ReactComponent as IconCancelFee } from '../assets/icons/icon-cancel-fee.svg';
import { ReactComponent as IconCheckMark } from '../assets/icons/icon-checkmark.svg';
import { ReactComponent as IconEdit } from '../assets/icons/icon-edit.svg';
import { ReactComponent as IconNoShow } from '../assets/icons/icon-no-show.svg';
import { ReactComponent as IconReport } from '../assets/icons/icon-report.svg';
import { getDogReportById } from '../services/dogReports';
import * as DateHelpers from './date';
import { openConfirmClassCancellationModalDialog } from '../actions/modal/openActions';
import { isAdminOrSuperadmin } from './userRoles';

export const checkIfDropdownItemIsDisabled = (itemData, classType) => {
    const classDate = itemData.getIn(['class_occurrence_detail', 'date']);
    const pickupStartTime = itemData.getIn(['class_occurrence_detail', 'pickup_start_time']);
    const dropoffEndTime = itemData.getIn(['class_occurrence_detail', 'dropoff_end_time']);
    const classStartDateTime = DateHelpers.formatDateToSlashes(classDate) + ' ' + pickupStartTime;
    const classEndDateTime = DateHelpers.formatDateToSlashes(classDate) + ' ' + dropoffEndTime;

    // negative - class didn't start, positive - class started
    const timeTillClassStarts = moment().diff(moment(classStartDateTime, 'MM/DD/YYYY HH:mm:ss'));
    const timeTillClassEnds = moment().diff(moment(classEndDateTime, 'MM/DD/YYYY HH:mm:ss'));

    if (classType === 'Upcoming Classes') {
        if (timeTillClassStarts > 0 && timeTillClassEnds < 0) {
            return false;
        }
        if ((timeTillClassStarts > 0 && timeTillClassEnds > 0) || (timeTillClassStarts < 0 && timeTillClassEnds < 0)) {
            return true;
        }
    }

    return false;
};

export const checkIfDropdownItemIsDisabledTrainings = (itemData, trainingType) => {
    const trainingStart = itemData.getIn(['event_detail', 'start']);
    const trainingEnd = itemData.getIn(['event_detail', 'end']);

    // negative - class didn't start, positive - class started
    const timeTillTrainingStarts = moment().diff(DateHelpers.normalizeTimezoneDateToDateFormat(trainingStart));
    const timeTillTrainingEnds = moment().diff(DateHelpers.normalizeTimezoneDateToDateFormat(trainingEnd));

    if (trainingType === 'Upcoming Trainings') {
        if (timeTillTrainingStarts > 0 && timeTillTrainingEnds < 0) {
            return false;
        }
        if ((timeTillTrainingStarts > 0 && timeTillTrainingEnds > 0) || (timeTillTrainingStarts < 0 && timeTillTrainingEnds < 0)) {
            return true;
        }
    }

    return false;
};

export const checkIfTwoHourBeforeClassStarts = (itemData) => {
    const classStart = itemData.getIn(['class_occurrence_detail', 'start']);
    const diff = moment(classStart).diff(moment(), 'hours');
    return diff < 2;
};

const CLASS_STATUS = {
    'noinfo': 0,
    'attended': 1,
    'cancelled': 2,
    'noshow': 3,
    'cancelled_early': 4,
    'cancelled_waived': 5,
    'cancelled_penalty': 6,
    'cancelled_error': 1000
};

const TRAINING_STATUS = {
    'noinfo': 0,
    'attended': 1,
    'cancelled': 2,
    'noshow': 3,
    'cancelled_error': 1000
};

export const createClassesDropdownItems = ({ classesType, data, actions, context }) => {
    const start = data.getIn(['class_occurrence_detail', 'start']);
    const isDayOver = DateHelpers.isInPast(start) && !DateHelpers.isToday(start);
    const normalizeRowData = rowData => rowData.toJS ? rowData.toJS() : rowData;
    const upcomingClassesItemsTypes = [
        {
            iconClass: 'icon_edit',
            icon: IconEdit,
            text: 'Edit',
            isDisabled: false,
            onClickCB: rowData => actions['edit']({ classData: normalizeRowData(rowData), context }),
        },
        {
            iconClass: 'icon_check-mark',
            icon: IconCheckMark,
            text: 'Attended',
            isDisabled: checkIfDropdownItemIsDisabled(data, classesType),
            onClickCB: rowData => actions['attended'](normalizeRowData(rowData), CLASS_STATUS['attended']),
        },
        {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Cancel',
            isDisabled: false,
            onClickCB: rowData => {
                if (checkIfTwoHourBeforeClassStarts(rowData)) {
                    return actions['cancelWithFee'](normalizeRowData(rowData), CLASS_STATUS['cancelled']);
                }
                return actions['cancel'](normalizeRowData(rowData), 2);
            },
        },
        {
            iconClass: 'icon_no-show',
            icon: IconNoShow,
            text: 'No Show',
            isDisabled: checkIfDropdownItemIsDisabled(data, classesType),
            onClickCB: rowData => actions['no-show'](normalizeRowData(rowData), CLASS_STATUS['noshow']),
        },
    ];
    const previousClassesItemsTypes = {
        'attended': {
            iconClass: 'icon_check-mark',
            icon: IconCheckMark,
            text: 'Change to: Attended',
            isDisabled: false,
            onClickCB: rowData => actions['attended'](normalizeRowData(rowData), CLASS_STATUS['attended']),
        },
        'cancel': {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Cancel',
            isDisabled: false,
            onClickCB: rowData => actions['cancel'](normalizeRowData(rowData), CLASS_STATUS['cancelled']),
        },
        'cancel-waive': {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Waive Penalty',
            isDisabled: false,
            onClickCB: rowData => actions['waive-fee'](normalizeRowData(rowData), CLASS_STATUS['cancelled_waived']),
        },
        'cancel-penalty': {
            iconClass: 'icon_cancel',
            icon: IconCancelFee,
            text: 'Change to: Assess Penalty',
            isDisabled: false,
            onClickCB: rowData => actions['assess-fee'](normalizeRowData(rowData), CLASS_STATUS['cancelled_penalty']),
        },
        'no-show': {
            iconClass: 'icon_no-show',
            icon: IconNoShow,
            text: 'Change to: No Show',
            isDisabled: false,
            onClickCB: rowData => actions['no-show'](normalizeRowData(rowData), CLASS_STATUS['noshow']),
        },
        'delete-report': {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Snapshot Not Sent',
            isDisabled: false,
            onClickCB: rowData => actions['delete-report'](normalizeRowData(rowData)),
        },
        'create-report': {
            iconClass: 'icon_report',
            icon: IconReport,
            text: 'Create Snapshot',
            isDisabled: false,
            onClickCB: rowData => actions['create-report'](
                { isEventBased: true, data: normalizeRowData(rowData) }),
        },
        'create-report-extra': {
            iconClass: 'icon_report',
            icon: IconReport,
            text: 'Create Snapshot',
            extraTextUpper: 'Dog must be marked as',
            extraTextLower: 'attended to create a Snapshot.',
            isDisabled: true,
            onClickCB: rowData => actions['create-report'](
                { isEventBased: true, data: normalizeRowData(rowData) },
            ),
        },
        'edit-report': {
            iconClass: 'icon_check-mark',
            icon: IconCheckMark,
            text: 'Edit Snapshot',
            isDisabled: false,
            onClickCB: rowData => {
                const { id } = normalizeRowData(rowData).reports[0];
                getDogReportById({ id }).then((reportData) => {
                    actions['edit-report']({ isEventBased: true, data: reportData.data });
                });
            },
        },
    };

    const hasReports = data.get('reports').size;
    const status = data.get('extended_status');
    if (classesType === 'Upcoming Classes') {
        return upcomingClassesItemsTypes;
    }

    const result = [];

    //
    // Add dropdown options to change class status
    //
    switch (status) { // eslint-disable-line default-case
        case CLASS_STATUS['attended']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousClassesItemsTypes['no-show']);
                result.push(previousClassesItemsTypes['cancel']);
            }
            break;
        case CLASS_STATUS['cancelled_early']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousClassesItemsTypes['attended']);
                result.push(previousClassesItemsTypes['no-show']);
            }
            break;
        case CLASS_STATUS['cancelled_waived']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousClassesItemsTypes['attended']);
                result.push(previousClassesItemsTypes['no-show']);
                result.push(previousClassesItemsTypes['cancel-penalty']);
            }
            break;
        case CLASS_STATUS['cancelled_penalty']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousClassesItemsTypes['attended']);
                result.push(previousClassesItemsTypes['no-show']);
                result.push(previousClassesItemsTypes['cancel-waive']);
            }
            break;
        case CLASS_STATUS['noshow']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousClassesItemsTypes['attended']);
                result.push(previousClassesItemsTypes['cancel']);
            }
            break;
    }

    //
    // Add dropdown options to create/edit/delete snapshot reports
    //
    if (hasReports) {
        if (!isDayOver) {
            result.push(previousClassesItemsTypes['edit-report']);
        }
        result.push(previousClassesItemsTypes['delete-report']);
    } else {
        if (status === CLASS_STATUS['attended']) { // eslint-disable-line no-lonely-if
            result.push(previousClassesItemsTypes['create-report']);
        } else if (status === CLASS_STATUS['noshow']) {
            result.push(previousClassesItemsTypes['create-report-extra']);
        }
    }

    return result.length === 0 ? null : result;
};

export const createTrainingsDropdownItems = ({ trainingsType, data, actions, context }) => {
    const start = data.getIn(['event_detail', 'start']);
    const isDayOver = DateHelpers.isInPast(start) && !DateHelpers.isToday(start);
    const normalizeRowData = rowData => rowData.toJS ? rowData.toJS() : rowData;
    const upcomingTrainingsItemsTypes = [
        {
            iconClass: 'icon_edit',
            icon: IconEdit,
            text: 'Edit',
            isDisabled: false,
            onClickCB: rowData => actions['edit']({ trainingData: normalizeRowData(rowData), context }),
        },
        {
            iconClass: 'icon_check-mark',
            icon: IconCheckMark,
            text: 'Attended',
            isDisabled: checkIfDropdownItemIsDisabledTrainings(data, trainingsType),
            onClickCB: rowData => actions['attended'](normalizeRowData(rowData), TRAINING_STATUS['attended']),
        },
        {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Cancel',
            isDisabled: false,
            onClickCB: rowData => {
                return actions['cancel'](normalizeRowData(rowData), 2);
            },
        },
        {
            iconClass: 'icon_no-show',
            icon: IconNoShow,
            text: 'No Show',
            isDisabled: checkIfDropdownItemIsDisabledTrainings(data, trainingsType),
            onClickCB: rowData => actions['no-show'](normalizeRowData(rowData), TRAINING_STATUS['noshow']),
        },
    ];
    const previousTrainingsItemsTypes = {
        'attended': {
            iconClass: 'icon_check-mark',
            icon: IconCheckMark,
            text: 'Change to: Attended',
            isDisabled: false,
            onClickCB: rowData => actions['attended'](normalizeRowData(rowData), TRAINING_STATUS['attended']),
        },
        'cancel': {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Cancel',
            isDisabled: false,
            onClickCB: rowData => actions['cancel'](normalizeRowData(rowData), TRAINING_STATUS['cancelled']),
        },
        'no-show': {
            iconClass: 'icon_no-show',
            icon: IconNoShow,
            text: 'Change to: No Show',
            isDisabled: false,
            onClickCB: rowData => actions['no-show'](normalizeRowData(rowData), TRAINING_STATUS['noshow']),
        },
    };

    const status = data.get('status');
    if (trainingsType === 'Upcoming Trainings') {
        return upcomingTrainingsItemsTypes;
    }

    const result = [];

    //
    // Add dropdown options to change training status
    //
    switch (status) { // eslint-disable-line default-case
        case TRAINING_STATUS['noinfo']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousTrainingsItemsTypes['attended']);
                result.push(previousTrainingsItemsTypes['no-show']);
                result.push(previousTrainingsItemsTypes['cancel']);
            }
            break;
        case TRAINING_STATUS['attended']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousTrainingsItemsTypes['no-show']);
                result.push(previousTrainingsItemsTypes['cancel']);
            }
            break;
        case TRAINING_STATUS['cancelled']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousTrainingsItemsTypes['attended']);
                result.push(previousTrainingsItemsTypes['no-show']);
            }
            break;
        case TRAINING_STATUS['noshow']:
            if (!isDayOver || isAdminOrSuperadmin) {
                result.push(previousTrainingsItemsTypes['attended']);
                result.push(previousTrainingsItemsTypes['cancel']);
            }
            break;
    }

    return result.length === 0 ? null : result;
}

export const createEventAttendingDogsDropdownItems = ({ eventData, rowData, actions, context, type }) => {
    let dropdownItem = null;
    const dropdownItems = [];
    const { end, start, dropoff_end_time, pickup_start_time } = eventData;
    // actual start time should be pickup_start_time for class events
    const actualStartTime = DateHelpers.combineEventDateTime(start, pickup_start_time);
    const isToday = DateHelpers.isToday(start);
    const hasStarted = DateHelpers.isInPastFormatted(actualStartTime);
    const isWithin2Before = hasStarted ? false : DateHelpers.isNowWithin2HoursBefore(start);
    const isOngoing = isToday && hasStarted;
    const isDayOver = DateHelpers.isInPastFormatted(actualStartTime) && !DateHelpers.isToday(start);
    const status = rowData.status_detail.toLowerCase();
    const extendedStatus = rowData.status;

    const eventItems = {
        class: {
            upcoming: ['edit', 'cancel', 'cancelWithoutNotification', 'cancelWaive', 'cancelPenalty'],
            ongoing: ['deleteReportCard', 'reportCard', 'edit', 'noShow', 'attended', 'cancel', 'cancelWithoutNotification', 'cancelWaive', 'cancelPenalty'],
            past: ['deleteReportCard', 'reportCard', 'noShow', 'attended', 'cancel', 'cancelWithoutNotification', 'cancelWaive', 'cancelPenalty'],
        },
        training: {
            upcoming: ['edit', 'cancel'],
            ongoing: ['edit', 'noShow', 'attended', 'cancel'],
            past: ['cancel', 'attended', 'noShow'],
        },
    };

    const items = {
        edit: {
            iconClass: 'icon_edit',
            icon: IconEdit,
            text: 'Edit',
            onClickCB: rowData => actions['edit']({ classData: rowData, context, eventData }),
        },
        attended: {
            iconClass: 'icon_check-mark',
            icon: IconCheckMark,
            text: 'Attended',
            isDisabled: false,
            onClickCB: rowData => actions['attended'](rowData, 1),
        },
        attendedChangeTo: {
            iconClass: 'icon_check-mark',
            icon: IconCheckMark,
            text: 'Change to: Attended',
            isDisabled: false,
            onClickCB: rowData => actions['attendedChangeTo'](rowData, 1),
        },
        cancel: {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Cancel',
            isDisabled: false,
            onClickCB: rowData => {
                const data = {
                    rowData: {
                        ...rowData,
                        noNotification: false,
                    },
                    action: actions['cancel'],
                };
                openConfirmClassCancellationModalDialog(data);
            }
        },
        cancelWithoutNotification: {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Cancel without notification',
            isDisabled: false,
            onClickCB: rowData => {
                const data = {
                    rowData: {
                        ...rowData,
                        noNotification: true,
                    },
                    action: actions['cancel'],
                };
                openConfirmClassCancellationModalDialog(data);
            }
        },
        cancelPast: {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Cancel',
            isDisabled: false,
            onClickCB: rowData => {
                const data = {
                    rowData: {
                        ...rowData,
                        noNotification: false,
                    },
                    action: actions['cancelPast'],
                };
                openConfirmClassCancellationModalDialog(data);
            }
        },
        cancelPastWithoutNotification: {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Cancel, no notify',
            isDisabled: false,
            onClickCB: rowData => {
                const data = {
                    rowData: {
                        ...rowData,
                        noNotification: true,
                    },
                    action: actions['cancelPast'],
                };
                openConfirmClassCancellationModalDialog(data);
            }
        },
        cancelLate: {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Cancel',
            isDisabled: false,
            onClickCB: rowData => {
                actions['cancelLate']({ ...rowData, noNotification: false }, 2);
            }
        },
        cancelLateWithoutNotification: {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Cancel, no notify',
            isDisabled: false,
            onClickCB: rowData => {
                actions['cancelLate']({ ...rowData, noNotification: true }, 2);
            }
        },
        cancelPenaltyChangeTo: {
            iconClass: 'icon_cancel',
            icon: IconCancelFee,
            text: 'Change to: Assess Penalty',
            isDisabled: false,
            onClickCB: rowData => actions['cancelPenaltyChangeTo'](rowData, CLASS_STATUS['cancelled_penalty']),
        },
        cancelWaiveChangeTo: {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Waive Penalty',
            isDisabled: false,
            onClickCB: rowData => actions['cancelWaiveChangeTo'](rowData, CLASS_STATUS['cancelled_waived']),
        },
        noShow: {
            iconClass: 'icon_no-show',
            icon: IconNoShow,
            text: 'No Show',
            isDisabled: false,
            onClickCB: rowData => actions['noShow'](rowData, 3),
        },
        noShowChangeTo: {
            iconClass: 'icon_no-show',
            icon: IconNoShow,
            text: 'Change to: No Show',
            isDisabled: false,
            onClickCB: rowData => actions['noShowChangeTo'](rowData, 3),
        },
        reportCreate: {
            iconClass: 'icon_report',
            icon: IconReport,
            text: 'Create Snapshot',
            isDisabled: status !== 'attended',
            extraTextUpper: status !== 'attended'
                ? 'Dog must be marked as'
                : undefined,
            extraTextLower: status !== 'attended'
                ? 'attended to create a snapshot.'
                : undefined,
            onClickCB: rowData => actions['reportCreate']({ isEventBased: true, data: rowData }),
        },
        reportEdit: {
            iconClass: 'icon_check-mark',
            icon: IconCheckMark,
            text: 'Edit Snapshot',
            isDisabled: false,
            onClickCB: rowData => {
                const { id } = rowData.reports[0];
                getDogReportById({ id }).then((reportData) => {
                    actions['reportEdit']({ isEventBased: true, data: reportData.data });
                });
            },
        },
        reportDelete: {
            iconClass: 'icon_cancel',
            icon: IconCancel,
            text: 'Change to: Snapshot Not Sent',
            isDisabled: false,
            onClickCB: rowData => actions['reportDelete'](rowData),
        },
    };

    const getDropdownItems = {
        edit: () => {
            if (type === 'training'){
                if (moment().isAfter(end)) {
                    return null;
                }
            } else {
                if (DateHelpers.isAfterDropoffEnd(end, dropoff_end_time)) {
                    return null;
                }
            }
            return items.edit;
        },
        attended: () => {
            if (status === 'attended') {
                return null;
            }

            if (status === 'scheduled') {
                return items.attended;
            }

            if (hasStarted && isToday) {
                return items.attendedChangeTo;
            }

            if (isDayOver && isAdminOrSuperadmin) {
                return items.attendedChangeTo;
            }

            return null;
        },
        cancel: () => {
            if (status === 'cancelled') {
                return null;
            }

            if (isWithin2Before && type === 'class') {
                return items.cancelLate;
            }

            if (status === 'scheduled') {
                return items.cancel;
            }

            if (hasStarted && isToday) {
                return items.cancelPast;
            }

            if (isDayOver && isAdminOrSuperadmin) {
                return items.cancelPast;
            }

            return null;
        },
        cancelWithoutNotification: () => {
            if (status === 'cancelled') {
                return null;
            }

            if (isWithin2Before) {
                return items.cancelLateWithoutNotification;
            }

            if (status === 'scheduled') {
                return items.cancelWithoutNotification;
            }

            if (hasStarted && isToday) {
                return items.cancelPastWithoutNotification;
            }

            return null;
        },
        cancelWaive: () => {
            if (extendedStatus === CLASS_STATUS['cancelled_penalty']) {
                return items.cancelWaiveChangeTo;
            }

            return null;
        },
        cancelPenalty: () => {
            if (extendedStatus === CLASS_STATUS['cancelled_waived']) {
                return items.cancelPenaltyChangeTo;
            }

            return null;
        },
        noShow: () => {
            if (status === 'no show') {
                return null;
            }

            if (status === 'scheduled') {
                return items.noShow;
            }

            if (hasStarted && isToday) {
                return items.noShowChangeTo;
            }

            if (isDayOver && isAdminOrSuperadmin) {
                return items.noShowChangeTo;
            }

            return null;
        },
        reportCard: () => {
            if (status !== 'attended') {
                return null;
            }

            if (!rowData.reports.length) {
                return items.reportCreate;
            }

            return items.reportEdit;
        },
        deleteReportCard: () => {
            if (rowData.reports.length) {
                return items.reportDelete;
            }
            return null;
        },
    };

    const eventTime = isDayOver ? 'past' : (isOngoing ? 'ongoing' : 'upcoming');
    eventItems[type][eventTime].forEach(item => {
        dropdownItem = getDropdownItems[item]();
        if (dropdownItem) {
            dropdownItems.push(dropdownItem);
        }
    });

    return dropdownItems;
};

export const shouldDisplayEventProductDropdown = eventProductData => {
    if (isAdminOrSuperadmin) {
        return true;
    } else {
        const start = eventProductData.class_occurrence_detail
            ? eventProductData.class_occurrence_detail.start
            : eventProductData.start;
        const status = eventProductData.status_detail ? eventProductData.status_detail.toLocaleLowerCase() : undefined;
        const isDayOver = DateHelpers.isInPast(start) && !DateHelpers.isToday(start);
        const isTerminalState = status === 'cancelled' || status === 'no show';
        return !(isDayOver && isTerminalState);
    }
};
