/* eslint-disable camelcase */
import { createAction } from 'redux-actions';
import { setFormError, setGlobalError, setGlobalRestError } from '../../actions/errorHandlerActions';
import * as UpdateActions from '../../actions/modal/updateActions';
import { postAvailableAddressAsync } from '../../services/availableAddresses';
import * as EventService from '../../services/event';
import { postRequest } from '../../services/modals';
import { createStripeCardToken } from '../../services/paymentCards';
import * as EventTypes from '../../types/dashboard/eventTypes';
import * as dashboardPageActions from '../../actions/dashboard/dashboardPageActions';

export const resetCurrentEventReducer = createAction(EventTypes.RESET_CURRENT_EVENT_REDUCER);
export const setCurrentEvent = createAction(EventTypes.SET_CURRENT_EVENT);
export const updateCurrentTrainingEvent = createAction(EventTypes.UPDATE_CURRENT_TRAINING_EVENT);
export const setCurrentEventProducts = createAction(EventTypes.SET_CURRENT_EVENT_PRODUCTS);
export const clearCurrentEventProducts = createAction(EventTypes.CLEAR_CURRENT_EVENT_PRODUCTS);
export const addBusyField = createAction(EventTypes.ADD_BUSY_FIELD);
export const removeBusyField = createAction(EventTypes.REMOVE_BUSY_FIELD);
export const setNewClassOccurrenceEvent = createAction(EventTypes.SET_NEW_CLASS_OCCURRENCE_EVENT);
export const setNewTrainingEvent = createAction(EventTypes.SET_NEW_TRAINING_EVENT);
export const setClassOccurrenceEventInfo = createAction(EventTypes.SET_CLASS_OCCURRENCE_EVENT_INFO);
export const setTrainingEventInfo = createAction(EventTypes.SET_TRAINING_EVENT_INFO);
export const decreaseAvailableSpots = createAction(EventTypes.DECREASE_AVAILABLE_SPOTS);
export const changeProductStatus = createAction(EventTypes.CHANGE_PRODUCT_STATUS);
export const updateProductReports = createAction(EventTypes.UPDATE_PRODUCT_REPORTS);
export const deleteProductReports = createAction(EventTypes.DELETE_PRODUCT_REPORTS);
export const addDogToEventProducts = createAction(EventTypes.ADD_DOG_TO_EVENT_PRODUCTS);
export const deleteUnsavedTrainingEventProduct = createAction(EventTypes.DELETE_UNSAVED_TRAINING_EVENT_PRODUCT);
export const updateEventProduct = createAction(EventTypes.UPDATE_EVENT_PRODUCT);
export const updateEventProductLocations = createAction(EventTypes.UPDATE_EVENT_PRODUCT_LOCATIONS);

export const cancelTrainingProduct = eventData => {
    const { event, id, dog_detail } = eventData;
    const event_id = typeof event === "object" ? event.id : event
    return (dispatch, getState) => {
        if (event === undefined || id === undefined) {
            return dispatch(deleteUnsavedTrainingEventProduct(dog_detail.id));
        }
        return dispatch(EventService.deleteTrainingProduct({ eventId: event_id, productId: id }))
            .then(() => {
                dispatch(changeProductStatus({ id, status:2, status_detail: 'Cancelled' }));
            if (getState().getIn(['dashboardPage', 'glanceTrainingsProducts', 'items'])) {
                dispatch(dashboardPageActions.changeTrainingProductStatus({ id, status:2, status_detail: 'Cancelled' }));
            }
            })
            .catch(err => {
                setGlobalError(err);
            });
    };
};

export const bookDogToEvent = ({ formConfig, eventData }) => {
    return dispatch => {
        const {
            new_pickup_location_is_the_same, new_drop_off_location_is_the_same, newPickupAddress, newDropoffAddress,
            newCard,
        } = eventData;
        const { ownerId, owner, eventType, product, dogId } = formConfig.additionalData;

        let newPickupAddressRequest;
        if (newPickupAddress !== undefined) {
            newPickupAddressRequest = dispatch(postAvailableAddressAsync(ownerId, newPickupAddress))
                .then((postedAddress) => {
                    const { data } = postedAddress;
                    if (new_pickup_location_is_the_same) {
                        eventData.drop_off_location = data.id;
                        eventData.drop_off_location_detail = data;
                    }
                    eventData.pickup_location = data.id;
                    eventData.pickup_location_detail = data;
                })
                .catch((error) => {
                    dispatch(setFormError(error.response));
                });
        }

        let newDropoffAddressRequest;
        if (newDropoffAddress !== undefined) {
            newDropoffAddressRequest = dispatch(postAvailableAddressAsync(ownerId, newDropoffAddress))
                .then((postedAddress) => {
                    const { data } = postedAddress;
                    if (new_drop_off_location_is_the_same) {
                        eventData.pickup_location = data.id;
                        eventData.pickup_location_detail = data;
                    }
                    eventData.drop_off_location = data.id;
                    eventData.drop_off_location_detail = data;
                })
                .catch((error) => {
                    dispatch(setFormError(error.response));
                });
        }

        let newCardRequest;
        if (newCard !== undefined) {
            newCardRequest = dispatch(createStripeCardToken(ownerId, newCard, true, owner))
                .then((postedCard) => {
                    eventData.card = postedCard.id;
                })
                .catch((error) => {
                    dispatch(setFormError(error.response));
                });
        }

        return Promise.all([newPickupAddressRequest, newDropoffAddressRequest, newCardRequest]).then(() => {
            if (eventType === 'class') {
                eventData.dogs = [dogId];

                return dispatch(postRequest(formConfig.url, eventData))
                    .then(response => {
                        dispatch(UpdateActions.addDogToEventProducts({ responseData: response.data[0] }));
                        return response;
                    })
                    .catch(error => {
                        dispatch(setGlobalRestError(error));
                    });
            }

            if (eventType === 'new_training') {
                product.pickup_location = eventData.pickup_location;
                product.pickup_location_detail = eventData.pickup_location_detail;
                product.drop_off_location = eventData.drop_off_location;
                product.drop_off_location_detail = eventData.drop_off_location_detail;

                dispatch(UpdateActions.addDogToEventProducts({ responseData: product }));
            }

            if (eventType === 'training') {
                eventData.purchased = product.purchased;
                eventData.dog_detail = product.dog_detail;
                product.pickup_location = eventData.pickup_location;
                product.pickup_location_detail = eventData.pickup_location_detail;
                product.drop_off_location = eventData.drop_off_location;
                product.drop_off_location_detail = eventData.drop_off_location_detail;
                return dispatch(postRequest(formConfig.url, eventData))
                    .then(response => {
                        dispatch(UpdateActions.addDogToEventProducts({ responseData: product }));
                        return response;
                    })
            }
            return product;
        });
    };
};

export const editTrainingProduct = ({ eventId, productId, eventData }) => {
    return dispatch => {
        const {
            new_pickup_location_is_the_same,
            new_drop_off_location_is_the_same,
            newPickupAddress,
            newDropoffAddress,
            customerId
        } = eventData;

        let newPickupAddressRequest;
        if (newPickupAddress !== undefined) {
            newPickupAddressRequest = dispatch(postAvailableAddressAsync(customerId, newPickupAddress))
                .then((postedAddress) => {
                    const { data } = postedAddress;
                    if (new_pickup_location_is_the_same) {
                        eventData.drop_off_location = data.id;
                        eventData.drop_off_location_detail = data;
                    }
                    eventData.pickup_location = data.id;
                    eventData.pickup_location_detail = data;
                })
                .catch((error) => {
                    dispatch(setFormError(error.response));
                });
        }

        let newDropoffAddressRequest;
        if (newDropoffAddress !== undefined) {
            newDropoffAddressRequest = dispatch(postAvailableAddressAsync(customerId, newDropoffAddress))
                .then((postedAddress) => {
                    const { data } = postedAddress;
                    if (new_drop_off_location_is_the_same) {
                        eventData.pickup_location = data.id;
                        eventData.pickup_location_detail = data;
                    }
                    eventData.drop_off_location = data.id;
                    eventData.drop_off_location_detail = data;
                })
                .catch((error) => {
                    dispatch(setFormError(error.response));
                });
        }

        return Promise.all([newPickupAddressRequest, newDropoffAddressRequest]).then(() => {
            return dispatch(EventService.patchTrainingProduct({ eventId, productId, eventData }));
        });
    };
};

export const setProductAttendedStatus = ({ id, event }) => {
    return (dispatch, getState) => {
        const event_id = typeof event === "object" ? event.id : event
        dispatch(EventService.patchTrainingProduct({
            eventId: event_id,
            productId: id,
            eventData: {
                status: 1
            }
        })).then((response) => {
            dispatch(changeProductStatus({ id, status:1, status_detail: 'Attended' }));
            if (getState().getIn(['dashboardPage', 'glanceTrainingsProducts', 'items'])) {
                dispatch(dashboardPageActions.changeTrainingProductStatus({ id, status:1, status_detail: 'Attended' }));
            }
        });
    };
};

export const setProductNoShowStatus = ({ id, event }) => {
    return (dispatch, getState) => {
        const event_id = typeof event === "object" ? event.id : event
        dispatch(EventService.patchTrainingProduct({
            eventId: event_id,
            productId: id,
            eventData: {
                status: 3
            }
        })).then((response) => {
            dispatch(changeProductStatus({ id, status:3, status_detail: 'No Show' }));
            if (getState().getIn(['dashboardPage', 'glanceTrainingsProducts', 'items'])) {
                dispatch(dashboardPageActions.changeTrainingProductStatus({ id, status:3, status_detail: 'No Show' }));
            }
        });
    };
};

export const setProductCancelStatus = ({ id, event }) => {
    return (dispatch, getState) => {
        const event_id = typeof event === "object" ? event.id : event
        dispatch(EventService.patchTrainingProduct({
            eventId: event_id,
            productId: id,
            eventData: {
                status: 2
            }
        })).then((response) => {
            dispatch(changeProductStatus({ id, status:2, status_detail: 'Cancelled' }));
            if (getState().getIn(['dashboardPage', 'glanceTrainingsProducts', 'items'])) {
                dispatch(dashboardPageActions.changeTrainingProductStatus({ id, status:2, status_detail: 'Cancelled' }));
            }
        });
    };
};
