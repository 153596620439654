import { fromJS } from 'immutable';
import { handleActions } from 'redux-actions';
import * as Types from './types';

const INITIAL_STATE = fromJS({
    list: [],
    isTableView: false,
    nextQuery: null,
    pageSize: 0,
    isLoading: false
});

const marketingPageReducer = handleActions({
    [Types.RESET_REDUCER]: (state) => {
        return INITIAL_STATE.set('isTableView', state.get('isTableView'));
    },
    [Types.START_LIST_REQUEST]: (state) => {
        return state.set('isLoading', true);
    },
    [Types.FINISH_LIST_REQUEST]: (state, action) => {
        const { payload: { list = [], ...rest } } = action;
        const newState = Object.assign({ isLoading: false }, { ...rest });

        return state.update(state => {
            return state.mergeDeep(fromJS(newState)).updateIn(['list'], l => l.concat(fromJS(list)));
        });
    },
    [Types.UPDATE_MODEL]: (state, action) => {
        const { payload } = action;
        return state.update((state) => {
            return state.mergeDeep(fromJS(payload));
        });
    },
    [Types.REMOVE_ITEM_BY_ID]: (state, action) => {
        return state.update((state) => {
            return state.update('list', items => items.filter(item => item.get('id') !== action.payload));
        });
    },
}, INITIAL_STATE);

marketingPageReducer.__name = 'marketingPage';
export default marketingPageReducer;
