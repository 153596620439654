import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { finishHealthStep } from '../../../../actions/onboarding/dogCreationActions';
import { goToHealthStep, skipAllStepsAndFinishLater } from '../../../../actions/onboarding/onBoardingStepsActions';
import SkipStep from '../../../common/SkipStep/SkipStep';
import Step from '../../../common/Step';
import StepDescription from '../../../common/StepDescription';
import StepName from '../../../common/StepName';
import HealthFormContainer from './HealthForm';

const handleHealthFormSubmit = (values, callback) => {
    const newValues = values;
    callback(newValues);
};

class HealthPage extends React.PureComponent {
    componentDidMount() {
        const { isHealthComplete, goToHealthStep } = this.props;
        if (!isHealthComplete) {
            goToHealthStep();
        }
    }

    render() {
        const { stepName = 'health', handleDogCreation } = this.props;
        const Disclaimer = 'To help us provide the best care for your dog, there are a few things we should know.';
        return (
            <Step
                logoClassName='health__logo'
                contentClassName='health__content'
                contentsContainerClassName='health__container'
                stepName={stepName}
                componentsImage={<div className='health__img'/>}
                nameAndDescriptionTopped
                nameComponent={
                    <StepName
                        className='health__step-name'
                        numberText='Step 2'
                        nameText='Health Information'/>
                }
                descriptionComponent={
                    <StepDescription
                        className='health__step-description'
                        text='Tell us more'
                        disclaimer={Disclaimer}
                        imageClassName='health__step-description-image'/>
                }
                formComponent={
                    <HealthFormContainer
                        onSubmit={values => handleHealthFormSubmit(values, handleDogCreation)}/>
                }
                skipComponent={
                    <SkipStep
                        className='health__skip-step'
                        remoteSubmitText='Finish later'
                        remoteSubmitFormName='dogHealthForm'/>
                }/>
        );
    }
}

HealthPage.propTypes = {
    dog: PropTypes.shape({}),
    changeLocationOnHealthComplete: PropTypes.func,
    stepName: PropTypes.string,
    handleDogCreation: PropTypes.func,
    dispatch: PropTypes.func,
    goToHealthStep: PropTypes.func,
    isHealthComplete: PropTypes.bool,
    handleAllStepsSkip: PropTypes.func
};

const mapStateToProps = (state) => ({
    isHealthComplete: state.getIn(['steps', 'isHealthComplete'])
});

const mapDispatchToProps = (dispatch) => ({
    handleAllStepsSkip: bindActionCreators(skipAllStepsAndFinishLater, dispatch),
    handleDogCreation: bindActionCreators(finishHealthStep, dispatch),
    goToHealthStep: bindActionCreators(goToHealthStep, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthPage);
