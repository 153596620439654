import { find } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { ReactComponent as CheckmarkIcon } from '../../../../../assets/icons/forgot-password-checkmark.svg';
import { ReactComponent as AllClassesIcon } from '../../../../../assets/icons/icon-all-classes.svg';
import { ReactComponent as ScheduleIcon } from '../../../../../assets/icons/icon-calendar.svg';
import PointsMap from '../../../../common/PointsMap';
import { setSmsVerificationModalOpened } from '../../../../../actions/miscActions';

const formatTime = (dateString, timeString) => {
    const time = moment(`${dateString} ${timeString}`);
    const hours = time.format('h');
    const minutes = time.minutes() ? time.format(':mm') : '';
    const ampm = time.format('a');
    return hours + minutes + ampm;
};

const formatDate = (dateValue) => {
    const date = moment(dateValue, ['YYYY-MM-DD']);
    return date.format('MM/DD/YY');
};

const getLocationString = (location) => {
    const { city, state, zip_code: zipCode } = location;
    let { address1, address2 } = location;
    address1 = address1 || '';
    address2 = address2 || '';
    return `${address1} ${address2}, ${city}, ${state} ${zipCode}`;
};

class ConfirmationStep extends PureComponent {
    componentDidMount() {
        const { setSmsVerificationModalOpened, phoneVerified } = this.props;
        if (!phoneVerified) {
            setSmsVerificationModalOpened();
        }
    }

    getPickupLocationData = () => {
        const { submitData: { pickup }, formData: { addresses } } = this.props;
        return find(addresses, address => address.id === pickup) || null;
    };

    getDropoffLocationData = () => {
        const { submitData: { dropoff }, formData: { addresses } } = this.props;
        return find(addresses, address => address.id === dropoff) || null;
    };

    renderMaps = (mapsList) => (
        mapsList.map((map, index) => {
            const { address1, address2, name, zip_code: zipCode, city, state } = map;
            const point = {
                address: getLocationString(map),
                type: index ? 'dropoff' : 'pickup'
            };
            return (
                <div key={`confirmation__map_${index}`} className='confirmation__map'>
                    <div className='confirmation__map__wrapper'>
                        <PointsMap points={[point]}/>
                    </div>
                    <div className='confirmation__map__content'>
                        <p
                            className={`confirmation__map__title confirmation__map__title_${index
                            ? 'dropoff'
                            : 'pickup'}`}>{index ? 'Dropoff' : 'Pickup'} Address</p>
                        <p className='confirmation__map__name'>{name}</p>
                        <p className='confirmation__map__address'>{address2 || ''} {address1}</p>
                        <p className='confirmation__map__city'>{city}, {state} {zipCode}</p>
                    </div>
                </div>
            );
        })
    );

    render() {
        const { formData } = this.props;
        const { classInfo: { title, date, pickup_start_time, pickup_end_time } } = formData;
        const pickupStart = formatTime(date, pickup_start_time);
        const pickupEnd = formatTime(date, pickup_end_time);
        const classDate = formatDate(date);
        const pickupAddress = this.getPickupLocationData();
        const dropoffAddress = this.getDropoffLocationData();
        return (
            <div className='confirmation'>
                <CheckmarkIcon className='confirmation__logo'/>
                <h5 className='confirmation__title'>You&#39;re All Set</h5>
                <p className='confirmation__text'>You booked <span>{title}</span>, scheduled on <span>{classDate}</span>
                    with a pickup time between <span>{pickupStart}</span> and <span>{pickupEnd}</span>.</p>
                <p className='confirmation__subline'>Please Note: Your card will have a pending charge within 24 hours
                    of the class. If we are unable to charge this card for any reason, enrollment in the class will be
                    cancelled.</p>
                <div className='confirmation__maps-container'>
                    {this.renderMaps([
                        pickupAddress,
                        dropoffAddress
                    ])}
                </div>
                <div className='confirmation__nav'>
                    <Link to='/schedule' className='confirmation__nav__item'>
                        <ScheduleIcon className='confirmation__nav__icon confirmation__nav__icon_schedule'/>
                        <span>View Schedule</span>
                    </Link>

                    <Link to='/classes' className='confirmation__nav__item'>
                        <AllClassesIcon className='confirmation__nav__icon'/>
                        <span>Book Another Class</span>
                    </Link>
                </div>
            </div>
        );
    }
}

ConfirmationStep.propTypes = {
    submitData: PropTypes.shape({}),
    formData: PropTypes.shape({}),
    setSmsVerificationModalOpened: PropTypes.func,
    phoneVerified: PropTypes.bool
};

const mapStateToProps = state => {
    const stateProps = {};
    const { available_phones: availablePhones, phoneId: defaultPhone } = state.get('user').toJS();
    if (defaultPhone && availablePhones && availablePhones.length) {
        const { verified_at: verifiedAt } = find(availablePhones, phone => phone.id === defaultPhone.id);
        stateProps.phoneVerified = !(verifiedAt === null);
    }
    return stateProps;
};

const mapDispatchToProps = {
    setSmsVerificationModalOpened
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationStep);
