import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../../common/Icon';
import { ReactComponent as IconAdd } from '../../../assets/icons/icon-add.svg';

const AddNewItem = ({ text, onClickHandler, className = '', disabled = false }) => {
    const addNewClassName = classNames(
        'add-new-item',
        className,
        { 'add-new-item_disabled': disabled }
    );
    return (
      // eslint-disable-next-line
        <a className={addNewClassName} onClick={onClickHandler}>
            <Icon glyph={IconAdd} className='icon_add-new-item'/>
            <div className='add-new-item__text'>{ text }</div>
        </a>
    );
};

AddNewItem.propTypes = {
    text: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool
};

export default AddNewItem;
