import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form/immutable';
import { updateClassesScheduleList } from '../../../../../actions/dashboard/classesScheduleActions';
import { getAccessLevelOptions } from '../../../../../actions/miscActions';
import { openCreateClassModalDialog } from '../../../../../actions/modal/openActions';
import { ReactComponent as IconGrid } from '../../../../../assets/icons/icon-grid-view.svg';
import { ReactComponent as IconList } from '../../../../../assets/icons/icon-list-view.svg';
import AddNewItem from '../../../../../components/common/AddNewItem';
import { isEmployee, isSuperadmin, isTrainer } from '../../../../../helpers/userRoles';
import { getClasses, getClassesOptions, getNextClasses } from '../../../../../services/classesSchedule';
import FilterTags from '../../../../common/FilterTags';
import FilterForm from '../../../../common/FiterForm';
import Icon from '../../../../common/Icon';
import ClassScheduleGrid from './ClassScheduleGrid';
import ClassScheduleTable from './ClassScheduleTable';

class ClassSchedulePage extends React.PureComponent {
    static propTypes = {
        handleGettingClasses: PropTypes.func,
        classesList: PropTypes.shape({}),
        accessLevelOptions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({})), PropTypes.shape({})]),
        handleClassesScheduleFilters: PropTypes.func,
        handleOpenCreateClassModal: PropTypes.func,
        handleGettingAccessLevelOptions: PropTypes.func,
        handleGettingNextClasses: PropTypes.func,
        handleGettingClassesOptions: PropTypes.func,
    };

    state = {
        view: 'list',
        query: '',
    };

    componentDidMount() {
        const {
            classesList, handleGettingClasses, handleGettingAccessLevelOptions, accessLevelOptions,
            handleGettingClassesOptions,
        } = this.props;
        // needed for modal dialogs
        if (!accessLevelOptions.size) {
            handleGettingAccessLevelOptions();
        }

        if (!classesList.get('classes').size) {
            handleGettingClasses();
            handleGettingClassesOptions();
        }
    }

    componentWillUnmount() {
        const { handleClassesScheduleFilters } = this.props;
        handleClassesScheduleFilters();
    }

    getView() {
        const { classesList, handleGettingNextClasses, handleGettingClasses } = this.props;

        if (!classesList.get('filteredClasses').size && !classesList.get('isLoading')) {
            return <div className='classes-schedule-table__empty-content'>No data</div>;
        }

        const isMobile = window.innerWidth <= 736;

        return this.state.view === 'grid' || isMobile
            ? (<ClassScheduleGrid
                data={classesList}
                readOnly={isTrainer() || isEmployee()}
                isLoading={classesList.get('isLoading')}
                getNext={handleGettingNextClasses}/>)
            : (<ClassScheduleTable
                readOnly={isTrainer() || isEmployee()}
                isManual
                // isSortable={false}
                onSorting={value => handleGettingClasses(`o=${value}`)}
                defaultPageSize={classesList.get('pageSize')}
                pages={classesList.get('pages')}
                count={classesList.get('count')}
                data={classesList.get('filteredClasses')}
                classNameModifier={'classes-schedule-table_with-description'}
                loading={classesList.get('isLoading')}
                nextUrl={classesList.get('nextUrl')}
                getNext={handleGettingNextClasses}/>);
    }

    viewToggleClickHandler = e => {
        e.preventDefault();
        const { view } = this.state;
        this.setState({
            view: view === 'list' ? 'grid' : 'list',
        });
    };

    renderViewToggle = () => {
        const { view } = this.state;
        const current =
            view === 'grid'
                ? (<Icon glyph={IconList} className='icon_list-view classes__view-icon'/>)
                : (<Icon glyph={IconGrid} className='icon_grid-view classes__view-icon'/>);
        return (
          // eslint-disable-next-line
            <a href='' role="button" onClick={this.viewToggleClickHandler}>
                {current}
            </a>
        );
    };

    renderFilterForm = () => {
        const { classesList, handleClassesScheduleFilters } = this.props;

        if (classesList.get('isLoading') && !classesList.get('filters').size) {
            return <div>Loading filter options...</div>;
        }

        return (
            <FilterForm
                name='class-schedule-filters'
                options={classesList.get('filters')}
                filterTags={this.filterTags}
                filtersRef={element => {
                    this.filterForm = element;
                }}
                onChangeHandler={value => {
                    handleClassesScheduleFilters(value.queryObject);
                }}
                newVersion/>
        );
    };

    renderFilterTags = () => {
        return (
            <FilterTags
                hideIfEmpty
                className='classes__filter-tags'
                filterForm={this.filterForm}
                ref={element => {
                    this.filterTags = element;
                }}
                newVersion/>
        );
    };

    renderAddClass = () => {
        const { handleOpenCreateClassModal } = this.props;
        return (
            <AddNewItem
                className='classes__add-new'
                text='New class'
                onClickHandler={() => {
                    handleOpenCreateClassModal();
                }}/>
        );
    };

    render() {
        // eslint-disable-next-line max-len
        const { classesList } = this.props;
        return (
            <div className='classes'>
                <div className='classes__top'>
                    {this.renderFilterForm()}
                    {this.renderViewToggle()}
                    {isSuperadmin() && this.renderAddClass()}
                </div>
                {this.renderFilterTags()}
                <div className='classes__header'>
                    CLASSES SCHEDULE{' '}
                    {classesList.get('count') !== null && `(${classesList.get('count')})`}
                </div>
                {this.getView()}
            </div>
        );
    }
}

const form = reduxForm({
    form: 'classesForm',
});

const mapStateToProps = state => {
    return {
        classesList: state.getIn(['classesList']),
        accessLevelOptions: state.getIn(['misc', 'options', 'accessLevel']),
    };
};

const mapDispatchToProps = dispatch => ({
    handleGettingClasses: bindActionCreators(getClasses, dispatch),
    handleGettingClassesOptions: bindActionCreators(getClassesOptions, dispatch),
    handleGettingNextClasses: bindActionCreators(getNextClasses, dispatch),
    handleClassesScheduleFilters: bindActionCreators(updateClassesScheduleList, dispatch),
    handleOpenCreateClassModal: bindActionCreators(openCreateClassModalDialog, dispatch),
    handleGettingAccessLevelOptions: bindActionCreators(getAccessLevelOptions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ClassSchedulePage));
