import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table';
import { formatDateToSlashes } from '../../../../../../helpers/date';
import { dataStates } from '../../../../../../helpers/state';

const TrainersInvoice = ({ items, dataState }) => {
    const renderTable = () => {
        if (dataState === dataStates.loaded && items && items.results.length) {
            const columns = [
                {
                    id: 'date',
                    Header: 'Date',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d,
                    Cell: row => (<div>{formatDateToSlashes(row.value.datetime, true)}</div>),
                    Footer: (<div>Total Due</div>)
                },
                {
                    id: 'ownersName',
                    Header: 'Owner\'s Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.owner_detail.full_name,
                },
                {
                    id: 'dogsName',
                    Header: 'Dog\'s Name',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.dog_detail.name,
                },
                {
                    id: 'type',
                    Header: 'Class/Program',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.name,
                },
                {
                    id: 'price',
                    Header: 'Price ($)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.price,
                    Cell: row => {
                        if (row.value === undefined) return null;
                        return <div>{`$${row.value}`}</div>;
                    },
                },
                {
                    id: 'sales',
                    Header: 'Sales ($)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.sales,
                    Cell: row => {
                        if (row.value === undefined) return null;
                        return <div>{`$${row.value}`}</div>;
                    },
                    Footer: `$${items.total.sales}`
                },
                {
                    id: 'discount',
                    Header: 'Discount ($)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.discount,
                    Cell: row => {
                        if (row.value === undefined) return null;
                        return <div>{`$${row.value}`}</div>;
                    },
                },
                {
                    id: 'trainerCommission',
                    Header: 'Trainer Commission (%)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.trainer_commission,
                    Cell: row => {
                        if (row.value === undefined) return null;
                        return <div>{`${row.value}%`}</div>;
                    },
                },
                {
                    id: 'trainerShare',
                    Header: 'Trainer Share ($)',
                    headerClassName: 'custom-reports__th custom-reports__th_wrapped',
                    className: 'custom-reports__td',
                    accessor: d => d.trainer_share,
                    Cell: row => <div>{`$${row.value}`}</div>,
                    Footer: `$${items.total.trainer_share}`
                },
            ];
            const items_length = items.results.length
            let page_size = 0
            if (items_length > 50) {
                page_size = 50
            } else {
                page_size = items_length
            }

            return (
                <ReactTable
                    showPagination={true ? items_length > 50 : false}
                    defaultPageSize={page_size}
                    data={items.results}
                    columns={columns} />);
        }
        return null;
    };

    return (
        <div className='custom-reports__report'>
            {items !== undefined && renderTable()}
        </div>
    );
};

TrainersInvoice.propTypes = {
    items: PropTypes.shape({}).isRequired,
    dataState: PropTypes.string.isRequired,
};

export default TrainersInvoice;
