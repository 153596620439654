import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import * as DogsService from '../../../../../services/dogs';
import * as DogReposrtServices from '../../../../../services/dogReports';
import AjaxLoader from '../../../../common/AjaxLoader/';
import GoBackButton from '../../../../common/GoBackButton/';
import DogsListSlider from './DogsListSlider/';
import DogInfo from './DogInfo/';
import DogMedicalInfo from './DogMedicalInfo/';
import DogVaccinationInfo from './DogVaccinationInfo/';

import DogReportCardsList from './DogReportCardsList/';
import { getVaccinationRecords } from '../../../../../services/vaccinations';
import { getImmunizationOptions, getVeterinarianOptions } from '../../../../../actions/miscActions';

class DogDetailsPage extends React.PureComponent {
    static propTypes = {
        getDogsList: PropTypes.func,
        dogs: PropTypes.arrayOf(PropTypes.shape({})),
        immunizationOptions: PropTypes.arrayOf(PropTypes.shape({})),
        veterinarianOptions: PropTypes.arrayOf(PropTypes.shape({})),
        dog: PropTypes.shape({}),
        customer: PropTypes.shape({}),
        dogReports: PropTypes.shape({}),
        params: PropTypes.shape({}),
        getCustomersDogById: PropTypes.func,
        isDogLoading: PropTypes.bool,
        isDogsLoading: PropTypes.bool,
        isCustomerLoading: PropTypes.bool,
        dispatchPush: PropTypes.func,
        handleGetVaccinationRecords: PropTypes.func,
        handleGettingCurrentCustomer: PropTypes.func,
        hendleGetImmunizationOptions: PropTypes.func,
        hendleGetVeterinarianOptions: PropTypes.func,
        getDogReports: PropTypes.func,
    };

    componentWillMount() {
        const {
            params,
            getDogsList,
            dogs,
            hendleGetImmunizationOptions,
            hendleGetVeterinarianOptions,
            immunizationOptions,
            veterinarianOptions,
        } = this.props;

        if (!immunizationOptions.length) {
            hendleGetImmunizationOptions();
        }
        if (!veterinarianOptions.length) {
            hendleGetVeterinarianOptions();
        }
        this.getDog(params.dog);

        if (!dogs.length) {
            getDogsList('?is_deceased=false&is_banned=false');
        }
    }

    getDog(id) {
        const { params, getCustomersDogById, dog, handleGetVaccinationRecords, getDogReports, dogReports } = this.props;

        if (id !== dog.instance.id) {
            getCustomersDogById(id);
        }

        handleGetVaccinationRecords({
            role: 'customers/me',
            context: 'dogs',
            id: params.dog
        });
        getDogReports(id, dogReports.nexUrl);
    }

    changeDog(id) {
        const { dispatchPush, params } = this.props;
        const currentDog = params.dog * 1;
        if (currentDog !== id) {
            dispatchPush(`/dogs/${id}`);
            this.getDog(id);
        }
    }

    renderSlider() {
        const { params } = this.props;
        const dogs = this.props.dogs.reverse();
        const currentDogId = parseInt(params.dog, 10);
        return (
            <div className='dog-details__slider-card'>
                <DogsListSlider
                    dogs={dogs}
                    currentDogId={currentDogId}
                    handleSlideChange={this.changeDog.bind(this)}/>
            </div>
        );
    }

    renderContent() {
        const { dog, customer, dogReports, getDogReports } = this.props;
        return (
            <div className='dog-details__body'>
                <div className='dog-details__sidebar'>
                    {<DogInfo dog={dog} />}
                    {<DogMedicalInfo dog={dog} customer={customer}/>}
                    {<DogVaccinationInfo dog={dog} customer={customer}/>}
                </div>
                <div className='dog-details__content'>
                    {<DogReportCardsList
                        dogName={dog.instance.name}
                        dogReports={dogReports}
                        getDogReports={getDogReports}/>}
                </div>
            </div>
        );
    }

    renderBackButton() {
        const { dispatchPush } = this.props;
        const goBack = () => {
            dispatchPush('/dogs/');
        };
        return (
            <GoBackButton
                text='Back to My Dogs'
                onClickHandler={goBack}
                className='go-back–button_custom inner-page__back-button'/>
        );
    }

    render() {
        const { isDogLoading, isDogsLoading, dog } = this.props;
        const loading = isDogLoading || isDogsLoading || dog.instance.id === undefined;

        return (
            <div className='inner-page'>
                <div className='inner-page__container'>
                    <div className='dog-details'>
                        {this.renderBackButton()}
                        { !isDogsLoading && this.renderSlider()}
                        { loading ? <AjaxLoader /> : this.renderContent()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        dogReports: state.getIn(['currentDog', 'reports']).toJS(),
        dog: state.getIn(['currentDog']).toJS(),
        isDogLoading: state.getIn(['currentDog', 'isLoading']),
        dogs: state.getIn(['dogsList', 'dogs']).toJS(),
        isDogsLoading: state.getIn(['dogsList', 'isLoading']),
        customer: state.getIn(['currentCustomer', 'instance']).toJS(),
        isCustomerLoading: state.getIn(['currentCustomer', 'isLoading']),
        immunizationOptions: state.getIn(['misc', 'options', 'immunization']).toJS(),
        veterinarianOptions: state.getIn(['misc', 'options', 'veterinarian']).toJS(),
    };
};

const mapDispatchToProps = dispatch => ({
    getCustomersDogById: bindActionCreators(DogsService.getCustomersDogById, dispatch),
    dispatchPush: bindActionCreators(push, dispatch),
    getDogsList: bindActionCreators(DogsService.getDogs, dispatch),
    handleGetVaccinationRecords: bindActionCreators(getVaccinationRecords, dispatch),
    hendleGetImmunizationOptions: bindActionCreators(getImmunizationOptions, dispatch),
    hendleGetVeterinarianOptions: bindActionCreators(getVeterinarianOptions, dispatch),
    getDogReports: bindActionCreators(DogReposrtServices.getCustomerDogReports, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DogDetailsPage);
