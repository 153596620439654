import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as MessagesActions from '../../../../../actions/messagesActions';
import * as MiscActions from '../../../../../actions/miscActions';
import * as ModalOpenActions from '../../../../../actions/modal/openActions';
import { ReactComponent as IconAdd } from '../../../../../assets/icons/icon-add.svg';
import { ReactComponent as IconEdit } from '../../../../../assets/icons/icon-edit.svg';
import { isTrainer } from '../../../../../helpers/userRoles';
import ContentCard from '../../../../common/ContentCard';
import { prettifyPhone } from '../../../../../helpers/normalize';

// eslint-disable-next-line max-len
const DetailsPage = (props) => {
    const {
        customer, paymentCards, handleContactInfoModalOpened, handleAdditionalOwnersModalOpened,
        handleEmergencyContactModalOpened, handleDogCreationModalOpened, handlePDLocationsEditModalOpened,
        handlePDLocationsAddModalOpened, handleAddCreditCardModalOpened, openPaymentCardDeleteModalDialog,
        setMessagesWidgetOpened,
    } = props;
    const customerId = customer.get('id');
    const customerEmail = customer.get('email');

    const areAddressesEditable = customer => {
        const availableAddresses = customer.get('normalized_available_addresses').toJS();
        // const defaultAddressId = customer.get('addressId');
        const otherAddresses = availableAddresses.filter(item => item.id !== 1);
        return !!otherAddresses.length;
    };

    return (
        <div className='details'>
            {customerId === undefined
                ? 'Loading...'
                : (<div className='details__wrapper'>
                    <div className='details__column'>
                        <ContentCard
                            readOnly={isTrainer()}
                            header='Contact Info'
                            actionIcon={IconEdit}
                            actionOnClick={handleContactInfoModalOpened}
                            actionClassName='icon_card-edit'
                            items={[
                                {
                                    key: `phone_${customerId}`,
                                    header: 'Phone',
                                    data: customer.get('primary_phone') === 'None'
                                        ? customer.get('primary_phone')
                                        : prettifyPhone(customer.get('primary_phone')),
                                    dataUrl: customer.get('primary_phone') === 'None'
                                        ? null
                                        : `tel:${customer.get('primary_phone')}`,
                                    info: `SMS Notification: ${customer.get('sms_notifications')}`,
                                    actionCB: () => setMessagesWidgetOpened({
                                        name: customer.get('full_name'),
                                        id: customerId,
                                    }),
                                },
                                {
                                    key: `phone-2_${customerId}`,
                                    header: 'Phone 2',
                                    data: customer.get('secondary_phone') === 'None'
                                        ? customer.get('secondary_phone')
                                        : prettifyPhone(customer.get('secondary_phone')),
                                    dataUrl: customer.get('secondary_phone') === 'None'
                                        ? null
                                        : `tel:${customer.get('secondary_phone')}`
                                },
                                {
                                    key: `email_${customerId}`,
                                    header: 'Email',
                                    data: customerEmail,
                                    dataUrl: `mailto:${customerEmail}`
                                },
                                {
                                    key: `address_${customerId}`,
                                    header: 'Location',
                                    content: customer.get('addressId') ? customer.get('address') : 'None'
                                }

                            ]}/>
                        <ContentCard
                            readOnly={isTrainer()}
                            header='Additional owners'
                            actionIcon={IconEdit}
                            actionOnClick={handleAdditionalOwnersModalOpened}
                            actionClassName='icon_card-edit'
                            items={customer.get('additional_owners').map((owner) => {
                                return (
                                    {
                                        key: `owner_${owner.get('id')}`,
                                        header: owner.get('name'),
                                        data: owner.get('relationship'),
                                        info: prettifyPhone(owner.get('phone_number')),
                                        infoUrl: `tel:${owner.get('phone_number')}`
                                    }
                                );
                            })}/>
                        <ContentCard
                            readOnly={isTrainer()}
                            header='Emergency Contact'
                            actionIcon={IconEdit}
                            actionOnClick={handleEmergencyContactModalOpened}
                            actionClassName='icon_card-edit'
                            items={customer.get('emergency_contacts').map((contact) => {
                                return (
                                    {
                                        key: `contact_${contact.get('id')}`,
                                        header: contact.get('name'),
                                        data: contact.get('relationship'),
                                        info: prettifyPhone(contact.get('phone_number')),
                                        infoUrl: contact.get('phone_number') === ('None' || undefined)
                                            ? null
                                            : `tel:${contact.get('phone_number')}`
                                    }
                                );
                            })}/>
                        <ContentCard
                            readOnly={isTrainer()}
                            header='Email/sms Notification'
                            items={[
                                {
                                    key: `sms_${customerId}`,
                                    data: 'Sms Notifications',
                                    info: customer.get('sms_notifications')
                                },
                                {
                                    key: `email-notif_${customerId}`,
                                    data: 'Email Notifications',
                                    info: customer.get('email_notifications')
                                }
                            ]}/>
                    </div>
                    <div className='details__column'>
                        <ContentCard
                            readOnly={isTrainer()}
                            header={`${customer.get('full_name').split(' ')[0]}'s dogs`}
                            actionIcon={IconAdd}
                            actionOnClick={() => {
                                handleDogCreationModalOpened({ isDogCreationOwnerPreselected: true });
                            }}
                            actionClassName='icon_card-add'
                            items={customer.getIn(['dogs', '0', 'name']) === 'None'
                                ? [
                                    {
                                        key: 'dog_none',
                                        data: customer.getIn(['dogs', '0', 'name'])
                                    }
                                ]
                                : customer.get('dogs').map((dog) => {
                                    const photo = dog.get('photo_processed') || dog.get('photo') || 'no-photo';
                                    return (
                                        {
                                            key: `dog_${dog.get('id')}`,
                                            img: photo,
                                            name: dog.get('name'),
                                            nameUrl: `/dashboard/dog/${dog.get('id')}/details`,
                                            breed: dog.get('breed_detail'),
                                            statusCode: dog.get('assessment_status'),
                                            status: dog.get('assessment_status_detail')
                                        }
                                    );
                                })
                            }/>
                        <ContentCard
                            readOnly={isTrainer()}
                            header='Pickup/Dropoff Locations'
                            actionIcon={IconEdit}
                            actionOnClick={areAddressesEditable(customer)
                                ? handlePDLocationsEditModalOpened
                                : undefined
                            }
                            actionClassName='icon_card-edit'
                            anotherActionIcon={IconAdd}
                            anotherActionOnClick={handlePDLocationsAddModalOpened}
                            anotherActionClassName='icon_card-add'
                            items={customer.get('normalized_available_addresses').size
                                ? customer.get('normalized_available_addresses').map((address) => {
                                    const availableAddress2 = address.get('address2') === null ? '' : ` ${address.get(
                                        'address2')}`;
                                    return {
                                        key: `available-address_${address.get('id')}`,
                                        header: address.get('name'),
                                        content: `${address.get('address1')}${availableAddress2}, ${address.get(
                                            'city')} ${address.get('state')} ${address.get('zip_code')}${address.get('location_notes') ? `, ${address.get('location_notes')}` : ''}`
                                    };
                                })
                                : [
                                    {
                                        key: 'available-address_none',
                                        data: 'None'
                                    }
                                ]
                            }/>
                        <ContentCard
                            readOnly={isTrainer()}
                            header='Payment Information'
                            anotherActionIcon={IconAdd}
                            anotherActionOnClick={handleAddCreditCardModalOpened}
                            anotherActionClassName='icon_card-add'
                            items={paymentCards.get('cards').size
                                ? (paymentCards.get('cards').map((card) => {
                                    return (
                                        {
                                            key: `payment-card_${card.get('id')}`,
                                            data: `${card.get('type')} ending in *${card.get('last4')} ${card.get('is_default') ? ' is Default Card' : ''}`,
                                            defaultCard: card.get('is_default') ? 'Default Card' : '',
                                            info: 'Remove Card',
                                            actionCB: () => { openPaymentCardDeleteModalDialog(card, customer); }
                                        }
                                    );
                                }))
                                : ([
                                    {
                                        key: 'payment-card_none',
                                        data: 'None'
                                    }
                                ])
                            }/>
                    </div>
                </div>)
            }
        </div>
    );
};

DetailsPage.propTypes = {
    customer: PropTypes.shape({}),
    paymentCards: PropTypes.shape({}),
    accessLevelOptions: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({})),
        PropTypes.shape({})
    ]),
    handleContactInfoModalOpened: PropTypes.func,
    handleAdditionalOwnersModalOpened: PropTypes.func,
    handleEmergencyContactModalOpened: PropTypes.func,
    handleDogCreationModalOpened: PropTypes.func,
    handlePDLocationsEditModalOpened: PropTypes.func,
    handlePDLocationsAddModalOpened: PropTypes.func,
    handleAddCreditCardModalOpened: PropTypes.func,
    openPaymentCardDeleteModalDialog: PropTypes.func,
    setMessagesWidgetOpened: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        customer: state.getIn(['currentCustomer', 'instance']),
        paymentCards: state.getIn(['currentCustomer', 'payment_cards']),
        accessLevelOptions: state.getIn(['misc', 'options', 'accessLevel']).toJS()
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleContactInfoModalOpened: bindActionCreators(MiscActions.setContactInfoModalOpened, dispatch),
    handleAdditionalOwnersModalOpened: bindActionCreators(MiscActions.setAdditionalOwnersModalOpened, dispatch),
    handleEmergencyContactModalOpened: bindActionCreators(MiscActions.setEmergencyContactModalOpened, dispatch),
    handleDogCreationModalOpened: bindActionCreators(MiscActions.setDogCreationModalOpened, dispatch),
    handlePDLocationsEditModalOpened: bindActionCreators(MiscActions.setPDLocationsEditModalOpened, dispatch),
    handlePDLocationsAddModalOpened: bindActionCreators(MiscActions.setPDLocationsAddModalOpened, dispatch),
    handleAddCreditCardModalOpened: bindActionCreators(MiscActions.setAddCreditCardModalOpened, dispatch),
    openPaymentCardDeleteModalDialog: bindActionCreators(ModalOpenActions.openPaymentCardDeleteModalDialog, dispatch),
    setMessagesWidgetOpened: bindActionCreators(MessagesActions.refreshMessagesWidget, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsPage);
