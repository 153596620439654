import React from "react";
import ReactToPrint from "react-to-print";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import classNames from 'classnames';
import TrainerAgreement from "./PDF_templates/TrainerAgreement/TrainerAgreement";

const tabStyle = {
    height: 500,
    overflow: "scroll",
};

class CustomerAgreementPage extends React.Component {
    static contextTypes = {
        router: () => null,
    };

    render() {
        const { customer, routeParams } = this.props;
        const data = customer.getIn(["forms", "items"]).toJS();
        const index = routeParams.agreement;
        const fiilteredAgreement = data.filter((item) => item.id === parseInt(index));
        const agreement = fiilteredAgreement[0];
        const containerButtonClass = classNames({
            'customer-agreement-page__container': true,
        });
        const wrapperPageClass = classNames({
            'customers-classes': true,

        });
        const backgroundPageClass = classNames({
            'customer-agreement-page__background':true,
        });
        const backButtonClass = classNames({
            'button form-edit-create-page__control-button button_dark': true,
        });
        const downloadButtonClass = classNames({
            'button form-edit-create-page__control-button': true,
        });
        return (
            <div className={wrapperPageClass}>

                {agreement === undefined ? (
                    "Loading..."
                ) : (<>
                    <div className='forms-table__header'>
                        <div className='forms-table__header-content'>
                            Agreement
                        </div>
                    </div>
                    <div className='customers-classes__content'>
                        <div className={backgroundPageClass}>
                        {" "}
                        <br />
                        <div className={containerButtonClass}>
                            <button
                                className={backButtonClass}
                                type="button"
                                onClick={this.context.router.goBack}
                            >
                                Go Back
                            </button>
                            <br />
                            <ReactToPrint
                                trigger={() => (
                                    <button
                                        className={downloadButtonClass}
                                        type="button"
                                    >
                                        Download agreement
                                    </button>
                                )}
                                content={() => this.componentRef}
                                bodyClass={"printElement1"}
                            />
                        </div>
                        <ComponentToPrint
                            agreement={agreement}
                            propsRef={(el) => (this.componentRef = el)}
                        />
                    </div>
                    </div>
                    </>
                )}
            </div>
        );
    }
}

class ComponentToPrint extends React.Component {
    render() {
        const { agreement } = this.props;

        return (
            <>
                <div style={tabStyle} >
                    <div ref={this.props.propsRef} >
                        <TrainerAgreement agreement={agreement}  />
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        customer: state.getIn(["currentCustomer"]),
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CustomerAgreementPage));
