import PropTypes from 'prop-types';
import React from 'react';
import Swiper from 'react-id-swiper';
import classNames from 'classnames';
import moment from 'moment';
import AvatarUploader from '../../../../../common/AvatarUploader/';

class DogsListSlider extends React.PureComponent {
    static propTypes = {
        dogs: PropTypes.arrayOf(PropTypes.shape({})),
        handleSlideChange: PropTypes.func,
        currentDogId: PropTypes.number
    };

    componentWillMount() {
        const { dogs, currentDogId } = this.props;
        this.initialSlide = 0;
        for (let i = 0; i < dogs.length; i += 1) {
            const dog = dogs[i];
            this.initialSlide = dog.id === currentDogId ? i : this.initialSlide;
        }
        window.addEventListener('load', () => {
            if (this.swiper) this.swiper.update(true);
        });
    }

    swiper = null;

    render() {
        const { dogs, handleSlideChange } = this.props;

        const params = {
            containerClass: 'dogs-list-slider',
            pagination: {},
            autoHeight: true,
            slidesPerView: 'auto',
            initialSlide: this.initialSlide,
            slideActiveClass: 'dogs-list-slider__item_active',
            centeredSlides: true,
            slideToClickedSlide: true,
            runCallbackOnInit: true,
            onInit: (swiper) => {
                this.swiper = swiper;
            },
        };

        return (
            <Swiper {...params}>
                {dogs.map((dog, index) => {
                    const dogClasses = classNames({
                        'dogs-list-slider__item': true,
                    });
                    const photo = dog.photo;
                    const age = moment().diff(dog.birthday, 'years');
                    let ageDetails = `${age} year${age === 1 ? '' : 's'} old`;
                    if (age === 0) ageDetails = 'less than a year';

                    return (
                        <div className={dogClasses} key={index} onClick={() => handleSlideChange(dog.id)} >
                            <AvatarUploader
                                photo={photo}
                                target='dog'
                                customerDogId={dog.id}
                                className='dogs-list-slider__photo'/>
                            <p className='dogs-list-slider__name'>{dog.name}</p>
                            <ul className='dogs-list-slider__info'>
                                <li className='dogs-list-slider__info-item'>
                                    {dog.breed_detail}
                                </li>
                                <li className='dogs-list-slider__info-item'>
                                    {dog.gender ? 'Female' : 'Male' }
                                </li>
                                <li className='dogs-list-slider__info-item'>
                                    {ageDetails}
                                </li>
                            </ul>
                        </div>
                    );
                })}
            </Swiper>
        );
    }

}

export default DogsListSlider;
